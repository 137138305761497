import React, { FunctionComponent } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Column,
  PageDetails,
  PageToolbar,
  Row,
  Section,
} from 'shared/components';
import { usePageTitle } from 'shared/hooks';
import { color, font } from 'shared/utils/styles';
import styled from 'styled-components';

const Dashboard: FunctionComponent = () => {
  usePageTitle('Home - Dashboard');
  const { orgName } = useParams<{ orgName: string}>();
  return (
    <React.Fragment>
      <PageDetails>
        <Row>
          <Column>
            <Section title={'Quick Access Links'}>
              <QuickAccessLinks>
                <QuickAccessLink to={`/${orgName}/goals`}>My Current Goals</QuickAccessLink>
                <QuickAccessLink to={`/${orgName}/evaluations`}>My Current Evaluation</QuickAccessLink>
                <QuickAccessLink to={`/${orgName}/directory/direct-reports`}>My Team Members</QuickAccessLink>
              </QuickAccessLinks>
            </Section>
          </Column>
          {/* <Column span={6}>
            <Section title={'Action Items'}>None</Section>
          </Column>
          <Column>
            <Section title={'Directory - My Team Members'}>None</Section>
          </Column> */}
        </Row>
      </PageDetails>
    </React.Fragment>
  );
};

export default Dashboard;

const QuickAccessLinks = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`

const QuickAccessLink = styled(Link)`
  ${font.regular};
  color: ${color.primary};

  &:hover {
    text-decoration: underline;
  }
`
