import { parseISO, formatDistanceToNow } from 'date-fns';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { Maybe } from 'graphql/jsutils/Maybe';
import { monthDayYearFormat, monthDayYearTimeFormat } from '../dateFormats';

export const formatDecimal = (value: number | null | undefined, precision: Maybe<number>) => {
  if (value != null && value != undefined) {
    return value.toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: precision != null && precision != undefined ? precision : 2,
      minimumFractionDigits: precision != null && precision != undefined ? precision : 0,
    });
  }
};

export const formatCurrency = (value: number | null | undefined, precision: Maybe<number>) => {
  if (value != null && value != undefined) {
    const val = Math.abs(value);
    const rounded = Number(val.toFixed(2))
    const formatted = rounded.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: precision != null && precision != undefined ? precision : 2,
      minimumFractionDigits: Number.isInteger(rounded) && (precision == null || precision == undefined) ? 0 : precision != null && precision != undefined ? precision : 2,
    });
    return value < 0 ? `(${formatted})`: formatted; 
  }
};

export const formatPercentage = (value: number | null | undefined, precision: Maybe<number>) => {
  if (value != null && value != undefined) {
    return value.toLocaleString(undefined, {
      style: 'percent',
      maximumFractionDigits: precision != null && precision != undefined ? precision : 2,
      minimumFractionDigits: precision != null && precision != undefined ? precision : 0,
    });
  }
};

export const formatDate = (value: string | Date | null | undefined, formatStr: string = monthDayYearFormat, dateStr?: string) => {
  if (value != null && value != undefined) {
    if (dateStr) {
      const dateToFormat = value instanceof Date ? value : parse(value, dateStr, new Date());
      return format(dateToFormat, formatStr);
    } else {
      const dateToFormat = value instanceof Date ? value : parseISO(value);
      return format(dateToFormat, formatStr);
    }
  }
};

export const formatDatetime = (value: string | Date | null | undefined ) => {
  if (value != null && value != undefined) {
    const dateToFormat = value instanceof Date ? value : parseISO(value);
    return format(dateToFormat, monthDayYearTimeFormat)
  }
};

export const formatRelativeDate = (value: string | Date | null | undefined ) => {
  if (value != null && value != undefined) {
    const dateToFormat = value instanceof Date ? value : parseISO(value);
    const relativeDate = formatDistanceToNow(dateToFormat, {
      addSuffix: true,
    });
    return relativeDate;
  }
};