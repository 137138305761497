// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent, MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import FieldWrapper, { FieldWrapperProps } from "..";
import { useField } from "formik";
import ReactSelect, { components, Props as ReactSelectProps, Theme } from 'react-select';
import ReactSelectAsync, { Props as ReactSelectAsyncProps } from 'react-select/async';
import ReactSelectCreatable, { Props as ReactSelectCreatableProps } from 'react-select/creatable';
import ReactSelectCreatableAsync, { Props as ReactSelectCreatableAsyncProps } from 'react-select/async-creatable';
import { color, mixin } from "shared/utils/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectOption } from "./SelectOption";

const IconOption = (props: any) => {
  return (
    <components.Option {...props}>
      <div style={{ display: 'inline-flex', alignItems: 'center'}}>
        {props.data.icon ?
          <React.Fragment>
            <FontAwesomeIcon icon={['fal', props.data.icon]} fixedWidth={true} color={color.textLight}/>
            <div style={{ marginLeft: '10px'}}>{props.data.label}</div>
          </React.Fragment>
          :
          props.data.label
        }
      </div>
    </components.Option>
  );
};

const IconSingleValue = (props: any) => (
  <components.SingleValue {...props}>
    <div style={{ display: 'inline-flex', alignItems: 'center'}}>
      {props.data.icon ?
        <React.Fragment>
          <FontAwesomeIcon icon={['fal', props.data.icon]} fixedWidth={true} color={props.data.color}/>
          <div style={{ marginLeft: '10px'}}>{props.data.label}</div>
        </React.Fragment>
        :
        props.data.label
      }
    </div>
  </components.SingleValue>
);

export const customSelectStyles = {
  menuPortal: (base: any) => ({ ...base, zIndex: 100000 }),
  container: (provided: any) => ({
    ...provided,
    height: '30px',
    minHeight: '30px',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: '30px',
    minHeight: '30px',
    fontSize: '10px'
  }),
  control: (provided: any, state: { isFocused: boolean }) => {
    return {
      ...provided,
      height: '30px',
      minHeight: '30px',
      border: state.isFocused ? `1px solid ${color.border}` : `1px solid ${color.border}`,
      borderColor: state.isFocused ? color.primary : color.border,
    }
  }
}

export const defaultSelectProps = {
  components: {Option: IconOption, SingleValue: IconSingleValue},
  styles: customSelectStyles,
  isClearable: false,
  options: [],
  placeholder: "Select a value...",
  noOptionsMessage: () => "No Options",
  theme: (theme: Theme) => ({
    ...theme,
    borderRadius: 3,
    colors: {
      ...theme.colors,
      primary: color.primary,
      primary75: mixin.lighten(color.primary, 0.25),
      primary50: mixin.lighten(color.primary, 0.50),
      primary25: mixin.lighten(color.primary, 0.75),
      danger: color.danger,
    }
  }),
  menuPortalTarget: document.body,
  isMulti: false,
}

interface SelectProps extends ReactSelectProps<SelectOption> {
  loadOptions?: () => void;
}

const Select: FunctionComponent<SelectProps> = ({
  loadOptions,
  ...props 
}) => {
  const [shouldLoadOptions, setShouldLoadOptions] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const timeout = useRef<number>();

  const onFocus = useCallback(async (event: React.FocusEvent<HTMLElement>) => {
    if (props.onFocus) props.onFocus(event);
    if (loadOptions && shouldLoadOptions) {
      setIsLoading(true);
      try {
        await loadOptions();
      } finally {
        setIsLoading(false);
        setShouldLoadOptions(false);
        timeout.current = setTimeout(() => setShouldLoadOptions(true), 10000)
      }
    }
  }, [loadOptions, shouldLoadOptions, setShouldLoadOptions, timeout, props.onFocus]);

  return (
    <ReactSelect
      style={{ padding: '0'}}
      {...defaultSelectProps}
      {...props}
      isLoading={isLoading}
      onFocus={onFocus}
    />
  );
};

export default Select;

export const SelectAsync: FunctionComponent<ReactSelectAsyncProps<SelectOption>> = ({
  ...props
}) => {

  return (
    <ReactSelectAsync
      {...defaultSelectProps}
      {...props}
    />
  );
};

export const SelectCreatable: FunctionComponent<ReactSelectCreatableProps<SelectOption>> = ({
  ...props
}) => {

  return (
    <ReactSelectCreatable
      {...defaultSelectProps}
      {...props}
    />
  );
};

export const SelectCreatableAsync: FunctionComponent<ReactSelectCreatableAsyncProps<SelectOption>> = ({
  ...props
}) => {

  return (
    <ReactSelectCreatableAsync
      {...defaultSelectProps}
      {...props}
    />
  );
};

interface SelectFieldProps extends SelectProps, FieldWrapperProps {
  name: string;
}

export const SelectField: FunctionComponent<SelectFieldProps> = ({
  label,
  error,
  ...props
}) => {
  return (
    <FieldWrapper label={label} error={error}>
      <Select {...props} />
    </FieldWrapper>
  );
};

export const SelectFormikField: FunctionComponent<SelectFieldProps> = ({
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  return (
    <SelectField error={meta.error} {...props} {...field} onChange={(opt) => setValue(opt)} value={field.value} />
  );
};

interface SelectAsyncFieldProps extends ReactSelectAsyncProps<SelectOption>, FieldWrapperProps {
  name: string;
}

export const SelectAsyncField: FunctionComponent<SelectAsyncFieldProps> = ({
  label,
  error,
  ...props
}) => {
  return (
    <FieldWrapper label={label} error={error}>
      <SelectAsync {...props} />
    </FieldWrapper>
  );
};

export const SelectAsyncFormikField: FunctionComponent<SelectAsyncFieldProps> = ({
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  return (
    <SelectAsyncField error={meta.error} {...props} {...field} onChange={(opt) => setValue(opt)} value={field.value} />
  );
};

interface SelectCreatableFieldProps extends ReactSelectCreatableProps<SelectOption>, FieldWrapperProps {
  name: string;
}

export const SelectCreatableField: FunctionComponent<SelectCreatableFieldProps> = ({
  label,
  error,
  ...props
}) => {
  return (
    <FieldWrapper label={label} error={error}>
      <SelectCreatable {...props} />
    </FieldWrapper>
  );
};

export const SelectCreatableFormikField: FunctionComponent<SelectCreatableFieldProps> = ({
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  return (
    <SelectCreatableField error={meta.error} {...props} {...field} onChange={(opt) => setValue(opt)} value={field.value} />
  );
};

interface SelectCreatableAsyncFieldProps extends ReactSelectCreatableAsyncProps<SelectOption>, FieldWrapperProps {
  name: string;
}

export const SelectCreatableAsyncField: FunctionComponent<SelectCreatableAsyncFieldProps> = ({
  label,
  error,
  ...props
}) => {
  return (
    <FieldWrapper label={label} error={error}>
      <SelectCreatableAsync {...props} />
    </FieldWrapper>
  );
};

export const SelectCreatableAsyncFormikField: FunctionComponent<SelectCreatableAsyncFieldProps> = ({
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  return (
    <SelectCreatableAsyncField error={meta.error} {...props} {...field} onChange={(opt) => setValue(opt)} value={field.value} />
  );
};

