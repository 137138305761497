import { useMutation, queryCache } from 'react-query';
import { number } from 'yup';
import { GraphQLResult } from '..';
import * as mutations from '../client/mutations';
import {
  AddUsersToEmployeeRoleContributorsVariables,
  CreateEmployeeRoleInput,
  CreateEvaluationInput,
  CreateGoalInput,
  CreatePeerRequestInput,
  CreateStrengthOrOpportunityInput,
  CreateUserInput,
  GetEvaluationSummary,
  GetManagerEvaluation,
  GetPeerRequest,
  GetSelfEvaluation,
  ReviewClassificationEnum,
  UpdateEmployeeRoleInput,
  UpdateGoalInput,
  UpdateReviewValueInput,
  UpdateStrengthOrOpportunityInput,
  UpdateUserInput,
} from '../graphql/generated';

export const useCreateEvaluation = (config?: any) => {
  return useMutation(
    (evaluation: CreateEvaluationInput) => {
      return mutations.createEvaluation(evaluation);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['userGoals']);
      },
      ...config,
    }
  );
};

export const useCreateGoal = (config?: any) => {
  return useMutation(
    (goal: CreateGoalInput) => {
      return mutations.createGoal(goal);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['evaluation', variables.evaluationId]);
        queryCache.refetchQueries(['evaluationSummary', variables.evaluationId]);
      },
      ...config,
    }
  );
};

export const useUpdateGoal = (evaluationId: number, config?: any) => {
  return useMutation(
    (goal: UpdateGoalInput) => {
      return mutations.updateGoal(goal);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['goal', variables.id]);
        queryCache.refetchQueries(['evaluation', evaluationId]);
        queryCache.refetchQueries(['evaluationSummary', evaluationId]);
        queryCache.refetchQueries(['managerEvaluation', evaluationId]);
        queryCache.refetchQueries(['selfEvaluation', evaluationId]);
      },
      ...config,
    }
  );
};

export const useDeleteGoal = (
  evaluationId: number,
  parentId?: number,
  config?: any
) => {
  return useMutation(
    (id: number) => {
      return mutations.deleteGoal(id);
    },
    {
      // onMutate: (id) => {
      //   queryCache.cancelQueries(['evaluation', evaluationId]);
      //   queryCache.setQueryData(['evaluation', evaluationId], (old?: GraphQLResult<GetEvaluation>) => {
      //     if (old?.data?.evaluation?.goals) {
      //       const goalIndexToDelete = old.data.evaluation.goals.findIndex(x => x.id == id);
      //       if (goalIndexToDelete != -1) {
      //         const oldCopy = old;
      //         oldCopy.data?.evaluation?.goals?.splice(goalIndexToDelete, 1);
      //         return oldCopy;
      //       }
      //     }
      //     return old;
      //   })
      // },
      onSettled: () => {
        queryCache.refetchQueries(['evaluation', evaluationId]);
        queryCache.refetchQueries(['evaluationSummary', evaluationId]);
      },
      ...config,
    }
  );
};

export const useCreateUser = (currentUserId: number, config?: any) => {
  return useMutation(
    (user: CreateUserInput) => {
      return mutations.createUser(user);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['directory', currentUserId]);
      },
      ...config,
    }
  );
};

export const useUpdateUser = (currentUserId: number, config?: any) => {
  return useMutation(
    (user: UpdateUserInput) => {
      return mutations.updateUser(user);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['directory', currentUserId]);
        queryCache.refetchQueries(['directoryUser', variables.id]);
      },
      ...config,
    }
  );
};

export const useDeleteUser = (currentUserId: number, config?: any) => {
  return useMutation(
    (id: number) => {
      return mutations.deleteUser(id);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['directory', currentUserId]);
      },
      ...config,
    }
  );
};

export const useCreateSelfEvaluation = (evaluationId: number, config?: any) => {
  return useMutation(
    (id: number) => {
      return mutations.createSelfEvaluation(id);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['evaluation', evaluationId]);
        queryCache.refetchQueries(['evaluationSummary', evaluationId]);
        queryCache.refetchQueries(['selfEvaluation', variables]);
      },
      ...config,
    }
  );
};

export const useCreateManagerEvaluation = (evaluationId: number, config?: any) => {
  return useMutation(
    (id: number) => {
      return mutations.createManagerEvaluation(id);
    },
    {
      onSuccess: (data, variables) => {
        queryCache.refetchQueries(['evaluation', evaluationId]);
        queryCache.refetchQueries(['evaluationSummary', evaluationId]);
        queryCache.refetchQueries(['managerEvaluation', variables]);
      },
      ...config,
    }
  );
};

export const useCreateReviewOpportunity = (evaluationId: number, peerRequestId?: number, config?: any) => {
  return useMutation(
    (input: CreateStrengthOrOpportunityInput) => {
      return mutations.createReviewOpportunity(input);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['selfEvaluation', evaluationId]);
        queryCache.refetchQueries(['managerEvaluation', evaluationId]);
        if (peerRequestId) {
          queryCache.refetchQueries(['peerRequest', peerRequestId]);
        }
      },
      ...config,
    }
  );
};

export const useUpdateReviewOpportunity = (evaluationId: number, peerRequestId?: number, config?: any) => {
  return useMutation(
    (input: UpdateStrengthOrOpportunityInput) => {
      return mutations.updateReviewOpportunity(input);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['selfEvaluation', evaluationId]);
        queryCache.refetchQueries(['managerEvaluation', evaluationId]);
        if (peerRequestId) {
          queryCache.refetchQueries(['peerRequest', peerRequestId]);
        }
      },
      ...config,
    }
  );
};

export const useDeleteReviewOpportunity = (evaluationId: number, peerRequestId?: number, config?: any) => {
  return useMutation(
    ({ id, reviewClassification }: {id: number; reviewClassification: ReviewClassificationEnum }) => {
      return mutations.deleteReviewOpportunity(id, reviewClassification);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['selfEvaluation', evaluationId]);
        queryCache.refetchQueries(['managerEvaluation', evaluationId]);
        if (peerRequestId) {
          queryCache.refetchQueries(['peerRequest', peerRequestId]);
        }
      },
      ...config,
    }
  );
};

export const useCreateReviewStrength = (evaluationId: number, peerRequestId?: number, config?: any) => {
  return useMutation(
    (input: CreateStrengthOrOpportunityInput) => {
      return mutations.createReviewStrength(input);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['selfEvaluation', evaluationId]);
        queryCache.refetchQueries(['managerEvaluation', evaluationId]);
        if (peerRequestId) {
          queryCache.refetchQueries(['peerRequest', peerRequestId]);
        }
      },
      ...config,
    }
  );
};

export const useUpdateReviewStrength = (evaluationId: number, peerRequestId?: number, config?: any) => {
  return useMutation(
    (input: UpdateStrengthOrOpportunityInput) => {
      return mutations.updateReviewStrength(input);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['selfEvaluation', evaluationId]);
        queryCache.refetchQueries(['managerEvaluation', evaluationId]);
        if (peerRequestId) {
          queryCache.refetchQueries(['peerRequest', peerRequestId]);
        }
      },
      ...config,
    }
  );
};

export const useDeleteReviewStrength = (evaluationId: number, peerRequestId?: number, config?: any) => {
  return useMutation(
    ({ id, reviewClassification }: {id: number; reviewClassification: ReviewClassificationEnum }) => {
      return mutations.deleteReviewStrength(id, reviewClassification);
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries(['selfEvaluation', evaluationId]);
        queryCache.refetchQueries(['managerEvaluation', evaluationId]);
        if (peerRequestId) {
          queryCache.refetchQueries(['peerRequest', peerRequestId]);
        }
      },
      // onMutate: (variables) => {
      //   const selfEvaluationSnapshot = queryCache.getQueryData(['selfEvaluation', evaluationId]) as GraphQLResult<GetSelfEvaluation>;
      //   if (selfEvaluationSnapshot.data?.evaluation) {
      //     let evaluation = selfEvaluationSnapshot.data.evaluation;
      //   }

      //   const managerEvaluationSnapshot = queryCache.getQueryData(['managerEvaluation', evaluationId]) as GraphQLResult<GetManagerEvaluation>;
      //   if (peerRequestId) {
      //     const peerRequestSnapshot = queryCache.getQueryData(['peerRequest', peerRequestId]) as GraphQLResult<GetPeerRequest>;
      //   }

      //   return { selfEvaluationSnapshot, managerEvaluationSnapshot };

      // },
      ...config,
    }
  );
};

export const useUpdateReviewValue = (evaluationId: number, config?: any) => {
  return useMutation(
    (value: UpdateReviewValueInput) => {
      return mutations.updateReviewValue(value);
    },
    {
      onSuccess: () => {
        // queryCache.refetchQueries(['valueReview', reviewId]);
        queryCache.refetchQueries(['evaluation', evaluationId]);
        queryCache.refetchQueries(['evaluationSummary', evaluationId]);
        queryCache.refetchQueries(['managerEvaluation', evaluationId]);
        queryCache.refetchQueries(['selfEvaluation', evaluationId]);
      },
      ...config,
    }
  );
};

export const useSubmitEvaluationReview = (evaluationId: number, config?: any) => {
  return useMutation(
    (id: number) => {
      return mutations.submitEvaluationReview(id);
    },
    {
      onSuccess: () => {
        // queryCache.refetchQueries(['valueReview', reviewId]);
        queryCache.refetchQueries(['evaluation', evaluationId]);
        queryCache.refetchQueries(['evaluationSummary', evaluationId]);
        queryCache.refetchQueries(['managerEvaluation', evaluationId]);
        queryCache.refetchQueries(['selfEvaluation', evaluationId]);
      },
      ...config,
    }
  );
};

export const useUnsubmitEvaluationReview = (evaluationId: number, config?: any) => {
  return useMutation(
    (id: number) => {
      return mutations.unsubmitEvaluationReview(id);
    },
    {
      onSuccess: () => {
        // queryCache.refetchQueries(['valueReview', reviewId]);
        queryCache.refetchQueries(['evaluation', evaluationId]);
        queryCache.refetchQueries(['evaluationSummary', evaluationId]);
        queryCache.refetchQueries(['managerEvaluation', evaluationId]);
        queryCache.refetchQueries(['selfEvaluation', evaluationId]);
      },
      ...config,
    }
  );
};

export const useSubmitValueReview = (evaluationId: number, config?: any) => {
  return useMutation(
    (id: number) => {
      return mutations.submitValueReview(id);
    },
    {
      onSuccess: () => {
        // queryCache.refetchQueries(['valueReview', reviewId]);
        queryCache.refetchQueries(['evaluation', evaluationId]);
        queryCache.refetchQueries(['evaluationSummary', evaluationId]);
        queryCache.refetchQueries(['managerEvaluation', evaluationId]);
        queryCache.refetchQueries(['selfEvaluation', evaluationId]);
      },
      ...config,
    }
  );
};

export const useUnsubmitValueReview = (evaluationId: number, config?: any) => {
  return useMutation(
    (id: number) => {
      return mutations.unsubmitValueReview(id);
    },
    {
      onSuccess: () => {
        // queryCache.refetchQueries(['valueReview', reviewId]);
        queryCache.refetchQueries(['evaluation', evaluationId]);
        queryCache.refetchQueries(['evaluationSummary', evaluationId]);
        queryCache.refetchQueries(['managerEvaluation', evaluationId]);
        queryCache.refetchQueries(['selfEvaluation', evaluationId]);
      },
      ...config,
    }
  );
};

export const useSubmitObjectiveReview = (evaluationId: number, config?: any) => {
  return useMutation(
    (id: number) => {
      return mutations.submitObjectiveReview(id);
    },
    {
      onSuccess: () => {
        // queryCache.refetchQueries(['valueReview', reviewId]);
        queryCache.refetchQueries(['evaluation', evaluationId]);
        queryCache.refetchQueries(['evaluationSummary', evaluationId]);
        queryCache.refetchQueries(['managerEvaluation', evaluationId]);
        queryCache.refetchQueries(['selfEvaluation', evaluationId]);
      },
      ...config,
    }
  );
};

export const useCreatePeerRequest = (evaluationId: number, config?: any) => {
  return useMutation((request: CreatePeerRequestInput) => {
    return mutations.createPeerRequest(request);
  },
  {
    onSuccess: () => {
      queryCache.refetchQueries(['evaluation', evaluationId]);
      queryCache.refetchQueries(['evaluationSummary', evaluationId]);
    },
    ...config,
  })
}

export const useDeletePeerRequest = (evaluationId: number, config?: any) => {
  return useMutation((id: number) => {
    return mutations.deletePeerRequest(id);
  },
  {
    onSuccess: () => {
      queryCache.refetchQueries(['evaluation', evaluationId]);
      queryCache.refetchQueries(['evaluationSummary', evaluationId]);
    },
    ...config,
  })
}

export const useAcceptPeerRequest = (evaluationId: number, config?: any) => {
  return useMutation((id: number) => {
    return mutations.acceptPeerRequest(id);
  },
  {
    onSuccess: () => {
      queryCache.refetchQueries(['evaluation', evaluationId]);
      queryCache.refetchQueries(['evaluationSummary', evaluationId]);
    },
    ...config,
  })
}

export const useRejectPeerRequest = (evaluationId: number, config?: any) => {
  return useMutation(({ id, reasonId }: { id: number; reasonId: number }) => {
    return mutations.rejectPeerRequest(id, reasonId);
  },
  {
    onSuccess: () => {
      queryCache.refetchQueries(['evaluation', evaluationId]);
      queryCache.refetchQueries(['evaluationSummary', evaluationId]);
    },
    ...config,
  })
}

export const useCancelPeerRequest = (evaluationId: number, config?: any) => {
  return useMutation(({ id, reasonId }: { id: number; reasonId: number }) => {
    return mutations.cancelPeerRequest(id, reasonId);
  },
  {
    onSuccess: () => {
      queryCache.refetchQueries(['evaluation', evaluationId]);
      queryCache.refetchQueries(['evaluationSummary', evaluationId]);
    },
    ...config,
  })
}

export const useSubmitEvaluationForReview = (evaluationId: number, config?: any) => {
  return useMutation((id: number) => {
    return mutations.submitEvaluationForReview(id);
  },
  {
    onSuccess: () => {
      queryCache.refetchQueries(['evaluation', evaluationId]);
      queryCache.refetchQueries(['evaluationSummary', evaluationId]);
    },
    ...config,
  })
}

// export const useSubmitEvaluationForReview = (evaluationId: number, config?: any) => {
//   return useMutation(
//     (id: number) => {
//       return mutations.submitEvaluationForReview(id);
//     },
//     {
//       onMutate: (variables, data) => {
//         const snapshot = queryCache.getQueryData([
//           'evaluationSummary', 
//           evaluationId
//         ]);
//         queryCache.setQueryData(
//           ['evaluationSummary', evaluationId],
//           (old?: GraphQLResult<GetEvaluationSummary>) => {
//             if (old && old?.data?.evaluation?.managerEvaluation?.status) {
//               console.log('snapshot', snapshot)
//               console.log('data', data)
//               console.log('variables', variables)
//               const updated = { ...old };
//               if (updated.data?.evaluation?.managerEvaluation) {
//                 console.log('updating')
//                 updated.data.evaluation.managerEvaluation.status = {
//                   __typename: 'EvaluationStatus',
//                   id: 3,
//                   name: 'Complete',
//                 };
//               }
              
//               console.log('Updated', updated)
//               return updated;
//             } else {
//               console.log('returned old')
//               return old;
//             }
//           }
//         );
//         return snapshot;
//       },
//       onSuccess: (data, variables) => {   
//         queryCache.refetchQueries(['evaluation', evaluationId]);
//         queryCache.refetchQueries(['evaluationSummary', evaluationId]);
//       },
//       ...config,
//     }
//   );
// };







export const useUnsubmitEvaluationFromReview = (evaluationId: number, config?: any) => {
  return useMutation((id: number) => {
    return mutations.unsubmitEvaluationFromReview (id);
  },
  {
    onSuccess: () => {
      queryCache.refetchQueries(['evaluation', evaluationId]);
      queryCache.refetchQueries(['evaluationSummary', evaluationId]);
    },
    ...config,
  })
}

export const usePublishEvaluationForEmployeeReview = (evaluationId: number, config?: any) => {
  return useMutation((id: number) => {
    return mutations.publishEvaluationForEmployeeReview(id);
  },
  {
    onSuccess: () => {
      queryCache.refetchQueries(['evaluation', evaluationId]);
      queryCache.refetchQueries(['evaluationSummary', evaluationId]);
    },
    ...config,
  })
}

export const useRequestEvaluationChanges = (evaluationId: number, config?: any) => {
  return useMutation(({ id, requestedChanges }: { id: number; requestedChanges: string }) => {
    return mutations.requestEvaluationChanges(id, requestedChanges);
  },
  {
    onSuccess: () => {
      queryCache.refetchQueries(['evaluation', evaluationId]);
      queryCache.refetchQueries(['evaluationSummary', evaluationId]);
    },
    ...config,
  })
}

export const useApproveEvaluation = (evaluationId: number, config?: any) => {
  return useMutation((id: number) => {
    return mutations.approveEvaluation(id);
  },
  {
    onSuccess: () => {
      queryCache.refetchQueries(['evaluation', evaluationId]);
      queryCache.refetchQueries(['evaluationSummary', evaluationId]);
    },
    ...config,
  })
}

export const useSignEvaluation = (evaluationId: number, config?: any) => {
  return useMutation((id: number) => {
    return mutations.signEvaluation(id);
  },
  {
    onSuccess: () => {
      queryCache.refetchQueries(['evaluation', evaluationId]);
      queryCache.refetchQueries(['evaluationSummary', evaluationId]);
    },
    ...config,
  })
}

export const useCreateEmployeeRole = (config?: any) => {
  return useMutation((input: CreateEmployeeRoleInput) => {
    return mutations.createEmployeeRole(input);
  },
  {
    onSuccess: () => {
      queryCache.refetchQueries(['employeeRoles']);
      queryCache.refetchQueries(['editableEmployeeRoles']);
    },
    ...config,
  })
}

export const useUpdateEmployeeRole = (config?: any) => {
  return useMutation((input: UpdateEmployeeRoleInput) => {
    return mutations.updateEmployeeRole(input);
  },
  {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['employeeRoles']);
      queryCache.refetchQueries(['editableEmployeeRoles']);
      queryCache.refetchQueries(['employeeRoles', variables.id]);
    },
    ...config,
  })
}

export const useMakeUserAdmin = (currentUserId: number, config?: any) => {
  return useMutation((input: { userId: number; groupId: number }) => {
    return mutations.addUsersToGroup(input.userId, input.groupId);
  },
  {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['directory', currentUserId]);
    },
    ...config,
  })
}

export const useAddUsersToEmployeeRoleContributors = (config?: any) => {
  return useMutation((input: { employeeRoleId: number; userIds: number[] }) => {
    return mutations.addUsersToEmployeeRoleContributors(input.employeeRoleId, input.userIds);
  },
  {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['employeeRoles']);
      queryCache.refetchQueries(['editableEmployeeRoles']);
      queryCache.refetchQueries(['employeeRoles', variables.employeeRoleId]);
    },
    ...config,
  })
}

export const useRemoveUsersFromEmployeeRoleContributors = (config?: any) => {
  return useMutation((input: { employeeRoleId: number; userIds: number[] }) => {
    return mutations.removeUsersFromEmployeeRoleContributors(input.employeeRoleId, input.userIds);
  },
  {
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(['employeeRoles']);
      queryCache.refetchQueries(['editableEmployeeRoles']);
      queryCache.refetchQueries(['employeeRoles', variables.employeeRoleId]);
    },
    ...config,
  })
}


