import { useAppContext } from 'App';
import GoalsList from 'App/Organization/shared/Goals/GoalsList';
import React, { FunctionComponent } from 'react';
import { usePageTitle } from 'shared/hooks';

interface CurrentGoalsProps {
  onSetNewGoals: () => void;
}

const Goals: FunctionComponent<CurrentGoalsProps> = ({ onSetNewGoals }) => {
  usePageTitle('Home - Goals');
  const appContext = useAppContext();
  return (
    <React.Fragment>
      <GoalsList
        pageTitle={'Home'}
        employeeId={appContext.currentUserId}
        onSetNewGoals={onSetNewGoals}
      />
    </React.Fragment>
  );
};

export default Goals;
