import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  .ReactModal__Overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 300ms ease-in-out;
    z-index: 1001;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    overflow: hidden;
    padding: 40px;
    height: 100%;
  }

  .SideMenu__Overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    opacity: 0;
    display: flex;
    background-color: rgba(0, 0, 0, 0.2);
    overflow: hidden;
    height: 100%;
    padding: 0;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1; 
  }

  .ReactModal__Overlay--before-close {
    opacity: 0; 
  }

  .ReactModal__Content {
    transition: all 500ms ease-in-out;
    transform: translateY(30px);
    opacity: 0;
    margin: auto;
    border-radius: 3px;
    padding: 0;
    height: 100%;
    overflow: hidden;
    width: 100%;
  }

  .SideMenu__Content {
    position: absolute;
    right: 0;
    opacity: 0;
    margin-left: auto;
    border-radius: 3px;
    padding: 0;
    height: 100%;
    overflow: hidden;
  }

  .ReactModal__Content--after-open {
    opacity: 1;
    // transform: translateY(0);
  }

  .ReactModal__Content--before-close {
    opacity: 0;
    transform: translateY(30px); 
  }

  .ReactModal__Overlay--after-open {
    justify-content: center;
  }

  .ReactModal__Content--after-open {
    min-height: 1px;
  }

  .ReactModal__Content--after-open:focus {
    outline: none;
  }

  .ReactModal__Content--after-open.SideMenu__Content{
  }
`;
