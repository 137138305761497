import { AgGridColumn } from 'ag-grid-react';
import React, { useMemo, useState } from 'react';
import { PageHeader, PageDetails, Table, SelectField } from 'shared/components';
import NoAccess from 'shared/components/noAccess';
import { useRemainingHeight, useTable } from 'shared/hooks';
import { useGetEvaluationCycles } from 'shared/hooks/api';
import EmptyDataMessage from '../shared/NoData';
import EvaluationsSummaryTable from './EvaluationsSummaryTable';

type Option = {
  value: number;
  label: string;
};

const PerformanceReview = () => {
  const [
    selectedEvaluationCycle,
    setSelectedEvaluationCycle,
  ] = useState<Option | null>(null);
  const {
    data: evaluationCycleData,
    status: getEvaulationCycleStatus,
  } = useGetEvaluationCycles();

  const evaluationCycleOptions: Option[] = useMemo(() => {
    return (
      evaluationCycleData?.evaluationsCycles?.map((x) => ({
        value: x.id,
        label: x.name,
      })) ?? []
    );
  }, [evaluationCycleData]);

  return (
    <React.Fragment>
      <PageHeader
        title={'Employee Reviews'}
        subtitle={'A list of employees and a summary of their evaluation status for a given evalution cycle.'}
        tools={[
          <div key={'evaluation-cycle'} style={{ width: '300px' }}>
            <SelectField
              labelOnRight={true}
              name={'evaluationCycle'}
              label={'Evaluation Cycle'}
              placeholder={'Select an evaluation cycle'}
              value={selectedEvaluationCycle}
              onChange={(val) => {
                if (val) {
                  setSelectedEvaluationCycle(val as Option);
                }
              }}
              options={evaluationCycleOptions}
            />
          </div>,
        ]}
      />
      {selectedEvaluationCycle ? (
        <EvaluationsSummaryTable
          evaluationCycleId={selectedEvaluationCycle.value}
        />
      ) : (
        <NoAccess
          title={'No Evaluation Cycle Selected'}
          subtitle={
            'Select an evaluation cycle using the dropdown above to view a summary of employee evaluations for that review cycle.'
          }
          icon={'comment-alt-exclamation'}
        />
      )}
    </React.Fragment>
  );
};

export default PerformanceReview;
