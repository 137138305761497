import * as React from 'react';
import toaster from './Toaster';
import Position from './positions';
import { createGlobalStyle } from 'styled-components';
import Alert, { AlertVariant } from '../alert/Alert';

const ToastStyles = createGlobalStyle`
  .Toaster__message-wrapper {
    padding: 8px;
    text-align: left;
  }
`;

interface RenderArgs {
  id: string;
  onClose: () => void;
}

export interface Toast {
  position?: keyof typeof Position;
  duration?: number | null;
  title?: string;
  message?: React.ReactNode;
  variant?: AlertVariant;
  render?: (options: RenderArgs) => React.ReactNode;
}

export const useNotification = () => {
  const notify = ({
    position = "top",
    duration = 3000,
    render,
    title,
    message,
    variant = "success",
  }: Toast) => {
    const options = {
      position,
      duration,
    };

    if (render) {
      return toaster.notify(
        ({ onClose, id }) => (
          <React.Fragment>
            <ToastStyles />
            {render({ onClose, id })}
          </React.Fragment>
        ),
        options
      );
    }

    toaster.notify(
      ({ onClose, id }) => (
        <React.Fragment>
          <ToastStyles />
          <div style={{ width: '350px' }}>
            <Alert
              title={title}
              message={message}
              variant={variant}
              onClose={onClose}
            />
          </div>
        </React.Fragment>
      ),
      options
    );
  };

  return notify;
};
