/* eslint-disable react/display-name */
import React, { FunctionComponent, forwardRef, FC } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Input, InputProps } from '../input/Input';
import '../../../css/react-datepicker.css';
import styled from 'styled-components';
import { color, font } from 'shared/utils/styles';
import format from 'date-fns/format';
import { Button } from 'shared/components';
import FieldWrapper, { FieldWrapperProps } from '..';
import { useField } from 'formik';
import ReactDOM from 'react-dom';

type OverridenDatePickerProps = Omit<ReactDatePickerProps, 'onChange'> & {
  onChange?: (
    date: Date | [Date, Date] | null,
    event: React.SyntheticEvent<any, Event> | undefined
  ) => void;
};

interface DatePickerProps extends OverridenDatePickerProps {
  placeholder?: string;
  inputProps?: InputProps;
}

const HeaderWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const HeaderDateText = styled.div`
  color: ${color.textDarkest};
  text-transform: uppercase;
  ${font.size(12)};
  ${font.medium};
`;

const el = document.body;

type DatePickerPopperContainerProps = {
  children: React.ReactNode;
};

const getPopperContainer = () => {
  const el = document.querySelector('body');
  return el != null ? el : document.body;
};

const DatePickerPopperContainer: FC<DatePickerPopperContainerProps> = ({
  children,
}) => ReactDOM.createPortal(children, getPopperContainer());

const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  (
    {
      selected,
      onChange,
      placeholder = 'Select a date...',
      inputProps = {},
      ...props
    },
    ref
  ) => {
    return (
      <ReactDatePicker
        selected={selected}
        onChange={(date, event) => {
          if (onChange) onChange(date, event);
        }}
        customInput={<Input ref={ref} {...inputProps} />}
        showPopperArrow={false}
        isClearable={true}
        placeholderText={placeholder}
        popperContainer={DatePickerPopperContainer}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <HeaderWrapper>
            <Button
              slim={true}
              variant={'simple'}
              icon={'chevron-left'}
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            />
            <HeaderDateText>{format(date, 'MMMM yyyy')}</HeaderDateText>
            <Button
              slim={true}
              variant={'simple'}
              icon={'chevron-right'}
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            />
          </HeaderWrapper>
        )}
        {...props}
      />
    );
  }
);

export default DatePicker;

interface DatePickerFieldProps extends DatePickerProps, FieldWrapperProps {
  name: string;
}

export const DatePickerField = forwardRef<
  HTMLInputElement,
  DatePickerFieldProps
>(({ label, error, ...props }, ref) => {
  return (
    <FieldWrapper label={label} error={error}>
      <DatePicker {...props} ref={ref} />
    </FieldWrapper>
  );
});

export const DatePickerFormikField: FunctionComponent<DatePickerFieldProps> = ({
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  return (
    <DatePickerField
      error={meta.error}
      {...props}
      {...field}
      onChange={(date) => setValue(date)}
      selected={field.value}
    />
  );
};
