import React, { Component } from 'react';
import Select, { Props } from 'react-select';
import SelectBase from 'react-select/src/Select';
import { defaultSelectProps } from 'shared/components/form/select/Select';
import { ICellEditorParams } from 'ag-grid-community';
import { TableContextState } from './TableContextState';
import { SelectOption } from '../form/select/SelectOption';

type ReactSelectRef = Select<SelectOption, SelectBase<SelectOption>> | null;
type SelectCellEditorParams = { 
  optionsKey: string;
};

export const TableContext = React.createContext<TableContextState>({
  selectOptions: {},
});

class SelectCellEditor extends Component<
  Props & ICellEditorParams & SelectCellEditorParams
> {
  state = {
    value: this.props.value,
  };

  SelectRef: ReactSelectRef = null;

  componentDidUpdate = (prevProps: ICellEditorParams) => {
    if(prevProps.value !== this.props.value) {
      this.setState({ value: prevProps.value })
    }
  }

  handleChange = (option?: any) => {
    if (typeof this.state.value === 'number') {
      this.setState(
        {
          value: option?.value,
        },
        () => this.props.api?.stopEditing()
      );
    } else {
      this.setState(
        {
          value: option,
        },
        () => this.props.api?.stopEditing()
      );
    }
  };

  afterGuiAttached = () => {
    if (this.SelectRef) {
      this.SelectRef.focus();
    }
  };

  isPopup() {
    return true;
  }

  getValue() {
    return this.state.value;
  }

  onBlur = () => {
    this.props.api?.stopEditing();
  };

  render() {
    return (
      <TableContext.Consumer>
        {(context) => {
          const options = context.selectOptions ? context.selectOptions[this.props.optionsKey] || [] : [];
          let selectValue;
          if (typeof this.state.value === 'number') {
            selectValue = options.find(x => x.value == this.state.value)
          } else {
            selectValue = this.state.value;
          }
          return (
            <div style={{ width: '200px' }}>
              <Select
                {...defaultSelectProps}
                defaultMenuIsOpen={true}
                onChange={this.handleChange}
                ref={(ref) => {
                  this.SelectRef = ref;
                }}
                onBlur={this.onBlur}
                value={selectValue}
                options={options}
              />
            </div>
          );
        }}
      </TableContext.Consumer>
    );
  }
}

export default SelectCellEditor;
