import { ClientError, GraphQLClient } from 'graphql-request';
import { getSdk, SdkFunctionWrapper } from '../graphql/generated';
import authModuleInstance from 'shared/auth/msal';
import axios from 'axios';

const graphEndpoint = process.env.GRAPHQL_ENDPOINT!;
const restBaseUrl = process.env.REST_ENDPOINT!;

const clientErrorWrapper: SdkFunctionWrapper = async <T>(action: () => Promise<T>): Promise<T> => {
  try {
    const result = await action();
    return result;
  } catch (e) {
    if (e instanceof ClientError) {
      return e.response as unknown as T;
    }
    throw e;
  }
}

export const getOrgPathComponent = () => {
  const pathComponents = window.location.pathname.split('/');
  if (pathComponents.length > 1 && pathComponents[1] != "") {
    return pathComponents[1];
  } else {
    return "";
  }
}

const getAuthorizedGraphQlClientWithScopes = async (scopes: string[]) => {
  const accessToken = await authModuleInstance.acquireToken(scopes);
  const graphqlClient = new GraphQLClient(graphEndpoint);
  graphqlClient.setHeader("Authorization", `Bearer ${accessToken}`);
  const orgPath = getOrgPathComponent();
  if (orgPath != "") {
    graphqlClient.setHeader("X-TENANT", orgPath);
  }
  return getSdk(graphqlClient, clientErrorWrapper);
}

export const getAuthorizedGraphqlClient = async () => {
  return await getAuthorizedGraphQlClientWithScopes(["api://213220ba-0f2b-46de-8456-5419e96ff631/Default"]);
}

const getAuthorizedlClientWithScopes = async (scopes: string[]) => {
  const accessToken = await authModuleInstance.acquireToken(scopes);
  const orgPath = getOrgPathComponent();
  let headers: Record<string, string> = { 'Authorization': `Bearer ${accessToken}` }
  if (orgPath != "") {
    headers = { ...headers, 'X-TENANT': orgPath }
  }
  const client = axios.create({
    baseURL: restBaseUrl,
    headers: headers, 
  });
  return client;
}

const getAuthorizedAiClientWithScopes = async (scopes: string[]) => {
  const accessToken = await authModuleInstance.acquireToken(scopes);
  const orgPath = getOrgPathComponent();
  let headers: Record<string, string> = {
    Authorization: `Bearer ${accessToken}`,
  };
  if (orgPath != '') {
    headers = { ...headers, 'X-TENANT': orgPath };
  }
  const client = axios.create({
    baseURL: process.env.AI_ENDPOINT,
    headers: headers,
  });
  return client;
};

export const getAuthorizedAiClient = async () => {
  return await getAuthorizedAiClientWithScopes([
    "api://78bbc132-76b1-4f9d-954e-ec78e20f422c/Default"
  ]);
};

export const postAiChats = async (body: any) => {
  try {
    const client = await getAuthorizedAiClient();
    const data = await client.post("", body, {responseType: 'json'});
    return data;
  } catch (error) {
    console.error('Error posting AI chat:', error);
    throw error;
  }
};


export const getAuthorizedClient = async () => {
  return await getAuthorizedlClientWithScopes(["api://213220ba-0f2b-46de-8456-5419e96ff631/Default"]);
}

export async function getCurrentUserOrganization(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetCurrentUserOrganization();
  return data;
}

export async function getCurrentUser(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetCurrentUser();
  return data;
}

export async function getDirectory(key: string, userId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDirectory({ userId });
  return data;
}

export async function getDirectoryUser(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDirectoryUser({ id });
  return data;
}

export async function getDirectoryUserGoals(key: string, userId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDirectoryUserGoals({ userId });
  return data;
}

export async function getEvaluation(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetEvaluation({ id });
  return data;
}

export async function getGoal(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetGoal({ id });
  return data;
}

export async function getEvaluationCycles(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetEvaluationCycles();
  return data;
}

export async function getLatestEvaluationCycles(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetLatestEvaluationCycles();
  return data;
}

export async function getGoalStatusOptions(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetGoalStatusOptions();
  return data;
}

export async function getDirectReports(key: string, userId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDirectReports({ userId });
  return data;
}

export async function getCompanies(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetCompanies();
  return data;
}

export async function getEmployeeRoles(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetEmployeeRoles();
  return data;
}

export async function getEditableEmployeeRoles(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetEditableEmployeeRoles();
  return data;
}

export async function getEmployeeRole(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetEmployeeRole({ id });
  return data;
}

export async function getDepartments(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetDepartments();
  return data;
}

export async function getEvaluationSummary(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetEvaluationSummary({ id });
  return data;
}

export async function getSelfEvaluation(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetSelfEvaluation({ id });
  return data;
}

export async function getManagerEvaluation(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetManagerEvaluation({ id });
  return data;
}

export async function getPeerRequest(key: string, id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPeerRequest({ id });
  return data;
}

export async function getEvaluationCycleEvaluationSummaries(key: string, evaluationCycleId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetEvaluationCycleEvaluationSummaries({ evaluationCycleId });
  return data;
}

export async function getPeerRequestDenyReasons(key: string) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.GetPeerRequestDenyReasons();
  return data;
}