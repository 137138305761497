import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Debug,
  Dialog,
  DropdownButton,
  DropdownItem,
  Modal,
  PageDetails,
  Tooltip,
} from 'shared/components';
import { MenuSeparator } from 'shared/components/menu/DropdownButton';
import NoAccess from 'shared/components/noAccess';
import { useNotification } from 'shared/components/notifications';
import { CenteredSpinner } from 'shared/components/spinner/Spinner';
import {
  useCreateReviewOpportunity,
  useCreateReviewStrength,
  useDeleteReviewOpportunity,
  useDeleteReviewStrength,
  useGetPeerRequest,
  useSubmitValueReview,
  useUnsubmitValueReview
} from 'shared/hooks/api';
import {
  CreateStrengthOrOpportunityInput,
  ReviewClassificationEnum,
} from 'shared/hooks/api/graphql/generated';
import { color } from 'shared/utils/styles';
import ReviewDetails, { ReviewDetailItem } from './ReviewDetails';

interface PeerReviewProps {
  evaluationId: number;
  peerRequestId: number;
  unsubmitEnabled: boolean;
  onClose: () => void;
}

interface ValuesReview {
  id: number;
  type: { id: number; name: string };
  status: { id: number; name: string };
  values: ReviewDetailItem[];
  subject: string;
}

const PeerReviewModal: FunctionComponent<PeerReviewProps> = ({
  evaluationId,
  peerRequestId,
  unsubmitEnabled,
  onClose,
}) => {
  const { data, status: getPeerRequestStatus } = useGetPeerRequest(
    peerRequestId
  );

  const review: ValuesReview | undefined = useMemo(() => {
    if (data?.peerRequest && data.peerRequest.requestedReview) {
      const valuesReview = data.peerRequest.requestedReview;
      return {
        id: valuesReview.id,
        type: valuesReview.type ?? undefined,
        status: valuesReview.status ?? undefined,
        values: valuesReview.values.map((value) => ({
          id: value.reviewValueId,
          itemId: value.id,
          details: value.description ? [value.description] : [],
          name: value.name,
          score: value.score ?? undefined,
          strengths: value.strengths.map((x) => ({
            id: x.id,
            description: x.description || '',
          })),
          opportunities: value.opportunities.map((x) => ({
            id: x.id,
            description: x.description || '',
          })),
        })),
        subject: valuesReview.subject.displayName,
      };
    }
  }, [data]);

  const readonly = useMemo(() => {
    return review?.status.name == 'Complete';
  }, [review]);

  const isEnoughContent = useMemo(() => {
    const valuesOpportunities = review?.values.flatMap((x) => x.opportunities);
    const valuesStrengths = review?.values.flatMap((x) => x.strengths);

    if (valuesOpportunities?.length == 0 && valuesStrengths?.length == 0) {
      return false;
    } else {
      return true;
    }
  }, [review]);

  const [submitReview, { status: submitReviewStatus }] = useSubmitValueReview(
    evaluationId
  );
  const [unsubmitReview, { status: unsubmitReviewStatus }] = useUnsubmitValueReview(
    evaluationId
  );

  const footerTools = useMemo(() => {
    const tools = [];
    if (!readonly) {
      tools.push(
        <DropdownButton
          title={'Done'}
          key={'finish'}
          icon={'chevron-down'}
          iconOnRight={true}
          color={'success'}
          isWorking={submitReviewStatus == 'loading'}
        >
          <DropdownItem icon={'times'} onClick={onClose}>
            Close
          </DropdownItem>
          <MenuSeparator />
          <DropdownItem
            icon={
              <FontAwesomeIcon
                icon={['fal', 'check-square']}
                style={{ color: color.success }}
              />
            }
            onClick={() => setIsShowingSubmitDialog(true)}
            disabled={!isEnoughContent}
            tooltip={
              !isEnoughContent
                ? 'In order to submit this review, you must have entered in at least 1 strength or opportunity.'
                : undefined
            }
          >
            Submit
          </DropdownItem>
        </DropdownButton>
      );
    }
    return tools;
  }, [isEnoughContent, readonly, submitReviewStatus]);

  const [isShowingSubmitDialog, setIsShowingSubmitDialog] = useState(false);
  const [isShowingUnsubmitDialog, setIsShowingUnsubmitDialog] = useState(false);

  const onSubmitReview = useCallback(async () => {
    if (review) {
      try {
        const result = await submitReview(review.id);
        if (result.data?.submitValueReview) {
          notify({
            duration: 3000,
            title: 'Success!',
            message: 'Review Submitted!',
            variant: 'success',
          });
          onClose();
        } else if (result.errors) {
          const forbiddenError = result.errors.find(
            (x: any) => x?.extensions?.code == 'FORBIDDEN'
          );
          if (forbiddenError) {
            notify({
              duration: 5000,
              title: 'Permission Denied',
              variant: 'danger',
              message: forbiddenError?.message,
            });
          } else {
            throw Error();
          }
        } else {
          throw Error();
        }
      } catch (error) {
        notify({
          duration: 5000,
          title: 'Uh-oh!',
          variant: 'danger',
          message: 'An unknown error occurred while submitting the 360 review!',
        });
      }
    }
  }, [submitReview, onClose, review]);

  const onUnsubmitReview = useCallback(async () => {
    if (review) {
      try {
        const result = await unsubmitReview(review.id);
        if (result.data?.unsubmitValueReview) {
          notify({
            duration: 3000,
            title: 'Success!',
            message: 'Review Unsubmitted!',
            variant: 'success',
          });
          onClose();
        } else if (result.errors) {
          const forbiddenError = result.errors.find(
            (x: any) => x?.extensions?.code == 'FORBIDDEN'
          );
          if (forbiddenError) {
            notify({
              duration: 5000,
              title: 'Permission Denied',
              variant: 'danger',
              message: forbiddenError?.message,
            });
          } else {
            throw Error();
          }
        } else {
          throw Error();
        }
      } catch (error) {
        notify({
          duration: 5000,
          title: 'Uh-oh!',
          variant: 'danger',
          message: 'An unknown error occurred while unsubmitting the 360 review!',
        });
      }
    }
  }, [unsubmitReview, onClose, review]);

  const tools = useMemo(() => {
    if (review) {
      const elements = [
        <Badge
          key={'review-status'}
          color={review.status.name == 'Complete' ? 'success' : 'warning'}
        >
          {review.status.name}
        </Badge>,
      ];

      if (review.status.name == 'Complete') {
        elements.push(
          <Tooltip disabled={unsubmitEnabled} content={'Unsubmit is only allowed for 30 days after the end of the evaluation cycle.'}>
            <span>
              <Button
                key={'unsubmit-review'}
                color={'warning'}
                slim={true}
                disabled={!unsubmitEnabled}
                onClick={() => setIsShowingUnsubmitDialog(true)}
              >
                Unsubmit
              </Button>
            </span>
          </Tooltip>
        );
      }

      return elements;
    } else {
      return [];
    }
  }, [review, submitReviewStatus, unsubmitReviewStatus]);


  const [
    createReviewStrength,
    { status: createReviewStrengthStatus },
  ] = useCreateReviewStrength(evaluationId, peerRequestId);
  const [
    createReviewOpportunity,
    { status: createReviewOpportunityStatus },
  ] = useCreateReviewOpportunity(evaluationId, peerRequestId);

  const [
    deleteReviewStrength,
    { status: deleteReviewStrengthStatus },
  ] = useDeleteReviewStrength(evaluationId, peerRequestId);
  const [
    deleteReviewOpportunity,
    { status: deleteReviewOpportunityStatus },
  ] = useDeleteReviewOpportunity(evaluationId, peerRequestId);
  const notify = useNotification();

  const [creatingIds, setCreatingIds] = useState<string[]>([]);
  const [deletingIds, setDeletingIds] = useState<string[]>([]);

  const onCreateStrengthOrOpportunity = useCallback(
    async (
      reviewId: number,
      reviewEntryId: number,
      type: 'strength' | 'opportunity',
      reviewClassification: ReviewClassificationEnum
    ) => {
      const input: CreateStrengthOrOpportunityInput = {
        reviewId: reviewId,
        reviewEntryId: reviewEntryId,
        reviewClassification: reviewClassification,
        description: '',
      };
      switch (type) {
        case 'strength': {
          setCreatingIds((ids) => [...ids, `strength:${reviewEntryId}`]);
          try {
            const result = await createReviewStrength(input);
            if (result.data?.createReviewStrength) {
              // notify({
              //   duration: 3000,
              //   title: 'Success!',
              //   message: 'Evaluation started!',
              //   variant: 'success',
              // });
            } else if (result.errors) {
              const forbiddenError = result.errors.find(
                (x: any) => x?.extensions?.code == 'FORBIDDEN'
              );
              if (forbiddenError) {
                notify({
                  duration: 5000,
                  title: 'Permission Denied',
                  variant: 'danger',
                  message: forbiddenError?.message,
                });
              } else {
                throw Error();
              }
            } else {
              throw Error();
            }
          } catch (error) {
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              variant: 'danger',
              message: 'An unknown error occurred while creating the strength!',
            });
          } finally {
            setCreatingIds((ids) => {
              const copy = [...ids];
              const firstIndex = copy.findIndex(
                (x) => x == `strength:${reviewEntryId}`
              );
              if (firstIndex != -1) {
                copy.splice(firstIndex);
              }
              return [...copy];
            });
          }
          break;
        }
        case 'opportunity': {
          setCreatingIds((ids) => [...ids, `opportunity:${reviewEntryId}`]);
          try {
            const result = await createReviewOpportunity(input);
            if (result.data?.createReviewOpportunity) {
              // notify({
              //   duration: 3000,
              //   title: 'Success!',
              //   message: 'Evaluation started!',
              //   variant: 'success',
              // });
            } else if (result.errors) {
              const forbiddenError = result.errors.find(
                (x: any) => x?.extensions?.code == 'FORBIDDEN'
              );
              if (forbiddenError) {
                notify({
                  duration: 5000,
                  title: 'Permission Denied',
                  variant: 'danger',
                  message: forbiddenError?.message,
                });
              } else {
                throw Error();
              }
            } else {
              throw Error();
            }
          } catch (error) {
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              variant: 'danger',
              message:
                'An unknown error occurred while creating the opportunity!',
            });
          } finally {
            setCreatingIds((ids) => {
              const copy = [...ids];
              const firstIndex = copy.findIndex(
                (x) => x == `opportunity:${reviewEntryId}`
              );
              if (firstIndex != -1) {
                copy.splice(firstIndex);
              }
              return [...copy];
            });
          }
          break;
        }
      }
    },
    [createReviewStrength, createReviewOpportunity]
  );

  const onDeleteStrengthOrOpportunity = useCallback(
    async (
      id: number,
      type: 'strength' | 'opportunity',
      reviewClassification: ReviewClassificationEnum
    ) => {
      const input = {
        id: id,
        reviewClassification: reviewClassification,
      };
      let didDelete = false;
      switch (type) {
        case 'strength': {
          setDeletingIds((ids) => [...ids, `strength:${id}`]);
          try {
            const result = await deleteReviewStrength(input);
            if (result.data?.deleteReviewStrength) {
              // notify({
              //   duration: 3000,
              //   title: 'Success!',
              //   message: 'Evaluation started!',
              //   variant: 'success',
              // });
              didDelete = true;
            } else if (result.errors) {
              const forbiddenError = result.errors.find(
                (x: any) => x?.extensions?.code == 'FORBIDDEN'
              );
              if (forbiddenError) {
                notify({
                  duration: 5000,
                  title: 'Permission Denied',
                  variant: 'danger',
                  message: forbiddenError?.message,
                });
              } else {
                throw Error();
              }
            } else {
              throw Error();
            }
          } catch (error) {
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              variant: 'danger',
              message: 'An unknown error occurred while deleting the strength!',
            });
          } finally {
            setDeletingIds((ids) => {
              const copy = [...ids];
              const firstIndex = copy.findIndex((x) => x == `strength:${id}`);
              if (firstIndex != -1) {
                copy.splice(firstIndex);
              }
              return [...copy];
            });
          }
          break;
        }
        case 'opportunity': {
          setDeletingIds((ids) => [...ids, `opportunity:${id}`]);
          try {
            const result = await deleteReviewOpportunity(input);
            if (result.data?.deleteReviewOpportunity) {
              // notify({
              //   duration: 3000,
              //   title: 'Success!',
              //   message: 'Evaluation started!',
              //   variant: 'success',
              // });
              didDelete = true;
            } else if (result.errors) {
              const forbiddenError = result.errors.find(
                (x: any) => x?.extensions?.code == 'FORBIDDEN'
              );
              if (forbiddenError) {
                notify({
                  duration: 5000,
                  title: 'Permission Denied',
                  variant: 'danger',
                  message: forbiddenError?.message,
                });
              } else {
                throw Error();
              }
            } else {
              throw Error();
            }
          } catch (error) {
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              variant: 'danger',
              message:
                'An unknown error occurred while deleting the opportunity!',
            });
          } finally {
            setDeletingIds((ids) => {
              const copy = [...ids];
              const firstIndex = copy.findIndex(
                (x) => x == `opportunity:${id}`
              );
              if (firstIndex != -1) {
                copy.splice(firstIndex);
              }
              return [...copy];
            });
          }
          break;
        }
      }
      return didDelete;
    },
    [deleteReviewStrength, deleteReviewOpportunity]
  );

  const isCreatingStrengthOrOpportunity = useMemo(() => {
    return (
      createReviewOpportunityStatus == 'loading' ||
      createReviewStrengthStatus == 'loading'
    );
  }, [createReviewOpportunityStatus, createReviewStrengthStatus]);

  const isDeletingStrengthOrOpportunity = useMemo(() => {
    return (
      deleteReviewOpportunityStatus == 'loading' ||
      deleteReviewStrengthStatus == 'loading'
    );
  }, [deleteReviewOpportunityStatus, deleteReviewStrengthStatus]);

  return (
    <React.Fragment>
      <Modal
        title={'360 Review'}
        subtitle={review ? `Subject: ${review.subject}` : 'Details'}
        isOpen={true}
        onClose={onClose}
        size={'lg'}
        tools={tools}
        footerTools={footerTools}
        style={{ maxHeight: '80%' }}
      >
        {getPeerRequestStatus == 'loading' && !review ? (
          <div style={{ height: '200px' }}>
            <CenteredSpinner />
          </div>
        ) : review ? (
          <PageDetails>
            <ReviewDetails
              type={'peer'}
              isManagerView={false}
              readonly={readonly}
              items={review.values}
              emptyMessage={
                readonly
                  ? 'This review was submitted without any values. What a shame...'
                  : `Apparently there aren't any values set for the evaluation cycle that this review is for... Oh well, less work for you! The review can be completed once these have been added by the HR department.`
              }
              onCreateStrengthOrOpportunity={(entryId, type) => {
                onCreateStrengthOrOpportunity(
                  review.id,
                  entryId,
                  type,
                  ReviewClassificationEnum.Value
                );
              }}
              isCreatingStrengthOrOpportunity={isCreatingStrengthOrOpportunity}
              onDeleteStrengthOrOpportunity={async (id, type) => {
                return await onDeleteStrengthOrOpportunity(
                  id,
                  type,
                  ReviewClassificationEnum.Value
                );
              }}
              isDeletingStrengthOrOpportunity={isDeletingStrengthOrOpportunity}
              creatingIds={creatingIds}
              deletingIds={deletingIds}
              evaluationId={evaluationId}
              reviewClassification={ReviewClassificationEnum.Value}
              showSupplementaryContent={false}
              supplementaryContent={[]}
            />
            {/* <Debug state={{ data, review }} /> */}
          </PageDetails>
        ) : (
          <NoAccess
            title={'Hmmm...'}
            subtitle={'This review cannot be completed at this time.'}
            icon={'comment-alt-exclamation'}
          />
        )}
      </Modal>
      {review && isShowingSubmitDialog && (
        <Dialog
          isOpen={true}
          title={`Submit 360 Review?`}
          message={
            'You should only submit this 360 Review if you are finished filling it out and are ready for it to be reviewed by others. Are you ready to submit?'
          }
          confirmTitle={'Yes, submit'}
          cancelTitle={`Actually, I'm not quite finished...`}
          onClose={() => setIsShowingSubmitDialog(false)}
          onConfirm={() => onSubmitReview()}
          onCancel={() => setIsShowingSubmitDialog(false)}
          isWorking={submitReviewStatus == 'loading'}
          variant={'info'}
          confirmation={'Yes'}
        />
      )}
      {review && isShowingUnsubmitDialog && (
        <Dialog
          isOpen={true}
          title={`Unsubmit 360 Review?`}
          message={
            'You are about to unsubmit this 360 Review. After unsubmitting, the review will no longer be accessible to others for review until you submit it again. Are you sure you want to unsubmit?'
          }
          confirmTitle={'Yes, unsubmit'}
          cancelTitle={`Actually, I'll leave it as is...`}
          onClose={() => setIsShowingUnsubmitDialog(false)}
          onConfirm={() => onUnsubmitReview()}
          onCancel={() => setIsShowingUnsubmitDialog(false)}
          isWorking={unsubmitReviewStatus == 'loading'}
          variant={'warning'}
          confirmation={'Yes'}
        />
      )}

    </React.Fragment>
  );
};

export default PeerReviewModal;
