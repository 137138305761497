import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Column } from 'ag-grid-community';
import { ToolbarMenu } from '../pageContent';
import { DropdownItem, MenuSeparator } from '../menu/DropdownButton';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { font, color } from 'shared/utils/styles';

interface GroupMenuButtonProps {
  groupedColumns: string[];
  setGroupedColumns: (groupModel: string[]) => void;
  gridColumns: Column[];
}

const GroupMenuButton: FunctionComponent<GroupMenuButtonProps> = ({
  groupedColumns,
  setGroupedColumns,
  gridColumns,
}) => {
  const groupableColumns = React.useMemo(() => {
    const groupable: Column[] = [];
    gridColumns.forEach((col) => {
      const colDef = col.getColDef();
      if (colDef.enableRowGroup) {
        groupable.push(col);
      }
    });
    return groupable;
  }, [gridColumns]);

  const onGroupMenuItemClicked = useCallback(
    (col: Column, group: boolean) => {
      if (group) {
        setGroupedColumns([col.getColId()]);
      } else {
        setGroupedColumns([]);
      }
    },
    [setGroupedColumns]
  );

  const groupButtonTitle = useMemo(() => {
    const groups: string[] = [];
    groupedColumns.forEach((grouped) => {
      const col = gridColumns
        .find((x) => x.getColId() === grouped)
        ?.getColDef();
      if (col && col.headerName) groups.push(col.headerName);
    });
    return groups.length > 0 ? `Grouped by: ${groups.join(', ')}` : 'Group';
  }, [groupedColumns, gridColumns]);

  const onClearGroupClicked = useCallback(() => {
    setGroupedColumns([]);
  }, [setGroupedColumns]);

  return (
    <ToolbarMenu icon={'layer-group'} title={groupButtonTitle} anchor={'right'}>
      {groupableColumns.length > 0 ? (
        groupableColumns.map((col) => {
          const isGrouped = groupedColumns.includes(col.getColId());
          return getGroupMenuItem({ col, isGrouped, onGroupMenuItemClicked })
        })
      ) : (
        <DropdownItem>No Groupable Columns</DropdownItem>
      )}
      <MenuSeparator />
      <DropdownItem onClick={onClearGroupClicked}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: '5px 0',
          }}
        >
          Clear Grouping
        </div>
      </DropdownItem>
    </ToolbarMenu>
  );
};

interface GroupMenuItemProps extends React.HTMLAttributes<HTMLDivElement> {
  col: Column;
  isGrouped: boolean;
  onGroupMenuItemClicked: (col: Column, group: boolean) => void;
}

const GroupCheck = styled.div<{ isGrouped: boolean }>`
  padding: 2px;
  ${font.size(12)};
  color: ${color.primary};
  visibility: ${(props) => (props.isGrouped ? 'visible' : 'hidden')};
`;

const getGroupMenuItem = ({
  col,
  isGrouped,
  onGroupMenuItemClicked,
}: GroupMenuItemProps) => {
  const title = col.getColDef().headerName || '(No Header)';
  return (
    <DropdownItem key={col.getColId()} onClick={() => {
      onGroupMenuItemClicked(col, !isGrouped);
    }}>
        <GroupCheck isGrouped={isGrouped}>
          <FontAwesomeIcon icon={['fas', 'check']} fixedWidth={true} />
        </GroupCheck>
        <div style={{ marginLeft: '10px' }}>{title}</div>
    </DropdownItem>
  );
};

export default GroupMenuButton;
