import { useAppContext } from 'App';
import EvaluationsList from 'App/Organization/shared/Evaluations/EvaluationsList';
import React, { FunctionComponent } from 'react';
import { usePageTitle } from 'shared/hooks';

const Evaluations: FunctionComponent = () => {
  usePageTitle('Home - Evaluations');
  const appContext = useAppContext();
  return <EvaluationsList pageTitle={'Home'} employeeId={appContext.currentUserId}/>
};

export default Evaluations;
