import { useAppContext } from 'App';
import { FieldArray, FormikProps } from 'formik';
import React, { FunctionComponent, useMemo } from 'react';
import {
  Button,
  Column,
  FormFieldWrapper,
  InputFormikField,
  Row,
  Section,
  SelectFormikField,
  TextareaFormikField,
} from 'shared/components';
import { useGetEmployeeRoles } from 'shared/hooks/api';
import { PartialNullable } from 'shared/utils/utilityTypes';
import styled from 'styled-components';
import { EmployeeRoleFormValues } from './NewEmployeeRoleModal';

interface EmployeeRoleModalFormProps {
  form: FormikProps<PartialNullable<EmployeeRoleFormValues>>;
}

const EmployeeRoleModalForm: FunctionComponent<EmployeeRoleModalFormProps> = ({
  form,
}) => {
  const appContext = useAppContext();
  const {
    data: employeeRolesData,
    status: employeeRolesStatus,
  } = useGetEmployeeRoles();

  const employeeRoleOptions = useMemo(() => {
    return employeeRolesData?.employeeRoles || [];
  }, [employeeRolesData]);

  return (
    <React.Fragment>
      <FormFieldWrapper>
        <InputFormikField
          label={'Name'}
          name={'name'}
          placeholder={'Enter a name...'}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <TextareaFormikField
          label={'Mission Statement'}
          name={'missionStatement'}
          placeholder={'Enter a mission statement...'}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <SelectFormikField
          label={'Manager Role Scorecard'}
          name={'managerRole'}
          options={employeeRoleOptions}
          placeholder={'Select the manager role scorecard...'}
          isLoading={employeeRolesStatus == 'loading'}
          isClearable={true}
        />
      </FormFieldWrapper>
      <Section title="Competencies">
        <FieldArray
          name={'competencies'}
          render={(arrayHelpers) => (
            <>
              {form.values.competencies &&
                form.values.competencies.length > 0 &&
                form.values.competencies.map((competency, index) => (
                  <FormFieldWrapper key={index}>
                    <Row>
                      <Column span={5}>
                        <InputFormikField
                          name={`competencies.${index}.category`}
                          placeholder={'Enter a category...'}
                        />
                      </Column>
                      <Column span={6}>
                        <InputFormikField
                          name={`competencies.${index}.name`}
                          placeholder={'Enter a competency...'}
                        />
                      </Column>
                      <Column span={1}>
                        <Button
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                          icon={'times'}
                          variant={'simple'}
                          color={'danger'}
                        />
                      </Column>
                    </Row>
                  </FormFieldWrapper>
                ))}
              <Button
                type="button"
                onClick={() => arrayHelpers.push({ name: '', category: null })}
                icon={'plus'}
                variant={'outline'}
              >
                Add a competency
              </Button>
            </>
          )}
        />
      </Section>
      <Section title="Objectives">
        <FieldArray
          name={'objectives'}
          render={(arrayHelpers) => (
            <>
              {form.values.objectives &&
                form.values.objectives.length > 0 &&
                form.values.objectives.map((objective, index) => (
                  <FormFieldWrapper key={index}>
                    <Row>
                      <Column span={11}>
                        <InputFormikField
                          name={`objectives.${index}.name`}
                          placeholder={'Enter an objective...'}
                        />
                      </Column>
                      <Column span={1}>
                        <Button
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                          icon={'times'}
                          variant={'simple'}
                          color={'danger'}
                        />
                      </Column>
                    </Row>
                    <FieldArray
                      name={`objectives.${index}.keyResults`}
                      render={(keyResultsHelpers) => (
                        <FormFieldWrapper style={{ marginLeft: '20px', marginRight: '20px' }}>
                          {objective.keyResults &&
                            objective.keyResults.length > 0 &&
                            objective.keyResults.map((keyResult, kIndex) => (
                              <Row key={kIndex}>
                                <Column span={11}>
                                  <InputFormikField
                                    name={`objectives.${index}.keyResults.${kIndex}`}
                                    placeholder={'Enter a key result...'}
                                  />
                                </Column>
                                <Column span={1}>
                                  <Button
                                    type="button"
                                    onClick={() =>
                                      keyResultsHelpers.remove(kIndex)
                                    }
                                    icon={'times'}
                                    variant={'simple'}
                                    color={'danger'}
                                  />
                                </Column>
                              </Row>
                            ))}
                          <Button
                            type="button"
                            onClick={() => keyResultsHelpers.push('')}
                            icon={'plus'}
                            variant={'outline'}
                            style={{ marginTop: '10px' }}
                          >
                            Add a Key Result
                          </Button>
                        </FormFieldWrapper>
                      )}
                    />
                  </FormFieldWrapper>
                ))}
              <Button
                type="button"
                onClick={() => arrayHelpers.push({ name: '', keyResults: [] })}
                icon={'plus'}
                variant={'outline'}
              >
                Add an objective
              </Button>
            </>
          )}
        />
      </Section>
      <Section title="Qualifications">
        <FieldArray
          name={'qualifications'}
          render={(arrayHelpers) => (
            <>
              {form.values.qualifications &&
                form.values.qualifications.length > 0 &&
                form.values.qualifications.map((qualification, index) => (
                  <FormFieldWrapper key={index}>
                    <Row>
                      <Column span={11}>
                        <InputFormikField
                          name={`qualifications.${index}`}
                          placeholder={'Enter a qualification...'}
                        />
                      </Column>
                      <Column span={1}>
                        <Button
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                          icon={'times'}
                          variant={'simple'}
                          color={'danger'}
                        />
                      </Column>
                    </Row>
                  </FormFieldWrapper>
                ))}
              <Button
                type="button"
                onClick={() => arrayHelpers.push('')}
                icon={'plus'}
                variant={'outline'}
              >
                Add a qualification
              </Button>
            </>
          )}
        />
      </Section>
    </React.Fragment>
  );
};

export default EmployeeRoleModalForm;
