import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import { GraphQLError } from 'graphql-request/dist/src/types';
import gql from 'graphql-tag';
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  Date: string;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: string;
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: string;
  /** The `Seconds` scalar type represents a period of time represented as the total number of seconds. */
  Seconds: any;
  /** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds. */
  Milliseconds: any;
  Decimal: number;
  Uri: any;
  Guid: any;
  Short: any;
  UShort: any;
  UInt: any;
  Long: any;
  BigInt: any;
  ULong: any;
  Byte: any;
  SByte: any;
};

















export type TenantQuery = {
  __typename?: 'TenantQuery';
  /** Wether or not the current user has permissions to create and update user profiles. */
  canEditUsers?: Maybe<Scalars['Boolean']>;
  /** A list of companies for the current organization. */
  companies?: Maybe<Array<Company>>;
  /** The current user. */
  currentUser: CurrentUser;
  /** A list of departments for the current organization. */
  departments?: Maybe<Array<Department>>;
  /** A list of all employees who are a direct report of the specified user. */
  directReports?: Maybe<Array<User>>;
  /** A list of employee roles that the current user can edit. */
  editableEmployeeRoles?: Maybe<Array<EmployeeRole>>;
  /** A specific employee role. */
  employeeRole?: Maybe<EmployeeRole>;
  /** A list of employee roles for the current organization. */
  employeeRoles?: Maybe<Array<EmployeeRole>>;
  /** A specific evaluation. */
  evaluation?: Maybe<Evaluation>;
  /** A list of evaluations for a specific user. */
  evaluations?: Maybe<Array<Evaluation>>;
  /** A list of evaluation cycles for the current organization. */
  evaluationsCycles?: Maybe<Array<EvaluationCycle>>;
  /** A list of evaluations for a specified evaluation cycle. */
  evaluationSummaries?: Maybe<Array<Evaluation>>;
  /** A specific goal tied to an evaluation. */
  goal?: Maybe<EvaluationObjective>;
  /** A list of goal statuses for the current organization. */
  goalStatuses?: Maybe<Array<EvaluationObjectiveStatus>>;
  /** A list of evaluation cycles for the current organization. */
  latestEvaluationsCycles?: Maybe<Array<EvaluationCycle>>;
  /** A specific peer request. */
  peerRequest?: Maybe<PeerRequest>;
  /** A list of reasons for a peer request being declined or cancelled. */
  peerRequestDenyReasons?: Maybe<Array<PeerRequestDenyReason>>;
  /** A specific user. */
  user?: Maybe<User>;
  /** A list of all users who are in the directory of the organization */
  users?: Maybe<Array<User>>;
};


export type TenantQueryDirectReportsArgs = {
  userId: Scalars['Int'];
};


export type TenantQueryEmployeeRoleArgs = {
  id: Scalars['Int'];
};


export type TenantQueryEvaluationArgs = {
  id: Scalars['Int'];
};


export type TenantQueryEvaluationsArgs = {
  userId: Scalars['Int'];
};


export type TenantQueryEvaluationSummariesArgs = {
  evaluationCycleId: Scalars['Int'];
};


export type TenantQueryGoalArgs = {
  id: Scalars['Int'];
};


export type TenantQueryPeerRequestArgs = {
  id: Scalars['Int'];
};


export type TenantQueryUserArgs = {
  id: Scalars['Int'];
};

/** CurrentUser */
export type CurrentUser = {
  __typename?: 'CurrentUser';
  /** The organization that the current user belongs to. */
  organization?: Maybe<Organization>;
  /** The current user's employee profile. */
  profile?: Maybe<User>;
};

/** Organization */
export type Organization = {
  __typename?: 'Organization';
  id: Scalars['Int'];
  name: Scalars['String'];
  organizationPath: Scalars['String'];
};

/** User */
export type User = Actor & {
  __typename?: 'User';
  /** The company that the user belongs to. */
  company: Company;
  /** When the user was created. */
  createdAt: Scalars['DateTimeOffset'];
  /** Whether or not the current user has permissions to create and update user profiles. */
  currentUserCanEdit?: Maybe<Scalars['Boolean']>;
  /** The user's department. */
  department: Department;
  /** The id of the actor. */
  displayName: Scalars['String'];
  /** The id of the actor. */
  displayNameAlt: Scalars['String'];
  email: Scalars['String'];
  /** The employee role of the user according to the employee directory. */
  evaluation?: Maybe<Evaluation>;
  firstName: Scalars['String'];
  /** The id of the actor. */
  id: Scalars['Int'];
  /** Whether or not the current user has admin permissions. */
  isCurrentUserAdmin?: Maybe<Scalars['Boolean']>;
  /** Whether or not the user has admin permissions. */
  isUserAdmin?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  /** The user's manager according to the employee directory. */
  manager?: Maybe<User>;
  /** The employee role of the user according to the employee directory. */
  role?: Maybe<EmployeeRole>;
  title?: Maybe<Scalars['String']>;
  /** When the user was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  username?: Maybe<Scalars['String']>;
};


/** User */
export type UserEvaluationArgs = {
  evaluationCycleId: Scalars['Int'];
};

/** Company */
export type Company = {
  __typename?: 'Company';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

export type Actor = {
  /** The id of the actor. */
  displayName: Scalars['String'];
  /** The id of the actor. */
  displayNameAlt: Scalars['String'];
  /** The id of the actor. */
  id: Scalars['Int'];
};

/** Department */
export type Department = {
  __typename?: 'Department';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** EmployeeRole */
export type EmployeeRole = {
  __typename?: 'EmployeeRole';
  /** The values for this evaluation cycle. */
  competencies: Array<Competency>;
  /** The contributors for this role. */
  contributors?: Maybe<Array<User>>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** Wether or not the current user has permissions to edit the current role scorecard. */
  currentUserCanEdit?: Maybe<Scalars['Boolean']>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  /** The role of manager for employees with this assigned role. */
  managerRole?: Maybe<EmployeeRole>;
  missionStatement?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** The objwctives for this role. */
  objectives?: Maybe<Array<Objective>>;
  /** The qualifications for this role. */
  qualifications: Array<Qualification>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** Objective */
export type Objective = {
  __typename?: 'Objective';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  /** The key results for this objective. */
  keyResults?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** Qualification */
export type Qualification = {
  __typename?: 'Qualification';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** Competency */
export type Competency = {
  __typename?: 'Competency';
  category?: Maybe<Scalars['String']>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** Evaluation */
export type Evaluation = {
  __typename?: 'Evaluation';
  changesRequested?: Maybe<Scalars['String']>;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** Whether or not the current user is an Admin for this evaluation. */
  currentUserIsAdmin: Scalars['Boolean'];
  /** Whether or not the current user is the manager of the employee of this evaluation. */
  currentUserIsManager: Scalars['Boolean'];
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  employeeSignedDate?: Maybe<Scalars['DateTimeOffset']>;
  /** The evaluation cycle that this evaluation belongs to. */
  evaluationCycle: EvaluationCycle;
  /** The goals for this evaluations. */
  goals?: Maybe<Array<EvaluationObjective>>;
  id: Scalars['Int'];
  /** The manager evaluation for the employee that this evaluation is associated with */
  managerEvaluation?: Maybe<EvaluationReview>;
  managerSignedDate?: Maybe<Scalars['DateTimeOffset']>;
  /** The overall performance score for this evaluation. */
  overallPerformanceScore?: Maybe<EvaluationPerformanceScore>;
  /** The peer requests that have been sent to others by the employee associated with this evaluation. */
  peerRequests: Array<PeerRequest>;
  /** The peer requests that have been sent to the employee of this evaluation */
  reviewsRequestedByPeers: Array<PeerRequest>;
  /** The self evaluation for the employee that this evaluation is associated with */
  selfEvaluation?: Maybe<EvaluationReview>;
  /** The status of this evaluation */
  status?: Maybe<EvaluationStatus>;
  /** The subject (user) of this evaluation. */
  subject: User;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** EvaluationPerformanceScore */
export type EvaluationPerformanceScore = {
  __typename?: 'EvaluationPerformanceScore';
  objectivesScore: Scalars['Decimal'];
  totalScore: Scalars['Decimal'];
  valuesScore: Scalars['Decimal'];
};

/** EvaluationCycle */
export type EvaluationCycle = {
  __typename?: 'EvaluationCycle';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  managerReviewStartDate?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  peerFeedbackStartDate?: Maybe<Scalars['Date']>;
  releaseToEmployeeDate?: Maybe<Scalars['Date']>;
  selfEvaluationStartDate?: Maybe<Scalars['Date']>;
  /** The type of evaluation cycle. */
  type: EvaluationType;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
  /** The values for this evaluation cycle. */
  values: Array<EvaluationValue>;
};

/** EvaluationType */
export type EvaluationType = {
  __typename?: 'EvaluationType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** EvaluationValue */
export type EvaluationValue = {
  __typename?: 'EvaluationValue';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  orderId?: Maybe<Scalars['Decimal']>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** EvaluationObjective */
export type EvaluationObjective = {
  __typename?: 'EvaluationObjective';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  dueDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
  performanceReward?: Maybe<Scalars['Decimal']>;
  /** The status of this goal. */
  status: EvaluationObjectiveStatus;
  /** The subgoals for this goal. */
  subgoals?: Maybe<Array<EvaluationObjective>>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
  weight?: Maybe<Scalars['Decimal']>;
};

/** EvaluationObjectiveStatus */
export type EvaluationObjectiveStatus = {
  __typename?: 'EvaluationObjectiveStatus';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** An evaluation review that is made up of both a single "goals review" and "values review". Used to track the status of the the self and manager evaluation processes. */
export type EvaluationReview = {
  __typename?: 'EvaluationReview';
  author?: Maybe<Actor>;
  /** The goals review that is part of this self evaluation. */
  goalsReview: ObjectiveReview;
  /** The id of the evaluation review. */
  id: Scalars['Int'];
  /** The status of this evaluation review. */
  status: ReviewStatus;
  /** The type of this evaluation review. */
  type: ReviewType;
  /** The values review that is part of this self evaluation. */
  valuesReview: ValueReview;
};

/** ReviewType */
export type ReviewType = {
  __typename?: 'ReviewType';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** ReviewStatus */
export type ReviewStatus = {
  __typename?: 'ReviewStatus';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** ObjectiveReview */
export type ObjectiveReview = {
  __typename?: 'ObjectiveReview';
  /** The author of this review. */
  author: User;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The goals that the review is based on and their associated strengths and weaknesses for this review. */
  goals: Array<ObjectiveReviewObjective>;
  id: Scalars['Int'];
  /** The goals that the review is based on and their associated strengths and weaknesses for this review, as provided by the employee on their self review. */
  selfReviewContent: Array<ObjectiveReviewObjective>;
  /** The status of this objective review. */
  status: ReviewStatus;
  /** The subject of the review. */
  subject: User;
  /** The type of this objective review. */
  type: ReviewType;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** ObjectiveReviewObjective */
export type ObjectiveReviewObjective = {
  __typename?: 'ObjectiveReviewObjective';
  dueDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** The opportunities for the employee associated with this value review with respect to this value */
  opportunities: Array<EvaluationStrengthOrOpportunity>;
  parentId?: Maybe<Scalars['Int']>;
  performanceReward?: Maybe<Scalars['Decimal']>;
  /** The status of this goal. */
  status: EvaluationObjectiveStatus;
  /** The strengths for the employee associated with this value review with respect to this value */
  strengths: Array<EvaluationStrengthOrOpportunity>;
  /** The subgoals for this goal. */
  subgoals?: Maybe<Array<EvaluationObjective>>;
  weight?: Maybe<Scalars['Decimal']>;
};

/** EvaluationStrengthOrOpportunity */
export type EvaluationStrengthOrOpportunity = {
  __typename?: 'EvaluationStrengthOrOpportunity';
  author?: Maybe<Actor>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  orderId?: Maybe<Scalars['Decimal']>;
};

/** ValueReview */
export type ValueReview = {
  __typename?: 'ValueReview';
  /** The author of this review. */
  author: User;
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  /** The values that the review is based on and their associated strengths and weaknesses for this review, as provided by this employees peers. */
  peerReviewContent: Array<ValueReviewValue>;
  /** The status of this value review. */
  status: ReviewStatus;
  /** The subject of the review. */
  subject: User;
  /** The type of this value review. */
  type: ReviewType;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
  /** The values that the review is based on and their associated strengths and weaknesses for this review. */
  values: Array<ValueReviewValue>;
};

/** ValueReviewValue */
export type ValueReviewValue = {
  __typename?: 'ValueReviewValue';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** The opportunities for the employee associated with this value review with respect to this value */
  opportunities: Array<EvaluationStrengthOrOpportunity>;
  reviewValueId: Scalars['Int'];
  score?: Maybe<Scalars['Decimal']>;
  /** The strengths for the employee associated with this value review with respect to this value */
  strengths: Array<EvaluationStrengthOrOpportunity>;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** PeerRequest */
export type PeerRequest = {
  __typename?: 'PeerRequest';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  /** The type of evaluation cycle. */
  denyReason?: Maybe<PeerRequestDenyReason>;
  id: Scalars['Int'];
  /** The peer that is being requested to provide a review. */
  requestedPeer: User;
  /** The values review that is associated with this peer request. */
  requestedReview?: Maybe<ValueReview>;
  /** The employee that is the review is requested for. */
  reviewSubject: User;
  /** The status of this value review. */
  status: PeerRequestStatus;
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** PeerRequestStatus */
export type PeerRequestStatus = {
  __typename?: 'PeerRequestStatus';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** PeerRequestDenyReason */
export type PeerRequestDenyReason = {
  __typename?: 'PeerRequestDenyReason';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

/** EvaluationStatus */
export type EvaluationStatus = {
  __typename?: 'EvaluationStatus';
  /** When the object was created. */
  createdAt: Scalars['DateTimeOffset'];
  createdBy?: Maybe<Actor>;
  /** When the object was deleted. */
  deletedAt?: Maybe<Scalars['DateTimeOffset']>;
  deletedBy?: Maybe<Actor>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** When the object was last updated. */
  updatedAt: Scalars['DateTimeOffset'];
  updatedBy?: Maybe<Actor>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptPeerRequest?: Maybe<PeerRequest>;
  addUsersToEmployeeRoleContributors?: Maybe<Scalars['Boolean']>;
  addUserToGroup?: Maybe<Scalars['Boolean']>;
  approveEvaluation?: Maybe<Evaluation>;
  cancelPeerRequest?: Maybe<PeerRequest>;
  createEmployeeRole?: Maybe<EmployeeRole>;
  createEvaluation?: Maybe<EditEvaluationResult>;
  createGoal?: Maybe<EvaluationObjective>;
  createManagerEvaluation?: Maybe<EvaluationReview>;
  createPeerRequest?: Maybe<PeerRequest>;
  createReviewOpportunity?: Maybe<EvaluationStrengthOrOpportunity>;
  createReviewStrength?: Maybe<EvaluationStrengthOrOpportunity>;
  createSelfEvaluation?: Maybe<EvaluationReview>;
  createUser?: Maybe<EditUserResult>;
  deleteEmployeeRole?: Maybe<EmployeeRole>;
  deleteGoal?: Maybe<EvaluationObjective>;
  deletePeerRequest?: Maybe<PeerRequest>;
  deleteReviewOpportunity?: Maybe<EvaluationStrengthOrOpportunity>;
  deleteReviewStrength?: Maybe<EvaluationStrengthOrOpportunity>;
  deleteUser?: Maybe<User>;
  publishEvaluationForEmployeeReview?: Maybe<Evaluation>;
  rejectPeerRequest?: Maybe<PeerRequest>;
  removeUsersFromEmployeeRoleContributors?: Maybe<Scalars['Boolean']>;
  requestEvaluationChanges?: Maybe<Evaluation>;
  signEvaluation?: Maybe<Evaluation>;
  submitEvaluationForReview?: Maybe<Evaluation>;
  submitEvaluationReview?: Maybe<EvaluationReview>;
  submitObjectiveReview?: Maybe<ObjectiveReview>;
  submitValueReview?: Maybe<ValueReview>;
  unsubmitEvaluationFromReview?: Maybe<Evaluation>;
  unsubmitEvaluationReview?: Maybe<EvaluationReview>;
  unsubmitValueReview?: Maybe<ValueReview>;
  updateEmployeeRole?: Maybe<EmployeeRole>;
  updateGoal?: Maybe<EvaluationObjective>;
  updateReviewOpportunity?: Maybe<EvaluationStrengthOrOpportunity>;
  updateReviewStrength?: Maybe<EvaluationStrengthOrOpportunity>;
  updateReviewValue?: Maybe<ValueReviewValue>;
  updateUser?: Maybe<EditUserResult>;
};


export type MutationAcceptPeerRequestArgs = {
  id: Scalars['Int'];
};


export type MutationAddUsersToEmployeeRoleContributorsArgs = {
  employeeRoleId: Scalars['Int'];
  userIds: Array<Scalars['Int']>;
};


export type MutationAddUserToGroupArgs = {
  userId?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
};


export type MutationApproveEvaluationArgs = {
  id: Scalars['Int'];
};


export type MutationCancelPeerRequestArgs = {
  id: Scalars['Int'];
  reasonId: Scalars['Int'];
};


export type MutationCreateEmployeeRoleArgs = {
  role: CreateEmployeeRoleInput;
};


export type MutationCreateEvaluationArgs = {
  evaluation: CreateEvaluationInput;
};


export type MutationCreateGoalArgs = {
  goal: CreateGoalInput;
};


export type MutationCreateManagerEvaluationArgs = {
  evaluationId: Scalars['Int'];
};


export type MutationCreatePeerRequestArgs = {
  request: CreatePeerRequestInput;
};


export type MutationCreateReviewOpportunityArgs = {
  opportunity: CreateStrengthOrOpportunityInput;
};


export type MutationCreateReviewStrengthArgs = {
  strength: CreateStrengthOrOpportunityInput;
};


export type MutationCreateSelfEvaluationArgs = {
  evaluationId: Scalars['Int'];
};


export type MutationCreateUserArgs = {
  user: CreateUserInput;
};


export type MutationDeleteEmployeeRoleArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteGoalArgs = {
  id: Scalars['Int'];
};


export type MutationDeletePeerRequestArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteReviewOpportunityArgs = {
  id: Scalars['Int'];
  reviewClassification: ReviewClassificationEnum;
};


export type MutationDeleteReviewStrengthArgs = {
  id: Scalars['Int'];
  reviewClassification: ReviewClassificationEnum;
};


export type MutationDeleteUserArgs = {
  id: Scalars['Int'];
};


export type MutationPublishEvaluationForEmployeeReviewArgs = {
  id: Scalars['Int'];
};


export type MutationRejectPeerRequestArgs = {
  id: Scalars['Int'];
  reasonId: Scalars['Int'];
};


export type MutationRemoveUsersFromEmployeeRoleContributorsArgs = {
  employeeRoleId: Scalars['Int'];
  userIds: Array<Scalars['Int']>;
};


export type MutationRequestEvaluationChangesArgs = {
  id: Scalars['Int'];
  requestedChanges: Scalars['String'];
};


export type MutationSignEvaluationArgs = {
  id: Scalars['Int'];
};


export type MutationSubmitEvaluationForReviewArgs = {
  id: Scalars['Int'];
};


export type MutationSubmitEvaluationReviewArgs = {
  id: Scalars['Int'];
};


export type MutationSubmitObjectiveReviewArgs = {
  id: Scalars['Int'];
};


export type MutationSubmitValueReviewArgs = {
  id: Scalars['Int'];
};


export type MutationUnsubmitEvaluationFromReviewArgs = {
  id: Scalars['Int'];
};


export type MutationUnsubmitEvaluationReviewArgs = {
  id: Scalars['Int'];
};


export type MutationUnsubmitValueReviewArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateEmployeeRoleArgs = {
  role: UpdateEmployeeRoleInput;
};


export type MutationUpdateGoalArgs = {
  goal: UpdateGoalInput;
};


export type MutationUpdateReviewOpportunityArgs = {
  opportunity: UpdateStrengthOrOpportunityInput;
};


export type MutationUpdateReviewStrengthArgs = {
  strength: UpdateStrengthOrOpportunityInput;
};


export type MutationUpdateReviewValueArgs = {
  value: UpdateReviewValueInput;
};


export type MutationUpdateUserArgs = {
  user: UpdateUserInput;
};

export type EditUserResult = User | UserAlreadyExists;

/** A result type given when the resource that was attempted to be created already exists. */
export type UserAlreadyExists = Error & {
  __typename?: 'UserAlreadyExists';
  /** The existing entity with matching properties. */
  existing?: Maybe<User>;
  message: Scalars['String'];
};

export type Error = {
  message: Scalars['String'];
};

/** An input object used for creating a user. */
export type CreateUserInput = {
  /** The first name of the user. */
  firstName: Scalars['String'];
  /** The last name of the user. */
  lastName: Scalars['String'];
  /** The title of the user. */
  title?: Maybe<Scalars['String']>;
  /** The email address of the user. This is what the user will use when logging in. */
  email: Scalars['String'];
  /** The id of the employee role that this user holds. */
  employeeRoleId?: Maybe<Scalars['Int']>;
  /** The id of this user's manager. */
  managerId?: Maybe<Scalars['Int']>;
  /** The id of the department that this user belongs to. */
  departmentId: Scalars['Int'];
  /** The id of the company that this user belongs to. */
  companyId: Scalars['Int'];
};

/** An input object used for updating a user. */
export type UpdateUserInput = {
  /** The id of the user that will be updated. */
  id: Scalars['Int'];
  /** The first name of the user. */
  firstName?: Maybe<Scalars['String']>;
  /** The last name of the user. */
  lastName?: Maybe<Scalars['String']>;
  /** The title of the user. */
  title?: Maybe<Scalars['String']>;
  /** The email address of the user. This is what the user will use when logging in. */
  email?: Maybe<Scalars['String']>;
  /** The id of the employee role that this user holds. */
  employeeRoleId?: Maybe<Scalars['Int']>;
  /** The id of this user's manager. */
  managerId?: Maybe<Scalars['Int']>;
  /** The id of the department that this user belongs to. */
  departmentId?: Maybe<Scalars['Int']>;
  /** The id of the company that this user belongs to. */
  companyId?: Maybe<Scalars['Int']>;
};

export type EditEvaluationResult = Evaluation | EvaluationAlreadyExists;

/** A result type given when the resource that was attempted to be created already exists. */
export type EvaluationAlreadyExists = Error & {
  __typename?: 'EvaluationAlreadyExists';
  /** The existing entity with matching properties. */
  existing?: Maybe<Evaluation>;
  message: Scalars['String'];
};

/** An input object used for creating an evaluation for a given user. */
export type CreateEvaluationInput = {
  /** The id of the user for which the evaluation will be created. */
  employeeId: Scalars['Int'];
  /** The id of the evaluation cycle that this evaluation belongs to. */
  evaluationCycleId: Scalars['Int'];
  /** The id of an evaluation cycle to use as a goals template. */
  goalsTemplateEvaluationCycleId?: Maybe<Scalars['Int']>;
  goalsTemplate: GoalsTemplateEnum;
};

export enum GoalsTemplateEnum {
  PreviousEvaluation = 'PREVIOUS_EVALUATION',
  RoleScorecard = 'ROLE_SCORECARD',
  None = 'NONE'
}

/** An input object used for creating a goal. */
export type CreateGoalInput = {
  /** The name of the goal. */
  name: Scalars['String'];
  /** The id of the evaluation that this goal belongs to. */
  evaluationId: Scalars['Int'];
  /** The id of the goal that is a parent of this goal. */
  evaluationObjectiveParentId?: Maybe<Scalars['Int']>;
  /** The id of the status for this goal. */
  evaluationObjectiveStatusId?: Maybe<Scalars['Int']>;
  /** The weight of this goal compared to other goals. */
  objectiveWeight?: Maybe<Scalars['Decimal']>;
  /** The due date of this goal. */
  dueDate?: Maybe<Scalars['Date']>;
};

/** An input object used for updating a goal. */
export type UpdateGoalInput = {
  /** The id of the goal. */
  id: Scalars['Int'];
  /** The name of the goal. */
  name?: Maybe<Scalars['String']>;
  /** The id of the status for this goal. */
  evaluationObjectiveStatusId?: Maybe<Scalars['Int']>;
  /** The weight of this goal compared to other goals. */
  objectiveWeight?: Maybe<Scalars['Decimal']>;
  /** The score of this goal as entered by a manager. */
  performanceReward?: Maybe<Scalars['Decimal']>;
  /** The due date of this goal. */
  dueDate?: Maybe<Scalars['Date']>;
};

/** An input object used for creating an strength or opportunity for a goal or value on a given review. */
export type CreateStrengthOrOpportunityInput = {
  /** The id of the review this strength or opportunity is being added to. */
  reviewId: Scalars['Int'];
  /** The id of the goal or value that the strength or opportunity is being added for. */
  reviewEntryId: Scalars['Int'];
  /** The description of the strength or opportunity. */
  description: Scalars['String'];
  reviewClassification: ReviewClassificationEnum;
};

export enum ReviewClassificationEnum {
  Goal = 'GOAL',
  Value = 'VALUE'
}

/** An input object used for updating a strength or opportunity for a goal or value on a given review. */
export type UpdateStrengthOrOpportunityInput = {
  /** The id of the strength or opportunity that is being updated. */
  id: Scalars['Int'];
  /** The description of the strength or opportunity. */
  description: Scalars['String'];
  reviewClassification: ReviewClassificationEnum;
};

/** An input object used for updating a review value. */
export type UpdateReviewValueInput = {
  /** The id of the review value. */
  id: Scalars['Int'];
  /** The score of the review value. */
  score?: Maybe<Scalars['Decimal']>;
};

/** An input object used for creating a peer request. */
export type CreatePeerRequestInput = {
  /** The id of the evaluation that this the peer request is associated with. */
  evaluationId: Scalars['Int'];
  /** The id of the peer that the request is being sent to. */
  peerId: Scalars['Int'];
};

/** An input object used for creating an employee role. */
export type CreateEmployeeRoleInput = {
  /** The name of the role. */
  name: Scalars['String'];
  /** The mission statement of the role */
  missionStatement?: Maybe<Scalars['String']>;
  /** The id of the manager role. */
  managerRoleId?: Maybe<Scalars['Int']>;
  /** The objectives of the role. */
  objectives: Array<CreateObjectiveInput>;
  /** The competencies of the role. */
  competencies: Array<CreateCompetencyInput>;
  /** The competencies of the role. */
  qualifications: Array<Scalars['String']>;
};

/** An input object used for creating an objective. */
export type CreateObjectiveInput = {
  /** The name of the objective. */
  name: Scalars['String'];
  /** The key results of the objective. */
  keyResults: Array<Scalars['String']>;
};

/** An input object used for creating a competency. */
export type CreateCompetencyInput = {
  /** The name of the competency. */
  name: Scalars['String'];
  /** The category of the competency. */
  category: Scalars['String'];
};

/** An input object used for creating an employee role. */
export type UpdateEmployeeRoleInput = {
  /** The id of the role. */
  id: Scalars['Int'];
  /** The name of the role. */
  name: Scalars['String'];
  /** The mission statement of the role */
  missionStatement?: Maybe<Scalars['String']>;
  /** The id of the manager role. */
  managerRoleId?: Maybe<Scalars['Int']>;
  /** The objectives of the role. */
  objectives: Array<CreateObjectiveInput>;
  /** The competencies of the role. */
  competencies: Array<CreateCompetencyInput>;
  /** The competencies of the role. */
  qualifications: Array<Scalars['String']>;
};

/** A result type given when an error occurred during an operation */
export type ErrorResult = Error & {
  __typename?: 'ErrorResult';
  message: Scalars['String'];
};

export type CreateEvaluationVariables = Exact<{
  evaluation: CreateEvaluationInput;
}>;


export type CreateEvaluation = (
  { __typename?: 'Mutation' }
  & { createEvaluation?: Maybe<(
    { __typename: 'Evaluation' }
    & EvaluationFragment
  ) | (
    { __typename: 'EvaluationAlreadyExists' }
    & { existing?: Maybe<(
      { __typename?: 'Evaluation' }
      & EvaluationFragment
    )> }
    & ErrorFragment_EvaluationAlreadyExists_
  )> }
);

export type EvaluationFragment = (
  { __typename?: 'Evaluation' }
  & Pick<Evaluation, 'id'>
  & { evaluationCycle: (
    { __typename?: 'EvaluationCycle' }
    & Pick<EvaluationCycle, 'id' | 'name'>
    & { type: (
      { __typename?: 'EvaluationType' }
      & Pick<EvaluationType, 'id' | 'name'>
    ) }
  ) }
);

export type CreateGoalVariables = Exact<{
  goal: CreateGoalInput;
}>;


export type CreateGoal = (
  { __typename?: 'Mutation' }
  & { createGoal?: Maybe<(
    { __typename?: 'EvaluationObjective' }
    & Pick<EvaluationObjective, 'id' | 'name' | 'weight' | 'dueDate' | 'parentId'>
    & { status: (
      { __typename?: 'EvaluationObjectiveStatus' }
      & Pick<EvaluationObjectiveStatus, 'id' | 'name'>
    ), subgoals?: Maybe<Array<(
      { __typename?: 'EvaluationObjective' }
      & Pick<EvaluationObjective, 'id' | 'name' | 'weight' | 'dueDate' | 'parentId'>
      & { status: (
        { __typename?: 'EvaluationObjectiveStatus' }
        & Pick<EvaluationObjectiveStatus, 'id' | 'name'>
      ), subgoals?: Maybe<Array<(
        { __typename?: 'EvaluationObjective' }
        & Pick<EvaluationObjective, 'id' | 'name'>
      )>> }
    )>> }
  )> }
);

export type UpdateGoalVariables = Exact<{
  goal: UpdateGoalInput;
}>;


export type UpdateGoal = (
  { __typename?: 'Mutation' }
  & { updateGoal?: Maybe<(
    { __typename?: 'EvaluationObjective' }
    & Pick<EvaluationObjective, 'id' | 'name' | 'weight' | 'dueDate' | 'parentId'>
    & { status: (
      { __typename?: 'EvaluationObjectiveStatus' }
      & Pick<EvaluationObjectiveStatus, 'id' | 'name'>
    ), subgoals?: Maybe<Array<(
      { __typename?: 'EvaluationObjective' }
      & Pick<EvaluationObjective, 'id' | 'name' | 'weight' | 'dueDate' | 'parentId'>
      & { status: (
        { __typename?: 'EvaluationObjectiveStatus' }
        & Pick<EvaluationObjectiveStatus, 'id' | 'name'>
      ), subgoals?: Maybe<Array<(
        { __typename?: 'EvaluationObjective' }
        & Pick<EvaluationObjective, 'id' | 'name'>
      )>> }
    )>> }
  )> }
);

export type DeleteGoalVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteGoal = (
  { __typename?: 'Mutation' }
  & { deleteGoal?: Maybe<(
    { __typename?: 'EvaluationObjective' }
    & Pick<EvaluationObjective, 'id' | 'name'>
  )> }
);

export type CreateUserVariables = Exact<{
  user: CreateUserInput;
}>;


export type CreateUser = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename: 'User' }
    & UserFragment
  ) | (
    { __typename: 'UserAlreadyExists' }
    & { existing?: Maybe<(
      { __typename?: 'User' }
      & UserFragment
    )> }
    & ErrorFragment_UserAlreadyExists_
  )> }
);

export type UpdateUserVariables = Exact<{
  user: UpdateUserInput;
}>;


export type UpdateUser = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename: 'User' }
    & UserFragment
  ) | (
    { __typename: 'UserAlreadyExists' }
    & { existing?: Maybe<(
      { __typename?: 'User' }
      & UserFragment
    )> }
    & ErrorFragment_UserAlreadyExists_
  )> }
);

export type AddUserToGroupVariables = Exact<{
  userId: Scalars['Int'];
  groupId: Scalars['Int'];
}>;


export type AddUserToGroup = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addUserToGroup'>
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'email' | 'title'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  ), role?: Maybe<(
    { __typename?: 'EmployeeRole' }
    & Pick<EmployeeRole, 'id' | 'name'>
  )>, department: (
    { __typename?: 'Department' }
    & Pick<Department, 'id' | 'name'>
  ), manager?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'email'>
  )> }
);

export type DeleteUserVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteUser = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>
  )> }
);

export type ErrorFragment_UserAlreadyExists_ = (
  { __typename?: 'UserAlreadyExists' }
  & Pick<UserAlreadyExists, 'message'>
);

export type ErrorFragment_EvaluationAlreadyExists_ = (
  { __typename?: 'EvaluationAlreadyExists' }
  & Pick<EvaluationAlreadyExists, 'message'>
);

export type ErrorFragment_ErrorResult_ = (
  { __typename?: 'ErrorResult' }
  & Pick<ErrorResult, 'message'>
);

export type ErrorFragment = ErrorFragment_UserAlreadyExists_ | ErrorFragment_EvaluationAlreadyExists_ | ErrorFragment_ErrorResult_;

export type CreateSelfEvaluationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CreateSelfEvaluation = (
  { __typename?: 'Mutation' }
  & { createSelfEvaluation?: Maybe<(
    { __typename: 'EvaluationReview' }
    & Pick<EvaluationReview, 'id'>
    & { status: (
      { __typename?: 'ReviewStatus' }
      & Pick<ReviewStatus, 'id' | 'name'>
    ), type: (
      { __typename?: 'ReviewType' }
      & Pick<ReviewType, 'id' | 'name'>
    ) }
  )> }
);

export type CreateManagerEvaluationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CreateManagerEvaluation = (
  { __typename?: 'Mutation' }
  & { createManagerEvaluation?: Maybe<(
    { __typename: 'EvaluationReview' }
    & Pick<EvaluationReview, 'id'>
    & { status: (
      { __typename?: 'ReviewStatus' }
      & Pick<ReviewStatus, 'id' | 'name'>
    ), type: (
      { __typename?: 'ReviewType' }
      & Pick<ReviewType, 'id' | 'name'>
    ) }
  )> }
);

export type CreateReviewOpportunityVariables = Exact<{
  opportunity: CreateStrengthOrOpportunityInput;
}>;


export type CreateReviewOpportunity = (
  { __typename?: 'Mutation' }
  & { createReviewOpportunity?: Maybe<(
    { __typename?: 'EvaluationStrengthOrOpportunity' }
    & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
  )> }
);

export type UpdateReviewOpportunityVariables = Exact<{
  opportunity: UpdateStrengthOrOpportunityInput;
}>;


export type UpdateReviewOpportunity = (
  { __typename?: 'Mutation' }
  & { updateReviewOpportunity?: Maybe<(
    { __typename?: 'EvaluationStrengthOrOpportunity' }
    & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
  )> }
);

export type DeleteReviewOpportunityVariables = Exact<{
  id: Scalars['Int'];
  reviewClassification: ReviewClassificationEnum;
}>;


export type DeleteReviewOpportunity = (
  { __typename?: 'Mutation' }
  & { deleteReviewOpportunity?: Maybe<(
    { __typename?: 'EvaluationStrengthOrOpportunity' }
    & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
  )> }
);

export type UpdateReviewStrengthVariables = Exact<{
  strength: UpdateStrengthOrOpportunityInput;
}>;


export type UpdateReviewStrength = (
  { __typename?: 'Mutation' }
  & { updateReviewStrength?: Maybe<(
    { __typename?: 'EvaluationStrengthOrOpportunity' }
    & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
  )> }
);

export type CreateReviewStrengthVariables = Exact<{
  strength: CreateStrengthOrOpportunityInput;
}>;


export type CreateReviewStrength = (
  { __typename?: 'Mutation' }
  & { createReviewStrength?: Maybe<(
    { __typename?: 'EvaluationStrengthOrOpportunity' }
    & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
  )> }
);

export type DeleteReviewStrengthVariables = Exact<{
  id: Scalars['Int'];
  reviewClassification: ReviewClassificationEnum;
}>;


export type DeleteReviewStrength = (
  { __typename?: 'Mutation' }
  & { deleteReviewStrength?: Maybe<(
    { __typename?: 'EvaluationStrengthOrOpportunity' }
    & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
  )> }
);

export type UpdateReviewValueVariables = Exact<{
  value: UpdateReviewValueInput;
}>;


export type UpdateReviewValue = (
  { __typename?: 'Mutation' }
  & { updateReviewValue?: Maybe<(
    { __typename?: 'ValueReviewValue' }
    & Pick<ValueReviewValue, 'id' | 'name' | 'description' | 'updatedAt'>
  )> }
);

export type SubmitEvaluationReviewVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SubmitEvaluationReview = (
  { __typename?: 'Mutation' }
  & { submitEvaluationReview?: Maybe<(
    { __typename?: 'EvaluationReview' }
    & Pick<EvaluationReview, 'id'>
    & { status: (
      { __typename?: 'ReviewStatus' }
      & Pick<ReviewStatus, 'id' | 'name'>
    ), goalsReview: (
      { __typename?: 'ObjectiveReview' }
      & Pick<ObjectiveReview, 'id'>
      & { status: (
        { __typename?: 'ReviewStatus' }
        & Pick<ReviewStatus, 'id' | 'name'>
      ) }
    ), valuesReview: (
      { __typename?: 'ValueReview' }
      & Pick<ValueReview, 'id'>
      & { status: (
        { __typename?: 'ReviewStatus' }
        & Pick<ReviewStatus, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type UnsubmitEvaluationReviewVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UnsubmitEvaluationReview = (
  { __typename?: 'Mutation' }
  & { unsubmitEvaluationReview?: Maybe<(
    { __typename?: 'EvaluationReview' }
    & Pick<EvaluationReview, 'id'>
    & { status: (
      { __typename?: 'ReviewStatus' }
      & Pick<ReviewStatus, 'id' | 'name'>
    ), goalsReview: (
      { __typename?: 'ObjectiveReview' }
      & Pick<ObjectiveReview, 'id'>
      & { status: (
        { __typename?: 'ReviewStatus' }
        & Pick<ReviewStatus, 'id' | 'name'>
      ) }
    ), valuesReview: (
      { __typename?: 'ValueReview' }
      & Pick<ValueReview, 'id'>
      & { status: (
        { __typename?: 'ReviewStatus' }
        & Pick<ReviewStatus, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type SubmitValueReviewVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SubmitValueReview = (
  { __typename?: 'Mutation' }
  & { submitValueReview?: Maybe<(
    { __typename?: 'ValueReview' }
    & Pick<ValueReview, 'id'>
    & { status: (
      { __typename?: 'ReviewStatus' }
      & Pick<ReviewStatus, 'id' | 'name'>
    ) }
  )> }
);

export type UnsubmitValueReviewVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UnsubmitValueReview = (
  { __typename?: 'Mutation' }
  & { unsubmitValueReview?: Maybe<(
    { __typename?: 'ValueReview' }
    & Pick<ValueReview, 'id'>
    & { status: (
      { __typename?: 'ReviewStatus' }
      & Pick<ReviewStatus, 'id' | 'name'>
    ) }
  )> }
);

export type SubmitObjectiveReviewVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SubmitObjectiveReview = (
  { __typename?: 'Mutation' }
  & { submitValueReview?: Maybe<(
    { __typename?: 'ValueReview' }
    & Pick<ValueReview, 'id'>
    & { status: (
      { __typename?: 'ReviewStatus' }
      & Pick<ReviewStatus, 'id' | 'name'>
    ) }
  )> }
);

export type CreatePeerRequestVariables = Exact<{
  request: CreatePeerRequestInput;
}>;


export type CreatePeerRequest = (
  { __typename?: 'Mutation' }
  & { createPeerRequest?: Maybe<(
    { __typename?: 'PeerRequest' }
    & Pick<PeerRequest, 'id'>
    & { status: (
      { __typename?: 'PeerRequestStatus' }
      & Pick<PeerRequestStatus, 'id' | 'name'>
    ) }
  )> }
);

export type AcceptPeerRequestVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AcceptPeerRequest = (
  { __typename?: 'Mutation' }
  & { acceptPeerRequest?: Maybe<(
    { __typename?: 'PeerRequest' }
    & Pick<PeerRequest, 'id'>
    & { status: (
      { __typename?: 'PeerRequestStatus' }
      & Pick<PeerRequestStatus, 'id' | 'name'>
    ) }
  )> }
);

export type RejectPeerRequestVariables = Exact<{
  id: Scalars['Int'];
  reasonId: Scalars['Int'];
}>;


export type RejectPeerRequest = (
  { __typename?: 'Mutation' }
  & { rejectPeerRequest?: Maybe<(
    { __typename?: 'PeerRequest' }
    & Pick<PeerRequest, 'id'>
    & { status: (
      { __typename?: 'PeerRequestStatus' }
      & Pick<PeerRequestStatus, 'id' | 'name'>
    ), denyReason?: Maybe<(
      { __typename?: 'PeerRequestDenyReason' }
      & Pick<PeerRequestDenyReason, 'id' | 'name'>
    )> }
  )> }
);

export type CancelPeerRequestVariables = Exact<{
  id: Scalars['Int'];
  reasonId: Scalars['Int'];
}>;


export type CancelPeerRequest = (
  { __typename?: 'Mutation' }
  & { cancelPeerRequest?: Maybe<(
    { __typename?: 'PeerRequest' }
    & Pick<PeerRequest, 'id'>
    & { status: (
      { __typename?: 'PeerRequestStatus' }
      & Pick<PeerRequestStatus, 'id' | 'name'>
    ), denyReason?: Maybe<(
      { __typename?: 'PeerRequestDenyReason' }
      & Pick<PeerRequestDenyReason, 'id' | 'name'>
    )> }
  )> }
);

export type DeletePeerRequestVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePeerRequest = (
  { __typename?: 'Mutation' }
  & { deletePeerRequest?: Maybe<(
    { __typename?: 'PeerRequest' }
    & Pick<PeerRequest, 'id'>
    & { status: (
      { __typename?: 'PeerRequestStatus' }
      & Pick<PeerRequestStatus, 'id' | 'name'>
    ) }
  )> }
);

export type SubmitEvaluationForReviewVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SubmitEvaluationForReview = (
  { __typename?: 'Mutation' }
  & { submitEvaluationForReview?: Maybe<(
    { __typename?: 'Evaluation' }
    & Pick<Evaluation, 'id' | 'changesRequested'>
    & { status?: Maybe<(
      { __typename?: 'EvaluationStatus' }
      & Pick<EvaluationStatus, 'id' | 'name'>
    )>, subject: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    ) }
  )> }
);

export type UnsubmitEvaluationFromReviewVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UnsubmitEvaluationFromReview = (
  { __typename?: 'Mutation' }
  & { unsubmitEvaluationFromReview?: Maybe<(
    { __typename?: 'Evaluation' }
    & Pick<Evaluation, 'id' | 'changesRequested'>
    & { status?: Maybe<(
      { __typename?: 'EvaluationStatus' }
      & Pick<EvaluationStatus, 'id' | 'name'>
    )>, subject: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    ) }
  )> }
);

export type PublishEvaluationForEmployeeReviewVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PublishEvaluationForEmployeeReview = (
  { __typename?: 'Mutation' }
  & { publishEvaluationForEmployeeReview?: Maybe<(
    { __typename?: 'Evaluation' }
    & Pick<Evaluation, 'id' | 'changesRequested'>
    & { status?: Maybe<(
      { __typename?: 'EvaluationStatus' }
      & Pick<EvaluationStatus, 'id' | 'name'>
    )>, subject: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    ) }
  )> }
);

export type RequestEvaluationChangesVariables = Exact<{
  id: Scalars['Int'];
  requestedChanges: Scalars['String'];
}>;


export type RequestEvaluationChanges = (
  { __typename?: 'Mutation' }
  & { requestEvaluationChanges?: Maybe<(
    { __typename?: 'Evaluation' }
    & Pick<Evaluation, 'id' | 'changesRequested'>
    & { status?: Maybe<(
      { __typename?: 'EvaluationStatus' }
      & Pick<EvaluationStatus, 'id' | 'name'>
    )>, subject: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    ) }
  )> }
);

export type ApproveEvaluationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ApproveEvaluation = (
  { __typename?: 'Mutation' }
  & { approveEvaluation?: Maybe<(
    { __typename?: 'Evaluation' }
    & Pick<Evaluation, 'id' | 'changesRequested'>
    & { status?: Maybe<(
      { __typename?: 'EvaluationStatus' }
      & Pick<EvaluationStatus, 'id' | 'name'>
    )>, subject: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    ) }
  )> }
);

export type SignEvaluationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SignEvaluation = (
  { __typename?: 'Mutation' }
  & { signEvaluation?: Maybe<(
    { __typename?: 'Evaluation' }
    & Pick<Evaluation, 'id' | 'changesRequested'>
    & { status?: Maybe<(
      { __typename?: 'EvaluationStatus' }
      & Pick<EvaluationStatus, 'id' | 'name'>
    )>, subject: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
    ) }
  )> }
);

export type CreateEmployeeRoleVariables = Exact<{
  role: CreateEmployeeRoleInput;
}>;


export type CreateEmployeeRole = (
  { __typename?: 'Mutation' }
  & { createEmployeeRole?: Maybe<(
    { __typename: 'EmployeeRole' }
    & Pick<EmployeeRole, 'id' | 'name' | 'missionStatement'>
    & { competencies: Array<(
      { __typename?: 'Competency' }
      & Pick<Competency, 'name' | 'category'>
    )>, qualifications: Array<(
      { __typename?: 'Qualification' }
      & Pick<Qualification, 'id' | 'name'>
    )>, objectives?: Maybe<Array<(
      { __typename?: 'Objective' }
      & Pick<Objective, 'id' | 'name' | 'keyResults'>
    )>> }
  )> }
);

export type UpdateEmployeeRoleVariables = Exact<{
  role: UpdateEmployeeRoleInput;
}>;


export type UpdateEmployeeRole = (
  { __typename?: 'Mutation' }
  & { updateEmployeeRole?: Maybe<(
    { __typename: 'EmployeeRole' }
    & Pick<EmployeeRole, 'id' | 'name' | 'missionStatement'>
    & { managerRole?: Maybe<(
      { __typename?: 'EmployeeRole' }
      & Pick<EmployeeRole, 'id' | 'name'>
    )>, competencies: Array<(
      { __typename?: 'Competency' }
      & Pick<Competency, 'name' | 'category'>
    )>, qualifications: Array<(
      { __typename?: 'Qualification' }
      & Pick<Qualification, 'id' | 'name'>
    )>, objectives?: Maybe<Array<(
      { __typename?: 'Objective' }
      & Pick<Objective, 'id' | 'name' | 'keyResults'>
    )>> }
  )> }
);

export type DeleteEmployeeRoleVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteEmployeeRole = (
  { __typename?: 'Mutation' }
  & { deleteEmployeeRole?: Maybe<(
    { __typename: 'EmployeeRole' }
    & Pick<EmployeeRole, 'id' | 'name' | 'missionStatement'>
    & { managerRole?: Maybe<(
      { __typename?: 'EmployeeRole' }
      & Pick<EmployeeRole, 'id' | 'name'>
    )>, competencies: Array<(
      { __typename?: 'Competency' }
      & Pick<Competency, 'name' | 'category'>
    )>, qualifications: Array<(
      { __typename?: 'Qualification' }
      & Pick<Qualification, 'id' | 'name'>
    )>, objectives?: Maybe<Array<(
      { __typename?: 'Objective' }
      & Pick<Objective, 'id' | 'name' | 'keyResults'>
    )>> }
  )> }
);

export type AddUsersToEmployeeRoleContributorsVariables = Exact<{
  employeeRoleId: Scalars['Int'];
  userIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type AddUsersToEmployeeRoleContributors = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addUsersToEmployeeRoleContributors'>
);

export type RemoveUsersFromEmployeeRoleContributorsVariables = Exact<{
  employeeRoleId: Scalars['Int'];
  userIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RemoveUsersFromEmployeeRoleContributors = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeUsersFromEmployeeRoleContributors'>
);

export type GetCurrentUserOrganizationVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserOrganization = (
  { __typename?: 'TenantQuery' }
  & { currentUser: (
    { __typename?: 'CurrentUser' }
    & { organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'organizationPath'>
    )> }
  ) }
);

export type GetCurrentUserVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUser = (
  { __typename?: 'TenantQuery' }
  & { currentUser: (
    { __typename?: 'CurrentUser' }
    & { profile?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'title'>
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'organizationPath'>
    )> }
  ) }
);

export type GetDirectoryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type GetDirectory = (
  { __typename?: 'TenantQuery' }
  & Pick<TenantQuery, 'canEditUsers'>
  & { users?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'email' | 'title'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ), manager?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'email'>
    )>, role?: Maybe<(
      { __typename?: 'EmployeeRole' }
      & Pick<EmployeeRole, 'id' | 'name'>
    )>, department: (
      { __typename?: 'Department' }
      & Pick<Department, 'id' | 'name'>
    ) }
  )>>, directReports?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'email' | 'title'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ), manager?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'email'>
    )>, role?: Maybe<(
      { __typename?: 'EmployeeRole' }
      & Pick<EmployeeRole, 'id' | 'name'>
    )>, department: (
      { __typename?: 'Department' }
      & Pick<Department, 'id' | 'name'>
    ) }
  )>> }
);

export type GetDirectReportsVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type GetDirectReports = (
  { __typename?: 'TenantQuery' }
  & { directReports?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'email' | 'title'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ), role?: Maybe<(
      { __typename?: 'EmployeeRole' }
      & Pick<EmployeeRole, 'id' | 'name'>
    )>, department: (
      { __typename?: 'Department' }
      & Pick<Department, 'id' | 'name'>
    ) }
  )>> }
);

export type GetDirectoryUserVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetDirectoryUser = (
  { __typename?: 'TenantQuery' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'email' | 'title' | 'currentUserCanEdit' | 'isCurrentUserAdmin' | 'isUserAdmin'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ), role?: Maybe<(
      { __typename?: 'EmployeeRole' }
      & Pick<EmployeeRole, 'id' | 'name'>
    )>, department: (
      { __typename?: 'Department' }
      & Pick<Department, 'id' | 'name'>
    ), manager?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'email'>
    )> }
  )> }
);

export type GetDirectoryUserGoalsVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type GetDirectoryUserGoals = (
  { __typename?: 'TenantQuery' }
  & { evaluations?: Maybe<Array<(
    { __typename?: 'Evaluation' }
    & Pick<Evaluation, 'id'>
    & { status?: Maybe<(
      { __typename?: 'EvaluationStatus' }
      & Pick<EvaluationStatus, 'id' | 'name'>
    )>, goals?: Maybe<Array<(
      { __typename?: 'EvaluationObjective' }
      & Pick<EvaluationObjective, 'id' | 'name'>
    )>>, subject: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'email' | 'title'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ), role?: Maybe<(
        { __typename?: 'EmployeeRole' }
        & Pick<EmployeeRole, 'id' | 'name'>
      )>, department: (
        { __typename?: 'Department' }
        & Pick<Department, 'id' | 'name'>
      ) }
    ), evaluationCycle: (
      { __typename?: 'EvaluationCycle' }
      & Pick<EvaluationCycle, 'id' | 'name' | 'managerReviewStartDate' | 'peerFeedbackStartDate' | 'releaseToEmployeeDate' | 'selfEvaluationStartDate'>
      & { type: (
        { __typename?: 'EvaluationType' }
        & Pick<EvaluationType, 'id' | 'name'>
      ) }
    ) }
  )>> }
);

export type GetEvaluationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetEvaluation = (
  { __typename?: 'TenantQuery' }
  & { evaluation?: Maybe<(
    { __typename?: 'Evaluation' }
    & Pick<Evaluation, 'id'>
    & { goals?: Maybe<Array<(
      { __typename?: 'EvaluationObjective' }
      & Pick<EvaluationObjective, 'id' | 'name' | 'weight' | 'dueDate'>
      & { status: (
        { __typename?: 'EvaluationObjectiveStatus' }
        & Pick<EvaluationObjectiveStatus, 'id' | 'name'>
      ), subgoals?: Maybe<Array<(
        { __typename?: 'EvaluationObjective' }
        & Pick<EvaluationObjective, 'id' | 'name' | 'weight' | 'dueDate' | 'parentId'>
        & { status: (
          { __typename?: 'EvaluationObjectiveStatus' }
          & Pick<EvaluationObjectiveStatus, 'id' | 'name'>
        ) }
      )>> }
    )>>, subject: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'email' | 'title'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'name'>
      ), role?: Maybe<(
        { __typename?: 'EmployeeRole' }
        & Pick<EmployeeRole, 'id' | 'name'>
      )>, department: (
        { __typename?: 'Department' }
        & Pick<Department, 'id' | 'name'>
      ) }
    ), evaluationCycle: (
      { __typename?: 'EvaluationCycle' }
      & Pick<EvaluationCycle, 'id' | 'name' | 'managerReviewStartDate' | 'peerFeedbackStartDate' | 'releaseToEmployeeDate' | 'selfEvaluationStartDate'>
      & { type: (
        { __typename?: 'EvaluationType' }
        & Pick<EvaluationType, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type GetGoalVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetGoal = (
  { __typename?: 'TenantQuery' }
  & { goal?: Maybe<(
    { __typename?: 'EvaluationObjective' }
    & Pick<EvaluationObjective, 'id' | 'name' | 'weight' | 'dueDate' | 'parentId'>
    & { status: (
      { __typename?: 'EvaluationObjectiveStatus' }
      & Pick<EvaluationObjectiveStatus, 'id' | 'name'>
    ), subgoals?: Maybe<Array<(
      { __typename?: 'EvaluationObjective' }
      & Pick<EvaluationObjective, 'id' | 'name' | 'weight' | 'dueDate' | 'parentId'>
      & { status: (
        { __typename?: 'EvaluationObjectiveStatus' }
        & Pick<EvaluationObjectiveStatus, 'id' | 'name'>
      ), subgoals?: Maybe<Array<(
        { __typename?: 'EvaluationObjective' }
        & Pick<EvaluationObjective, 'id' | 'name' | 'weight' | 'dueDate' | 'parentId'>
        & { status: (
          { __typename?: 'EvaluationObjectiveStatus' }
          & Pick<EvaluationObjectiveStatus, 'id' | 'name'>
        ) }
      )>> }
    )>> }
  )> }
);

export type GetEvaluationCyclesVariables = Exact<{ [key: string]: never; }>;


export type GetEvaluationCycles = (
  { __typename?: 'TenantQuery' }
  & { evaluationsCycles?: Maybe<Array<(
    { __typename?: 'EvaluationCycle' }
    & Pick<EvaluationCycle, 'id' | 'name'>
    & { type: (
      { __typename?: 'EvaluationType' }
      & Pick<EvaluationType, 'id' | 'name'>
    ) }
  )>> }
);

export type GetLatestEvaluationCyclesVariables = Exact<{ [key: string]: never; }>;


export type GetLatestEvaluationCycles = (
  { __typename?: 'TenantQuery' }
  & { latestEvaluationsCycles?: Maybe<Array<(
    { __typename?: 'EvaluationCycle' }
    & Pick<EvaluationCycle, 'id' | 'name'>
    & { type: (
      { __typename?: 'EvaluationType' }
      & Pick<EvaluationType, 'id' | 'name'>
    ) }
  )>> }
);

export type GetGoalStatusOptionsVariables = Exact<{ [key: string]: never; }>;


export type GetGoalStatusOptions = (
  { __typename?: 'TenantQuery' }
  & { goalStatuses?: Maybe<Array<(
    { __typename?: 'EvaluationObjectiveStatus' }
    & { value: EvaluationObjectiveStatus['id'], label: EvaluationObjectiveStatus['name'] }
  )>> }
);

export type GetCompaniesVariables = Exact<{ [key: string]: never; }>;


export type GetCompanies = (
  { __typename?: 'TenantQuery' }
  & { companies?: Maybe<Array<(
    { __typename?: 'Company' }
    & { value: Company['id'], label: Company['name'] }
  )>> }
);

export type GetEmployeeRolesVariables = Exact<{ [key: string]: never; }>;


export type GetEmployeeRoles = (
  { __typename?: 'TenantQuery' }
  & { employeeRoles?: Maybe<Array<(
    { __typename?: 'EmployeeRole' }
    & { value: EmployeeRole['id'], label: EmployeeRole['name'] }
  )>> }
);

export type GetEditableEmployeeRolesVariables = Exact<{ [key: string]: never; }>;


export type GetEditableEmployeeRoles = (
  { __typename?: 'TenantQuery' }
  & { editableEmployeeRoles?: Maybe<Array<(
    { __typename?: 'EmployeeRole' }
    & Pick<EmployeeRole, 'id' | 'name'>
  )>> }
);

export type GetEmployeeRoleVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetEmployeeRole = (
  { __typename?: 'TenantQuery' }
  & { employeeRole?: Maybe<(
    { __typename?: 'EmployeeRole' }
    & Pick<EmployeeRole, 'id' | 'name' | 'missionStatement' | 'currentUserCanEdit'>
    & { managerRole?: Maybe<(
      { __typename?: 'EmployeeRole' }
      & Pick<EmployeeRole, 'id' | 'name'>
    )>, competencies: Array<(
      { __typename?: 'Competency' }
      & Pick<Competency, 'name' | 'category'>
    )>, qualifications: Array<(
      { __typename?: 'Qualification' }
      & Pick<Qualification, 'id' | 'name'>
    )>, objectives?: Maybe<Array<(
      { __typename?: 'Objective' }
      & Pick<Objective, 'id' | 'name' | 'keyResults'>
    )>>, contributors?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>> }
  )> }
);

export type GetDepartmentsVariables = Exact<{ [key: string]: never; }>;


export type GetDepartments = (
  { __typename?: 'TenantQuery' }
  & { departments?: Maybe<Array<(
    { __typename?: 'Department' }
    & { value: Department['id'], label: Department['name'] }
  )>> }
);

export type GetEvaluationSummaryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetEvaluationSummary = (
  { __typename?: 'TenantQuery' }
  & { evaluation?: Maybe<(
    { __typename?: 'Evaluation' }
    & Pick<Evaluation, 'id' | 'managerSignedDate' | 'employeeSignedDate' | 'changesRequested' | 'currentUserIsAdmin'>
    & { status?: Maybe<(
      { __typename?: 'EvaluationStatus' }
      & Pick<EvaluationStatus, 'id' | 'name'>
    )>, subject: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName'>
      & { manager?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      )> }
    ), evaluationCycle: (
      { __typename?: 'EvaluationCycle' }
      & Pick<EvaluationCycle, 'id' | 'name' | 'managerReviewStartDate' | 'peerFeedbackStartDate' | 'releaseToEmployeeDate' | 'selfEvaluationStartDate'>
    ), managerEvaluation?: Maybe<(
      { __typename?: 'EvaluationReview' }
      & Pick<EvaluationReview, 'id'>
      & { status: (
        { __typename?: 'ReviewStatus' }
        & Pick<ReviewStatus, 'id' | 'name'>
      ) }
    )>, selfEvaluation?: Maybe<(
      { __typename?: 'EvaluationReview' }
      & Pick<EvaluationReview, 'id'>
      & { status: (
        { __typename?: 'ReviewStatus' }
        & Pick<ReviewStatus, 'id' | 'name'>
      ) }
    )>, peerRequests: Array<(
      { __typename?: 'PeerRequest' }
      & Pick<PeerRequest, 'id'>
      & { status: (
        { __typename?: 'PeerRequestStatus' }
        & Pick<PeerRequestStatus, 'id' | 'name'>
      ), denyReason?: Maybe<(
        { __typename?: 'PeerRequestDenyReason' }
        & Pick<PeerRequestDenyReason, 'id' | 'name'>
      )>, requestedReview?: Maybe<(
        { __typename?: 'ValueReview' }
        & Pick<ValueReview, 'id'>
        & { status: (
          { __typename?: 'ReviewStatus' }
          & Pick<ReviewStatus, 'id' | 'name'>
        ) }
      )>, requestedPeer: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'title'>
        & { role?: Maybe<(
          { __typename?: 'EmployeeRole' }
          & Pick<EmployeeRole, 'id' | 'name'>
        )> }
      ), reviewSubject: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'title'>
        & { role?: Maybe<(
          { __typename?: 'EmployeeRole' }
          & Pick<EmployeeRole, 'id' | 'name'>
        )> }
      ) }
    )>, reviewsRequestedByPeers: Array<(
      { __typename?: 'PeerRequest' }
      & Pick<PeerRequest, 'id'>
      & { status: (
        { __typename?: 'PeerRequestStatus' }
        & Pick<PeerRequestStatus, 'id' | 'name'>
      ), denyReason?: Maybe<(
        { __typename?: 'PeerRequestDenyReason' }
        & Pick<PeerRequestDenyReason, 'id' | 'name'>
      )>, requestedReview?: Maybe<(
        { __typename?: 'ValueReview' }
        & Pick<ValueReview, 'id'>
        & { status: (
          { __typename?: 'ReviewStatus' }
          & Pick<ReviewStatus, 'id' | 'name'>
        ) }
      )>, requestedPeer: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'title'>
        & { role?: Maybe<(
          { __typename?: 'EmployeeRole' }
          & Pick<EmployeeRole, 'id' | 'name'>
        )> }
      ), reviewSubject: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'title'>
        & { role?: Maybe<(
          { __typename?: 'EmployeeRole' }
          & Pick<EmployeeRole, 'id' | 'name'>
        )> }
      ) }
    )> }
  )> }
);

export type GetSelfEvaluationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSelfEvaluation = (
  { __typename?: 'TenantQuery' }
  & { evaluation?: Maybe<(
    { __typename?: 'Evaluation' }
    & Pick<Evaluation, 'id' | 'managerSignedDate' | 'employeeSignedDate'>
    & { selfEvaluation?: Maybe<(
      { __typename?: 'EvaluationReview' }
      & Pick<EvaluationReview, 'id'>
      & { author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      )>, type: (
        { __typename?: 'ReviewType' }
        & Pick<ReviewType, 'id' | 'name'>
      ), status: (
        { __typename?: 'ReviewStatus' }
        & Pick<ReviewStatus, 'id' | 'name'>
      ), goalsReview: (
        { __typename?: 'ObjectiveReview' }
        & Pick<ObjectiveReview, 'id'>
        & { type: (
          { __typename?: 'ReviewType' }
          & Pick<ReviewType, 'id' | 'name'>
        ), status: (
          { __typename?: 'ReviewStatus' }
          & Pick<ReviewStatus, 'id' | 'name'>
        ), subject: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName'>
        ), author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName'>
        ), goals: Array<(
          { __typename?: 'ObjectiveReviewObjective' }
          & Pick<ObjectiveReviewObjective, 'id' | 'name' | 'performanceReward'>
          & { subgoals?: Maybe<Array<(
            { __typename?: 'EvaluationObjective' }
            & Pick<EvaluationObjective, 'id' | 'name'>
          )>>, strengths: Array<(
            { __typename?: 'EvaluationStrengthOrOpportunity' }
            & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
          )>, opportunities: Array<(
            { __typename?: 'EvaluationStrengthOrOpportunity' }
            & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
          )> }
        )> }
      ), valuesReview: (
        { __typename?: 'ValueReview' }
        & Pick<ValueReview, 'id'>
        & { type: (
          { __typename?: 'ReviewType' }
          & Pick<ReviewType, 'id' | 'name'>
        ), status: (
          { __typename?: 'ReviewStatus' }
          & Pick<ReviewStatus, 'id' | 'name'>
        ), subject: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName'>
        ), author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName'>
        ), values: Array<(
          { __typename?: 'ValueReviewValue' }
          & Pick<ValueReviewValue, 'id' | 'reviewValueId' | 'name' | 'description' | 'score'>
          & { strengths: Array<(
            { __typename?: 'EvaluationStrengthOrOpportunity' }
            & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
          )>, opportunities: Array<(
            { __typename?: 'EvaluationStrengthOrOpportunity' }
            & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
          )> }
        )> }
      ) }
    )> }
  )> }
);

export type GetManagerEvaluationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetManagerEvaluation = (
  { __typename?: 'TenantQuery' }
  & { evaluation?: Maybe<(
    { __typename?: 'Evaluation' }
    & Pick<Evaluation, 'id' | 'managerSignedDate' | 'employeeSignedDate'>
    & { managerEvaluation?: Maybe<(
      { __typename?: 'EvaluationReview' }
      & Pick<EvaluationReview, 'id'>
      & { author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      )>, type: (
        { __typename?: 'ReviewType' }
        & Pick<ReviewType, 'id' | 'name'>
      ), status: (
        { __typename?: 'ReviewStatus' }
        & Pick<ReviewStatus, 'id' | 'name'>
      ), goalsReview: (
        { __typename?: 'ObjectiveReview' }
        & Pick<ObjectiveReview, 'id'>
        & { type: (
          { __typename?: 'ReviewType' }
          & Pick<ReviewType, 'id' | 'name'>
        ), status: (
          { __typename?: 'ReviewStatus' }
          & Pick<ReviewStatus, 'id' | 'name'>
        ), subject: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName'>
        ), author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName'>
        ), goals: Array<(
          { __typename?: 'ObjectiveReviewObjective' }
          & Pick<ObjectiveReviewObjective, 'id' | 'name' | 'performanceReward'>
          & { subgoals?: Maybe<Array<(
            { __typename?: 'EvaluationObjective' }
            & Pick<EvaluationObjective, 'id' | 'name'>
          )>>, strengths: Array<(
            { __typename?: 'EvaluationStrengthOrOpportunity' }
            & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
          )>, opportunities: Array<(
            { __typename?: 'EvaluationStrengthOrOpportunity' }
            & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
          )> }
        )>, selfReviewContent: Array<(
          { __typename?: 'ObjectiveReviewObjective' }
          & Pick<ObjectiveReviewObjective, 'id' | 'name'>
          & { subgoals?: Maybe<Array<(
            { __typename?: 'EvaluationObjective' }
            & Pick<EvaluationObjective, 'id' | 'name'>
          )>>, strengths: Array<(
            { __typename?: 'EvaluationStrengthOrOpportunity' }
            & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
            & { author?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'id' | 'displayName'>
            )> }
          )>, opportunities: Array<(
            { __typename?: 'EvaluationStrengthOrOpportunity' }
            & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
            & { author?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'id' | 'displayName'>
            )> }
          )> }
        )> }
      ), valuesReview: (
        { __typename?: 'ValueReview' }
        & Pick<ValueReview, 'id'>
        & { type: (
          { __typename?: 'ReviewType' }
          & Pick<ReviewType, 'id' | 'name'>
        ), status: (
          { __typename?: 'ReviewStatus' }
          & Pick<ReviewStatus, 'id' | 'name'>
        ), subject: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName'>
        ), author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'displayName'>
        ), values: Array<(
          { __typename?: 'ValueReviewValue' }
          & Pick<ValueReviewValue, 'id' | 'reviewValueId' | 'name' | 'description' | 'score'>
          & { strengths: Array<(
            { __typename?: 'EvaluationStrengthOrOpportunity' }
            & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
          )>, opportunities: Array<(
            { __typename?: 'EvaluationStrengthOrOpportunity' }
            & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
          )> }
        )>, peerReviewContent: Array<(
          { __typename?: 'ValueReviewValue' }
          & Pick<ValueReviewValue, 'id' | 'reviewValueId' | 'name' | 'description'>
          & { strengths: Array<(
            { __typename?: 'EvaluationStrengthOrOpportunity' }
            & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
            & { author?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'id' | 'displayName'>
            )> }
          )>, opportunities: Array<(
            { __typename?: 'EvaluationStrengthOrOpportunity' }
            & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
            & { author?: Maybe<(
              { __typename?: 'User' }
              & Pick<User, 'id' | 'displayName'>
            )> }
          )> }
        )> }
      ) }
    )> }
  )> }
);

export type GetPeerRequestVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPeerRequest = (
  { __typename?: 'TenantQuery' }
  & { peerRequest?: Maybe<(
    { __typename?: 'PeerRequest' }
    & Pick<PeerRequest, 'id'>
    & { status: (
      { __typename?: 'PeerRequestStatus' }
      & Pick<PeerRequestStatus, 'id' | 'name'>
    ), denyReason?: Maybe<(
      { __typename?: 'PeerRequestDenyReason' }
      & Pick<PeerRequestDenyReason, 'id' | 'name'>
    )>, requestedReview?: Maybe<(
      { __typename?: 'ValueReview' }
      & Pick<ValueReview, 'id'>
      & { type: (
        { __typename?: 'ReviewType' }
        & Pick<ReviewType, 'id' | 'name'>
      ), status: (
        { __typename?: 'ReviewStatus' }
        & Pick<ReviewStatus, 'id' | 'name'>
      ), subject: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ), author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName'>
      ), values: Array<(
        { __typename?: 'ValueReviewValue' }
        & Pick<ValueReviewValue, 'id' | 'reviewValueId' | 'name' | 'description' | 'score'>
        & { strengths: Array<(
          { __typename?: 'EvaluationStrengthOrOpportunity' }
          & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
        )>, opportunities: Array<(
          { __typename?: 'EvaluationStrengthOrOpportunity' }
          & Pick<EvaluationStrengthOrOpportunity, 'id' | 'description'>
        )> }
      )> }
    )>, requestedPeer: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'title'>
      & { role?: Maybe<(
        { __typename?: 'EmployeeRole' }
        & Pick<EmployeeRole, 'id' | 'name'>
      )> }
    ), reviewSubject: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'title'>
      & { role?: Maybe<(
        { __typename?: 'EmployeeRole' }
        & Pick<EmployeeRole, 'id' | 'name'>
      )> }
    ) }
  )> }
);

export type GetEvaluationCycleEvaluationSummariesVariables = Exact<{
  evaluationCycleId: Scalars['Int'];
}>;


export type GetEvaluationCycleEvaluationSummaries = (
  { __typename?: 'TenantQuery' }
  & { users?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'title' | 'email'>
    & { department: (
      { __typename?: 'Department' }
      & Pick<Department, 'id' | 'name'>
    ), manager?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'email'>
    )>, company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    ), evaluation?: Maybe<(
      { __typename?: 'Evaluation' }
      & Pick<Evaluation, 'id'>
      & { status?: Maybe<(
        { __typename?: 'EvaluationStatus' }
        & Pick<EvaluationStatus, 'id' | 'name'>
      )>, goals?: Maybe<Array<(
        { __typename?: 'EvaluationObjective' }
        & Pick<EvaluationObjective, 'id' | 'weight'>
      )>>, overallPerformanceScore?: Maybe<(
        { __typename?: 'EvaluationPerformanceScore' }
        & Pick<EvaluationPerformanceScore, 'objectivesScore' | 'valuesScore' | 'totalScore'>
      )>, evaluationCycle: (
        { __typename?: 'EvaluationCycle' }
        & Pick<EvaluationCycle, 'id' | 'name' | 'managerReviewStartDate' | 'peerFeedbackStartDate' | 'releaseToEmployeeDate' | 'selfEvaluationStartDate'>
      ), managerEvaluation?: Maybe<(
        { __typename?: 'EvaluationReview' }
        & Pick<EvaluationReview, 'id'>
        & { status: (
          { __typename?: 'ReviewStatus' }
          & Pick<ReviewStatus, 'id' | 'name'>
        ) }
      )>, selfEvaluation?: Maybe<(
        { __typename?: 'EvaluationReview' }
        & Pick<EvaluationReview, 'id'>
        & { status: (
          { __typename?: 'ReviewStatus' }
          & Pick<ReviewStatus, 'id' | 'name'>
        ) }
      )>, peerRequests: Array<(
        { __typename?: 'PeerRequest' }
        & Pick<PeerRequest, 'id'>
        & { status: (
          { __typename?: 'PeerRequestStatus' }
          & Pick<PeerRequestStatus, 'id' | 'name'>
        ), requestedReview?: Maybe<(
          { __typename?: 'ValueReview' }
          & Pick<ValueReview, 'id'>
          & { status: (
            { __typename?: 'ReviewStatus' }
            & Pick<ReviewStatus, 'id' | 'name'>
          ) }
        )> }
      )> }
    )> }
  )>> }
);

export type GetPeerRequestDenyReasonsVariables = Exact<{ [key: string]: never; }>;


export type GetPeerRequestDenyReasons = (
  { __typename?: 'TenantQuery' }
  & { peerRequestDenyReasons?: Maybe<Array<(
    { __typename?: 'PeerRequestDenyReason' }
    & Pick<PeerRequestDenyReason, 'id' | 'name'>
  )>> }
);

export const EvaluationFragment = gql`
    fragment EvaluationFragment on Evaluation {
  id
  evaluationCycle {
    id
    name
    type {
      id
      name
    }
  }
}
    `;
export const UserFragment = gql`
    fragment UserFragment on User {
  id
  firstName
  lastName
  displayName
  email
  title
  company {
    id
    name
  }
  role {
    id
    name
  }
  department {
    id
    name
  }
  manager {
    id
    firstName
    lastName
    displayName
    email
  }
}
    `;
export const ErrorFragment = gql`
    fragment ErrorFragment on Error {
  message
}
    `;
export const CreateEvaluationDocument = gql`
    mutation CreateEvaluation($evaluation: CreateEvaluationInput!) {
  createEvaluation(evaluation: $evaluation) {
    __typename
    ...EvaluationFragment
    ...ErrorFragment
    ... on EvaluationAlreadyExists {
      existing {
        ...EvaluationFragment
      }
    }
  }
}
    ${EvaluationFragment}
${ErrorFragment}`;
export const CreateGoalDocument = gql`
    mutation CreateGoal($goal: CreateGoalInput!) {
  createGoal(goal: $goal) {
    id
    name
    weight
    dueDate
    parentId
    status {
      id
      name
    }
    subgoals {
      id
      name
      weight
      dueDate
      parentId
      status {
        id
        name
      }
      subgoals {
        id
        name
      }
    }
  }
}
    `;
export const UpdateGoalDocument = gql`
    mutation UpdateGoal($goal: UpdateGoalInput!) {
  updateGoal(goal: $goal) {
    id
    name
    weight
    dueDate
    parentId
    status {
      id
      name
    }
    subgoals {
      id
      name
      weight
      dueDate
      parentId
      status {
        id
        name
      }
      subgoals {
        id
        name
      }
    }
  }
}
    `;
export const DeleteGoalDocument = gql`
    mutation DeleteGoal($id: Int!) {
  deleteGoal(id: $id) {
    id
    name
  }
}
    `;
export const CreateUserDocument = gql`
    mutation CreateUser($user: CreateUserInput!) {
  createUser(user: $user) {
    __typename
    ...UserFragment
    ...ErrorFragment
    ... on UserAlreadyExists {
      existing {
        ...UserFragment
      }
    }
  }
}
    ${UserFragment}
${ErrorFragment}`;
export const UpdateUserDocument = gql`
    mutation UpdateUser($user: UpdateUserInput!) {
  updateUser(user: $user) {
    __typename
    ...UserFragment
    ...ErrorFragment
    ... on UserAlreadyExists {
      existing {
        ...UserFragment
      }
    }
  }
}
    ${UserFragment}
${ErrorFragment}`;
export const AddUserToGroupDocument = gql`
    mutation AddUserToGroup($userId: Int!, $groupId: Int!) {
  addUserToGroup(userId: $userId, groupId: $groupId)
}
    `;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: Int!) {
  deleteUser(id: $id) {
    id
    firstName
    lastName
    email
  }
}
    `;
export const CreateSelfEvaluationDocument = gql`
    mutation CreateSelfEvaluation($id: Int!) {
  createSelfEvaluation(evaluationId: $id) {
    __typename
    id
    status {
      id
      name
    }
    type {
      id
      name
    }
  }
}
    `;
export const CreateManagerEvaluationDocument = gql`
    mutation CreateManagerEvaluation($id: Int!) {
  createManagerEvaluation(evaluationId: $id) {
    __typename
    id
    status {
      id
      name
    }
    type {
      id
      name
    }
  }
}
    `;
export const CreateReviewOpportunityDocument = gql`
    mutation CreateReviewOpportunity($opportunity: CreateStrengthOrOpportunityInput!) {
  createReviewOpportunity(opportunity: $opportunity) {
    id
    description
  }
}
    `;
export const UpdateReviewOpportunityDocument = gql`
    mutation UpdateReviewOpportunity($opportunity: UpdateStrengthOrOpportunityInput!) {
  updateReviewOpportunity(opportunity: $opportunity) {
    id
    description
  }
}
    `;
export const DeleteReviewOpportunityDocument = gql`
    mutation DeleteReviewOpportunity($id: Int!, $reviewClassification: ReviewClassificationEnum!) {
  deleteReviewOpportunity(id: $id, reviewClassification: $reviewClassification) {
    id
    description
  }
}
    `;
export const UpdateReviewStrengthDocument = gql`
    mutation UpdateReviewStrength($strength: UpdateStrengthOrOpportunityInput!) {
  updateReviewStrength(strength: $strength) {
    id
    description
  }
}
    `;
export const CreateReviewStrengthDocument = gql`
    mutation CreateReviewStrength($strength: CreateStrengthOrOpportunityInput!) {
  createReviewStrength(strength: $strength) {
    id
    description
  }
}
    `;
export const DeleteReviewStrengthDocument = gql`
    mutation DeleteReviewStrength($id: Int!, $reviewClassification: ReviewClassificationEnum!) {
  deleteReviewStrength(id: $id, reviewClassification: $reviewClassification) {
    id
    description
  }
}
    `;
export const UpdateReviewValueDocument = gql`
    mutation UpdateReviewValue($value: UpdateReviewValueInput!) {
  updateReviewValue(value: $value) {
    id
    name
    description
    updatedAt
  }
}
    `;
export const SubmitEvaluationReviewDocument = gql`
    mutation SubmitEvaluationReview($id: Int!) {
  submitEvaluationReview(id: $id) {
    id
    status {
      id
      name
    }
    goalsReview {
      id
      status {
        id
        name
      }
    }
    valuesReview {
      id
      status {
        id
        name
      }
    }
  }
}
    `;
export const UnsubmitEvaluationReviewDocument = gql`
    mutation UnsubmitEvaluationReview($id: Int!) {
  unsubmitEvaluationReview(id: $id) {
    id
    status {
      id
      name
    }
    goalsReview {
      id
      status {
        id
        name
      }
    }
    valuesReview {
      id
      status {
        id
        name
      }
    }
  }
}
    `;
export const SubmitValueReviewDocument = gql`
    mutation SubmitValueReview($id: Int!) {
  submitValueReview(id: $id) {
    id
    status {
      id
      name
    }
  }
}
    `;
export const UnsubmitValueReviewDocument = gql`
    mutation UnsubmitValueReview($id: Int!) {
  unsubmitValueReview(id: $id) {
    id
    status {
      id
      name
    }
  }
}
    `;
export const SubmitObjectiveReviewDocument = gql`
    mutation SubmitObjectiveReview($id: Int!) {
  submitValueReview(id: $id) {
    id
    status {
      id
      name
    }
  }
}
    `;
export const CreatePeerRequestDocument = gql`
    mutation CreatePeerRequest($request: CreatePeerRequestInput!) {
  createPeerRequest(request: $request) {
    id
    status {
      id
      name
    }
  }
}
    `;
export const AcceptPeerRequestDocument = gql`
    mutation AcceptPeerRequest($id: Int!) {
  acceptPeerRequest(id: $id) {
    id
    status {
      id
      name
    }
  }
}
    `;
export const RejectPeerRequestDocument = gql`
    mutation RejectPeerRequest($id: Int!, $reasonId: Int!) {
  rejectPeerRequest(id: $id, reasonId: $reasonId) {
    id
    status {
      id
      name
    }
    denyReason {
      id
      name
    }
  }
}
    `;
export const CancelPeerRequestDocument = gql`
    mutation CancelPeerRequest($id: Int!, $reasonId: Int!) {
  cancelPeerRequest(id: $id, reasonId: $reasonId) {
    id
    status {
      id
      name
    }
    denyReason {
      id
      name
    }
  }
}
    `;
export const DeletePeerRequestDocument = gql`
    mutation DeletePeerRequest($id: Int!) {
  deletePeerRequest(id: $id) {
    id
    status {
      id
      name
    }
  }
}
    `;
export const SubmitEvaluationForReviewDocument = gql`
    mutation SubmitEvaluationForReview($id: Int!) {
  submitEvaluationForReview(id: $id) {
    id
    status {
      id
      name
    }
    changesRequested
    subject {
      id
      displayName
    }
  }
}
    `;
export const UnsubmitEvaluationFromReviewDocument = gql`
    mutation UnsubmitEvaluationFromReview($id: Int!) {
  unsubmitEvaluationFromReview(id: $id) {
    id
    status {
      id
      name
    }
    changesRequested
    subject {
      id
      displayName
    }
  }
}
    `;
export const PublishEvaluationForEmployeeReviewDocument = gql`
    mutation PublishEvaluationForEmployeeReview($id: Int!) {
  publishEvaluationForEmployeeReview(id: $id) {
    id
    status {
      id
      name
    }
    changesRequested
    subject {
      id
      displayName
    }
  }
}
    `;
export const RequestEvaluationChangesDocument = gql`
    mutation RequestEvaluationChanges($id: Int!, $requestedChanges: String!) {
  requestEvaluationChanges(id: $id, requestedChanges: $requestedChanges) {
    id
    status {
      id
      name
    }
    changesRequested
    subject {
      id
      displayName
    }
  }
}
    `;
export const ApproveEvaluationDocument = gql`
    mutation ApproveEvaluation($id: Int!) {
  approveEvaluation(id: $id) {
    id
    status {
      id
      name
    }
    changesRequested
    subject {
      id
      displayName
    }
  }
}
    `;
export const SignEvaluationDocument = gql`
    mutation SignEvaluation($id: Int!) {
  signEvaluation(id: $id) {
    id
    status {
      id
      name
    }
    changesRequested
    subject {
      id
      displayName
    }
  }
}
    `;
export const CreateEmployeeRoleDocument = gql`
    mutation CreateEmployeeRole($role: CreateEmployeeRoleInput!) {
  createEmployeeRole(role: $role) {
    __typename
    id
    name
    missionStatement
    competencies {
      name
      category
    }
    qualifications {
      id
      name
    }
    objectives {
      id
      name
      keyResults
    }
  }
}
    `;
export const UpdateEmployeeRoleDocument = gql`
    mutation UpdateEmployeeRole($role: UpdateEmployeeRoleInput!) {
  updateEmployeeRole(role: $role) {
    __typename
    id
    name
    missionStatement
    managerRole {
      id
      name
    }
    competencies {
      name
      category
    }
    qualifications {
      id
      name
    }
    objectives {
      id
      name
      keyResults
    }
  }
}
    `;
export const DeleteEmployeeRoleDocument = gql`
    mutation DeleteEmployeeRole($id: Int!) {
  deleteEmployeeRole(id: $id) {
    __typename
    id
    name
    missionStatement
    managerRole {
      id
      name
    }
    competencies {
      name
      category
    }
    qualifications {
      id
      name
    }
    objectives {
      id
      name
      keyResults
    }
  }
}
    `;
export const AddUsersToEmployeeRoleContributorsDocument = gql`
    mutation AddUsersToEmployeeRoleContributors($employeeRoleId: Int!, $userIds: [Int!]!) {
  addUsersToEmployeeRoleContributors(employeeRoleId: $employeeRoleId, userIds: $userIds)
}
    `;
export const RemoveUsersFromEmployeeRoleContributorsDocument = gql`
    mutation RemoveUsersFromEmployeeRoleContributors($employeeRoleId: Int!, $userIds: [Int!]!) {
  removeUsersFromEmployeeRoleContributors(employeeRoleId: $employeeRoleId, userIds: $userIds)
}
    `;
export const GetCurrentUserOrganizationDocument = gql`
    query GetCurrentUserOrganization {
  currentUser {
    organization {
      id
      name
      organizationPath
    }
  }
}
    `;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  currentUser {
    profile {
      id
      firstName
      lastName
      displayName
      title
    }
    organization {
      id
      name
      organizationPath
    }
  }
}
    `;
export const GetDirectoryDocument = gql`
    query GetDirectory($userId: Int!) {
  canEditUsers
  users {
    id
    firstName
    lastName
    displayName
    email
    title
    company {
      id
      name
    }
    manager {
      id
      displayName
      email
    }
    role {
      id
      name
    }
    department {
      id
      name
    }
  }
  directReports(userId: $userId) {
    id
    firstName
    lastName
    displayName
    email
    title
    company {
      id
      name
    }
    manager {
      id
      displayName
      email
    }
    role {
      id
      name
    }
    department {
      id
      name
    }
  }
}
    `;
export const GetDirectReportsDocument = gql`
    query GetDirectReports($userId: Int!) {
  directReports(userId: $userId) {
    id
    firstName
    lastName
    displayName
    email
    title
    company {
      id
      name
    }
    role {
      id
      name
    }
    department {
      id
      name
    }
  }
}
    `;
export const GetDirectoryUserDocument = gql`
    query GetDirectoryUser($id: Int!) {
  user(id: $id) {
    id
    firstName
    lastName
    displayName
    email
    title
    company {
      id
      name
    }
    role {
      id
      name
    }
    department {
      id
      name
    }
    manager {
      id
      firstName
      lastName
      displayName
      email
    }
    currentUserCanEdit
    isCurrentUserAdmin
    isUserAdmin
  }
}
    `;
export const GetDirectoryUserGoalsDocument = gql`
    query GetDirectoryUserGoals($userId: Int!) {
  evaluations(userId: $userId) {
    id
    status {
      id
      name
    }
    goals {
      id
      name
    }
    subject {
      id
      firstName
      lastName
      displayName
      email
      title
      company {
        id
        name
      }
      role {
        id
        name
      }
      department {
        id
        name
      }
    }
    evaluationCycle {
      id
      name
      managerReviewStartDate
      peerFeedbackStartDate
      releaseToEmployeeDate
      selfEvaluationStartDate
      type {
        id
        name
      }
    }
  }
}
    `;
export const GetEvaluationDocument = gql`
    query GetEvaluation($id: Int!) {
  evaluation(id: $id) {
    id
    goals {
      id
      name
      weight
      dueDate
      status {
        id
        name
      }
      subgoals {
        id
        name
        weight
        dueDate
        status {
          id
          name
        }
        parentId
      }
    }
    subject {
      id
      firstName
      lastName
      displayName
      email
      title
      company {
        id
        name
      }
      role {
        id
        name
      }
      department {
        id
        name
      }
    }
    evaluationCycle {
      id
      name
      managerReviewStartDate
      peerFeedbackStartDate
      releaseToEmployeeDate
      selfEvaluationStartDate
      type {
        id
        name
      }
    }
  }
}
    `;
export const GetGoalDocument = gql`
    query GetGoal($id: Int!) {
  goal(id: $id) {
    id
    name
    weight
    dueDate
    parentId
    status {
      id
      name
    }
    subgoals {
      id
      name
      weight
      dueDate
      parentId
      status {
        id
        name
      }
      subgoals {
        id
        name
        weight
        dueDate
        parentId
        status {
          id
          name
        }
      }
    }
  }
}
    `;
export const GetEvaluationCyclesDocument = gql`
    query GetEvaluationCycles {
  evaluationsCycles {
    id
    name
    type {
      id
      name
    }
  }
}
    `;
export const GetLatestEvaluationCyclesDocument = gql`
    query GetLatestEvaluationCycles {
  latestEvaluationsCycles {
    id
    name
    type {
      id
      name
    }
  }
}
    `;
export const GetGoalStatusOptionsDocument = gql`
    query GetGoalStatusOptions {
  goalStatuses {
    value: id
    label: name
  }
}
    `;
export const GetCompaniesDocument = gql`
    query GetCompanies {
  companies {
    value: id
    label: name
  }
}
    `;
export const GetEmployeeRolesDocument = gql`
    query GetEmployeeRoles {
  employeeRoles {
    value: id
    label: name
  }
}
    `;
export const GetEditableEmployeeRolesDocument = gql`
    query GetEditableEmployeeRoles {
  editableEmployeeRoles {
    id
    name
  }
}
    `;
export const GetEmployeeRoleDocument = gql`
    query GetEmployeeRole($id: Int!) {
  employeeRole(id: $id) {
    id
    name
    managerRole {
      id
      name
    }
    missionStatement
    competencies {
      name
      category
    }
    qualifications {
      id
      name
    }
    objectives {
      id
      name
      keyResults
    }
    currentUserCanEdit
    contributors {
      id
      firstName
      lastName
    }
  }
}
    `;
export const GetDepartmentsDocument = gql`
    query GetDepartments {
  departments {
    value: id
    label: name
  }
}
    `;
export const GetEvaluationSummaryDocument = gql`
    query GetEvaluationSummary($id: Int!) {
  evaluation(id: $id) {
    id
    managerSignedDate
    employeeSignedDate
    status {
      id
      name
    }
    changesRequested
    subject {
      id
      displayName
      manager {
        id
        displayName
      }
    }
    currentUserIsAdmin
    evaluationCycle {
      id
      name
      managerReviewStartDate
      peerFeedbackStartDate
      releaseToEmployeeDate
      selfEvaluationStartDate
    }
    managerEvaluation {
      id
      status {
        id
        name
      }
    }
    selfEvaluation {
      id
      status {
        id
        name
      }
    }
    peerRequests {
      id
      status {
        id
        name
      }
      denyReason {
        id
        name
      }
      requestedReview {
        id
        status {
          id
          name
        }
      }
      requestedPeer {
        id
        displayName
        title
        role {
          id
          name
        }
      }
      reviewSubject {
        id
        displayName
        title
        role {
          id
          name
        }
      }
    }
    reviewsRequestedByPeers {
      id
      status {
        id
        name
      }
      denyReason {
        id
        name
      }
      requestedReview {
        id
        status {
          id
          name
        }
      }
      requestedPeer {
        id
        displayName
        title
        role {
          id
          name
        }
      }
      reviewSubject {
        id
        displayName
        title
        role {
          id
          name
        }
      }
    }
  }
}
    `;
export const GetSelfEvaluationDocument = gql`
    query GetSelfEvaluation($id: Int!) {
  evaluation(id: $id) {
    id
    managerSignedDate
    employeeSignedDate
    selfEvaluation {
      id
      author {
        id
        displayName
      }
      type {
        id
        name
      }
      status {
        id
        name
      }
      goalsReview {
        id
        type {
          id
          name
        }
        status {
          id
          name
        }
        subject {
          id
          displayName
        }
        author {
          id
          displayName
        }
        goals {
          id
          name
          performanceReward
          subgoals {
            id
            name
          }
          strengths {
            id
            description
          }
          opportunities {
            id
            description
          }
        }
      }
      valuesReview {
        id
        type {
          id
          name
        }
        status {
          id
          name
        }
        subject {
          id
          displayName
        }
        author {
          id
          displayName
        }
        values {
          id
          reviewValueId
          name
          description
          score
          strengths {
            id
            description
          }
          opportunities {
            id
            description
          }
        }
      }
    }
  }
}
    `;
export const GetManagerEvaluationDocument = gql`
    query GetManagerEvaluation($id: Int!) {
  evaluation(id: $id) {
    id
    managerSignedDate
    employeeSignedDate
    managerEvaluation {
      id
      author {
        id
        displayName
      }
      type {
        id
        name
      }
      status {
        id
        name
      }
      goalsReview {
        id
        type {
          id
          name
        }
        status {
          id
          name
        }
        subject {
          id
          displayName
        }
        author {
          id
          displayName
        }
        goals {
          id
          name
          performanceReward
          subgoals {
            id
            name
          }
          strengths {
            id
            description
          }
          opportunities {
            id
            description
          }
        }
        selfReviewContent {
          id
          name
          subgoals {
            id
            name
          }
          strengths {
            id
            description
            author {
              ... on User {
                id
                displayName
              }
            }
          }
          opportunities {
            id
            description
            author {
              ... on User {
                id
                displayName
              }
            }
          }
        }
      }
      valuesReview {
        id
        type {
          id
          name
        }
        status {
          id
          name
        }
        subject {
          id
          displayName
        }
        author {
          id
          displayName
        }
        values {
          id
          reviewValueId
          name
          description
          score
          strengths {
            id
            description
          }
          opportunities {
            id
            description
          }
        }
        peerReviewContent {
          id
          reviewValueId
          name
          description
          strengths {
            id
            description
            author {
              ... on User {
                id
                displayName
              }
            }
          }
          opportunities {
            id
            description
            author {
              ... on User {
                id
                displayName
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const GetPeerRequestDocument = gql`
    query GetPeerRequest($id: Int!) {
  peerRequest(id: $id) {
    id
    status {
      id
      name
    }
    denyReason {
      id
      name
    }
    requestedReview {
      id
      type {
        id
        name
      }
      status {
        id
        name
      }
      subject {
        id
        displayName
      }
      author {
        id
        displayName
      }
      values {
        id
        reviewValueId
        name
        description
        score
        strengths {
          id
          description
        }
        opportunities {
          id
          description
        }
      }
    }
    requestedPeer {
      id
      displayName
      title
      role {
        id
        name
      }
    }
    reviewSubject {
      id
      displayName
      title
      role {
        id
        name
      }
    }
  }
}
    `;
export const GetEvaluationCycleEvaluationSummariesDocument = gql`
    query GetEvaluationCycleEvaluationSummaries($evaluationCycleId: Int!) {
  users {
    id
    displayName
    department {
      id
      name
    }
    manager {
      id
      displayName
      email
    }
    title
    email
    company {
      id
      name
    }
    evaluation(evaluationCycleId: $evaluationCycleId) {
      id
      status {
        id
        name
      }
      goals {
        id
        weight
      }
      overallPerformanceScore {
        objectivesScore
        valuesScore
        totalScore
      }
      evaluationCycle {
        id
        name
        managerReviewStartDate
        peerFeedbackStartDate
        releaseToEmployeeDate
        selfEvaluationStartDate
      }
      managerEvaluation {
        id
        status {
          id
          name
        }
      }
      selfEvaluation {
        id
        status {
          id
          name
        }
      }
      peerRequests {
        id
        status {
          id
          name
        }
        requestedReview {
          id
          status {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export const GetPeerRequestDenyReasonsDocument = gql`
    query GetPeerRequestDenyReasons {
  peerRequestDenyReasons {
    id
    name
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    CreateEvaluation(variables: CreateEvaluationVariables): Promise<{ data?: CreateEvaluation | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateEvaluation>(print(CreateEvaluationDocument), variables));
    },
    CreateGoal(variables: CreateGoalVariables): Promise<{ data?: CreateGoal | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateGoal>(print(CreateGoalDocument), variables));
    },
    UpdateGoal(variables: UpdateGoalVariables): Promise<{ data?: UpdateGoal | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateGoal>(print(UpdateGoalDocument), variables));
    },
    DeleteGoal(variables: DeleteGoalVariables): Promise<{ data?: DeleteGoal | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteGoal>(print(DeleteGoalDocument), variables));
    },
    CreateUser(variables: CreateUserVariables): Promise<{ data?: CreateUser | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateUser>(print(CreateUserDocument), variables));
    },
    UpdateUser(variables: UpdateUserVariables): Promise<{ data?: UpdateUser | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateUser>(print(UpdateUserDocument), variables));
    },
    AddUserToGroup(variables: AddUserToGroupVariables): Promise<{ data?: AddUserToGroup | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddUserToGroup>(print(AddUserToGroupDocument), variables));
    },
    DeleteUser(variables: DeleteUserVariables): Promise<{ data?: DeleteUser | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteUser>(print(DeleteUserDocument), variables));
    },
    CreateSelfEvaluation(variables: CreateSelfEvaluationVariables): Promise<{ data?: CreateSelfEvaluation | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateSelfEvaluation>(print(CreateSelfEvaluationDocument), variables));
    },
    CreateManagerEvaluation(variables: CreateManagerEvaluationVariables): Promise<{ data?: CreateManagerEvaluation | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateManagerEvaluation>(print(CreateManagerEvaluationDocument), variables));
    },
    CreateReviewOpportunity(variables: CreateReviewOpportunityVariables): Promise<{ data?: CreateReviewOpportunity | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateReviewOpportunity>(print(CreateReviewOpportunityDocument), variables));
    },
    UpdateReviewOpportunity(variables: UpdateReviewOpportunityVariables): Promise<{ data?: UpdateReviewOpportunity | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateReviewOpportunity>(print(UpdateReviewOpportunityDocument), variables));
    },
    DeleteReviewOpportunity(variables: DeleteReviewOpportunityVariables): Promise<{ data?: DeleteReviewOpportunity | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteReviewOpportunity>(print(DeleteReviewOpportunityDocument), variables));
    },
    UpdateReviewStrength(variables: UpdateReviewStrengthVariables): Promise<{ data?: UpdateReviewStrength | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateReviewStrength>(print(UpdateReviewStrengthDocument), variables));
    },
    CreateReviewStrength(variables: CreateReviewStrengthVariables): Promise<{ data?: CreateReviewStrength | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateReviewStrength>(print(CreateReviewStrengthDocument), variables));
    },
    DeleteReviewStrength(variables: DeleteReviewStrengthVariables): Promise<{ data?: DeleteReviewStrength | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteReviewStrength>(print(DeleteReviewStrengthDocument), variables));
    },
    UpdateReviewValue(variables: UpdateReviewValueVariables): Promise<{ data?: UpdateReviewValue | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateReviewValue>(print(UpdateReviewValueDocument), variables));
    },
    SubmitEvaluationReview(variables: SubmitEvaluationReviewVariables): Promise<{ data?: SubmitEvaluationReview | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<SubmitEvaluationReview>(print(SubmitEvaluationReviewDocument), variables));
    },
    UnsubmitEvaluationReview(variables: UnsubmitEvaluationReviewVariables): Promise<{ data?: UnsubmitEvaluationReview | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UnsubmitEvaluationReview>(print(UnsubmitEvaluationReviewDocument), variables));
    },
    SubmitValueReview(variables: SubmitValueReviewVariables): Promise<{ data?: SubmitValueReview | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<SubmitValueReview>(print(SubmitValueReviewDocument), variables));
    },
    UnsubmitValueReview(variables: UnsubmitValueReviewVariables): Promise<{ data?: UnsubmitValueReview | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UnsubmitValueReview>(print(UnsubmitValueReviewDocument), variables));
    },
    SubmitObjectiveReview(variables: SubmitObjectiveReviewVariables): Promise<{ data?: SubmitObjectiveReview | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<SubmitObjectiveReview>(print(SubmitObjectiveReviewDocument), variables));
    },
    CreatePeerRequest(variables: CreatePeerRequestVariables): Promise<{ data?: CreatePeerRequest | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreatePeerRequest>(print(CreatePeerRequestDocument), variables));
    },
    AcceptPeerRequest(variables: AcceptPeerRequestVariables): Promise<{ data?: AcceptPeerRequest | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AcceptPeerRequest>(print(AcceptPeerRequestDocument), variables));
    },
    RejectPeerRequest(variables: RejectPeerRequestVariables): Promise<{ data?: RejectPeerRequest | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RejectPeerRequest>(print(RejectPeerRequestDocument), variables));
    },
    CancelPeerRequest(variables: CancelPeerRequestVariables): Promise<{ data?: CancelPeerRequest | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CancelPeerRequest>(print(CancelPeerRequestDocument), variables));
    },
    DeletePeerRequest(variables: DeletePeerRequestVariables): Promise<{ data?: DeletePeerRequest | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeletePeerRequest>(print(DeletePeerRequestDocument), variables));
    },
    SubmitEvaluationForReview(variables: SubmitEvaluationForReviewVariables): Promise<{ data?: SubmitEvaluationForReview | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<SubmitEvaluationForReview>(print(SubmitEvaluationForReviewDocument), variables));
    },
    UnsubmitEvaluationFromReview(variables: UnsubmitEvaluationFromReviewVariables): Promise<{ data?: UnsubmitEvaluationFromReview | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UnsubmitEvaluationFromReview>(print(UnsubmitEvaluationFromReviewDocument), variables));
    },
    PublishEvaluationForEmployeeReview(variables: PublishEvaluationForEmployeeReviewVariables): Promise<{ data?: PublishEvaluationForEmployeeReview | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<PublishEvaluationForEmployeeReview>(print(PublishEvaluationForEmployeeReviewDocument), variables));
    },
    RequestEvaluationChanges(variables: RequestEvaluationChangesVariables): Promise<{ data?: RequestEvaluationChanges | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RequestEvaluationChanges>(print(RequestEvaluationChangesDocument), variables));
    },
    ApproveEvaluation(variables: ApproveEvaluationVariables): Promise<{ data?: ApproveEvaluation | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<ApproveEvaluation>(print(ApproveEvaluationDocument), variables));
    },
    SignEvaluation(variables: SignEvaluationVariables): Promise<{ data?: SignEvaluation | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<SignEvaluation>(print(SignEvaluationDocument), variables));
    },
    CreateEmployeeRole(variables: CreateEmployeeRoleVariables): Promise<{ data?: CreateEmployeeRole | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<CreateEmployeeRole>(print(CreateEmployeeRoleDocument), variables));
    },
    UpdateEmployeeRole(variables: UpdateEmployeeRoleVariables): Promise<{ data?: UpdateEmployeeRole | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<UpdateEmployeeRole>(print(UpdateEmployeeRoleDocument), variables));
    },
    DeleteEmployeeRole(variables: DeleteEmployeeRoleVariables): Promise<{ data?: DeleteEmployeeRole | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<DeleteEmployeeRole>(print(DeleteEmployeeRoleDocument), variables));
    },
    AddUsersToEmployeeRoleContributors(variables: AddUsersToEmployeeRoleContributorsVariables): Promise<{ data?: AddUsersToEmployeeRoleContributors | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<AddUsersToEmployeeRoleContributors>(print(AddUsersToEmployeeRoleContributorsDocument), variables));
    },
    RemoveUsersFromEmployeeRoleContributors(variables: RemoveUsersFromEmployeeRoleContributorsVariables): Promise<{ data?: RemoveUsersFromEmployeeRoleContributors | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<RemoveUsersFromEmployeeRoleContributors>(print(RemoveUsersFromEmployeeRoleContributorsDocument), variables));
    },
    GetCurrentUserOrganization(variables?: GetCurrentUserOrganizationVariables): Promise<{ data?: GetCurrentUserOrganization | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetCurrentUserOrganization>(print(GetCurrentUserOrganizationDocument), variables));
    },
    GetCurrentUser(variables?: GetCurrentUserVariables): Promise<{ data?: GetCurrentUser | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetCurrentUser>(print(GetCurrentUserDocument), variables));
    },
    GetDirectory(variables: GetDirectoryVariables): Promise<{ data?: GetDirectory | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDirectory>(print(GetDirectoryDocument), variables));
    },
    GetDirectReports(variables: GetDirectReportsVariables): Promise<{ data?: GetDirectReports | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDirectReports>(print(GetDirectReportsDocument), variables));
    },
    GetDirectoryUser(variables: GetDirectoryUserVariables): Promise<{ data?: GetDirectoryUser | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDirectoryUser>(print(GetDirectoryUserDocument), variables));
    },
    GetDirectoryUserGoals(variables: GetDirectoryUserGoalsVariables): Promise<{ data?: GetDirectoryUserGoals | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDirectoryUserGoals>(print(GetDirectoryUserGoalsDocument), variables));
    },
    GetEvaluation(variables: GetEvaluationVariables): Promise<{ data?: GetEvaluation | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetEvaluation>(print(GetEvaluationDocument), variables));
    },
    GetGoal(variables: GetGoalVariables): Promise<{ data?: GetGoal | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetGoal>(print(GetGoalDocument), variables));
    },
    GetEvaluationCycles(variables?: GetEvaluationCyclesVariables): Promise<{ data?: GetEvaluationCycles | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetEvaluationCycles>(print(GetEvaluationCyclesDocument), variables));
    },
    GetLatestEvaluationCycles(variables?: GetLatestEvaluationCyclesVariables): Promise<{ data?: GetLatestEvaluationCycles | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetLatestEvaluationCycles>(print(GetLatestEvaluationCyclesDocument), variables));
    },
    GetGoalStatusOptions(variables?: GetGoalStatusOptionsVariables): Promise<{ data?: GetGoalStatusOptions | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetGoalStatusOptions>(print(GetGoalStatusOptionsDocument), variables));
    },
    GetCompanies(variables?: GetCompaniesVariables): Promise<{ data?: GetCompanies | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetCompanies>(print(GetCompaniesDocument), variables));
    },
    GetEmployeeRoles(variables?: GetEmployeeRolesVariables): Promise<{ data?: GetEmployeeRoles | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetEmployeeRoles>(print(GetEmployeeRolesDocument), variables));
    },
    GetEditableEmployeeRoles(variables?: GetEditableEmployeeRolesVariables): Promise<{ data?: GetEditableEmployeeRoles | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetEditableEmployeeRoles>(print(GetEditableEmployeeRolesDocument), variables));
    },
    GetEmployeeRole(variables: GetEmployeeRoleVariables): Promise<{ data?: GetEmployeeRole | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetEmployeeRole>(print(GetEmployeeRoleDocument), variables));
    },
    GetDepartments(variables?: GetDepartmentsVariables): Promise<{ data?: GetDepartments | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetDepartments>(print(GetDepartmentsDocument), variables));
    },
    GetEvaluationSummary(variables: GetEvaluationSummaryVariables): Promise<{ data?: GetEvaluationSummary | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetEvaluationSummary>(print(GetEvaluationSummaryDocument), variables));
    },
    GetSelfEvaluation(variables: GetSelfEvaluationVariables): Promise<{ data?: GetSelfEvaluation | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetSelfEvaluation>(print(GetSelfEvaluationDocument), variables));
    },
    GetManagerEvaluation(variables: GetManagerEvaluationVariables): Promise<{ data?: GetManagerEvaluation | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetManagerEvaluation>(print(GetManagerEvaluationDocument), variables));
    },
    GetPeerRequest(variables: GetPeerRequestVariables): Promise<{ data?: GetPeerRequest | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPeerRequest>(print(GetPeerRequestDocument), variables));
    },
    GetEvaluationCycleEvaluationSummaries(variables: GetEvaluationCycleEvaluationSummariesVariables): Promise<{ data?: GetEvaluationCycleEvaluationSummaries | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetEvaluationCycleEvaluationSummaries>(print(GetEvaluationCycleEvaluationSummariesDocument), variables));
    },
    GetPeerRequestDenyReasons(variables?: GetPeerRequestDenyReasonsVariables): Promise<{ data?: GetPeerRequestDenyReasons | undefined; extensions?: any; headers: Headers; status: number; errors?: GraphQLError[] | undefined; }> {
        return withWrapper(() => client.rawRequest<GetPeerRequestDenyReasons>(print(GetPeerRequestDenyReasonsDocument), variables));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;