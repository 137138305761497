import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppContext } from 'App';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import {
  match,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { Button, PageHeader } from 'shared/components';
import { Tab } from 'shared/components/pageContent';
import { usePageTitle } from 'shared/hooks';
import { createQueryParamModalHelpers } from 'shared/utils/queryParamModal';
import NewGoalSetModal from '../shared/Goals/NewGoalSetModal';
import Dashboard from './Dashboard';
import Evaluations from './Evaluations';
import Goals from './Goals';

const Home: FunctionComponent = () => {
  const match = useRouteMatch();
  return (
    <React.Fragment>
      <Switch>
        <Route
          path={`${match.path}/dashboard`}
          exact={true}
          render={() => <HomeMain match={match} />}
        />
        <Route
          path={`${match.path}/goals`}
          exact={true}
          render={() => <HomeMain match={match} />}
        />
        <Route
          path={`${match.path}/goals/:goalSetId`}
          render={() => <div>Goal Set Details</div>}
        />
        <Route
          path={`${match.path}/evaluations`}
          exact={true}
          render={() => <HomeMain match={match} />}
        />
        <Route
          path={`${match.path}/evaluations/:evaluationId`}
          render={() => <div>Evaluation Details</div>}
        />
        <Redirect to={`${match.url}/dashboard`} />
      </Switch>
    </React.Fragment>
  );
};

interface HomeMainProps {
  match: match<{}>;
}

const HomeMain: FunctionComponent<HomeMainProps> = ({ match }) => {
  usePageTitle('Home');

  const newGoalSetModalHelpers = createQueryParamModalHelpers('new-goal-group');

  const onSetNewGoals = useCallback(() => {
    newGoalSetModalHelpers.open();
  }, [newGoalSetModalHelpers]);

  const tabs = useMemo(() => {
    const tabList: Tab[] = [
      { path: `/dashboard`, title: 'Dashboard', pinned: true, icon: 'home' },
      {
        path: `/goals`,
        title: 'My Goals',
        pinned: true,
        icon: 'bullseye-arrow',
      },
      {
        path: `/evaluations`,
        title: 'My Evaluations',
        pinned: true,
        icon: 'list-ul',
      },
    ];
    return tabList;
  }, []);

  const appContext = useAppContext();

  return (
    <React.Fragment>
      <PageHeader
        title={'Home'}
        titleTools={[
          <FontAwesomeIcon key={'home-icon'} icon={['fal', 'home']} />,
        ]}
        tools={[
          <Button
            key={'set-new-goals'}
            icon={'list-ul'}
            variant={'outline'}
            onClick={onSetNewGoals}
          >
            Set New Goals
          </Button>,
        ]}
        tabs={tabs}
        match={match}
      />
      <Switch>
        <Route
          path={`${match.path}/dashboard`}
          exact={true}
          render={() => <Dashboard />}
        />
        <Route
          path={`${match.path}/goals`}
          exact={true}
          render={() => <Goals onSetNewGoals={onSetNewGoals} />}
        />
        <Route
          path={`${match.path}/evaluations`}
          exact={true}
          render={() => <Evaluations />}
        />
        <Redirect to={`${match.url}/dashboard`} />
      </Switch>
      {newGoalSetModalHelpers.isOpen() && (
        <NewGoalSetModal
          onClose={newGoalSetModalHelpers.close}
          onSuccess={() => false}
          employeeId={appContext.currentUserId}
        />
      )}
    </React.Fragment>
  );
};

export default Home;
