import React, { FunctionComponent } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { EvaluationReview } from './EvaluationReviewModal';
import { ReviewClassificationEnum } from 'shared/hooks/api/graphql/generated';
import { color, font } from 'shared/utils/styles';
import styled from 'styled-components';

// Create styles
const styles = StyleSheet.create({
  Body: {
    paddingTop: 35,
    paddingBottom: 65,
  },
  Wrapper: {
    padding: 15,
    fontSize: 9,
  },
  ReviewDetailsValueDetailWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    borderBottom: `1px solid ${color.border}`,
    marginBottom: 7,
    maxHeight: '100%',
    overflow: 'hidden',
  },
  ReviewDetailsValueInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  ReviewDetailsValueStrengthOrOpportunity: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 7,
  },
  ReviewDetailsValueNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: 30,
  },
  ReviewDetailsValueName: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    // fontFamily: 'Helvetica-Bold',
    fontSize: 12,
  },
  ReviewDetailsValueDetailHeading: {
    fontFamily: 'Helvetica-Oblique',
    fontSize: 9,
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  ReviewDetailsValueDetailDescription: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 10,
  },
  EntriesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 3,
    paddingBottom: 3,
  },
  StrengthOrOpportunityWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 2,
    paddingBottom: 2,
  },
  StrengthOrOpportunityDescription: {},
  StrengthOrOpportunityAuthor: {
    fontSize: 9,
  },
  GoalTitles: {
    display: 'flex',
    flexDirection: 'column',
  },
  GoalTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'Helvetica-Bold',
    paddingBottom: 3,
  },
  GoalSubtitle: {
    fontSize: 10,
    // fontFamily: 'Helvetica-Bold',
  },
  GoalBody: {
    fontSize: 9,
    width: '80%',
    // fontFamily: 'Helvetica-Bold',
  },
  HorizontalSeparator: {},
  PageFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    fontSize: 7,
    bottom: 30,
    left: 0,
    right: 0,
    color: color.textMedium,
    fontFamily: 'Helvetica-Oblique',
    paddingHorizontal: 35,
  },
  PageNumber: {
    position: 'absolute',
    fontSize: 7,
    bottom: 30,
    left: 0,
    right: 0,
    color: color.textMedium,
    fontFamily: 'Helvetica-Oblique',
    paddingHorizontal: 35,
    textAlign: 'center',
  },
  SectionTitle: {
    fontSize: 15,
    // fontFamily: 'Helvetica-Bold',
    paddingBottom: 7,
    borderBottom: `1px solid ${color.border}`,
  },
  PageHeader: {
    position: 'absolute',
    top: 15,
    left: 15,
    right: 0,
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: 20,
  },
  PageHeaderText: {
    fontSize: 11,
    marginBottom: 3,
  },
  PageHeaderSubtext: {
    fontSize: 10,
    marginBottom: 3,
    color: color.textMedium,
  },
});

interface EvaluationReviewPdfProps {
  review: EvaluationReview;
  evaluationEmployeeName: string;
  evaluationCycleName: string;
  evaluationTypeName: string;
}

// Create Document Component
const EvaluationReviewPdf: FunctionComponent<EvaluationReviewPdfProps> = ({
  review,
  evaluationEmployeeName,
  evaluationCycleName,
  evaluationTypeName,
}) => (
  <Document>
    <Page size="A4" wrap={true} style={styles.Body}>
      <View style={styles.PageHeader}>
        <Text style={styles.PageHeaderText}>
          {`${evaluationTypeName} - ${evaluationEmployeeName}`}
        </Text>
        <Text style={styles.PageHeaderSubtext}>{evaluationCycleName}</Text>
      </View>
      <View style={styles.Wrapper}>
        <Text style={styles.SectionTitle}>{`Goals Review`}</Text>
        {review.goalsReview.goals.map((item) => {
          return (
            <ReviewItemDetails
              key={item.id}
              item={item}
              reviewClassification={ReviewClassificationEnum.Goal}
            />
          );
        })}
        <Text
          style={styles.SectionTitle}
          break={true}
        >{`Values Alignment`}</Text>
        {review.valuesReview.values.map((item) => {
          return (
            <ReviewItemDetails
              key={item.id}
              item={item}
              reviewClassification={ReviewClassificationEnum.Value}
            />
          );
        })}
      </View>
      <View style={styles.PageFooter} fixed={true}>
        <Text>{`${evaluationTypeName}: ${evaluationEmployeeName}`}</Text>
        <Text>{evaluationCycleName}</Text>
      </View>
      <Text
        fixed={true}
        style={styles.PageNumber}
        render={({ pageNumber }) => pageNumber}
      />
    </Page>
  </Document>
);

export default EvaluationReviewPdf;

interface ReviewDetailItem {
  id: number;
  itemId: number;
  name: string;
  score?: number;
  details: string[];
  strengths: { id: number; description: string; author?: string }[];
  opportunities: { id: number; description: string; author?: string }[];
}

interface ReviewItemDetailsProps {
  item: ReviewDetailItem;
  reviewClassification: ReviewClassificationEnum;
}

const ReviewItemDetails: FunctionComponent<ReviewItemDetailsProps> = ({
  item,
  reviewClassification,
}) => {
  return (
    <View style={styles.ReviewDetailsValueDetailWrapper}>
      <View style={styles.ReviewDetailsValueInfo}>
        <View style={styles.ReviewDetailsValueNameWrapper}>
          <View style={styles.ReviewDetailsValueName}>
            <View style={styles.GoalTitles}>
              <View style={styles.GoalTitle}>
                <Text>{item.name}</Text>
              </View>
              {reviewClassification == ReviewClassificationEnum.Goal && (
                <View style={styles.GoalSubtitle}>
                  <Text>{`${
                    item.details.length > 0 ? item.details.length : 'No'
                  } Subgoals`}</Text>
                </View>
              )}
              {item.details && item.details.length > 0 && (
                <View style={styles.GoalBody}>
                  {item.details?.map((detail, index) => {
                    return reviewClassification ==
                      ReviewClassificationEnum.Goal ? (
                      <View key={index}>
                        <Text>{`- ${detail}`}</Text>
                      </View>
                    ) : (
                      <Text key={index}>{detail}</Text>
                    );
                  })}
                </View>
              )}
            </View>
          </View>
          <View style={{ display: 'flex' }}>
            <Text>{`Score: ${item.score ? item.score + '%' : 'N/A'}`}</Text>
          </View>
        </View>
        <React.Fragment>
          <View style={styles.HorizontalSeparator} />
          <View style={styles.ReviewDetailsValueStrengthOrOpportunity}>
            <View style={styles.ReviewDetailsValueDetailHeading}>
              <Text>Strengths</Text>
            </View>
            <View />
            <View style={styles.EntriesWrapper}>
              {item.strengths.length == 0 ? (
                <Text>N/A</Text>
              ) : (
                item.strengths.map((strength) => (
                  <View
                    style={styles.ReviewDetailsValueDetailDescription}
                    key={strength.id}
                  >
                    <View style={styles.StrengthOrOpportunityWrapper}>
                      <View style={styles.StrengthOrOpportunityDescription}>
                        <Text>{strength.description}</Text>
                      </View>
                      {strength.author && (
                        <View style={styles.StrengthOrOpportunityAuthor}>
                          <Text>Author: {strength.author}</Text>
                        </View>
                      )}
                    </View>
                  </View>
                ))
              )}
            </View>
          </View>
          <View style={styles.ReviewDetailsValueStrengthOrOpportunity}>
            <View style={styles.ReviewDetailsValueDetailHeading}>
              <Text>Opportunities</Text>
            </View>
            <View style={styles.HorizontalSeparator} />
            <View style={styles.EntriesWrapper}>
              {item.opportunities.length == 0 ? (
                <Text>N/A</Text>
              ) : (
                item.opportunities.map((opportunity) => (
                  <View
                    style={styles.ReviewDetailsValueDetailDescription}
                    key={opportunity.id}
                  >
                    <View style={styles.StrengthOrOpportunityWrapper}>
                      <View style={styles.StrengthOrOpportunityDescription}>
                        <Text>{opportunity.description}</Text>
                      </View>
                      {opportunity.author && (
                        <View style={styles.StrengthOrOpportunityAuthor}>
                          <Text>Author: {opportunity.author}</Text>
                        </View>
                      )}
                    </View>
                  </View>
                ))
              )}
            </View>
          </View>
        </React.Fragment>
      </View>
    </View>
  );
};
