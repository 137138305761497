import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';
import Modal from 'react-modal';
import Alert, { AlertVariant } from '../alert/Alert';
import styled from 'styled-components';
import Button, { ButtonProps } from '../button/Button';
import { color } from 'shared/utils/styles';
import { InputField } from '../form/input/Input';

type ModalSize = 'sm' | 'lg' | 'xl';

interface DialogProps {
  title: string;
  isOpen: boolean;
  message?: string;
  onClose: () => void;
  onConfirm: () => void;
  onCancel: () => void;
  confirmTitle?: string;
  cancelTitle?: string;
  variant?: AlertVariant;
  hideCancel?: boolean;
  isWorking?: boolean;
  isWorkingTitle?: string;
  confirmation?: string;
  valid?: boolean;
}

const StyledAlert = styled(Alert)`
  width: 500px;
`;

const DialogContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`;

const DialogMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`;

const DialogMessageTextWrapper = styled.div`
  margin-bottom: 20px;
`;

const DialogConfirmation = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;
`;

const DialogTools = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
`;

const ConfirmButton = styled(Button)<
  ButtonProps & { dialogVariant: AlertVariant }
>`
  margin-right: 5px;
  color: ${(props) => color[props.dialogVariant]};
`;

const CancelButton = styled(Button)`
  margin-left: 5px;
`;

const Dialog: FunctionComponent<DialogProps> = ({
  isOpen,
  title,
  message,
  onClose,
  onConfirm,
  onCancel,
  confirmation,
  confirmTitle = 'OK',
  cancelTitle = 'Cancel',
  variant = 'info',
  hideCancel = false,
  isWorking = false,
  isWorkingTitle = '',
  valid = true,
  children
}) => {
  const [confirmationInput, setConfirmationInput] = useState('');
  const confirmationInputRef = useRef<HTMLInputElement>(null);

  const confirmIsDisabled = useMemo(() => {
    if (!confirmation) {
      return false;
    } else {
      return confirmation.toLowerCase() != confirmationInput.toLowerCase();
    }
  }, [confirmation, confirmationInput]);

  const MessageContent = useMemo(
    () => (
      <DialogMessageWrapper>
        <DialogMessageTextWrapper>{message}</DialogMessageTextWrapper>
        {confirmation && (
          <DialogConfirmation>
            <InputField
              name={'confirmation'}
              ref={confirmationInputRef} // pass the ref to the InputField
              label={`Please type "${confirmation}" to confirm`}
              value={confirmationInput}
              onChange={(e) => setConfirmationInput(e.target.value)}
              placeholder={''}
              fullWidth={true}
            />
          </DialogConfirmation>
        )}
        {children}
        <DialogTools>
          <ConfirmButton
            dialogVariant={variant}
            variant={'outline'}
            onClick={onConfirm}
            fullWidth={true}
            isWorking={isWorking}
            disabled={confirmIsDisabled || !valid}
          >
            {isWorking ? isWorkingTitle : confirmTitle}
          </ConfirmButton>
          <CancelButton variant={'outline'} onClick={onCancel} fullWidth={true}>
            {cancelTitle}
          </CancelButton>
        </DialogTools>
      </DialogMessageWrapper>
    ),
    [
      message,
      variant,
      onConfirm,
      onCancel,
      isWorking,
      isWorkingTitle,
      confirmTitle,
      onCancel,
      cancelTitle,
      confirmation,
      confirmationInput
    ]
  );

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        confirmationInputRef.current?.focus();
      }, 100);
      const enterListener = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && !confirmIsDisabled && valid) {
          onConfirm();
        }
      };

      window.addEventListener('keydown', enterListener);

      return () => {
        window.removeEventListener('keydown', enterListener);
      };
    }
  }, [isOpen, onConfirm, confirmIsDisabled, valid]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Modal"
      closeTimeoutMS={300}
      className={'Modal__Content'}
      overlayClassName={'Modal__Overlay'}
    >
      <DialogContainer>
        <StyledAlert
          title={title}
          message={MessageContent}
          onClose={onClose}
          variant={variant}
        />
      </DialogContainer>
    </Modal>
  );
};

export default Dialog;
