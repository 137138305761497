import { CellClickedEvent } from 'ag-grid-community';
import { AgGridColumn } from 'ag-grid-react';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { PageDetails, PageToolbar, Table, ToolbarSearchInput } from 'shared/components';
import { usePageTitle, useRemainingHeight, useTable } from 'shared/hooks';

interface AllProps {
  users?: any[];
  isLoading: boolean;
}

const All: FunctionComponent<AllProps> = ({ users, isLoading }) => {
  usePageTitle('Directory - All');
  const { orgName } = useParams<{ orgName: string }>();
  const { ref, height } = useRemainingHeight();
  const history = useHistory();
  const {
    searchText,
    setSearchText,
    sortModel,
    setSortModel,
    groupedColumns,
    setGroupedColumns,
    gridColumns,
    gridEvents,
  } = useTable({ autoSizeColumns: true });

  const onCellClicked = useCallback((event: CellClickedEvent) => {
    if (event.data.id) {
      history.push(`/${orgName}/directory/${event.data.id}`);
    }
  }, []);

  return (
    <React.Fragment>
      <PageToolbar
        left={
          <ToolbarSearchInput
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={'Search for an employee...'}
          />
        }
      />
      <PageDetails ref={ref}>
        <Table
          rowData={users}
          defaultColDef={{ sortable: true, enableRowGroup: true }}
          pagination={true}
          paginationPageSize={100}
          quickFilterText={searchText}
          groupUseEntireRow={true}
          {...gridEvents}
          style={{ height: height, width: '100%' }}
          noRowsOverlayIcon={'address-book'}
          noRowsOverlayTitle={'No Entries'}
          noRowsOverlaySubtitle={
            'It looks like there are no entries in the Directory. That seems awful lonely...'
          }
          isLoading={isLoading}
        >
          <AgGridColumn
            headerName={'Name'}
            field={'name'}
            onCellClicked={onCellClicked}
            sort={'asc'}
          />
          <AgGridColumn headerName={'Title'} field={'title'} />
          <AgGridColumn headerName={'Email'} field={'email'} />
          <AgGridColumn headerName={'Employee Role'} field={'role'} />
          <AgGridColumn headerName={'Department'} field={'department'} />
          <AgGridColumn headerName={'Manager'} field={'manager'} />
          <AgGridColumn headerName={'Manager Email'} field={'managerEmail'} />
          <AgGridColumn headerName={'Company'} field={'company'} />
        </Table>
      </PageDetails>
    </React.Fragment>
  );
};

export default All;
