import { useAppContext } from "App";
import GoalsList from "App/Organization/shared/Goals/GoalsList";
import React, { FunctionComponent } from "react";
import { usePageTitle } from "shared/hooks";

interface CurrentGoalsProps {
  onSetNewGoals: () => void;
}

const CurrentGoals: FunctionComponent<CurrentGoalsProps> = ({
  onSetNewGoals,
}) => {
  usePageTitle('My Goals');
  const appContext = useAppContext();
  return (
    <React.Fragment>
      <GoalsList
        pageTitle={'All'}
        employeeId={appContext.currentUserId}
        onSetNewGoals={onSetNewGoals}
      />
    </React.Fragment>
  );
};

export default CurrentGoals;
