import React, { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { color } from 'shared/utils/styles';

const StyledSpinner = styled.div<{
  fillColor: string;
  backgroundColor: string;
  size: number;
}>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: 2px ${(props) => props.backgroundColor} solid;
  border-top: 2px ${(props) => props.fillColor} solid;
  border-radius: 50%;
  margin: 0px auto;
  animation: simple-spinner-spin 0.6s infinite linear;

  @keyframes simple-spinner-spin {
    0% {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const CenteredSpinnerWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

interface SpinnerProps extends HTMLAttributes<HTMLDivElement> {
  size?: number;
  fillColor?: string;
  backgroundColor?: string;
}

const Spinner: FunctionComponent<SpinnerProps> = ({
  size = 25,
  fillColor = color.primary,
  backgroundColor = color.border,
}) => {
  return (
    <StyledSpinner
      size={size}
      fillColor={fillColor}
      backgroundColor={backgroundColor}
    />
  );
};

export const CenteredSpinner: FunctionComponent<SpinnerProps> = ({
  size = 25,
  fillColor = color.primary,
  backgroundColor = color.border,
  ...props
}) => {
  return (
    <CenteredSpinnerWrapper {...props}>
      <Spinner
        size={size}
        fillColor={fillColor}
        backgroundColor={backgroundColor}
      />
    </CenteredSpinnerWrapper>
  );
};

export default Spinner;
