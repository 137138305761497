import { AgGridColumn } from 'ag-grid-react';
import React, { FunctionComponent, useMemo, useState } from 'react';
import {
  PageHeader,
  PageDetails,
  Table,
  SelectField,
  PageToolbar,
  ToolbarSearchInput,
  SortMenuButton,
  GroupMenuButton,
  ToolbarButton,
} from 'shared/components';
import { useRemainingHeight, useTable } from 'shared/hooks';
import {
  useGetEvaluationCycleEvaluationSummaries,
  useGetEvaluationCycles,
} from 'shared/hooks/api';
import EmptyDataMessage from '../shared/NoData';

interface EvaluationsSummaryTableProps {
  evaluationCycleId: number;
}

interface EvaluationSummaryRow {
  id: number;
  name: string;
  title?: string;
  department: string;
  email: string;
  company: string;
  manager?: string;
  managerEmail?: string;
  status: string;
  selfEvaluationStatus: string;
  managerEvaluationStatus: string;
  performanceScore: string;
  peerReviewCompleted: string;
}

const EvaluationsSummaryTable: FunctionComponent<EvaluationsSummaryTableProps> = ({
  evaluationCycleId,
}) => {
  const { ref, height } = useRemainingHeight();
  const {
    searchText,
    setSearchText,
    gridEvents,
    sortModel,
    setSortModel,
    groupedColumns,
    gridColumns,
    setGroupedColumns,
  } = useTable({
    autoSizeColumns: true,
  });

  const {
    data,
    status,
    refetch,
    isFetching,
  } = useGetEvaluationCycleEvaluationSummaries(evaluationCycleId);

  function accurateSum(numbers: number[], precision: number = 2): number {
    const factor = Math.pow(10, precision);
    return numbers.reduce((acc, num) => acc + Math.round(num * factor), 0) / factor;
  }

  const rows: EvaluationSummaryRow[] = useMemo(() => {
    return (
      data?.users?.map((user) => {
        const goalWeights = user.evaluation?.goals?.map(goal => goal.weight || 0) || [];
        const totalGoalWeight = goalWeights.length > 0
          ? Math.round(accurateSum(goalWeights) * 100) + '%'
          : '-';

        const completedPeerRequests =
          user.evaluation?.peerRequests.filter(
            (x) =>
              x.status.name == 'Accepted' &&
              x.requestedReview?.status.name == 'Complete'
          ).length ?? 0;

        return {
          id: user.id,
          name: user.displayName,
          title: user.title ?? undefined,
          department: user.department.name,
          email: user.email,
          company: user.company.name,
          manager: user.manager?.displayName,
          managerEmail: user.manager?.email,
          status: user.evaluation?.status?.name
            ? user.evaluation?.status?.name : '-',
          selfEvaluationStatus:
            user.evaluation?.selfEvaluation ? user.evaluation?.selfEvaluation?.status?.name : '-',
          managerEvaluationStatus:
            user.evaluation?.managerEvaluation ? user.evaluation?.managerEvaluation?.status?.name : '-',
          performanceScore: user.evaluation?.overallPerformanceScore?.totalScore ? Number(user.evaluation?.overallPerformanceScore?.totalScore).toString() + '%' : '-',
          peerReviewCompleted: `${completedPeerRequests} Completed`,
          totalGoals: user.evaluation?.goals?.length || '-',
          totalGoalWeight
        };
      }) ?? []
    );
  }, [data]);

  return (
    <React.Fragment>
      <PageToolbar
        left={
          <ToolbarSearchInput
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={'Filter the list of evaluations by typing here...'}
          />
        }
        right={
          <React.Fragment>
            <SortMenuButton
              sortModel={sortModel}
              setSortModel={setSortModel}
              gridColumns={gridColumns}
            />
            <GroupMenuButton
              groupedColumns={groupedColumns}
              setGroupedColumns={setGroupedColumns}
              gridColumns={gridColumns}
            />
            <ToolbarButton
              icon={'sync'}
              onClick={() => refetch()}
              isWorking={isFetching}
            >
              Refetch
            </ToolbarButton>
          </React.Fragment>
        }
      />
      <PageDetails ref={ref}>
        <Table
          rowData={rows}
          defaultColDef={{ sortable: true, enableRowGroup: true, filter: true }}
          pagination={true}
          paginationPageSize={100}
          quickFilterText={searchText}
          groupUseEntireRow={true}
          {...gridEvents}
          style={{ height: height, width: '100%' }}
          noRowsOverlayIcon={'comment-alt-exclamation'}
          noRowsOverlayTitle={'No Evaluations...'}
          noRowsOverlaySubtitle={`It looks like there are no evaluations that have been started for the selected evaluation cycle. Is it me, or does something about this just not seem right...`}
          isLoading={status == 'loading'}
        >
          <AgGridColumn headerName={'ID'} field={'id'} hide={true} />
          <AgGridColumn headerName={'Employee Name'} field={'name'} sort={'asc'} />
          <AgGridColumn headerName={'Title'} field={'title'} />
          <AgGridColumn headerName={'Email'} field={'email'} />
          <AgGridColumn headerName={'Department'} field={'department'} />
          <AgGridColumn headerName={'Manager'} field={'manager'} />
          <AgGridColumn headerName={'Manager Email'} field={'managerEmail'} />
          <AgGridColumn headerName={'Company'} field={'company'} />
          <AgGridColumn headerName={'Overall Status'} field={'status'} />
          <AgGridColumn headerName={'Number of Goals'} field={'totalGoals'} />
          <AgGridColumn headerName={'Total Goal Weight'} field={'totalGoalWeight'} />
          <AgGridColumn
            headerName={'Self-Assessment Status'}
            field={'selfEvaluationStatus'}
          />
          <AgGridColumn
            headerName={'Manager Review Status'}
            field={'managerEvaluationStatus'}
          />
          <AgGridColumn
            headerName={'Performance Score'}
            field={'performanceScore'}
          />
          <AgGridColumn
            headerName={'360 Feedback Reviews'}
            field={'peerReviewCompleted'}
          />
        </Table>
      </PageDetails>
    </React.Fragment>
  );
};

export default EvaluationsSummaryTable;
