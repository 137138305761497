import React, { FunctionComponent, useMemo } from 'react';
import { Button, FormModal } from 'shared/components';
import { useNotification } from 'shared/components/notifications';
import { useGetEmployeeRole, useUpdateEmployeeRole } from 'shared/hooks/api';
import {
  ErrorFragment,
  UpdateEmployeeRoleInput,
} from 'shared/hooks/api/graphql/generated';
import EmployeeRoleModalForm from './EmployeeRoleModal';
import { useAppContext } from 'App';
import {
  employeeRoleFormSchema,
  EmployeeRoleFormValues,
} from './NewEmployeeRoleModal';
import { CenteredSpinner } from 'shared/components/spinner/Spinner';
import { PartialNullable } from 'shared/utils/utilityTypes';
import NoAccess from 'shared/components/noAccess';
import { createQueryParamModalHelpers } from 'shared/utils/queryParamModal';
import EmployeeRoleAccessModal from './EmployeeRoleAccessModal';
import ViewEmployeeRoleModalForm from './ViewEmployeeRoleModalForm';

interface ViewEmployeeRoleModalProps {
  id: number;
  onClose: () => void;
  onSuccess: () => void;
}

const ViewEmployeeRoleModal: FunctionComponent<ViewEmployeeRoleModalProps> = ({
  id,
  onClose,
  onSuccess,
}) => {
  const { currentUserId } = useAppContext();

  const { data, status: getEmployeeRoleStatus } = useGetEmployeeRole(id);

  const initialValues: PartialNullable<EmployeeRoleFormValues> = useMemo(() => {
    return {
      name: data?.employeeRole?.name,
      missionStatement: data?.employeeRole?.missionStatement,
      managerRole: data?.employeeRole?.managerRole
        ? {
            value: data.employeeRole.managerRole.id,
            label: data.employeeRole.managerRole.name,
          }
        : null,
      qualifications:
        data?.employeeRole?.qualifications.map((x) => x.name) ?? [],
      competencies: data?.employeeRole?.competencies.map((x) => ({
        name: x.name,
        category: x.category,
      })),
      objectives: data?.employeeRole?.objectives?.map((x) => ({
        name: x.name,
        keyResults: x.keyResults,
      })),
    };
  }, [data]);

  return (
    <>
      {!data && getEmployeeRoleStatus == 'loading' ? (
        <CenteredSpinner />
      ) : (
        <FormModal<EmployeeRoleFormValues>
          title={'Role Scorecard'}
          initialValues={initialValues}
          handleSubmit={async (form, resetForm) => {}}
          isWorking={status == 'loading'}
          onClose={onClose}
          validationSchema={employeeRoleFormSchema}
          size={'lg'}
          showButton={false}
        >
          {(props) => (
            <>
              {getEmployeeRoleStatus == 'loading' ? (
                <CenteredSpinner />
              ) : (
                <ViewEmployeeRoleModalForm form={props} />
              )}
            </>
          )}
        </FormModal>
      )}
    </>
  );
};

export default ViewEmployeeRoleModal;
