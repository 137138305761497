import { useField } from 'formik';
import React, { FunctionComponent, HTMLProps, InputHTMLAttributes, useMemo } from 'react';
import styled from 'styled-components';
import FieldWrapper, { FieldWrapperProps } from '.';

type Option = {
  value: any;
  label: string;
}

interface RadioButtonGroupProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  options: Option[];
  value: any;
  onChange: (option: Option) => void;
  name: string;
  vertical?: boolean;
}

const RadioButton = styled.input`
`

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  & > ${RadioButton} {
    margin-right: 5px;
  }
`

const RadioButtonGroupWrapper = styled.div<{vertical: boolean}>`
  display: flex;
  
  ${props => props.vertical ? `
    flex-direction: column;
    justify-content: flex-start;
    ` : `
    flex-direction: row;
    align-items: center;
  `}
  
  padding: 5px 0;
  
  & > ${RadioButtonWrapper} {
    ${props => props.vertical ? 'margin-bottom: 7px' : 'margin-right: 10px'};
  }
`

const RadioButtonGroup: FunctionComponent<RadioButtonGroupProps> = ({
  options,
  value,
  onChange,
  vertical = false,
  ...props
}) => {
  
  const radioButtons = useMemo(() => {
    return options.map(option => {
      return (
        <RadioButtonWrapper key={option.value}>
          <RadioButton
            {...props}
            type={'radio'}
            role={'radio'}
            value={value}
            onChange={() => onChange(option)}
            checked={option.value == value}
            aria-checked={option.value == value}
            id={option.value.toString()}
            name={props.name}
          />
          <label htmlFor={option.value.toString()}>{option.label}</label>
        </RadioButtonWrapper>
      )
    })
  }, [options, value, onChange, vertical])

  return (
    <RadioButtonGroupWrapper vertical={vertical}>
      {radioButtons}
    </RadioButtonGroupWrapper>
  );
}

interface RadioButtonGroupFieldProps extends RadioButtonGroupProps, FieldWrapperProps {
  name: string;
}

export const RadioButtonGroupField: FunctionComponent<RadioButtonGroupFieldProps> = ({
  label,
  error,
  ...props
}) => {
  return (
    <FieldWrapper label={label} error={error}>
      <RadioButtonGroup {...props} />
    </FieldWrapper>
  );
};

export const RadioButtonGroupFormikField: FunctionComponent<RadioButtonGroupFieldProps> = ({
  name,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  return (
    <RadioButtonGroupField error={meta.error} {...props} {...field} onChange={(opt) => setValue(opt.value)} value={field.value} />
  );
};

export default RadioButtonGroup;
