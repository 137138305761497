import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AgGridColumn } from 'ag-grid-react';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Dialog,
  Modal,
  PageDetails,
  PageToolbar,
  Table,
  TableContextState,
  ToolbarButton,
} from 'shared/components';
import { useNotification } from 'shared/components/notifications';
import { useTable } from 'shared/hooks';
import {
  useAddUsersToEmployeeRoleContributors,
  useGetEmployeeRole,
  useRemoveUsersFromEmployeeRoleContributors,
} from 'shared/hooks/api';
import { color } from 'shared/utils/styles';
import AddMembersModal from './AddMembersModal';

interface EmployeeRoleAccessModalProps {
  onClose: () => void;
  employeeRoleId: number;
  currentUserCanEdit: boolean;
}

export type EntityAccessRow = {
  id: number;
  name: string;
};

const EmployeeRoleAccessModal: FunctionComponent<EmployeeRoleAccessModalProps> = ({
  onClose,
  employeeRoleId,
  currentUserCanEdit,
}) => {
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const { data, isFetching, refetch } = useGetEmployeeRole(employeeRoleId);

  const [
    assignMembers,
    { status: assignMembersStatus },
  ] = useAddUsersToEmployeeRoleContributors({ throwOnError: true });

  const [
    removeUsers,
    { status: removeUsersStatus },
  ] = useRemoveUsersFromEmployeeRoleContributors({ throwOnError: true });

  const rows = useMemo(() => {
    if (data?.employeeRole) {
      const users = data.employeeRole.contributors || [];

      const combined: EntityAccessRow[] = [];

      users.forEach((user) => {
        combined.push({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
        });
      });

      return combined;
    }
  }, [data]);

  const { tableRef, gridEvents } = useTable({ autoSizeColumns: true });

  const [selectedRows, setSelectedRows] = useState<EntityAccessRow[]>([]);

  const onSelectionChanged = useCallback(() => {
    if (tableRef.current) {
      const rows = tableRef.current.gridApi.getSelectedRows() || [];
      setSelectedRows(rows);
    }
  }, [setSelectedRows, tableRef]);

  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [isShowingRemoveDialog, setIsShowingRemoveDialog] = useState(false);

  const notify = useNotification();

  const tableState: TableContextState = {
    // selectOptions: {
    //   roles,
    // },
  };

  return (
    <React.Fragment>
      <Modal
        title="Role Scorecard Access"
        isOpen={true}
        onClose={onClose}
        size={'lg'}
        tools={[
          <Button
            key={'add-users'}
            icon={'user-plus'}
            variant={'outline'}
            onClick={() => setShowAddUsersModal(true)}
            disabled={!currentUserCanEdit}
          >
            Add Users
          </Button>,
        ]}
      >
        <PageToolbar
          left={
            <React.Fragment>
              <ToolbarButton
                disabled={selectedRows.length == 0 || !currentUserCanEdit}
                icon={
                  <FontAwesomeIcon
                    icon={['fal', 'user-slash']}
                    color={color.danger}
                  />
                }
                onClick={() => setIsShowingRemoveDialog(true)}
              >
                Remove Selected User(s)
              </ToolbarButton>
            </React.Fragment>
          }
        />
        <PageDetails>
          <Table
            rowData={rows}
            defaultColDef={{ sortable: true }}
            {...gridEvents}
            style={{ height: '500px', width: '100%' }}
            tableRef={tableRef}
            isLoading={isFetching}
            onSelectionChanged={onSelectionChanged}
            rowSelection={'multiple'}
            context={tableState}
            suppressRowClickSelection={true}
            noRowsOverlayIcon={'users'}
            noRowsOverlayTitle={'No Users'}
            noRowsOverlaySubtitle={'No one has been directly added as a contributor of this role scorecard.'}
          >
            <AgGridColumn
              maxWidth={40}
              checkboxSelection={true}
              pinned={'left'}
              headerCheckboxSelectionFilteredOnly={true}
            />
            <AgGridColumn headerName={'Name'} field={'name'} minWidth={200} />
          </Table>
        </PageDetails>
      </Modal>
      {showAddUsersModal && (
        <AddMembersModal
          existingMembers={rows || []}
          isWorking={assignMembersStatus == 'loading'}
          onClose={() => setShowAddUsersModal(false)}
          onSubmit={async (members) => {
            const userIds = members.map((x) => x.value.id);

            try {
              const result = await assignMembers({ employeeRoleId, userIds });
              if (result.errors) {
                notify({
                  duration: 5000,
                  title: 'Uh-oh!',
                  variant: 'danger',
                  message:
                    'An unknown error occurred while adding the specified users as contributors!',
                });
              } else {
                notify({
                  duration: 3000,
                  title: 'Success!',
                  message: 'Users Added as Contributors!',
                  variant: 'success',
                });
                setShowAddUsersModal(false);
                refetch();
              }
            } catch {
              notify({
                duration: 5000,
                title: 'Uh-oh!',
                variant: 'danger',
                message:
                  'An unknown error occurred while adding the specified users as coontributors!',
              });
            }
          }}
        />
      )}
      {isShowingRemoveDialog && selectedRows.length > 0 && (
        <Dialog
          title={'Remove Users(s)'}
          message={`Are you sure you want to remove ${selectedRows.length} users(s) as contributors from this role scorecard? `}
          confirmTitle={`Yes, Remove Selected Users`}
          variant={'danger'}
          isOpen={true}
          onClose={() => setIsShowingRemoveDialog(false)}
          onCancel={() => setIsShowingRemoveDialog(false)}
          isWorking={removeUsersStatus == 'loading'}
          onConfirm={async () => {
            try {
              const userIds = selectedRows.map((x) => x.id);
              const result = await removeUsers({ employeeRoleId, userIds});
              if (result.data?.removeUsersFromEmployeeRoleContributors) {
                notify({
                  message: `${selectedRows.length} members(s) have been removed from this role scorecard as contributors.`,
                });
                refetch();
              }
            } catch {
              notify({
                duration: 5000,
                title: 'Uh-oh',
                message: 'Unable to remove selected users(s) at this time.',
                variant: 'danger',
              });
            }
            setIsShowingRemoveDialog(false);
            setSelectedRows([]);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default EmployeeRoleAccessModal;
