import get from 'lodash/get';
import { PartialNullable } from './utilityTypes';

export const VALUE_CREATED = 'created';
export const VALUE_UPDATED = 'updated';
export const VALUE_DELETED = 'deleted';
export const VALUE_UNCHANGED = 'unchanged';

export function getDiffValue<T>(diff: any, key: string): T | undefined {
  if (get(diff, key)?._type && get(diff, key)?._type != VALUE_UNCHANGED) {
    return get(diff, key).data;
  } else {
    return undefined;
  }
}

const deepDiff: any = (obj: any, intialObj: any) => {
  if (isFunction(obj) || isFunction(intialObj)) {
    throw 'Invalid argument. Function given, object expected.';
  }
  if (isValue(obj) || isValue(intialObj)) {
    const result = compareValues(obj, intialObj);
    return {
      _type: result,
      data: obj === undefined ? intialObj : obj,
    };
  }
  const diff: { [key: string]: any } = {};
  for (const key in obj) {
    if (isFunction(obj[key])) {
      continue;
    }
    let value2 = undefined;
    if (intialObj[key] !== undefined) {
      value2 = intialObj[key];
    }
    const diffResult = deepDiff(obj[key], value2);
    diff[key] = diffResult;
  }
  for (const key in intialObj) {
    if (isFunction(intialObj[key]) || diff[key] !== undefined) {
      continue;
    }
    const diffResult2 = deepDiff(undefined, intialObj[key]);
    diff[key] = diffResult2;
  }
  return diff;
};

export default deepDiff;

const compareValues = (value1: any, value2: any) => {
  if (value1 === value2) {
    return VALUE_UNCHANGED;
  }
  if (
    isDate(value1) &&
    isDate(value2) &&
    value1.getTime() === value2.getTime()
  ) {
    return VALUE_UNCHANGED;
  }
  if (value1 === undefined) {
    return VALUE_CREATED;
  }
  if (value2 === undefined) {
    return VALUE_DELETED;
  }
  return VALUE_UPDATED;
};

const isFunction = (x: any) => {
  return Object.prototype.toString.call(x) === '[object Function]';
};

const isArray = (x: any) => {
  return Object.prototype.toString.call(x) === '[object Array]';
};

const isDate = (x: any) => {
  return Object.prototype.toString.call(x) === '[object Date]';
};

const isObject = (x: any) => {
  return Object.prototype.toString.call(x) === '[object Object]';
};

const isValue = (x: any) => {
  return !isObject(x) && !isArray(x);
};

///////////////////////////////////////////////

type DiffType = 'changed' | 'unchanged';

type Diff<T> = {
  [D in keyof T]?: DiffValue<T[D]>;
};

type DiffValue<T> = {
  __diffType: DiffType;
  value: T;
};

class Difference<T> {
  diff: Diff<T>;

  constructor(diff: Diff<T>) {
    this.diff = diff;
  }

  partial(): Partial<T> {
    const val: Partial<T> = {};
    for (const key in this.diff) {
      val[key] = this.diff[key]?.value;
    }
    return val;
  }
}

export function diff<T extends object>(
  origin: T | PartialNullable<T> | Partial<T>,
  changed: T
): Partial<T> {
  const result: Diff<T> = {};
  const partial: Partial<T> = {};
  for (const key in changed) {
    const diffType = origin[key] === changed[key] ? 'unchanged' : 'changed';
    result[key] = {
      __diffType: diffType,
      value: changed[key],
    };

    if (diffType == 'changed') {
      partial[key] = changed[key];
    }
  }

  return partial;
}
