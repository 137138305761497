export const EvaluationStatus = {
  InProgress: 1,
  SubmittedForApproval: 2,
  ChangesRequested: 3,
  Approved: 4,
  PublishedForEmployeeReview: 5,
  Completed: 6,
}

export const ReviewType = {
  Self: 1,
  Manager: 2,
  Peer: 3,
}

export const ReviewStatus = {
  NotStarted: 1,
  InProgress: 2,
  Completed: 3,
}

export const PeerRequestStatus = {
  Accepted: 1,
  Declined: 2,
  Pending: 3,
  Void: 4,
}