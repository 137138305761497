import React, { FunctionComponent, useMemo } from 'react';
import { useGetManagerEvaluation } from 'shared/hooks/api';
import EvaluationReviewModal, { EvaluationReview } from './EvaluationReviewModal';

interface ManagerEvaluationReviewProps {
  evaluationId: number;
  currentUserIsEvaluationEmployee: boolean;
  employeeName: string;
  evaluationCycleName: string;
  onClose: () => void;
  unsubmitEnabled: boolean;
}

const ManagerEvaluationReviewModal: FunctionComponent<ManagerEvaluationReviewProps> = ({
  evaluationId,
  currentUserIsEvaluationEmployee,
  employeeName,
  evaluationCycleName,
  onClose,
  unsubmitEnabled,
}) => {
  const { data, status } = useGetManagerEvaluation(evaluationId);

  const review: EvaluationReview | undefined = useMemo(() => {
    if (data?.evaluation?.managerEvaluation && data.evaluation.managerEvaluation.goalsReview && data.evaluation.managerEvaluation.valuesReview) {
      const managerEvaluation = data.evaluation.managerEvaluation;
      const goalsReview = managerEvaluation.goalsReview;
      const valuesReview = managerEvaluation.valuesReview;
      return {
        id: managerEvaluation.id,
        type: managerEvaluation.type ?? undefined,
        status: managerEvaluation.status ?? undefined,
        goalsReview: {
          id: goalsReview.id,
          type: goalsReview.type ?? undefined,
          status: goalsReview.status ?? undefined,
          goals: goalsReview.goals.map(goal => ({
            id: goal.id,
            itemId: goal.id,
            details: goal.subgoals?.map(x => x.name) ?? [],
            name: goal.name,
            score: goal.performanceReward ?? undefined,
            strengths: goal.strengths.map(x => ({
              id: x.id,
              description: x.description || "",
            })),
            opportunities: goal.opportunities.map(x => ({
              id: x.id,
              description: x.description || "",
            })),
          })),
          selfReviewContent: goalsReview.selfReviewContent.map(goal => ({
            id: goal.id,
            itemId: goal.id,
            details: goal.subgoals?.map(x => x.name) ?? [],
            name: goal.name,
            score: undefined,
            strengths: goal.strengths.map(x => ({
              id: x.id,
              description: x.description || "",
              author: x.author?.displayName,
            })),
            opportunities: goal.opportunities.map(x => ({
              id: x.id,
              description: x.description || "",
              author: x.author?.displayName,
            })),
          })),
        },
        valuesReview: {
          id: valuesReview.id,
          type: valuesReview.type ?? undefined,
          status: valuesReview.status ?? undefined,
          values: valuesReview.values.map(value => ({
            id: value.reviewValueId,
            itemId: value.id,
            details: value.description ? [value.description] : [],
            name: value.name,
            score: value.score ?? undefined,
            strengths: value.strengths.map(x => ({
              id: x.id,
              description: x.description || "",
            })),
            opportunities: value.opportunities.map(x => ({
              id: x.id,
              description: x.description || "",
            })),
          })),
          peerReviewContent: valuesReview.peerReviewContent.map(value => ({
            id: value.reviewValueId,
            itemId: value.id,
            details: value.description ? [value.description] : [],
            name: value.name,
            score: undefined,
            strengths: value.strengths.map(x => ({
              id: x.id,
              description: x.description || "",
              author: x.author?.displayName,
            })),
            opportunities: value.opportunities.map(x => ({
              id: x.id,
              description: x.description || "",
              author: x.author?.displayName,
            })),
          })),
        },
      }
    }
  }, [data]);
  return (
    <EvaluationReviewModal
      review={review}
      title={'Manager Review'}
      subtitle={'Details'}
      evaluationId={Number(evaluationId)}
      type={'manager'}
      currentUserIsManager={!currentUserIsEvaluationEmployee}
      currentUserIsEvaluationEmployee={currentUserIsEvaluationEmployee}
      onClose={onClose}
      isLoading={status == 'loading'}
      size={'xl'}
      employeeName={employeeName}
      evaluationCycleName={evaluationCycleName}
      unsubmitEnabled={unsubmitEnabled}
    />
  );
};

export default ManagerEvaluationReviewModal;
