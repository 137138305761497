import React, { FunctionComponent } from 'react';
import { FormModal, SelectOption } from 'shared/components';
import * as Yup from 'yup';
import { useNotification } from 'shared/components/notifications';
import { useCreateEmployeeRole } from 'shared/hooks/api';
import {
  CreateEmployeeRoleInput,
  ErrorFragment,
} from 'shared/hooks/api/graphql/generated';
import EmployeeRoleModalForm from './EmployeeRoleModal';
import { useAppContext } from 'App';

interface NewEmployeeRoleModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

type Option = {
  value: number;
  label: string;
};

export type EmployeeRoleFormValues = {
  name: string;
  missionStatement?: string | null;
  managerRole?: SelectOption | null;
  qualifications?: string[] | null;
  competencies?:
    | {
        name: string;
        category?: string | null;
      }[]
    | null;
  objectives?:
    | {
        name: string;
        keyResults?: string[] | null;
      }[]
    | null;
};

export const employeeRoleFormSchema: Yup.ObjectSchema<EmployeeRoleFormValues> = Yup.object()
  .shape({
    name: Yup.string().required().label('Name'),
    missionStatement: Yup.string()
      .notRequired()
      .nullable()
      .label('Mission Statement'),
    managerRole: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .notRequired()
      .label('Manager Role Scorecard'),
    qualifications: Yup.array()
      .of(Yup.string().required())
      .notRequired()
      .nullable(),
    competencies: Yup.array()
      .of(
        Yup.object()
          .shape({
            name: Yup.string().required("A competency must have a name"),
            category: Yup.string().notRequired().nullable(),
          })
          .required()
      )
      .notRequired()
      .nullable(),
    objectives: Yup.array()
      .of(
        Yup.object()
          .shape({
            name: Yup.string().required(),
            keyResults: Yup.array()
              .of(Yup.string().required())
              .notRequired()
              .nullable(),
          })
          .required()
      )
      .notRequired()
      .nullable(),
  })
  .required();

const NewEmployeeRoleModal: FunctionComponent<NewEmployeeRoleModalProps> = ({
  onClose,
  onSuccess,
}) => {
  const notify = useNotification();
  const { currentUserId } = useAppContext();
  const [createEmployeeRole, { status }] = useCreateEmployeeRole({
    throwOnError: true,
  });
  return (
    <FormModal<EmployeeRoleFormValues>
      title={'Create New Role Scorecard'}
      submitTitle={'Create'}
      initialValues={{ managerRole: null, missionStatement: '' }}
      handleSubmit={async (form, resetForm) => {
        const input: CreateEmployeeRoleInput = {
          name: form.name,
          missionStatement: form.missionStatement,
          managerRoleId: form.managerRole?.value,
          objectives: form.objectives?.map(x => ({
            name: x.name,
            keyResults: x.keyResults ?? [],
          })) ?? [],
          qualifications: form.qualifications ?? [],
          competencies: form.competencies?.map(x => ({
            name: x.name,
            category: x.category ?? '',
          })) ?? [],
        };
        try {
          const result = await createEmployeeRole(input);

          if (result.data?.createEmployeeRole) {
            switch (result.data.createEmployeeRole?.__typename) {
              case 'EmployeeRole': {
                notify({
                  duration: 3000,
                  title: 'Success!',
                  message: 'Role Scorecard Created!',
                  variant: 'success',
                });
                resetForm();
                onClose();
                onSuccess();
                break;
              }
              default: {
                notify({
                  duration: 7000,
                  title: 'Failed to create role scorecard',
                  message: (result.data.createEmployeeRole as ErrorFragment)
                    .message,
                  variant: 'danger',
                });
                break;
              }
            }
          } else if (result.errors) {
            const forbiddenError = result.errors.find(
              (x: any) => x?.extensions?.code == 'FORBIDDEN'
            );
            if (forbiddenError) {
              notify({
                duration: 5000,
                title: 'Permission Denied',
                variant: 'danger',
                message: forbiddenError?.message,
              });
            } else {
              throw Error();
            }
          } else {
            throw Error();
          }
        } catch (error) {
          notify({
            duration: 5000,
            title: 'Yikes!',
            variant: 'danger',
            message:
              'An unknown error occurred while creating the role scorecard!',
          });
        }
      }}
      isWorking={status == 'loading'}
      onClose={onClose}
      validationSchema={employeeRoleFormSchema}
    >
      {(props) => <EmployeeRoleModalForm form={props}/>}
    </FormModal>
  );
};

export default NewEmployeeRoleModal;
