import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/pro-light-svg-icons';

import { Row, Column } from 'shared/components';
import { color, font } from 'shared/utils/styles';

const CardBody = styled.div<{ active: boolean }>`
  border: 1px solid
    ${(props) =>
      props.active && props.active == true ? color.primary : color.border};
  border-radius: 5px;
  padding: 0.5rem;
  cursor: pointer;
  height: 6rem;
  margin: 0.75rem;
  grid-gap: 0;
  &:hover {
    border: 1px solid
      ${(props) =>
        props.active && props.active == true
          ? color.textMedium
          : color.textLight};
  }
`;

const CardTitle = styled.p`
  ${font.size(15)};
  ${font.bold};
  margin-top: -5px;
  margin-bottom: 22px;
`;

const CardColumn = styled(Column)`
  height: 30px;
`;

const CardValue = styled.p`
  text-align: right;
  margin-top: -15px;
  font-size: 2.5rem;
`;

const StyledIcon = styled.span<{ color: string }>`
  color: ${(props) => color[props.color]};
  font-size: 1.5rem;
`;

export type IconColor =
  | 'primary'
  | 'success'
  | 'secondary'
  | 'warning'
  | 'danger'
  | 'info';

interface IconCardProps {
  active?: boolean;
  title: string;
  icon: IconName | React.ReactNode;
  iconColor?: IconColor;
  value: number;
  onClick?: () => void;
}

const IconCard: FunctionComponent<IconCardProps> = ({
  active = false,
  title,
  icon,
  iconColor = 'info',
  value,
  onClick,
}) => {
  const countValue = String(value);
  return (
    <CardBody active={active} onClick={onClick}>
      <CardTitle>{title}</CardTitle>
      <Row gap={0}>
        <CardColumn span={6}>
          <StyledIcon color={iconColor}>
            {icon && typeof icon === 'string' ? (
              <FontAwesomeIcon
                icon={['fas', icon as IconName]}
                fixedWidth={true}
              />
            ) : (
              icon
            )}
          </StyledIcon>
        </CardColumn>
        <CardColumn span={6}>
          <CardValue>{countValue}</CardValue>
        </CardColumn>
      </Row>
    </CardBody>
  );
};

export default IconCard;
