import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppContext } from 'App';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Alert,
  Badge,
  Button,
  Dialog,
  DropdownButton,
  DropdownItem,
  FormFieldWrapper,
  InputField,
  Modal,
  PageDetails,
  Tooltip,
} from 'shared/components';
import { MenuSeparator } from 'shared/components/menu/DropdownButton';
import { useNotification } from 'shared/components/notifications';
import { CenteredSpinner } from 'shared/components/spinner/Spinner';
import {
  useCreateReviewOpportunity,
  useCreateReviewStrength,
  useDeleteReviewOpportunity,
  useDeleteReviewStrength,
  useSubmitEvaluationForReview,
  useSubmitEvaluationReview,
  useUnsubmitEvaluationFromReview,
  useUnsubmitEvaluationReview
} from 'shared/hooks/api';
import {
  CreateStrengthOrOpportunityInput,
  ReviewClassificationEnum,
} from 'shared/hooks/api/graphql/generated';
import { dateTimeOffsetFormat, monthDayYearTimeFormat } from 'shared/utils/dateFormats';
import { color, font } from 'shared/utils/styles';
import styled from 'styled-components';
import { number } from 'yup';
import ReviewDetails, { ReviewDetailItem } from './ReviewDetails';
import parse from 'date-fns/parse';
import { formatDatetime } from 'shared/utils/formatting/formatters';
import { PDFDownloadLink } from '@react-pdf/renderer';
import EvaluationReviewPdf from './EvaluationReviewPdf';
import { ReviewStatus } from 'shared/constants';

interface EvaluationReviewProps {
  title: string;
  subtitle: string;
  evaluationId: number;
  type: 'self' | 'manager';
  currentUserIsManager: boolean;
  currentUserIsEvaluationEmployee: boolean;
  review?: EvaluationReview;
  isLoading: boolean;
  onClose: () => void;
  size: 'lg' | 'xl';
  readonly?: boolean;
  showSupplementaryContent?: boolean;
  isSigningEvaluation?: boolean;
  onSignEvaluation?: () => void;
  managerSignedDate?: string;
  employeeSignedDate?: string;
  employeeName: string;
  evaluationCycleName: string;
  unsubmitEnabled: boolean;
}

export interface EvaluationReview {
  id: number;
  type: { id: number; name: string };
  status: { id: number; name: string };
  goalsReview: GoalsReview;
  valuesReview: ValuesReview;
  author?: string;
}

interface GoalsReview {
  id: number;
  type: { id: number; name: string };
  status: { id: number; name: string };
  goals: ReviewDetailItem[];
  selfReviewContent: ReviewDetailItem[];
}

interface ValuesReview {
  id: number;
  type: { id: number; name: string };
  status: { id: number; name: string };
  values: ReviewDetailItem[];
  peerReviewContent: ReviewDetailItem[];
}

const EvaluationReviewModal: FunctionComponent<EvaluationReviewProps> = ({
  title,
  subtitle,
  evaluationId,
  type,
  currentUserIsManager,
  currentUserIsEvaluationEmployee,
  employeeSignedDate,
  managerSignedDate,
  review,
  isLoading,
  onClose,
  onSignEvaluation,
  isSigningEvaluation,
  employeeName,
  evaluationCycleName,
  size = 'lg',
  showSupplementaryContent: providedShowSupplementaryContent,
  readonly: providedReadonly,
  unsubmitEnabled,
}) => {
  const [selectedTab, setSelectedTab] = React.useState('goals-review');
  const readonly = useMemo(() => {
    return review?.status.name == 'Complete' ?? providedReadonly;
  }, [review, providedReadonly]);

  const showSupplementaryContent = useMemo(() => {
    return providedShowSupplementaryContent ?? type == 'manager';
  }, [type, providedShowSupplementaryContent]);

  const isEnoughContent = useMemo(() => {
    const goalsOpportunities = review?.goalsReview.goals.flatMap(
      (x) => x.opportunities
    );
    const goalsStrengths = review?.goalsReview.goals.flatMap(
      (x) => x.strengths
    );
    const valuesOpportunities = review?.valuesReview.values.flatMap(
      (x) => x.opportunities
    );
    const valuesStrengths = review?.valuesReview.values.flatMap(
      (x) => x.strengths
    );
    let hasAtLeastOneEntryForGoalsAndValues = true;
    if (
      (goalsOpportunities?.length == 0 && goalsStrengths?.length == 0) ||
      (valuesOpportunities?.length == 0 && valuesStrengths?.length == 0)
    ) {
      hasAtLeastOneEntryForGoalsAndValues = false;
    }
    let hasScoreForAllGoalsAndValues = true;
    if (type == 'manager') {
      review?.goalsReview.goals.forEach((goal) => {
        if (typeof goal.score != "number") hasScoreForAllGoalsAndValues = false;
      });
      review?.valuesReview.values.forEach((value) => {
        if (typeof value.score != "number") hasScoreForAllGoalsAndValues = false;
      });
    }

    return hasAtLeastOneEntryForGoalsAndValues && hasScoreForAllGoalsAndValues;
  }, [review, type]);

  const invalidContentMessage = useMemo(() => {
    let message: string | undefined;
    if (!isEnoughContent && type == 'self') {
      message =
        'In order to submit this review, you must have filled out at least 1 strength or opportunity for both the "Goals Review" and "Values Alignment" sections.';
    } else if (!isEnoughContent && type == 'manager') {
      message =
        'In order to submit this review, you must have filled out at least 1 strength or opportunity for both the "Goals Review" and "Values Alignment" sections. Additionally, a performance score must be provided for each of the goals and values listed.';
    }
    return message;
  }, [isEnoughContent, type]);

  const [enteredName, setEnteredName] = useState('');
  const appContext = useAppContext();

  const [
    submitReview,
    { status: submitReviewStatus },
  ] = useSubmitEvaluationReview(evaluationId);

  const [
    unsubmitReview,
    { status: unsubmitReviewStatus },
  ] = useUnsubmitEvaluationReview(evaluationId);

  const footerTools = useMemo(() => {
    const tools = [];
    if (selectedTab == 'goals-review') {
      tools.push(
        <Button
          key={'to-values-alignment'}
          iconOnRight={true}
          icon={'arrow-right'}
          color={'primary'}
          onClick={() => setSelectedTab('values-alignment')}
        >
          Next: Values Alignment
        </Button>
      );
    } else if (selectedTab == 'values-alignment') {
      tools.push(
        <Button
          key={'to-goals-review'}
          icon={'arrow-left'}
          onClick={() => setSelectedTab('goals-review')}
        >
          Previous: Goals Review
        </Button>
      );
      if (onSignEvaluation) {
        tools.push(
          <Button
            key={'to-acknowledgement'}
            iconOnRight={true}
            icon={'arrow-right'}
            color={'primary'}
            onClick={() => setSelectedTab('acknowledgement')}
          >
            Next: Acknowledgement
          </Button>
        );
      }
      if (!readonly) {
        tools.push(
          <DropdownButton
            title={'Done'}
            key={'finish'}
            icon={'chevron-down'}
            iconOnRight={true}
            color={'success'}
            isWorking={submitReviewStatus == 'loading'}
          >
            <DropdownItem icon={'times'} onClick={onClose}>
              Close
            </DropdownItem>
            <MenuSeparator />
            <DropdownItem
              icon={
                <FontAwesomeIcon
                  icon={['fal', 'check-square']}
                  style={{ color: color.success }}
                />
              }
              onClick={() => setIsShowingSubmitDialog(true)}
              disabled={!isEnoughContent}
              tooltip={invalidContentMessage}
            >
              Submit
            </DropdownItem>
          </DropdownButton>
        );
      }
    } else if (selectedTab == 'acknowledgement' && onSignEvaluation && (!employeeSignedDate && currentUserIsEvaluationEmployee) || (!managerSignedDate && currentUserIsManager)) {
      tools.push(
        <Button
          key={'submit-acknowledgement'}
          color={'success'}
          onClick={onSignEvaluation}
          disabled={enteredName != appContext.currentUserName}
          isWorking={isSigningEvaluation}
        >
          Submit Acknowledgement
        </Button>
      );
    }
    return tools;
  }, [selectedTab, isEnoughContent, onSignEvaluation, enteredName, isSigningEvaluation, employeeSignedDate, managerSignedDate, currentUserIsManager, currentUserIsEvaluationEmployee, readonly, submitReviewStatus, unsubmitReviewStatus]);

  const [isShowingSubmitDialog, setIsShowingSubmitDialog] = useState(false);
  const [isShowingUnsubmitDialog, setIsShowingUnsubmitDialog] = useState(false);
  const [
    submitForReview,
    { status: submitForReviewStatus },
  ] = useSubmitEvaluationForReview(Number(evaluationId));
  const [
    unsubmitFromReview,
    { status: unsubmitFromReviewStatus },
  ] = useUnsubmitEvaluationFromReview(Number(evaluationId));

  const onSubmitReview = useCallback(async (isManagerEval) => {
    if (review) {
      try {
        const result = await submitReview(review.id);
        if (result.data?.submitEvaluationReview) {
          let message = 'Evaluation Submitted!';
          let isSuccess = false;

          if (isManagerEval) {
            const resulty = await submitForReview(Number(evaluationId));
            if (resulty.data?.submitEvaluationForReview) {
              isSuccess = true;
              message = 'Evaluation Submitted for Approval!';
            } 
          } else {
            isSuccess = true;
          }

          if(isSuccess){
            notify({
              duration: 3000,
              title: 'Success!',
              message,
              variant: 'success',
            });
            onClose();
          } else {
            throw Error();
          }
        } else if (result.errors) {
          const forbiddenError = result.errors.find(
            (x: any) => x?.extensions?.code == 'FORBIDDEN'
          );
          if (forbiddenError) {
            notify({
              duration: 5000,
              title: 'Permission Denied',
              variant: 'danger',
              message: forbiddenError?.message,
            });
          } else {
            throw Error();
          }
        } else {
          throw Error();
        }
      } catch (error) {
        notify({
          duration: 5000,
          title: 'Uh-oh!',
          variant: 'danger',
          message: 'An unknown error occurred while submitting the evaluation!',
        });
      }
    }
  }, [submitReview, onClose, review]);

  const onUnsubmitReview = useCallback(async (isManagerEval) => {
    if (review) {
      try {
        const result = await unsubmitReview(review.id);
        if (result.data?.unsubmitEvaluationReview) {
          let message = 'Evaluation Unsubmitted!';

          if(isManagerEval) {
            const unsubmitFromReviewResult = await unsubmitFromReview(Number(evaluationId));
            if (unsubmitFromReviewResult.data?.unsubmitEvaluationFromReview) {
              message = 'Evaluation Unsubmitted for Approval!';
            }
          }

          notify({
            duration: 3000,
            title: 'Success!',
            message,
            variant: 'success',
          });
          onClose();
        } else if (result.errors) {
          const forbiddenError = result.errors.find(
            (x: any) => x?.extensions?.code == 'FORBIDDEN'
          );
          if (forbiddenError) {
            notify({
              duration: 5000,
              title: 'Permission Denied',
              variant: 'danger',
              message: forbiddenError?.message,
            });
          } else {
            throw Error();
          }
        } else {
          throw Error();
        }
      } catch (error) {
        notify({
          duration: 5000,
          title: 'Uh-oh!',
          variant: 'danger',
          message: 'An unknown error occurred while unsubmitting the evaluation!',
        });
      }
    }
  }, [unsubmitReview, onClose, review]);

  const tools = useMemo(() => {
    if (review) {
      const finalTools = [
        <Badge
          key={'review-status'}
          color={review.status.name == 'Complete' ? 'success' : 'warning'}
        >
          {review.status.name}
        </Badge>,
      ];
      if (review.status.id == ReviewStatus.Completed) {
        finalTools.push(
          <Tooltip disabled={unsubmitEnabled} content={'Unsubmit is only allowed for 30 days after the end of the evaluation cycle.'}>
            <span>
              <Button
                key={'unsubmit'}
                slim={true}
                disabled={!unsubmitEnabled}
                isWorking={unsubmitReviewStatus == 'loading'}
                color={'warning'}
                onClick={() => setIsShowingUnsubmitDialog(true)}
              >
                Unsubmit
              </Button>
            </span>
          </Tooltip>,
          <PDFDownloadLink key={'download-link'} document={<EvaluationReviewPdf review={review} evaluationTypeName={title} evaluationEmployeeName={employeeName} evaluationCycleName={evaluationCycleName} />} fileName="somename.pdf">
            {({ blob, url, loading, error }) => <Button icon={'file-export'} variant={'outline'} slim={true} disabled={loading}>Export</Button>}
          </PDFDownloadLink>
        )
      }
      return finalTools;
    } else {
      return [];
    }
  }, [review, title, employeeName, evaluationCycleName, onSubmitReview, onUnsubmitReview, submitReviewStatus, unsubmitReviewStatus]);

  const [
    createReviewStrength,
    { status: createReviewStrengthStatus },
  ] = useCreateReviewStrength(evaluationId);
  const [
    createReviewOpportunity,
    { status: createReviewOpportunityStatus },
  ] = useCreateReviewOpportunity(evaluationId);

  const [
    deleteReviewStrength,
    { status: deleteReviewStrengthStatus },
  ] = useDeleteReviewStrength(evaluationId);
  const [
    deleteReviewOpportunity,
    { status: deleteReviewOpportunityStatus },
  ] = useDeleteReviewOpportunity(evaluationId);
  const notify = useNotification();

  const [creatingIds, setCreatingIds] = useState<string[]>([]);
  const [deletingIds, setDeletingIds] = useState<string[]>([]);

  const onCreateStrengthOrOpportunity = useCallback(
    async (
      reviewId: number,
      reviewEntryId: number,
      type: 'strength' | 'opportunity',
      reviewClassification: ReviewClassificationEnum
    ) => {
      const input: CreateStrengthOrOpportunityInput = {
        reviewId: reviewId,
        reviewEntryId: reviewEntryId,
        reviewClassification: reviewClassification,
        description: '',
      };
      switch (type) {
        case 'strength': {
          setCreatingIds((ids) => [...ids, `strength:${reviewEntryId}`]);
          try {
            const result = await createReviewStrength(input);
            if (result.data?.createReviewStrength) {
              // notify({
              //   duration: 3000,
              //   title: 'Success!',
              //   message: 'Evaluation started!',
              //   variant: 'success',
              // });
            } else if (result.errors) {
              console.log(result.errors);
              const forbiddenError = result.errors.find(
                (x: any) => x?.extensions?.code == 'FORBIDDEN'
              );
              if (forbiddenError) {
                notify({
                  duration: 5000,
                  title: 'Permission Denied',
                  variant: 'danger',
                  message: forbiddenError?.message,
                });
              } else {
                throw Error();
              }
            } else {
              throw Error();
            }
          } catch (error) {
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              variant: 'danger',
              message: 'An unknown error occurred while creating the strength!',
            });
          } finally {
            setCreatingIds((ids) => {
              const copy = [...ids];
              const firstIndex = copy.findIndex(
                (x) => x == `strength:${reviewEntryId}`
              );
              if (firstIndex != -1) {
                copy.splice(firstIndex);
              }
              return [...copy];
            });
          }
          break;
        }
        case 'opportunity': {
          setCreatingIds((ids) => [...ids, `opportunity:${reviewEntryId}`]);
          try {
            const result = await createReviewOpportunity(input);
            if (result.data?.createReviewOpportunity) {
              // notify({
              //   duration: 3000,
              //   title: 'Success!',
              //   message: 'Evaluation started!',
              //   variant: 'success',
              // });
            } else if (result.errors) {
              const forbiddenError = result.errors.find(
                (x: any) => x?.extensions?.code == 'FORBIDDEN'
              );
              if (forbiddenError) {
                notify({
                  duration: 5000,
                  title: 'Permission Denied',
                  variant: 'danger',
                  message: forbiddenError?.message,
                });
              } else {
                throw Error();
              }
            } else {
              throw Error();
            }
          } catch (error) {
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              variant: 'danger',
              message:
                'An unknown error occurred while creating the opportunity!',
            });
          } finally {
            setCreatingIds((ids) => {
              const copy = [...ids];
              const firstIndex = copy.findIndex(
                (x) => x == `opportunity:${reviewEntryId}`
              );
              if (firstIndex != -1) {
                copy.splice(firstIndex);
              }
              return [...copy];
            });
          }
          break;
        }
      }
    },
    [createReviewStrength, createReviewOpportunity]
  );

  const onDeleteStrengthOrOpportunity = useCallback(
    async (
      id: number,
      type: 'strength' | 'opportunity',
      reviewClassification: ReviewClassificationEnum
    ) => {
      const input = {
        id: id,
        reviewClassification: reviewClassification,
      };
      let didDelete = false;
      switch (type) {
        case 'strength': {
          setDeletingIds((ids) => [...ids, `strength:${id}`]);
          try {
            const result = await deleteReviewStrength(input);
            if (result.data?.deleteReviewStrength) {
              // notify({
              //   duration: 3000,
              //   title: 'Success!',
              //   message: 'Evaluation started!',
              //   variant: 'success',
              // });
              didDelete = true;
            } else if (result.errors) {
              const forbiddenError = result.errors.find(
                (x: any) => x?.extensions?.code == 'FORBIDDEN'
              );
              if (forbiddenError) {
                notify({
                  duration: 5000,
                  title: 'Permission Denied',
                  variant: 'danger',
                  message: forbiddenError?.message,
                });
              } else {
                throw Error();
              }
            } else {
              throw Error();
            }
          } catch (error) {
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              variant: 'danger',
              message: 'An unknown error occurred while deleting the strength!',
            });
          } finally {
            setDeletingIds((ids) => {
              const copy = [...ids];
              const firstIndex = copy.findIndex((x) => x == `strength:${id}`);
              if (firstIndex != -1) {
                copy.splice(firstIndex);
              }
              return [...copy];
            });
          }
          break;
        }
        case 'opportunity': {
          setDeletingIds((ids) => [...ids, `opportunity:${id}`]);
          try {
            const result = await deleteReviewOpportunity(input);
            if (result.data?.deleteReviewOpportunity) {
              // notify({
              //   duration: 3000,
              //   title: 'Success!',
              //   message: 'Evaluation started!',
              //   variant: 'success',
              // });
              didDelete = true;
            } else if (result.errors) {
              const forbiddenError = result.errors.find(
                (x: any) => x?.extensions?.code == 'FORBIDDEN'
              );
              if (forbiddenError) {
                notify({
                  duration: 5000,
                  title: 'Permission Denied',
                  variant: 'danger',
                  message: forbiddenError?.message,
                });
              } else {
                throw Error();
              }
            } else {
              throw Error();
            }
          } catch (error) {
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              variant: 'danger',
              message:
                'An unknown error occurred while deleting the opportunity!',
            });
          } finally {
            setDeletingIds((ids) => {
              const copy = [...ids];
              const firstIndex = copy.findIndex(
                (x) => x == `opportunity:${id}`
              );
              if (firstIndex != -1) {
                copy.splice(firstIndex);
              }
              return [...copy];
            });
          }
          break;
        }
      }
      return didDelete;
    },
    [deleteReviewStrength, deleteReviewOpportunity]
  );

  const isCreatingStrengthOrOpportunity = useMemo(() => {
    return (
      createReviewOpportunityStatus == 'loading' ||
      createReviewStrengthStatus == 'loading'
    );
  }, [createReviewOpportunityStatus, createReviewStrengthStatus]);

  const isDeletingStrengthOrOpportunity = useMemo(() => {
    return (
      deleteReviewOpportunityStatus == 'loading' ||
      deleteReviewStrengthStatus == 'loading'
    );
  }, [deleteReviewOpportunityStatus, deleteReviewStrengthStatus]);

  const tabs = useMemo(() => {
    const tabs = [
      { path: 'goals-review', title: '1. Goals Review' },
      { path: 'values-alignment', title: '2. Values Alignment', },
    ];
    return onSignEvaluation
      ? [...tabs, { path: 'acknowledgement', title: '3. Acknowledgement' }]
      : tabs;
  }, [onSignEvaluation]);

  return (
    <React.Fragment>
      <Modal
        title={title}
        subtitle={subtitle}
        isOpen={true}
        onClose={onClose}
        size={size}
        localMatch={selectedTab}
        onTabClick={(path) => setSelectedTab(path)}
        tabs={tabs}
        tools={tools}
        footerTools={footerTools}
        style={{ maxHeight: '80%' }}
      >
        {isLoading && !review ? (
          <div style={{ height: '200px' }}>
            <CenteredSpinner />
          </div>
        ) : (
          review && (
            <PageDetails>
              {selectedTab == 'goals-review' ? (
                <ReviewDetails
                  type={type}
                  isManagerView={currentUserIsManager}
                  readonly={readonly}
                  items={review.goalsReview.goals}
                  emptyMessage={
                    currentUserIsManager
                      ? 'This employee has not set any goals for this evaluation cycle.'
                      : `It looks like you haven't set any goals for this evaluation cycle yet.`
                  }
                  onCreateStrengthOrOpportunity={(entryId, type) => {
                    onCreateStrengthOrOpportunity(
                      review.goalsReview.id,
                      entryId,
                      type,
                      ReviewClassificationEnum.Goal
                    );
                  }}
                  isCreatingStrengthOrOpportunity={
                    isCreatingStrengthOrOpportunity
                  }
                  onDeleteStrengthOrOpportunity={async (id, type) => {
                    return await onDeleteStrengthOrOpportunity(
                      id,
                      type,
                      ReviewClassificationEnum.Goal
                    );
                  }}
                  isDeletingStrengthOrOpportunity={
                    isDeletingStrengthOrOpportunity
                  }
                  creatingIds={creatingIds}
                  deletingIds={deletingIds}
                  evaluationId={evaluationId}
                  reviewClassification={ReviewClassificationEnum.Goal}
                  showSupplementaryContent={showSupplementaryContent}
                  supplementaryContent={review.goalsReview.selfReviewContent}
                  supplementaryContentTitle={
                    type == 'manager' ? 'Self-Assessment Content' : undefined
                  }
                  supplementaryContentSubtitle={
                    type == 'manager'
                      ? "There is no content from this employee's self-assessment to show here."
                      : undefined
                  }
                  showTools={type == 'manager'}
                />
              ) : selectedTab == 'values-alignment' ? (
                <ReviewDetails
                  type={type}
                  isManagerView={currentUserIsManager}
                  readonly={readonly}
                  items={review.valuesReview.values}
                  emptyMessage={
                    readonly
                      ? 'This review was submitted without any values. What a shame...'
                      : `Apparently there aren't any values set for the evaluation cycle that this review is for... Oh well, less work for you! The review can be completed once these have been added by the HR department.`
                  }
                  onCreateStrengthOrOpportunity={(entryId, type) => {
                    onCreateStrengthOrOpportunity(
                      review.valuesReview.id,
                      entryId,
                      type,
                      ReviewClassificationEnum.Value
                    );
                  }}
                  isCreatingStrengthOrOpportunity={
                    isCreatingStrengthOrOpportunity
                  }
                  onDeleteStrengthOrOpportunity={async (id, type) => {
                    return await onDeleteStrengthOrOpportunity(
                      id,
                      type,
                      ReviewClassificationEnum.Value
                    );
                  }}
                  isDeletingStrengthOrOpportunity={
                    isDeletingStrengthOrOpportunity
                  }
                  creatingIds={creatingIds}
                  deletingIds={deletingIds}
                  evaluationId={evaluationId}
                  showSupplementaryContent={showSupplementaryContent}
                  supplementaryContent={review.valuesReview.peerReviewContent}
                  reviewClassification={ReviewClassificationEnum.Value}
                  supplementaryContentTitle={
                    type == 'manager' ? '360 Review Content' : undefined
                  }
                  supplementaryContentSubtitle={
                    type == 'manager'
                      ? 'There is no content from 360 reviews to show here.'
                      : undefined
                  }
                  showTools={type == 'manager'}
                />
              ) : (
                selectedTab == 'acknowledgement' && (
                  <div>
                    {(!employeeSignedDate && currentUserIsEvaluationEmployee) || (!managerSignedDate && currentUserIsManager) ? (
                      <React.Fragment>
                        <Alert
                          title={'Disclaimer'}
                          message={'Your acknowledgement does not necessarily indicate agreement or disagreement with the content of this evaluation, it only indicates that it has been reviewed with you.'}
                          variant={'warning'}
                          shadow={false}
                        />
                        <FormFieldWrapper>
                          <InputField
                            label={`Please acknowledge reviewing the content of this evaluation by typing your name below:`}
                            name={'acknowledgement'}
                            placeholder={`Please type "${appContext.currentUserName}" here...`}
                            value={enteredName}
                            onChange={(e) => setEnteredName(e.target.value)}
                          />
                        </FormFieldWrapper>
                      </React.Fragment>
                    ) : (
                      <SignedTitlesWrapper>
                        <div>
                          <SignedTitle>Manager Acknowledgement:</SignedTitle>
                          <span> {managerSignedDate ? formatDatetime(parse(managerSignedDate, dateTimeOffsetFormat, new Date())) : 'Not Signed Yet'}</span>
                        </div>
                        <div>
                          <SignedTitle>Employee Acknowledgement:</SignedTitle>
                          <span> {employeeSignedDate ? formatDatetime(parse(employeeSignedDate, dateTimeOffsetFormat, new Date())) : 'Not Signed Yet'}</span>
                        </div>
                      </SignedTitlesWrapper>
                    )}
                  </div>
                )
              )}
            </PageDetails>
          )
        )}
      </Modal>
      {review && isShowingSubmitDialog && (
        <Dialog
          isOpen={true}
          title={`Submit ${type == 'manager' ? 'Manager Review' : 'Self-Assessment'}?`}
          message={
            'You should only submit this assessment if you are finished filling it out and are ready for it to be reviewed by others. Are you ready to submit?'
          }
          confirmTitle={'Yes, submit'}
          cancelTitle={`Actually, I'm not quite finished...`}
          onClose={() => setIsShowingSubmitDialog(false)}
          onConfirm={() => onSubmitReview(type === 'manager')}
          onCancel={() => setIsShowingSubmitDialog(false)}
          isWorking={submitReviewStatus == 'loading'}
          variant={'info'}
          confirmation={'Yes'}
        />
      )}
      {review && isShowingUnsubmitDialog && (
        <Dialog
          isOpen={true}
          title={`Unsubmit ${type == 'manager' ? 'Manager Review' : 'Self-Assessment'}?`}
          message={
            'Are you ready to unsubmit?'
          }
          confirmTitle={'Yes, unsubmit'}
          cancelTitle={`Actually, nevermind...`}
          onClose={() => setIsShowingUnsubmitDialog(false)}
          onConfirm={() => onUnsubmitReview(type == 'manager')}
          onCancel={() => setIsShowingUnsubmitDialog(false)}
          isWorking={unsubmitReviewStatus == 'loading'}
          variant={'warning'}
          confirmation={'Yes'}
        />
      )}
    </React.Fragment>
  );
};

const SignedTitlesWrapper = styled.span`
  display: flex;
  flex-direction: column;
  padding: 10px;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;
const SignedTitle = styled.span`
  ${font.bold};
`;

export default EvaluationReviewModal;
