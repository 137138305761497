import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { Button, Tooltip } from 'shared/components';
import { PeerRequestStatus, ReviewStatus } from 'shared/constants';
import { color, font } from 'shared/utils/styles';
import styled from 'styled-components';
import ReviewButton from './ReviewButton';

interface PeerRequestProps {
  employee: string;
  employeePosition: string;
  status: 'success' | 'danger' | 'warning';
  right?: React.ReactNode;
}

const PeerRequest: FunctionComponent<PeerRequestProps> = ({
  employee,
  employeePosition,
  status,
  right,
}) => {
  const icon = useMemo(() => {
    return status == 'success'
      ? 'check'
      : status == 'danger'
      ? 'ban'
      : 'hourglass-start';
  }, [status]);

  return (
    <PeerRequestWrapper>
      <PeerRequestLeftWrapper>
        <FontAwesomeIcon
          icon={['fal', icon]}
          style={{ color: color[status] }}
        />
        <PeerRequestTitleWrapper>
          <PeerRequestTitle>{employee}</PeerRequestTitle>
          <PeerRequestSubitle>{employeePosition}</PeerRequestSubitle>
        </PeerRequestTitleWrapper>
      </PeerRequestLeftWrapper>
      {right && <div>{right}</div>}
    </PeerRequestWrapper>
  );
};

interface PeerRequestFromOthersProps {
  employee: string;
  employeePosition: string;
  status?: { id: number; name: string };
  denyReason?: string;
  reviewStatus?: { id: number; name: string };
  onReviewClicked: () => void;
  onAccepted: () => Promise<void>;
  onRejected: () => Promise<void>;
  currentUserIsEvaluationEmployee: boolean;
  disabledMessage?: string;
  disabled?: boolean;
}

export const PeerRequestFromOthers: FunctionComponent<PeerRequestFromOthersProps> = ({
  employee,
  employeePosition,
  status,
  denyReason,
  reviewStatus,
  onReviewClicked,
  currentUserIsEvaluationEmployee,
  onAccepted,
  onRejected,
  disabledMessage,
  disabled = false,
}) => {
  const [acceptIsWorking, setAcceptIsWorking] = useState(false);
  const [rejectIsWorking, setRejectIsWorking] = useState(false);
  return (
    <PeerRequest
      employee={employee}
      employeePosition={employeePosition}
      status={
        status?.id == PeerRequestStatus.Accepted &&
        reviewStatus?.id == ReviewStatus.Completed
          ? 'success'
          : status?.id == PeerRequestStatus.Declined ||
            status?.id == PeerRequestStatus.Void
          ? 'danger'
          : 'warning'
      }
      right={
        <PeerRequestFromMeRightWrapper>
          <PeerRequestFromMeRightTools>
            {status?.id == PeerRequestStatus.Pending && !disabled && (
              <React.Fragment>
                <Tooltip content={'Accept'}>
                  <Button
                    icon={'check'}
                    color={'success'}
                    variant={'simple'}
                    onClick={async () => {
                      setAcceptIsWorking(true);
                      await onAccepted();
                      setAcceptIsWorking(false);
                    }}
                    isWorking={acceptIsWorking}
                    disabled={disabled || rejectIsWorking}
                  ></Button>
                </Tooltip>
                <Tooltip content={'Decline'}>
                  <Button
                    icon={'times'}
                    color={'danger'}
                    variant={'simple'}
                    onClick={async () => {
                      setRejectIsWorking(true);
                      await onRejected();
                      setRejectIsWorking(false);
                    }}
                    isWorking={rejectIsWorking}
                    disabled={disabled || acceptIsWorking}
                  ></Button>
                </Tooltip>
              </React.Fragment>
            )}
            {denyReason &&
              (status?.id == PeerRequestStatus.Declined ||
                status?.id == PeerRequestStatus.Void) && (
                <Tooltip content={`Reason: ${denyReason}`}>
                  <span>
                    <FontAwesomeIcon icon={['fal', 'info-circle']} />
                  </span>
                </Tooltip>
              )}
            {disabledMessage && disabled && (
                <Tooltip content={disabledMessage}>
                  <span>
                    <FontAwesomeIcon icon={['fal', 'info-circle']} />
                  </span>
                </Tooltip>
              )}
          </PeerRequestFromMeRightTools>
          {status?.id == PeerRequestStatus.Accepted ? (
            <ReviewButton
              status={reviewStatus}
              onClick={onReviewClicked}
              disabled={!currentUserIsEvaluationEmployee}
            />
          ) : (
            status?.id != PeerRequestStatus.Pending && (
              <div
                style={{
                  width: '80px',
                  textAlign: 'center',
                }}
              >
                {status?.name ?? 'Pending'}
              </div>
            )
          )}
        </PeerRequestFromMeRightWrapper>
      }
    />
  );
};

const PeerRequestFromMeRightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const PeerRequestFromMeRightTools = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const PeerRequestWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  justify-content: space-between;
  border: 1px solid ${color.border};
  border-radius: 5px;
  margin-bottom: 5px;

  & > div {
    ${font.medium};
  }
`;

const PeerRequestLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 15px;
  }
`;

const PeerRequestTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const PeerRequestTitle = styled.div`
  ${font.medium};
`;

const PeerRequestSubitle = styled.div`
  ${font.regular};
  color: ${color.textLight};
`;

interface PeerRequestByMeProps {
  employee: string;
  employeePosition: string;
  status?: { id: number; name: string };
  reviewStatus?: { id: number; name: string };
  onReviewClicked: () => void;
  currentUserIsEvaluationEmployee: boolean;
  denyReason?: string;
  onCancel?: () => void;
}

export const PeerRequestByMe: FunctionComponent<PeerRequestByMeProps> = ({
  employee,
  employeePosition,
  status,
  reviewStatus,
  onReviewClicked,
  currentUserIsEvaluationEmployee,
  denyReason,
  onCancel,
}) => {
  return (
    <PeerRequest
      employee={employee}
      employeePosition={employeePosition}
      status={
        status?.id == PeerRequestStatus.Accepted
          ? 'success'
          : status?.id == PeerRequestStatus.Declined ||
            status?.id == PeerRequestStatus.Void
          ? 'danger'
          : 'warning'
      }
      right={
        <PeerRequestFromMeRightWrapper>
          <PeerRequestFromMeRightTools>
            {onCancel && status?.id == PeerRequestStatus.Pending && (
              <Tooltip content={'Cancel Request'}>
                <Button
                  icon={'times'}
                  color={'danger'}
                  variant={'simple'}
                  onClick={onCancel}
                ></Button>
              </Tooltip>
            )}
            {denyReason &&
              (status?.id == PeerRequestStatus.Declined ||
                status?.id == PeerRequestStatus.Void) && (
                <Tooltip content={`Reason: ${denyReason}`}>
                  <span>
                    <FontAwesomeIcon icon={['fal', 'info-circle']} />
                  </span>
                </Tooltip>
              )}
          </PeerRequestFromMeRightTools>
          {status?.id == PeerRequestStatus.Accepted && reviewStatus?.id == ReviewStatus.Completed && !currentUserIsEvaluationEmployee ? (
            <ReviewButton
              status={reviewStatus}
              onClick={onReviewClicked}
              disabled={currentUserIsEvaluationEmployee}
            />
          ) : (
            <div
              style={{
                width: '80px',
                textAlign: 'center',
              }}
            >
              {reviewStatus?.id == ReviewStatus.Completed ? reviewStatus.name : status?.name ?? 'In Progress'}
            </div>
          )}
        </PeerRequestFromMeRightWrapper>
      }
    />
  );
};
