import React, {
  useContext,
} from 'react';
import NormalizedStyles from './Styles/NormalizedStyles';
import BaseStyles from './Styles/BaseStyles';
import Routes from './Routes';
import initializeIconLibrary from '../shared/utils/icons/IconsInit';
import ModalStyles from './Styles/ModalStyles';

import 'shared/css/ag-grid-custom.scss';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(
  'CompanyName=The Wolff Company,LicensedGroup=Aleks Vlahovic,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-007746,ExpiryDate=15_April_2021_[v2]_MTYxODQ0MTIwMDAwMA==9d116177d54500073eb38ba8f71da60b'
);

initializeIconLibrary();

interface AppContextValue {
  currentUserId: number;
  currentUserName: string;
  celebrate: () => void;
}

export const AppContext = React.createContext<AppContextValue>(
  {} as AppContextValue
);

export const useAppContext = () => {
  const value = useContext(AppContext);
  return value;
};

const App = () => {
  return (
    <React.Fragment>
      <NormalizedStyles />
      <BaseStyles />
      <ModalStyles />
      <Routes/>
    </React.Fragment>
  );
};

export default App;
