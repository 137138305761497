import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useNotification } from 'shared/components/notifications';
import { useGetManagerEvaluation, useSignEvaluation } from 'shared/hooks/api';
import EvaluationReviewModal, { EvaluationReview } from './EvaluationReviewModal';
import parse from 'date-fns/parse';
import { dateFormat } from 'shared/utils/dateFormats';
import { useAppContext } from 'App';

interface FinalEvaluationReviewProps {
  evaluationId: number;
  currentUserIsEvaluationEmployee: boolean;
  onClose: () => void;
  employeeName: string;
  evaluationCycleName: string;
  unsubmitEnabled: boolean;
}

const FinalEvaluationReviewModal: FunctionComponent<FinalEvaluationReviewProps> = ({
  evaluationId,
  currentUserIsEvaluationEmployee,
  employeeName,
  evaluationCycleName,
  onClose,
  unsubmitEnabled,
}) => {
  const { data, status } = useGetManagerEvaluation(evaluationId);

  const review: EvaluationReview | undefined = useMemo(() => {
    if (data?.evaluation?.managerEvaluation && data.evaluation.managerEvaluation.goalsReview && data.evaluation.managerEvaluation.valuesReview) {
      const managerEvaluation = data.evaluation.managerEvaluation;
      const goalsReview = managerEvaluation.goalsReview;
      const valuesReview = managerEvaluation.valuesReview;
      return {
        id: managerEvaluation.id,
        type: managerEvaluation.type ?? undefined,
        status: managerEvaluation.status ?? undefined,
        author: managerEvaluation.author?.displayName,
        goalsReview: {
          id: goalsReview.id,
          type: goalsReview.type ?? undefined,
          status: goalsReview.status ?? undefined,
          goals: goalsReview.goals.map(goal => ({
            id: goal.id,
            itemId: goal.id,
            details: goal.subgoals?.map(x => x.name) ?? [],
            name: goal.name,
            score: goal.performanceReward ?? undefined,
            strengths: goal.strengths.map(x => ({
              id: x.id,
              description: x.description || "",
            })),
            opportunities: goal.opportunities.map(x => ({
              id: x.id,
              description: x.description || "",
            })),
          })),
          selfReviewContent: goalsReview.selfReviewContent.map(goal => ({
            id: goal.id,
            itemId: goal.id,
            details: goal.subgoals?.map(x => x.name) ?? [],
            name: goal.name,
            score: undefined,
            strengths: goal.strengths.map(x => ({
              id: x.id,
              description: x.description || "",
              author: x.author?.displayName,
            })),
            opportunities: goal.opportunities.map(x => ({
              id: x.id,
              description: x.description || "",
              author: x.author?.displayName,
            })),
          })),
        },
        valuesReview: {
          id: valuesReview.id,
          type: valuesReview.type ?? undefined,
          status: valuesReview.status ?? undefined,
          values: valuesReview.values.map(value => ({
            id: value.reviewValueId,
            itemId: value.id,
            details: value.description ? [value.description] : [],
            name: value.name,
            score: value.score ?? undefined,
            strengths: value.strengths.map(x => ({
              id: x.id,
              description: x.description || "",
            })),
            opportunities: value.opportunities.map(x => ({
              id: x.id,
              description: x.description || "",
            })),
          })),
          peerReviewContent: valuesReview.peerReviewContent.map(value => ({
            id: value.reviewValueId,
            itemId: value.id,
            details: value.description ? [value.description] : [],
            name: value.name,
            score: undefined,
            strengths: value.strengths.map(x => ({
              id: x.id,
              description: x.description || "",
              author: x.author?.displayName,
            })),
            opportunities: value.opportunities.map(x => ({
              id: x.id,
              description: x.description || "",
              author: x.author?.displayName,
            })),
          })),
        },
      }
    }
  }, [data]);

  const { celebrate } = useAppContext();

  const [signEvaluation, { status: signEvaluationStatus }] = useSignEvaluation(evaluationId);
  const notify = useNotification();
  const onSignEvaluation = useCallback(async () => {
    try {
      const result = await signEvaluation(Number(evaluationId));
      if (result.data?.signEvaluation) {
        notify({
          message: 'Evaluation Signed!',
        });
        celebrate();
      } else if (result.errors) {
        const forbiddenError = result.errors.find(
          (x: any) => x?.extensions?.code == 'FORBIDDEN'
        );
        const badRequestError = result.errors.find(
          (x: any) => x?.extensions?.code == 'BAD_REQUESTT'
        );
        if (forbiddenError) {
          notify({
            duration: 5000,
            title: 'Permission Denied',
            variant: 'danger',
            message: forbiddenError?.message,
          });
        } else if (badRequestError) {
          notify({
            duration: 5000,
            title: 'Bad Request',
            variant: 'danger',
            message: badRequestError?.message,
          });
        } else {
          throw Error();
        }
      } else {
        throw Error();
      }
    } catch (error) {
      notify({
        duration: 5000,
        variant: 'danger',
        message: 'An unknown error occcurred while signing this evaluation!',
      });
    } finally {
      onClose();
    }
  }, [signEvaluation, evaluationId, celebrate]);

  return (
    <EvaluationReviewModal
      review={review}
      title={'Final Evaluation'}
      subtitle={review?.author ? `Prepared by ${review.author}` : 'Details'}
      evaluationId={Number(evaluationId)}
      type={'manager'}
      currentUserIsManager={!currentUserIsEvaluationEmployee}
      currentUserIsEvaluationEmployee={currentUserIsEvaluationEmployee}
      onClose={onClose}
      isLoading={status == 'loading'}
      size={'lg'}
      readonly={true}
      showSupplementaryContent={false}
      managerSignedDate={data?.evaluation?.managerSignedDate ?? undefined}
      employeeSignedDate={data?.evaluation?.employeeSignedDate ?? undefined}
      isSigningEvaluation={signEvaluationStatus == 'loading'}
      onSignEvaluation={onSignEvaluation}
      employeeName={employeeName}
      evaluationCycleName={evaluationCycleName}
      unsubmitEnabled={unsubmitEnabled}
    />
  );
};

export default FinalEvaluationReviewModal;
