import { IconName } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { Button } from 'shared/components';
import { font, color } from 'shared/utils/styles';
import styled from 'styled-components';

const EmptyDataMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px;

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const EmptyDataMessageTitle = styled.div`
  ${font.size(18)};
  ${font.bold};
  color: ${color.textDarkest};
`;

const EmptyDataMessageSubtitle = styled.div`
  ${font.size(14)};
  ${font.regular};
  color: ${color.textMedium};
  max-width: 500px;
  text-align: center;
  padding: 10px;
`;

export type EmptyDataMessageProps = {
  title: string;
  subtitle?: string;
  icon?: IconName;
  action?: () => void;
  actionTitle?: string;
  actionIcon?: IconName;
};

const EmptyDataMessage: FunctionComponent<EmptyDataMessageProps> = ({
  title,
  subtitle,
  icon,
  action,
  actionTitle,
  actionIcon,
}) => {
  return (
    <EmptyDataMessageWrapper>
      {icon && <FontAwesomeIcon icon={['fal', icon]} style={{ fontSize: '30px' }} />}
      <EmptyDataMessageTitle>{title}</EmptyDataMessageTitle>
      {subtitle && (
        <EmptyDataMessageSubtitle>{subtitle}</EmptyDataMessageSubtitle>
      )}
      {action && actionTitle && (
        <Button
          variant={'outline'}
          style={{ marginTop: '20px' }}
          onClick={action}
          icon={actionIcon}
        >
          {actionTitle}
        </Button>
      )}
    </EmptyDataMessageWrapper>
  );
};

export default EmptyDataMessage;
