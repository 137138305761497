import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewGoalSetModal from 'App/Organization/shared/Goals/NewGoalSetModal';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import {
  match,
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { Button, Dialog, PageHeader } from 'shared/components';
import { usePageTitle } from 'shared/hooks';
import { createQueryParamModalHelpers } from 'shared/utils/queryParamModal';
import Profile from './Profile';
import Goals from './Goals';
import { Tab } from 'shared/components/pageContent';
import { useDeleteUser, useGetDirectoryUser, useMakeUserAdmin } from 'shared/hooks/api';
import { useAppContext } from 'App';
import EvaluationsList from 'App/Organization/shared/Evaluations/EvaluationsList';
import { CenteredSpinner } from 'shared/components/spinner/Spinner';
import { useNotification } from 'shared/components/notifications';

const EmployeeDetails: FunctionComponent = () => {
  const match = useRouteMatch();
  const { employeeId } = useParams<{ employeeId: string }>();
  const { data, status, refetch } = useGetDirectoryUser(Number(employeeId));
  return (
    <React.Fragment>
      <Switch>
        <Route
          path={`${match.path}/profile`}
          exact={true}
          render={() => <EmployeeDetailsMain match={match} />}
        />
        <Route
          path={`${match.path}/goals`}
          exact={true}
          render={() => <EmployeeDetailsMain match={match} />}
        />
        <Route
          path={`${match.path}/goals/:goalSetId`}
          render={() => <div>Goal Set Details</div>}
        />
        <Route
          path={`${match.path}/evaluations`}
          exact={true}
          render={() => <EmployeeDetailsMain match={match} />}
        />
        <Route
          path={`${match.path}/evaluations/:evaluationId`}
          render={() => <div>Evaluation Details</div>}
        />
        <Redirect to={`${match.url}/profile`} />
      </Switch>
    </React.Fragment>
  );
};

interface EmployeeDetailsMain {
  match: match<{}>;
}

const EmployeeDetailsMain: FunctionComponent<EmployeeDetailsMain> = ({
  match,
}) => {
  const ADMIN_ID = 1; // Important to Update this if the AdminId ever changes
  const newGoalSetModalHelpers = createQueryParamModalHelpers('new-goal-group');

  const onSetNewGoals = useCallback(() => {
    newGoalSetModalHelpers.open();
  }, [newGoalSetModalHelpers]);

  const tabs = useMemo(() => {
    const tabList: Tab[] = [
      { path: `/profile`, title: 'Profile', pinned: true },
      { path: `/goals`, title: 'Goals', icon: 'lock' },
      { path: `/evaluations`, title: 'Evaluations', icon: 'lock' },
    ];
    return tabList;
  }, []);

  const appContext = useAppContext();
  const { employeeId, orgName } = useParams<{ employeeId: string; orgName: string }>();
  const { data, status, refetch } = useGetDirectoryUser(Number(employeeId));
  const [isShowingArchiveUserDialog, setIsShowingArchiveUserDialog] = useState(false)
  const [isShowingMakeAdminDialog, setIsShowingMakeAdminDialog] = useState(false)

  const [deleteUser, { status: deleteUserStatus }] = useDeleteUser(appContext.currentUserId);
  const [makeUserAdmin, { status: makeUserAdminStatus }] = useMakeUserAdmin(appContext.currentUserId);
  const notify = useNotification();
  const history = useHistory();

  const tools = useMemo(() => {
    const tools = [];
    if (
      data?.user?.id == appContext.currentUserId ||
      data?.user?.manager?.id == appContext.currentUserId
    ) {
      tools.push(
        <Button
          key={'set-new-goals'}
          icon={'list-ul'}
          variant={'outline'}
          onClick={onSetNewGoals}
        >
          Set New Goals
        </Button>,
      )
    }
    if (data?.user?.currentUserCanEdit) {
      tools.push(
        <Button
          key={'archive-user'}
          icon={'user'}
          color={'danger'}
          variant={'outline'}
          onClick={() => setIsShowingArchiveUserDialog(true)}
          disabled={!data.user.currentUserCanEdit}
        >
          Archive User
        </Button>,
      )
    }
    if (data?.user?.isCurrentUserAdmin) {
      tools.push(
        <Button
          key={'make-user-admin'}
          icon={'user'}
          color={'warning'}
          variant={'outline'}
          onClick={() => setIsShowingMakeAdminDialog(true)}
          disabled={data.user?.isUserAdmin || false}
        >
          Make User Admin
        </Button>,
      )
    }
    return tools;
  }, [data]);

  return (
    <React.Fragment>
      {!data?.user && status == 'loading' ? (
        <div style={{ height: '200px' }}>
          <CenteredSpinner/>
        </div>
      ) : data && data.user && (
      <React.Fragment>
        <PageHeader
          title={
            data?.user
              ? `${data.user.firstName} ${data.user.lastName}`
              : 'Loading...'
          }
          tools={tools}
          tabs={tabs}
          match={match}
        />
        <Switch>
          <Route
            path={`${match.path}/profile`}
            exact={true}
            render={() => <Profile />}
          />
          <Route
            path={`${match.path}/goals`}
            exact={true}
            render={() => <Goals onSetNewGoals={onSetNewGoals} />}
          />
          <Route
            path={`${match.path}/evaluations`}
            exact={true}
            render={() => <EvaluationsList employeeId={data.user!.id} pageTitle={`${data.user!.firstName} ${data.user!.lastName}`}/>}
          />
          <Redirect to={`${match.url}/profile`} />
        </Switch>
        {newGoalSetModalHelpers.isOpen() && (
          <NewGoalSetModal
            onClose={newGoalSetModalHelpers.close}
            onSuccess={() => false}
            employeeId={Number(employeeId)}
          />
        )}
      </React.Fragment>
      )}
      {isShowingMakeAdminDialog && (
        <Dialog
        isOpen={true}
        variant={'warning'}
        title={'Make User an Admin?'}
        message={
          'This will make this user an HR Admin. They will have full control over and access into the system. This cannot be undone without going to the BI team. Are you sure you want to do this?'
        }
        onClose={() => {setIsShowingMakeAdminDialog(false);}}
        onCancel={() => {setIsShowingMakeAdminDialog(false);}}
        onConfirm={ async () => {
            try {
              const result = await makeUserAdmin({ userId: Number(data?.user?.id), groupId: ADMIN_ID});
              if (result.errors) { throw Error(); }
              else {
                notify({ title: 'User Added To Admin' })
                setIsShowingMakeAdminDialog(false);
              }
            }
            catch {
              notify({
                duration: 5000,
                variant: 'danger',
                message: 'An Error occcurred while adding this user as admin!',
              });
              setIsShowingMakeAdminDialog(false);
            }
        }}
        confirmTitle={'Yes, make this user an Admin'}
        cancelTitle={'No, Cancel'}
        isWorking={makeUserAdminStatus == 'loading'}
        confirmation={data?.user?.displayName}
      >

        {/* <DialogField>
          <InputField
            name={'reason'}
            label={'Reason:'}
            onChange={(e) => {
              setArchiveReason(e.target.value);
            }}
            value={archiveReason}
            placeholder={'Provide a reason for archiving...'}
          />
        </DialogField> */}
      </Dialog>
      )}
      {isShowingArchiveUserDialog && data?.user?.id && data?.user?.currentUserCanEdit && data?.user?.displayName && (
        <Dialog
          variant={'danger'}
          isOpen={true}
          title={'Archive User'}
          confirmTitle={'Yes, archive user'}
          message={`Are you sure you want to archive the user named "${data?.user?.displayName}"? This will remove them from the directory and their profile will no longer be able to be accessed.`}
          isWorking={deleteUserStatus == 'loading'}
          onConfirm={async () => {
            try {
              const result = await deleteUser(Number(data.user?.id));
              if (result.data?.deleteUser) {
                notify({ title: 'User Archived' })
                setIsShowingArchiveUserDialog(false);
                history.replace(`/${orgName}/directory`);
              } else if (result.errors) {
                const forbiddenError = result.errors.find(x => x?.extensions?.code == "FORBIDDEN")
                if (forbiddenError) {
                  notify({
                    duration: 5000,
                    title: 'Permission Denied',
                    variant: 'danger',
                    message: forbiddenError?.message,
                  });
                } else {
                  throw Error();
                }
              } else {
                throw Error()
              }
            } catch {
              notify({
                duration: 5000,
                variant: 'danger',
                message: 'An Error occcurred while archiving this user!',
              });
              setIsShowingArchiveUserDialog(false);
            }
          }}
          onClose={() => setIsShowingArchiveUserDialog(false)}
          onCancel={() => setIsShowingArchiveUserDialog(false)}
        />
      )}
    </React.Fragment>
  );
};

export default EmployeeDetails;
