import styled from 'styled-components';

import { color, font, sizes } from 'shared/utils/styles';
import { Button } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MainNavbarWrapper = styled.div<{ isSidebarOpen: boolean }>`
    position: fixed;
    top: 0;
    left: ${props => props.isSidebarOpen ? `${sizes.appSidebarWidth + 1}px` : `${sizes.collapsedAppSidebarWidth + 1}px`};
    right: 0;
    height: ${sizes.navbarHeight}px;
    border-bottom: 1px solid ${color.border};
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 200ms width ease-in-out, 200ms left ease-in-out;
    padding: 10px 20px;
`;

export const MainNavbarLeft = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  & > :child(*) {
    margin-right: 10px;
  }
`
export const MainNavbarRight = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;

  & > * {
    margin-left: 10px;
  }
`

export const NavbarWrapper = styled.div`
  z-index: 999;
`

export const NavbarLeft = styled.div<{ isSidebarOpen: boolean}>`
    position: fixed;
    top: 0;
    left: 0;
    width: ${props => props.isSidebarOpen ? `${sizes.appSidebarWidth}px` : `${sizes.collapsedAppSidebarWidth}px` };
    height: ${sizes.navbarHeight}px;
    border-right: 1px solid ${color.border};
    background-color: #fff;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    transition: 200ms width ease-in-out, 200ms left ease-in-out;
    border-bottom: 1px solid ${color.border};
`

export const NavbarLogo = styled.div`
    display: inline-flex;s
    align-items: center;
    margin-right: 10px;
`

export const NavbarIcon = styled.div`
  display: flex;
  width: 40px;
  height: 30px;
  padding-right: 10px;
  color: ${color.info};
  align-items: center;
`

export const NavbarButton = styled(Button)`
  ${font.size(16)};
`

export const NavbarMenuIcon = styled(FontAwesomeIcon)`
  ${font.size(16)};
`

export const NavbarSidebarTitle = styled.div`
  display: flex;
  ${font.size(18)};
  ${font.bold};
  padding-bottom: 2px;
  align-items: center;
`