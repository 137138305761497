import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoalIcon, GoalsRow, GoalSubtitle, GoalTitle, GoalTitles, GoalsRowLeft } from "App/Organization/shared/Goals";
import EmptyDataMessage from "App/Organization/shared/NoData";
import { FullWidthSpinner } from "App/Routes";
import React, { FunctionComponent } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { PageDetails, Spinner } from "shared/components";
import { usePageTitle, useRemainingHeight } from "shared/hooks";
import { useGetDirectoryUserGoals } from "shared/hooks/api";
import { color } from "shared/utils/styles";

interface GoalsListProps {
  onSetNewGoals: () => void;
  employeeId: number;
  pageTitle: string;
}

const GoalsList: FunctionComponent<GoalsListProps> = ({
  onSetNewGoals,
  employeeId,
  pageTitle,
}) => {
  usePageTitle(`${pageTitle} - Goals`);
  const { data, status, refetch, errors } = useGetDirectoryUserGoals(Number(employeeId));
  const { orgName } = useParams<{orgName: string}>();
  const { height, ref } = useRemainingHeight();
  const history = useHistory();
  return (
    <React.Fragment>
      <PageDetails ref={ref}>
        {!data?.evaluations && errors ? (
        <React.Fragment>
          <EmptyDataMessage title={'You do not have access to this users goals.'} subtitle={`We could not show you this users goals because you don't have the right permissions to view them.`} icon={'user-lock'}/>
        </React.Fragment>
      ) : !data?.evaluations && status == 'loading' ? <FullWidthSpinner minHeight={300}/>
        : data?.evaluations?.length == 0 ? (
          <EmptyDataMessage
            icon={"list-ul"}
            title={"No Active Goal Sets"}
            actionIcon={"list-ul"}
            actionTitle={"Set New Goals"}
            action={onSetNewGoals}
          />
        ) : (
          data?.evaluations?.map((goalSet, index) => (
            <GoalsRow key={index} last={true} onClick={() => {
              history.push(`/${orgName}/goals/${goalSet.id}`)
            }}>
              <GoalsRowLeft>
                <GoalIcon color={color.success}>
                  <FontAwesomeIcon icon={["fal", "list-ul"]} />
                </GoalIcon>
                <GoalTitles>
                  <GoalTitle>{`Goals: ${goalSet.evaluationCycle.name}`}</GoalTitle>
                  <GoalSubtitle>{`${goalSet.goals?.length || 0} Goals`}</GoalSubtitle>
                </GoalTitles>
              </GoalsRowLeft>
            </GoalsRow>
          ))
        )}
      </PageDetails>
    </React.Fragment>
  );
};

export default GoalsList;