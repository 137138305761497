import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoalBody, GoalBodyLine } from 'App/Organization/Goals';
import { debounce } from 'lodash';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  HorizontalSeparator,
  InputField,
  Section,
  Textarea,
  Tooltip,
} from 'shared/components';
import { TextareaProps } from 'shared/components/form/Textarea';
import NoAccess from 'shared/components/noAccess';
import { useNotification } from 'shared/components/notifications';
import { useTimeout } from 'shared/components/notifications/useTimeout';
import {
  useUpdateGoal,
  useUpdateReviewOpportunity,
  useUpdateReviewStrength,
  useUpdateReviewValue,
} from 'shared/hooks/api';
import {
  ReviewClassificationEnum,
  UpdateGoalInput,
  UpdateReviewValueInput,
  UpdateStrengthOrOpportunityInput,
} from 'shared/hooks/api/graphql/generated';
import useCopyToClipboard from 'shared/hooks/useCopyToClipboard';
import { color, font } from 'shared/utils/styles';
import styled from 'styled-components';
import { GoalSubtitle, GoalTitle, GoalTitles } from '../../Goals';

interface ReviewDetailsProps {
  isManagerView: boolean;
  type: 'manager' | 'self' | 'peer';
  readonly: boolean;
  items: ReviewDetailItem[];
  emptyMessage: string;
  onCreateStrengthOrOpportunity: (
    entryId: number,
    type: 'strength' | 'opportunity'
  ) => void;
  isCreatingStrengthOrOpportunity: boolean;
  onDeleteStrengthOrOpportunity: (
    id: number,
    type: 'strength' | 'opportunity'
  ) => Promise<boolean>;
  isDeletingStrengthOrOpportunity: boolean;
  creatingIds: string[];
  deletingIds: string[];
  evaluationId: number;
  reviewClassification: ReviewClassificationEnum;
  showSupplementaryContent?: boolean;
  supplementaryContent: ReviewDetailItem[];
  supplementaryContentTitle?: string;
  supplementaryContentSubtitle?: string;
  showTools?: boolean;
}

export interface ReviewDetailItem {
  id: number;
  itemId: number;
  name: string;
  score?: number;
  details: string[];
  strengths: { id: number; description: string; author?: string }[];
  opportunities: { id: number; description: string; author?: string }[];
}

const ReviewDetails: FunctionComponent<ReviewDetailsProps> = ({
  isManagerView,
  type,
  readonly,
  items = [],
  emptyMessage,
  onCreateStrengthOrOpportunity,
  isCreatingStrengthOrOpportunity,
  onDeleteStrengthOrOpportunity,
  isDeletingStrengthOrOpportunity,
  creatingIds,
  deletingIds,
  evaluationId,
  reviewClassification,
  showSupplementaryContent = false,
  supplementaryContent,
  supplementaryContentTitle,
  supplementaryContentSubtitle,
  showTools = false,
}) => {
  const groupedSupplementaryContent = useMemo(() => {
    return groupedByValue(supplementaryContent);
  }, [supplementaryContent]);

  return (
    <ReviewDetailsContainer>
      <ReviewDetailsContent>
        {items.length > 0 ? (
          items.map((item) => (
            <ReviewDetailsValueDetail
              key={item.name}
              item={item}
              showScore={type == 'manager'}
              readonly={readonly}
              onCreateStrengthOrOpportunity={onCreateStrengthOrOpportunity}
              isCreatingStrengthOrOpportunity={isCreatingStrengthOrOpportunity}
              onDeleteStrengthOrOpportunity={onDeleteStrengthOrOpportunity}
              isDeletingStrengthOrOpportunity={isDeletingStrengthOrOpportunity}
              creatingIds={creatingIds}
              deletingIds={deletingIds}
              evaluationId={evaluationId}
              reviewClassification={reviewClassification}
              isExpanded={true}
            />
          ))
        ) : (
          <NoAccess
            icon={'comment-alt-exclamation'}
            title={'Nothing to see here...'}
            subtitle={emptyMessage}
          />
        )}
      </ReviewDetailsContent>
      {showSupplementaryContent &&
        supplementaryContentTitle &&
        supplementaryContentSubtitle && (
          <ReviewDetailsSupplementaryContent>
            <Section
              title={supplementaryContentTitle}
              style={{ width: '100%' }}
            >
              {groupedSupplementaryContent.length > 0 ? (
                groupedSupplementaryContent.map((item) => (
                  <ReviewDetailsValueDetail
                    key={item.name}
                    item={item}
                    showScore={false}
                    isExpanded={false}
                    readonly={true}
                    onCreateStrengthOrOpportunity={
                      onCreateStrengthOrOpportunity
                    }
                    isCreatingStrengthOrOpportunity={
                      isCreatingStrengthOrOpportunity
                    }
                    onDeleteStrengthOrOpportunity={
                      onDeleteStrengthOrOpportunity
                    }
                    isDeletingStrengthOrOpportunity={
                      isDeletingStrengthOrOpportunity
                    }
                    creatingIds={creatingIds}
                    deletingIds={deletingIds}
                    evaluationId={evaluationId}
                    reviewClassification={reviewClassification}
                    showTools={showTools}
                  />
                ))
              ) : (
                <NoAccess
                  icon={'comment-alt-exclamation'}
                  title={'Nothing to show...'}
                  subtitle={supplementaryContentSubtitle}
                />
              )}
            </Section>
          </ReviewDetailsSupplementaryContent>
        )}
    </ReviewDetailsContainer>
  );
};

function groupedByValue(arr: ReviewDetailItem[]) {
  const start: Record<number, ReviewDetailItem[]> = {};
  const result = arr.reduce((r, a) => {
    r[a.itemId] = r[a.itemId] || [];
    r[a.itemId].push(a);
    return r;
  }, start);

  const grouped: ReviewDetailItem[] = [];

  Object.keys(result).forEach((key) => {
    const values = result[Number(key)];
    const strengths = values
      .flatMap((x) => x.strengths)
      .filter((x) => x.description != '');
    const opportunities = values
      .flatMap((x) => x.opportunities)
      .filter((x) => x.description != '');
    grouped.push({ ...values[0], strengths, opportunities });
  });

  return grouped;
}

export default ReviewDetails;

const ReviewDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ReviewDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  flex-grow: 1;
  max-width: 100%;
`;

const ReviewDetailsSupplementaryContent = styled.div`
  display: flex;
  min-width: 300px;
  width: 30%;
  flex-grow: 1;
  margin-left: 20px;
  border-left: 1px solid ${color.border};
  padding: 0 10px;
`;

interface ReviewDetailsValueDetailProps {
  item: ReviewDetailItem;
  showScore: boolean;
  readonly: boolean;
  onCreateStrengthOrOpportunity: (
    entryId: number,
    type: 'strength' | 'opportunity'
  ) => void;
  isCreatingStrengthOrOpportunity: boolean;
  onDeleteStrengthOrOpportunity: (
    id: number,
    type: 'strength' | 'opportunity'
  ) => Promise<boolean>;
  isDeletingStrengthOrOpportunity: boolean;
  creatingIds: string[];
  deletingIds: string[];
  evaluationId: number;
  reviewClassification: ReviewClassificationEnum;
  isExpanded?: boolean;
  showTools?: boolean;
}

const ReviewDetailsValueDetail: FunctionComponent<ReviewDetailsValueDetailProps> = ({
  item,
  showScore = false,
  readonly = false,
  onCreateStrengthOrOpportunity,
  isCreatingStrengthOrOpportunity,
  onDeleteStrengthOrOpportunity,
  isDeletingStrengthOrOpportunity,
  creatingIds,
  deletingIds,
  evaluationId,
  reviewClassification,
  isExpanded: providedIsExpanded = false,
  showTools = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    setIsExpanded(providedIsExpanded);
  }, [providedIsExpanded]);

  const [score, setScore] = useState(
    typeof item.score == "number" ? item.score.toString() : undefined
  );
  const [strengths, setStrengths] = useState(item.strengths);
  const [opportunities, setOpportunities] = useState(item.opportunities);

  const notify = useNotification();

  const [updatingIds, setUpdatingIds] = useState<string[]>([]);

  const invalidScore = useMemo(() => {
    if (score && score != '' && isNaN(Number(score))) {
      return true;
    } else {
      return false;
    }
  }, [score]);

  useEffect(() => {
    setStrengths((local) => {
      const copy = [...local];
      const localIds = new Set(copy.map((x) => x.id));
      const remoteIds = new Set(item.strengths.map((x) => x.id));

      const existsInRemoteAndLocal: number[] = [];
      localIds.forEach((id) => {
        if (remoteIds.has(id)) {
          existsInRemoteAndLocal.push(id);
        }
      });

      const doesNotExistInRemote: number[] = [];
      localIds.forEach((id) => {
        if (!remoteIds.has(id)) {
          doesNotExistInRemote.push(id);
        }
      });

      const doesNotExistInLocal: number[] = [];
      remoteIds.forEach((id) => {
        if (!localIds.has(id)) {
          doesNotExistInLocal.push(id);
        }
      });

      const include = copy.filter((x) => existsInRemoteAndLocal.includes(x.id));
      const add = item.strengths.filter((x) =>
        doesNotExistInLocal.includes(x.id)
      );

      return [...include, ...add];
    });
  }, [item.strengths]);

  useEffect(() => {
    setOpportunities((local) => {
      const copy = [...local];
      const localIds = new Set(copy.map((x) => x.id));
      const remoteIds = new Set(item.opportunities.map((x) => x.id));

      const existsInRemoteAndLocal: number[] = [];
      localIds.forEach((id) => {
        if (remoteIds.has(id)) {
          existsInRemoteAndLocal.push(id);
        }
      });

      const doesNotExistInRemote: number[] = [];
      localIds.forEach((id) => {
        if (!remoteIds.has(id)) {
          doesNotExistInRemote.push(id);
        }
      });

      const doesNotExistInLocal: number[] = [];
      remoteIds.forEach((id) => {
        if (!localIds.has(id)) {
          doesNotExistInLocal.push(id);
        }
      });

      const include = copy.filter((x) => existsInRemoteAndLocal.includes(x.id));
      const add = item.opportunities.filter((x) =>
        doesNotExistInLocal.includes(x.id)
      );

      return [...include, ...add];
    });
  }, [item.opportunities]);

  const [updateGoal, { status: updateGoalStatus }] = useUpdateGoal(
    evaluationId
  );

  const [
    updateReviewValue,
    { status: updateReviewValueStatus },
  ] = useUpdateReviewValue(evaluationId);

  const onUpdateGoalOrValue = useCallback(
    async (
      id: number,
      value: number,
      reviewClassification: ReviewClassificationEnum
    ) => {
      switch (reviewClassification) {
        case ReviewClassificationEnum.Goal: {
          const input: UpdateGoalInput = {
            id: id,
            performanceReward: value,
          };
          try {
            const result = await updateGoal(input);
            if (result.data?.updateGoal) {
              // notify({
              //   duration: 3000,
              //   title: 'Success!',
              //   message: 'Evaluation started!',
              //   variant: 'success',
              // });
            } else if (result.errors) {
              const forbiddenError = result.errors.find(
                (x: any) => x?.extensions?.code == 'FORBIDDEN'
              );
              if (forbiddenError) {
                notify({
                  duration: 5000,
                  title: 'Permission Denied',
                  variant: 'danger',
                  message: forbiddenError?.message,
                });
              } else {
                throw Error();
              }
            } else {
              throw Error();
            }
          } catch (error) {
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              variant: 'danger',
              message: 'An unknown error occurred while updating the goal!',
            });
          }
          break;
        }
        case ReviewClassificationEnum.Value: {
          try {
            const input: UpdateReviewValueInput = {
              id: id,
              score: value,
            };
            const result = await updateReviewValue(input);
            if (result.data?.updateReviewValue) {
              // notify({
              //   duration: 3000,
              //   title: 'Success!',
              //   message: 'Evaluation started!',
              //   variant: 'success',
              // });
            } else if (result.errors) {
              console.log('error', result.errors);
              const forbiddenError = result.errors.find(
                (x: any) => x?.extensions?.code == 'FORBIDDEN'
              );
              if (forbiddenError) {
                notify({
                  duration: 5000,
                  title: 'Permission Denied',
                  variant: 'danger',
                  message: forbiddenError?.message,
                });
              } else {
                throw Error();
              }
            } else {
              throw Error();
            }
          } catch (error) {
            console.log('error', error);
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              variant: 'danger',
              message:
                'An unknown error occurred while updating the review value!',
            });
          }
          break;
        }
        default:
          break;
      }
    },
    [updateGoal, updateReviewValue]
  );

  const debouncedUpdateGoalOrValue = useCallback(
    debounce(onUpdateGoalOrValue, 750),
    [onUpdateGoalOrValue]
  );

  const onGoalOrValueScoreChanged = useCallback(
    (id: number, value: string) => {
      setScore(value);
      if (!isNaN(Number(value))) {
        debouncedUpdateGoalOrValue(id, Number(value), reviewClassification);
      }
    },
    [debouncedUpdateGoalOrValue]
  );

  return (
    <ReviewDetailsValueDetailWrapper>
      <ReviewDetailsValueInfo>
        <ReviewDetailsValueNameWrapper>
          <ReviewDetailsValueName onClick={() => setIsExpanded(!isExpanded)}>
            <GoalTitles>
              <GoalTitle>{item.name}</GoalTitle>
              {reviewClassification == ReviewClassificationEnum.Goal && (
                <GoalSubtitle>
                  {`${
                    item.details.length > 0 ? item.details.length : 'No'
                  } Subgoals`}
                </GoalSubtitle>
              )}
              {item.details && item.details.length > 0 && (
                <GoalBody
                  noPadding={
                    reviewClassification == ReviewClassificationEnum.Value
                  }
                >
                  {item.details?.map((detail, index) => {
                    return reviewClassification ==
                      ReviewClassificationEnum.Goal ? (
                      <GoalBodyLine key={index}>{detail}</GoalBodyLine>
                    ) : (
                      <div key={index}>{detail}</div>
                    );
                  })}
                </GoalBody>
              )}
            </GoalTitles>
          </ReviewDetailsValueName>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{ marginLeft: '5px', marginRight: '20px', cursor: 'pointer' }}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <FontAwesomeIcon
                icon={['fal', isExpanded ? 'chevron-up' : 'chevron-down']}
              />
            </div>
            {showScore && (
              <React.Fragment>
                <Tooltip content={(
                  <ScoreRubricWrapper>
                    <ScoreRubricTitle>Rubric</ScoreRubricTitle>
                    <HorizontalSeparator/>
                    <ScoreRubricItem>{`i.	Meets Expectation = 100%`}</ScoreRubricItem>
                    <ScoreRubricItem>{`ii.	Partially Meets = 26%-99%`}</ScoreRubricItem>
                    <ScoreRubricItem>{`iii.	Does not meet = 25% or lower`}</ScoreRubricItem>
                  </ScoreRubricWrapper>
                )}>
                  <span style={{ padding: '10px' }}>
                    <FontAwesomeIcon icon={['fal', 'info-circle']} />
                  </span>
                </Tooltip>
                <ValuesScoreInput>
                  <InputField
                    name={'score'}
                    placeholder={'Score'}
                    disabled={readonly}
                    onChange={(e) =>
                      onGoalOrValueScoreChanged(item.id, e.target.value)
                    }
                    value={score}
                    error={invalidScore ? 'Please enter a valid score' : undefined}
                    icon={'percent'}
                    accessoryOnRight={true}
                    style={{ textAlign: 'right' }}
                  />
                </ValuesScoreInput>
              </React.Fragment>
            )}
          </div>
        </ReviewDetailsValueNameWrapper>
        {isExpanded && (
          <React.Fragment>
            <HorizontalSeparator margin={10} />
            <ReviewDetailsValueStrengthOrOpportunity>
              <ReviewDetailsValueDetailHeading>
                Strengths
              </ReviewDetailsValueDetailHeading>
              <HorizontalSeparator />
              <EntriesWrapper>
                {readonly && strengths.length == 0 ? (
                  <div>N/A</div>
                ) : (
                  strengths.map((strength) => (
                    <ReviewDetailsValueDetailDescription key={strength.id}>
                      {readonly ? (
                        <StrengthOrOpportunityWrapper>
                          <StrengthOrOpportunityDescription>
                            {strength.description}
                          </StrengthOrOpportunityDescription>
                          {strength.author && (
                            <StrengthOrOpportunityAuthor>
                              Author: {strength.author}
                            </StrengthOrOpportunityAuthor>
                          )}
                        </StrengthOrOpportunityWrapper>
                      ) : (
                        <StrengthOrOpportunityTextarea
                          id={strength.id}
                          type={'strength'}
                          value={strength.description}
                          evaluationId={evaluationId}
                          reviewClassification={reviewClassification}
                          setStrengths={setStrengths}
                          setOpportunities={setOpportunities}
                          setUpdatingIds={setUpdatingIds}
                        />
                      )}
                      {!readonly ? (
                        <Button
                          icon={'times'}
                          slim={true}
                          color={'danger'}
                          variant={'simple'}
                          onClick={async () => {
                            await onDeleteStrengthOrOpportunity(
                              strength.id,
                              'strength'
                            );
                          }}
                          isWorking={
                            (isDeletingStrengthOrOpportunity &&
                              deletingIds.includes(`strength:${strength.id}`)) ||
                            updatingIds.includes(`strength:${strength.id}`)
                          }
                        />
                      ) : (
                        showTools && (
                          <CopyToClipboardButton text={strength.description} />
                        )
                      )}
                    </ReviewDetailsValueDetailDescription>
                  ))
                )}
                {!readonly && (
                  <Button
                    slim={true}
                    variant={'outline'}
                    fullWidth={true}
                    icon={'plus'}
                    onClick={() =>
                      onCreateStrengthOrOpportunity(item.itemId, 'strength')
                    }
                    isWorking={
                      isCreatingStrengthOrOpportunity &&
                      creatingIds.includes(`strength:${item.itemId}`)
                    }
                  >
                    Add Strength
                  </Button>
                )}
              </EntriesWrapper>
            </ReviewDetailsValueStrengthOrOpportunity>
            <ReviewDetailsValueStrengthOrOpportunity>
              <ReviewDetailsValueDetailHeading>
                Opportunities
              </ReviewDetailsValueDetailHeading>
              <HorizontalSeparator />
              <EntriesWrapper>
                {readonly && opportunities.length == 0 ? (
                  <div>N/A</div>
                ) : (
                  opportunities.map((opportunity) => (
                    <ReviewDetailsValueDetailDescription key={opportunity.id}>
                      {readonly ? (
                        <StrengthOrOpportunityWrapper>
                          <StrengthOrOpportunityDescription>
                            {opportunity.description}
                          </StrengthOrOpportunityDescription>
                          {opportunity.author && (
                            <StrengthOrOpportunityAuthor>
                              Author: {opportunity.author}
                            </StrengthOrOpportunityAuthor>
                          )}
                        </StrengthOrOpportunityWrapper>
                      ) : (
                        <StrengthOrOpportunityTextarea
                          id={opportunity.id}
                          type={'opportunity'}
                          value={opportunity.description}
                          evaluationId={evaluationId}
                          reviewClassification={reviewClassification}
                          setStrengths={setStrengths}
                          setOpportunities={setOpportunities}
                          setUpdatingIds={setUpdatingIds}
                        />
                      )}
                      {!readonly ? (
                        <Button
                          icon={'times'}
                          slim={true}
                          color={'danger'}
                          variant={'simple'}
                          onClick={async () => {
                            await onDeleteStrengthOrOpportunity(
                              opportunity.id,
                              'opportunity'
                            );
                          }}
                          isWorking={
                            (isDeletingStrengthOrOpportunity &&
                              deletingIds.includes(
                                `opportunity:${opportunity.id}`
                              )) ||
                            updatingIds.includes(`opportunity:${opportunity.id}`)
                          }
                        />
                      ) : (
                        showTools && (
                          <CopyToClipboardButton text={opportunity.description} />
                        )
                      )}
                    </ReviewDetailsValueDetailDescription>
                  ))
                )}
                {!readonly && (
                  <Button
                    slim={true}
                    variant={'outline'}
                    fullWidth={true}
                    icon={'plus'}
                    onClick={() =>
                      onCreateStrengthOrOpportunity(item.itemId, 'opportunity')
                    }
                    isWorking={
                      isCreatingStrengthOrOpportunity &&
                      creatingIds.includes(`opportunity:${item.itemId}`)
                    }
                  >
                    Add Opportunity
                  </Button>
                )}
              </EntriesWrapper>
            </ReviewDetailsValueStrengthOrOpportunity>
          </React.Fragment>
        )}
      </ReviewDetailsValueInfo>
    </ReviewDetailsValueDetailWrapper>
  );
};

const ScoreRubricWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;
const ScoreRubricTitle = styled.div`
  ${font.bold};
`;
const ScoreRubricItem = styled.div`

`;

interface CopyToClipboardButtonProps {
  text: string;
}

const CopyToClipboardButton: FunctionComponent<CopyToClipboardButtonProps> = ({
  text,
}) => {
  const [notice, setNotice] = useState<string | undefined>(undefined);

  const copy = useCopyToClipboard();

  const onCopy = useCallback(() => {
    copy(text);
    setNotice('Copied!');
    setTimeout(() => {
      setNotice(undefined);
    }, 1000);
  }, [copy, text]);

  return (
    <Tooltip content={'Copy to clipboard'}>
      <Button
        icon={notice ? undefined : 'copy'}
        slim={true}
        variant={'outline'}
        onClick={onCopy}
      >
        {notice}
      </Button>
    </Tooltip>
  );
};

interface StrengthOrOpportunityTextareaProps {
  id: number;
  value: string;
  evaluationId: number;
  reviewClassification: ReviewClassificationEnum;
  type: 'strength' | 'opportunity';
  setStrengths: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        description: string;
      }[]
    >
  >;
  setOpportunities: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        description: string;
      }[]
    >
  >;
  setUpdatingIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const StrengthOrOpportunityTextarea: FunctionComponent<StrengthOrOpportunityTextareaProps> = ({
  id,
  value,
  evaluationId,
  reviewClassification,
  setStrengths,
  setOpportunities,
  setUpdatingIds,
  type,
}) => {
  const notify = useNotification();
  const [
    updateReviewStrength,
    { status: updateReviewStrengthStatus },
  ] = useUpdateReviewStrength(evaluationId);

  const [
    updateReviewOpportunity,
    { status: updateReviewOpportunityStatus },
  ] = useUpdateReviewOpportunity(evaluationId);

  const onUpdateStrengthOrOpportunity = useCallback(
    async (
      id: number,
      description: string,
      type: 'strength' | 'opportunity'
    ) => {
      const input: UpdateStrengthOrOpportunityInput = {
        id: id,
        description: description,
        reviewClassification: reviewClassification,
      };
      switch (type) {
        case 'strength': {
          setUpdatingIds((ids) => [...ids, `strength:${id}`]);
          try {
            const result = await updateReviewStrength(input);
            if (result.data?.updateReviewStrength) {
              // notify({
              //   duration: 3000,
              //   title: 'Success!',
              //   message: 'Evaluation started!',
              //   variant: 'success',
              // });
            } else if (result.errors) {
              const forbiddenError = result.errors.find(
                (x: any) => x?.extensions?.code == 'FORBIDDEN'
              );
              if (forbiddenError) {
                notify({
                  duration: 5000,
                  title: 'Permission Denied',
                  variant: 'danger',
                  message: forbiddenError?.message,
                });
              } else {
                throw Error();
              }
            } else {
              throw Error();
            }
          } catch (error) {
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              variant: 'danger',
              message: 'An unknown error occurred while creating the strength!',
            });
          } finally {
            setUpdatingIds((ids) => {
              const copy = [...ids];
              const firstIndex = copy.findIndex((x) => x == `strength:${id}`);
              if (firstIndex != -1) {
                copy.splice(firstIndex);
              }
              return [...copy];
            });
          }
          break;
        }
        case 'opportunity': {
          setUpdatingIds((ids) => [...ids, `opportunity:${id}`]);
          try {
            const result = await updateReviewOpportunity(input);
            if (result.data?.updateReviewOpportunity) {
              // notify({
              //   duration: 3000,
              //   title: 'Success!',
              //   message: 'Evaluation started!',
              //   variant: 'success',
              // });
            } else if (result.errors) {
              const forbiddenError = result.errors.find(
                (x: any) => x?.extensions?.code == 'FORBIDDEN'
              );
              if (forbiddenError) {
                notify({
                  duration: 5000,
                  title: 'Permission Denied',
                  variant: 'danger',
                  message: forbiddenError?.message,
                });
              } else {
                throw Error();
              }
            } else {
              throw Error();
            }
          } catch (error) {
            notify({
              duration: 5000,
              title: 'Uh-oh!',
              variant: 'danger',
              message:
                'An unknown error occurred while creating the opportunity!',
            });
          } finally {
            setUpdatingIds((ids) => {
              const copy = [...ids];
              const firstIndex = copy.findIndex(
                (x) => x == `opportunity:${id}`
              );
              if (firstIndex != -1) {
                copy.splice(firstIndex);
              }
              return [...copy];
            });
          }
          break;
        }
      }
    },
    [updateReviewStrength, updateReviewOpportunity, reviewClassification]
  );

  const debouncedUpdate = useCallback(
    debounce(onUpdateStrengthOrOpportunity, 750),
    [onUpdateStrengthOrOpportunity]
  );

  const onStrengthChanged = useCallback(
    (id: number, value: string) => {
      setStrengths((existingStrengths) => {
        const copy = [...existingStrengths];
        const index = copy.findIndex((x) => x.id == id);
        if (index != -1) {
          copy[index].description = value;
        }
        return copy;
      });
      debouncedUpdate(id, value ?? '', 'strength');
    },
    [debouncedUpdate]
  );

  const onOpportunityChanged = useCallback(
    (id: number, value: string) => {
      setOpportunities((existingOpportunities) => {
        const copy = [...existingOpportunities];
        const index = copy.findIndex((x) => x.id == id);
        if (index != -1) {
          copy[index].description = value;
        }
        return copy;
      });
      debouncedUpdate(id, value ?? '', 'opportunity');
    },
    [debouncedUpdate]
  );

  return (
    <Textarea
      placeholder={`Enter ${
        type == 'opportunity' ? 'an opportunity' : 'a strength'
      } here...`}
      value={value}
      onChange={(e) => {
        if (type == 'opportunity') {
          onOpportunityChanged(id, e.target.value);
        } else {
          onStrengthChanged(id, e.target.value);
        }
      }}
    />
  );
};

const ReviewDetailsValueDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  border: 1px solid ${color.border};
  border-radius: 5px;
  margin-bottom: 10px;

  max-height: 100%;
  overflow: hidden;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const ReviewDetailsValueInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ValuesScoreInput = styled.div`
  width: 100px;
  min-width: 100px;
`;

const ReviewDetailsValueStrengthOrOpportunity = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const ReviewDetailsValueNameWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 30px;
`;

const ReviewDetailsValueName = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  ${font.bold};
  ${font.size(14)};

  cursor: pointer;

  & > span {
    color: ${color.primary};
  }

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const ReviewDetailsValueDetailHeading = styled.div`
  ${font.size(12)};
  ${font.bold};
  color: ${color.textDark};
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const ReviewDetailsValueDetailDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${font.size(12)};
  color: ${color.textMedium};

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const EntriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const StrengthOrOpportunityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const StrengthOrOpportunityDescription = styled.div`
  color: ${color.textDarkest};
`;

const StrengthOrOpportunityAuthor = styled.div`
  color: ${color.textLight};
  ${font.size(11)};
`;
