import React, {
  FunctionComponent,
  MutableRefObject,
  useEffect,
  useMemo,
} from 'react';
import { AgGridReactProps, AgGridReact } from 'ag-grid-react';
import { TableContext } from './SelectCellEditor';
import { TableContextState } from './TableContextState';
import { TableApi } from 'shared/hooks';
import styled from 'styled-components';
import Spinner from '../spinner/Spinner';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import NoAccess from '../noAccess';

interface Identifiable {
  id: any;
}

interface TableProps<T extends Identifiable> extends AgGridReactProps {
  style?: React.CSSProperties;
  context?: TableContextState;
  tableRef?: MutableRefObject<TableApi | undefined>;
  isLoading?: boolean;
  noRowsOverlayTitle?: string;
  noRowsOverlaySubtitle?: string;
  noRowsOverlayIcon?: IconName;
  rowData: T[] | undefined;
  className?: string;
  children: any;
}

const defaultThemeClass = 'ag-theme-balham';

function Table<T extends Identifiable>({
  style,
  className,
  context = {},
  suppressDragLeaveHidesColumns = true,
  suppressMovableColumns = true,
  tableRef,
  isLoading,
  noRowsOverlayTitle = 'Nothing to see here...',
  noRowsOverlaySubtitle = 'No Data Available',
  noRowsOverlayIcon = 'box-open',
  ...props
}: TableProps<T>) {
  const tableClassName = className
    ? `${defaultThemeClass} ${className}`
    : defaultThemeClass;

    

  const rowData = useMemo(() => {
    if (isNullOrEmpty(props.rowData) && isLoading) {
      return undefined;
    } else {
      return props.rowData || [];
    }
  }, [props.rowData, isLoading]);

  // useEffect(() => {
  //   console.log('show')
  //   tableRef?.current?.gridApi.showLoadingOverlay();
  //   // if (tableRef?.current?.gridApi) {
  //   //   if (isLoading && isNullOrEmpty(rowData)) {
  //   //     console.log('showLoading')
  //   //     // tableRef.current.gridApi.hideOverlay();
  //   //     tableRef.current.gridApi.showLoadingOverlay();
  //   //   } else if (!isLoading && !isNullOrEmpty(rowData)) {
  //   //     console.log('showNothing')
  //   //     tableRef.current.gridApi.hideOverlay();
  //   //   } else if (!isLoading && isNullOrEmpty(rowData)) {
  //   //     console.log('showNoRows')
  //   //     // tableRef.current.gridApi.hideOverlay();
  //   //     tableRef.current.gridApi.showNoRowsOverlay();
  //   //   }
  //   // }
  // }, [tableRef?.current?.gridApi, isLoading, rowData]);

  return (
    <TableContext.Provider value={context}>
      <div className={tableClassName} style={style}>
        <AgGridReact
          immutableData={true}
          suppressDragLeaveHidesColumns={suppressDragLeaveHidesColumns}
          suppressMovableColumns={suppressMovableColumns}
          suppressScrollOnNewData={true}
          rememberGroupStateWhenNewData={true}
          getRowNodeId={(data) => data.id}
          {...props}
          rowData={rowData}
          frameworkComponents={{
            customLoadingOverlay: LoadingOverlay,
            noRowsOverlay: NoAccess,
            ...props.frameworkComponents,
          }}
          loadingOverlayComponent={'customLoadingOverlay'}
          noRowsOverlayComponent={'noRowsOverlay'}
          noRowsOverlayComponentParams={{
            title: noRowsOverlayTitle,
            subtitle: noRowsOverlaySubtitle,
            icon: noRowsOverlayIcon,
          }}
        />
      </div>
    </TableContext.Provider>
  );
}

const LoadingOverlayWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingOverlay = () => {
  return (
    <LoadingOverlayWrapper>
      <Spinner />
    </LoadingOverlayWrapper>
  );
};

const isNullOrEmpty = (arr?: any[] | null) => {
  return arr == undefined || arr == null || arr?.length == 0
}

export default Table;
