import { useAppContext } from 'App';
import React, { FunctionComponent, useMemo } from 'react';
import {
  FormFieldWrapper,
  InputFormikField,
  SelectFormikField,
} from 'shared/components';
import { useGetCompanies, useGetDirectory, useGetEmployeeRoles, useGetDepartments } from 'shared/hooks/api';

const UserModalForm: FunctionComponent = () => {
  const appContext = useAppContext();
  const { data: directoryData, status: directoryStatus } = useGetDirectory(appContext.currentUserId);
  const { data: employeeRolesData, status: employeeRolesStatus } = useGetEmployeeRoles();
  const { data: departmentsData, status: departmentsStatus } = useGetDepartments();
  const { data: companiesData, status: companiesStatus } = useGetCompanies();

  const companyOptions = useMemo(() => {
    return companiesData?.companies || [];
  }, [companiesData]);

  const employeeRoleOptions = useMemo(() => {
    return employeeRolesData?.employeeRoles || [];
  }, [employeeRolesData]);
  
  const departmentOptions = useMemo(() => {
    return departmentsData?.departments || [];
  }, [departmentsData]);

  const managerOptions = useMemo(() => {
    return directoryData?.users?.map(user => ({
      value: user.id,
      label: user.displayName,
    }));
  }, [directoryData]);

  return (
    <React.Fragment>
      <FormFieldWrapper>
        <InputFormikField
          label={'First Name'}
          name={'firstName'}
          placeholder={'Enter a first name...'}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <InputFormikField
          label={'Last Name'}
          name={'lastName'}
          placeholder={'Enter a last name...'}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <InputFormikField
          label={'Email'}
          name={'email'}
          placeholder={`Enter the user's email...`}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <InputFormikField
          label={'Title'}
          name={'title'}
          placeholder={'Enter a title...'}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <SelectFormikField
          label={'Manager'}
          name={'manager'}
          options={managerOptions}
          placeholder={'Select a manager...'}
          isLoading={directoryStatus == 'loading'}
          isClearable={true}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <SelectFormikField
          label={'Role Scorecard'}
          name={'employeeRole'}
          options={employeeRoleOptions}
          placeholder={'Select a role scorecard...'}
          isLoading={employeeRolesStatus == 'loading'}
          isClearable={true}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <SelectFormikField
          label={'Department'}
          name={'department'}
          options={departmentOptions}
          placeholder={'Select a department...'}
          isLoading={departmentsStatus == 'loading'}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <SelectFormikField
          label={'Company'}
          name={'company'}
          options={companyOptions}
          placeholder={'Select a company...'}
          isLoading={companiesStatus == 'loading'}
        />
      </FormFieldWrapper>
    </React.Fragment>
  );
};

export default UserModalForm;
