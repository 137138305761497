import React, { FunctionComponent } from 'react'
import Tippy, { TippyProps } from '@tippyjs/react'
import styled from 'styled-components'
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';
import { font } from 'shared/utils/styles';

const ThemedTooltip = styled(Tippy)`
  background-color: #2c2e3e;
  color: #fff;
  ${font.size(13)};
  padding: 2px;
  border-radius: 4px;

  & > .tippy-roundarrow {
    fill: #2c2e3e;
  }
`

const Tooltip: FunctionComponent<TippyProps> = ({ children, content, placement = "top", ...props }) => {
  return (
    <ThemedTooltip
        content={content}
        arrow={true}
        animation="shift-away"
        placement={placement}
        {...props}
      >
        {children}
      </ThemedTooltip>
  )
}

export default Tooltip;