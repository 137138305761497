import React, { FunctionComponent, useMemo } from 'react';
import {
  match,
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import {
  PageDetails,
  PageHeader,
} from 'shared/components';
import { usePageTitle } from 'shared/hooks';
import { color, font, mixin } from 'shared/utils/styles';
import styled from 'styled-components';
import { useAppContext } from 'App';
import CurrentEvaluations from './Current';
import EvaluationDetails from '../shared/Evaluations/Evaluation';
import NoAccess from 'shared/components/noAccess';

const CurrentUserEvaluations = () => {
  usePageTitle('My Evaluation Center');
  const match = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${match.path}/my-evaluations`}
        exact={true}
        render={() => <CurrentUserEvaluationsMain match={match} />}
      />
      <Route
        path={`${match.path}/direct-reports`}
        exact={true}
        render={() => <CurrentUserEvaluationsMain match={match} />}
      />
      <Route path={`${match.path}/:evaluationId`} render={() => <EvaluationDetails />} />
      <Redirect to={`${match.url}/my-evaluations`} />
    </Switch>
  );
};

interface CurrentUserEvaluationsMainProps {
  match: match<{}>;
}

const CurrentUserEvaluationsMain: FunctionComponent<CurrentUserEvaluationsMainProps> = ({
  match,
}) => {
  const history = useHistory();
  const { orgName } = useParams<{ orgName: string }>();
  usePageTitle('My Evaluation Center');

  const directReports = useMemo(() => {
    return [];
  }, []);

  const appContext = useAppContext();

  const tabs = useMemo(() => {
    const tabList = [
      { path: `/my-evaluations`, title: 'My Evaluations', pinned: true },
      { path: `/direct-reports`, title: 'Direct Reports', onClick: () => {
        history.push(`/${orgName}/directory/direct-reports`);
      }},
    ];
    return tabList;
  }, [directReports]);

  return (
    <React.Fragment>
      <PageHeader
        title={'My Evaluation Center'}
        tabs={tabs}
        onTabClick={(tab) => {
          if (tab === 'direct-reports') {
            alert('Direct Reports');
          }
        }}
        match={match}
      />
      <Switch>
        <Route
          path={`${match.path}/my-evaluations`}
          exact={true}
          render={() => <CurrentEvaluations />}
        />
        {/* <Route
          path={`${match.path}/direct-reports`}
          exact={true}
          render={() => (
            <PageDetails>
              <NoAccess
                icon={'construction'}
                title={'Pardon our dust...'}
                subtitle={'This area of the app is under construction right now...'}
              />
            </PageDetails>
          )}
        /> */}
        <Redirect to={`${match.url}/my-evaluations`} />
      </Switch>
    </React.Fragment>
  );
};

interface EvaluationsRowProps {
  last: boolean;
  print?: boolean;
}

export const EvaluationsRow = styled.div<EvaluationsRowProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  ${(props) => !props.last && `border-bottom: 1px solid ${color.border}`};
  ${mixin.clickable};

  border-radius: 5px;
  ${props => !props.print ?
  
    `box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15),
      0 5px 20px 0 rgba(21, 27, 38, 0.08);` : `
        border: 1px solid ${color.border};
      `
  }
`;

export const EvaluationsRowLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  & > * {
    margin-right: 20px;
  }
`;

export const EvaluationsRowRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;

  & > * {
    margin-left: 20px;
  }
`;

export const EvaluationTitles = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const EvaluationTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${color.textDarkest};
  ${font.bold};

  & > * {
    margin-right: 10px;
  }
`;

export const EvaluationSubtitle = styled.p`
  ${font.size(12)};
  color: ${color.textMedium};
`;

export const EvaluationBody = styled.ul`
  ${font.size(11)};
  color: ${color.textMedium};
  padding-left: 15px;

  & > li {
    margin: 2px 0;
  }
`;

export const EvaluationBodyLine = styled.li`
`;

export const EvaluationTitleSubtitle = styled.p`
  ${font.size(12)};
  ${font.regular};
  color: ${color.textMedium};
  margin-left: 5px;
`;

export const EvaluationIcon = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${mixin.clickable};
  height: 36px;
  min-height: 36px;
  max-height: 36px;
  width: 36px;
  min-width: 36px;
  max-width: 36px;
  ${font.size(14)};
  ${font.medium};
  border-radius: 5px;
  color: #fff;
  background-color: ${(props) => props.color};
`;

export default CurrentUserEvaluations;
