import React, { useState, useEffect, FunctionComponent, useMemo } from 'react';
import { Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';

import Organization, { ErrorCodes, FullScreenMessage } from './Organization';
import history from 'browserHistory';
import { Breadcrumb, BreadcrumbsContext } from 'shared/hooks';
import styled from 'styled-components';
import { Button, Spinner } from 'shared/components';
import { useGetCurrentUserOrganization } from 'shared/hooks/api';
import { color, font } from 'shared/utils/styles';
import { useAuthModule } from 'shared/auth/msal';

const Routes = () => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  return (
    <Router history={history}>
      <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
        <Switch>
          <Route path="/" exact={true} component={Root} />
          <Route
            path="/_redirect"
            exact={true}
            render={() => <FullPageSpinner />}
          />
          <Route path="/:orgName" component={Organization} />
          <Redirect to={'/'} />
        </Switch>
      </BreadcrumbsContext.Provider>
    </Router>
  );
};

const Root = () => {
  const { data, errors } = useGetCurrentUserOrganization();
  const history = useHistory();

  useEffect(() => {
    if (data?.currentUser.organization) {
      history.push(`/${data.currentUser.organization.organizationPath}`);
    }
  }, [data]);

  const notRegisteredError = useMemo(() => {
    const error = errors?.find((x) => x.extensions?.code == 'NOT_FOUND');
    return error;
  }, [errors]);

  return (
    <React.Fragment>
      {notRegisteredError ? (
        <NotRegistered />
      ) : errors && errors.length > 0 ? (
        <FullScreenMessage
          title={'Oops! This is awkward...'}
          message={
            'An unknown error occurred whil trying to access the requested resource. Please try again.'
          }
          accessory={<ErrorCodes errors={errors} />}
        />
      ) : (
        <FullPageSpinner />
      )}
    </React.Fragment>
  );
};

export const NotRegistered: FunctionComponent = () => {
  const auth = useAuthModule();
  const greetings = [
    'Hey, you!',
    'You there! Stop!',
    'Uh, who are you again?',
    'new phone. who dis?',
  ];

  const selectedGreeting = useMemo(() => {
    return greetings[Math.floor(Math.random() * greetings.length)];
  }, []);

  return (
    <FullScreenMessage
      title={selectedGreeting}
      message={`We can't find a registration associated with your account. If you previously had access to this application, it could be that your account has been temporarily disabled. HR departments are in charge of adding users to their organization. Feel free to reach out to an HR representative so that you can get started using the app!`}
      accessory={
        <Button variant={'outline'} color={'primary'} onClick={auth.logout}>
          Use a different account (Sign Out)
        </Button>
      }
    />
  );
};

export const Logo = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 25px;
`;

export const LogoTitle = styled.div`
  ${font.bold};
  ${font.size(30)};
`;

export const Icon = styled.div`
  width: 50px;
  height: 40px;
  padding-right: 10px;
  color: ${color.info};
  align-items: center;
`;

export const OrgIcon = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  min-height: 36px;
  max-height: 36px;
  width: 36px;
  min-width: 36px;
  max-width: 36px;
  ${font.size(14)};
  ${font.medium};
  border-radius: 5px;
  color: #fff;
  background-color: ${(props) => props.backgroundColor};
`;

const FullPageSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const FullWidthSpinnerWrapper = styled.div<FullWidthSpinnerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: ${(props) => props.minHeight}px;
  width: 100%;
`;

export const FullPageSpinner = () => {
  return (
    <FullPageSpinnerWrapper>
      <Spinner />
    </FullPageSpinnerWrapper>
  );
};

type FullWidthSpinnerProps = {
  minHeight: number;
};

export const FullWidthSpinner: FunctionComponent<FullWidthSpinnerProps> = ({
  minHeight,
}) => {
  return (
    <FullWidthSpinnerWrapper minHeight={minHeight}>
      <Spinner />
    </FullWidthSpinnerWrapper>
  );
};

export default Routes;
