import React, { FunctionComponent, ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Column, HorizontalSeparator, PageDetails, Row } from 'shared/components';
import { usePageTitle } from 'shared/hooks';
import { useGetDirectoryUser } from 'shared/hooks/api';
import { createQueryParamModalHelpers } from 'shared/utils/queryParamModal';
import { color, font } from 'shared/utils/styles';
import styled from 'styled-components';
import EditUserModal from '../../modals/EditUserModal';
import ProfileCard from '../../ProfileCard';
import ViewEmployeeRoleModal from '../../modals/ViewEmployeeRoleModal';

const Profile: FunctionComponent = () => {
  usePageTitle('Employee - Profile');
  const { employeeId } = useParams<{ employeeId: string }>();
  const { data, status, refetch } = useGetDirectoryUser(Number(employeeId));

  const managerName = useMemo(() => {
    if (data?.user?.manager) {
      return data.user.manager.displayName;
    }
  }, [data])

  const updateUserModalHelpers = createQueryParamModalHelpers('edit-user');
  const viewUserScorecardModalHelpers = createQueryParamModalHelpers('view-scorecard');

  const tools = useMemo(() => {
    const toolsArray = [];
    if (data?.user?.role) {
      toolsArray.push(<Button key={'roleScorecard'} variant={'simple'} icon={'bullseye-pointer'} onClick={viewUserScorecardModalHelpers.open} >View Scorecard</Button>)
    }
    if (data?.user?.currentUserCanEdit) {
      toolsArray.push(<Button key={'edit'} variant={'simple'} icon={'edit'} onClick={updateUserModalHelpers.open}>Edit</Button>);
    }
    return toolsArray;
  }, [data, updateUserModalHelpers])

  return (
    <React.Fragment>
      <PageDetails>
        <Row>
          <Column sm={12} lg={6}>
            <ProfileCard title={"Employee Information"} tools={tools}>
              <DataTable>
                <DataTableRow
                  label={'Name'}
                  display={data?.user?.displayName}
                />
                <DataTableRow
                  label={'Email'}
                  display={data?.user?.email}
                />
                <DataTableRow
                  label={'Title'}
                  display={data?.user?.title}
                />
                <DataTableRow
                  label={'Role Scorecard'}
                  display={data?.user?.role?.name}
                />
                <DataTableRow
                  label={'Manager'}
                  display={managerName}
                />
                <DataTableRow
                  label={'Department'}
                  display={data?.user?.department.name}
                />
                <DataTableRow
                  label={'Company'}
                  display={data?.user?.company.name}
                />
              </DataTable>
            </ProfileCard>
          </Column>
        </Row>
      </PageDetails>
      {updateUserModalHelpers.isOpen() &&
        <EditUserModal
          onClose={updateUserModalHelpers.close}
          onSuccess={() => refetch}
          userId={Number(employeeId)}
        />}
      {viewUserScorecardModalHelpers.isOpen() &&
        <ViewEmployeeRoleModal
          onClose={viewUserScorecardModalHelpers.close}
          onSuccess={() => viewUserScorecardModalHelpers.close}
          id={Number(data?.user?.role?.id)} />}
    </React.Fragment>
  );
};

export default Profile;

interface DataPoint {
  label: string;
  display?: any;
  field?: React.ReactElement;
}

const DataTableRowWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  min-height: 35px;
  padding: 2.5px 0;
`;

const DataLabel = styled.div`
  width: 50%;
  ${font.size(13)};
  ${font.medium};
  color: ${color.textLight};
`;

const DataValue = styled.div`
  width: 50%;
  ${font.size(13)};
  ${font.medium};
  color: ${color.textDark};
`;

interface DataTableProps {
  isEditing?: boolean;
}

const DataTable: FunctionComponent<DataTableProps> = ({
  children,
  isEditing,
}) => {
  return (
    <React.Fragment>
      {React.Children.map(children, (child) => {
        if (child) {
          const row = child as ReactElement<DataPoint>;
          return (
            <Column>
              <DataTableRowWrapper>
                <DataLabel>{row.props.label}</DataLabel>
                <DataValue>
                  {row.props.field && isEditing
                    ? row.props.field
                    : row.props.display || '-'}
                </DataValue>
              </DataTableRowWrapper>
              <HorizontalSeparator />
            </Column>
          );
        }
      })}
    </React.Fragment>
  );
};

const DataTableRow: FunctionComponent<DataPoint> = () => {
  return null;
};
