import { CellClickedEvent } from 'ag-grid-community';
import { AgGridColumn } from 'ag-grid-react';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  PageDetails,
  PageToolbar,
  Table,
  ToolbarSearchInput,
} from 'shared/components';
import { usePageTitle, useRemainingHeight, useTable } from 'shared/hooks';
import { useGetEditableEmployeeRoles, useGetEmployeeRoles } from 'shared/hooks/api';
import UpdateEmployeeRoleModal from '../modals/UpdateEmployeeRoleModal';

interface EmployeeRolesProps {}

const EmployeeRoles: FunctionComponent<EmployeeRolesProps> = () => {
  usePageTitle('Directory - Role Scorecards');
  const { orgName } = useParams<{ orgName: string }>();
  const { ref, height } = useRemainingHeight();
  const history = useHistory();
  const {
    data: employeeRolesData,
    status: employeeRolesStatus,
  } = useGetEditableEmployeeRoles();
  const { searchText, setSearchText, gridEvents } = useTable({
    autoSizeColumns: true,
  });

  const roles = useMemo(() => {
    return employeeRolesData?.editableEmployeeRoles?.map((x) => ({
      id: x.id,
      name: x.name,
    }));
  }, [employeeRolesData]);

  const [selectedEmployeeRoleId, setSelectedEmployeeRoleId] = useState<
    number | undefined
  >();

  const onCellClicked = useCallback((event: CellClickedEvent) => {
    if (event.data.id) {
      setSelectedEmployeeRoleId(event.data.id);
    }
  }, []);

  return (
    <React.Fragment>
      <PageToolbar
        left={
          <ToolbarSearchInput
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={'Search for a role scorecard...'}
          />
        }
      />
      <PageDetails ref={ref}>
        <Table
          rowData={roles}
          defaultColDef={{ sortable: true, enableRowGroup: true }}
          pagination={true}
          paginationPageSize={100}
          quickFilterText={searchText}
          groupUseEntireRow={true}
          {...gridEvents}
          style={{ height: height, width: '100%' }}
          noRowsOverlayIcon={'address-book'}
          noRowsOverlayTitle={'No Role Scorecards'}
          noRowsOverlaySubtitle={'There are no role scorecards to show...'}
          isLoading={employeeRolesStatus === 'loading'}
        >
          <AgGridColumn
            headerName={'Name'}
            field={'name'}
            onCellClicked={onCellClicked}
            sort={'asc'}
          />
        </Table>
      </PageDetails>
      {selectedEmployeeRoleId && (
        <UpdateEmployeeRoleModal
          id={selectedEmployeeRoleId}
          onClose={() => setSelectedEmployeeRoleId(undefined)}
          onSuccess={() => setSelectedEmployeeRoleId(undefined)}
        />
      )}
    </React.Fragment>
  );
};

export default EmployeeRoles;
