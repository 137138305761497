import { useAppContext } from 'App';
import { FieldArray, FormikProps } from 'formik';
import React, { FunctionComponent, useMemo } from 'react';
import {
  Button,
  Column,
  FormFieldWrapper,
  InputFormikField,
  Row,
  Section,
  SelectFormikField,
  TextareaFormikField,
} from 'shared/components';
import { useGetEmployeeRoles } from 'shared/hooks/api';
import { PartialNullable } from 'shared/utils/utilityTypes';
import styled from 'styled-components';
import { EmployeeRoleFormValues } from './NewEmployeeRoleModal';

interface ViewEmployeeRoleModalFormProps {
  form: FormikProps<PartialNullable<EmployeeRoleFormValues>>;
}

const ViewEmployeeRoleModalForm: FunctionComponent<ViewEmployeeRoleModalFormProps> = ({
  form,
}) => {
  const appContext = useAppContext();
  const {
    data: employeeRolesData,
    status: employeeRolesStatus,
  } = useGetEmployeeRoles();

  const employeeRoleOptions = useMemo(() => {
    return employeeRolesData?.employeeRoles || [];
  }, [employeeRolesData]);

  return (
    <React.Fragment>
      <FormFieldWrapper>
        <InputFormikField
          label={'Name'}
          name={'name'}
          placeholder={'Enter a name...'}
          disabled={true}
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <TextareaFormikField
          label={'Mission Statement'}
          name={'missionStatement'}
          placeholder={'Enter a mission statement...'}
          disabled={true}
        />
      </FormFieldWrapper>
      <Section title="Competencies">
        <FieldArray
          name={'competencies'}
          render={(arrayHelpers) => (
            <>
              {form.values.competencies &&
                form.values.competencies.length > 0 &&
                form.values.competencies.map((competency, index) => (
                  <FormFieldWrapper key={index}>
                    <Row>
                      <Column span={5}>
                        <InputFormikField
                          name={`competencies.${index}.category`}
                          placeholder={'Enter a category...'}
                          disabled={true}
                        />
                      </Column>
                      <Column span={6}>
                        <InputFormikField
                          name={`competencies.${index}.name`}
                          placeholder={'Enter a competency...'}
                          disabled={true}
                        />
                      </Column>
                    </Row>
                  </FormFieldWrapper>
                ))}
            </>
          )}
        />
      </Section>
      <Section title="Objectives">
        <FieldArray
          name={'objectives'}
          render={(arrayHelpers) => (
            <>
              {form.values.objectives &&
                form.values.objectives.length > 0 &&
                form.values.objectives.map((objective, index) => (
                  <FormFieldWrapper key={index}>
                    <Row>
                      <Column span={11}>
                        <InputFormikField
                          name={`objectives.${index}.name`}
                          placeholder={'Enter an objective...'}
                          disabled={true}
                        />
                      </Column>
                    </Row>
                    <FieldArray
                      name={`objectives.${index}.keyResults`}
                      render={(keyResultsHelpers) => (
                        <FormFieldWrapper style={{ marginLeft: '20px', marginRight: '20px' }}>
                          {objective.keyResults &&
                            objective.keyResults.length > 0 &&
                            objective.keyResults.map((keyResult, kIndex) => (
                              <Row key={kIndex}>
                                <Column span={11}>
                                  <InputFormikField
                                    name={`objectives.${index}.keyResults.${kIndex}`}
                                    placeholder={'Enter a key result...'}
                                    disabled={true}
                                  />
                                </Column>
                              </Row>
                            ))}
                        </FormFieldWrapper>
                      )}
                    />
                  </FormFieldWrapper>
                ))}
            </>
          )}
        />
      </Section>
      <Section title="Qualifications">
        <FieldArray
          name={'qualifications'}
          render={(arrayHelpers) => (
            <>
              {form.values.qualifications &&
                form.values.qualifications.length > 0 &&
                form.values.qualifications.map((qualification, index) => (
                  <FormFieldWrapper key={index}>
                    <Row>
                      <Column span={11}>
                        <InputFormikField
                          name={`qualifications.${index}`}
                          placeholder={'Enter a qualification...'}
                          disabled={true}
                        />
                      </Column>
                    </Row>
                  </FormFieldWrapper>
                ))}
            </>
          )}
        />
      </Section>
    </React.Fragment>
  );
};

export default ViewEmployeeRoleModalForm;
