import { color, font, mixin } from "shared/utils/styles";
import styled from "styled-components";

interface GoalsRowProps {
  last: boolean;
}

export const GoalsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const GoalsRow = styled.div<GoalsRowProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  ${(props) => !props.last && `border-bottom: 1px solid ${color.border}`};
  ${mixin.clickable};

  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15),
    0 5px 20px 0 rgba(21, 27, 38, 0.08);
`;

export const GoalsRowLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > * {
    margin-right: 20px;
  }
`

export const GoalsRowRight  = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > * {
    margin-left: 20px;
  }
`

export const GoalTitles = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const GoalTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${font.size(15)};
  color: ${color.textDarkest};
  ${font.bold};

  & > * {
    margin-right: 10px;
  }
`;

export const GoalSubtitle = styled.p`
  ${font.size(13)};
  color: ${color.textMedium};
`;

export const GoalTitleSubtitle = styled.p`
  ${font.size(12)};
  ${font.regular};
  color: ${color.textMedium};
  margin-left: 5px;
`;

export const GoalIcon = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${mixin.clickable};
  height: 36px;
  min-height: 36px;
  max-height: 36px;
  width: 36px;
  min-width: 36px;
  max-width: 36px;
  ${font.size(14)};
  ${font.medium};
  border-radius: 5px;
  color: #fff;
  background-color: ${(props) => props.color};
`;