import React, { FunctionComponent, ReactElement, useMemo } from 'react';
import {
  FieldContainer,
  FieldLabel,
  FieldErrorIcon,
  FieldHeader,
} from './Styles';
import Tooltip from '../Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { color } from 'shared/utils/styles';

export interface FieldWrapperProps {
  label?: string;
  error?: string;
  labelOnRight?: boolean;
  horizontal?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
}

const FieldWrapper: FunctionComponent<FieldWrapperProps> = ({
  children,
  label,
  error,
  labelOnRight = false,
  horizontal = false,
  fullWidth = false,
  style,
}) => {
  const child = children as ReactElement;
  const fieldControl = React.cloneElement(child, {
    ...child.props,
    style:
      !label && error
        ? { ...child.props.style, borderColor: color.danger }
        : child.props.style,
  });

  const header = useMemo(() => {
    return (
      <FieldHeader labelOnRight={labelOnRight}>
        {label && <FieldLabel>{label}</FieldLabel>}
        {error && typeof error == 'string' ? (
          <Tooltip content={error}>
            <FieldErrorIcon>
              <FontAwesomeIcon icon={['fal', 'exclamation-circle']} />
            </FieldErrorIcon>
          </Tooltip>
        ) : (
          error && (
            <FieldErrorIcon>
              <FontAwesomeIcon icon={['fal', 'exclamation-circle']} />
            </FieldErrorIcon>
          )
        )}
      </FieldHeader>
    );
  }, [labelOnRight, label, error]);

  const headerFirst = useMemo(() => {
    return (label && !horizontal) || (label && horizontal && !labelOnRight);
  }, [label, horizontal, labelOnRight]);

  return (
    <FieldContainer horizontal={horizontal} labelOnRight={labelOnRight} fullWidth={fullWidth} style={style}>
      {headerFirst && label && header}
      {fieldControl}
      {!headerFirst && label && header}
    </FieldContainer>
  );
};

export default FieldWrapper;
