import React, { Component } from 'react';
import styled from 'styled-components';
import { DatePicker } from 'shared/components';
import { parse, format } from 'date-fns';
import { ICellEditorParams } from 'ag-grid-community';

type DateCellEditorParams = { 
  formatString?: string;
};

const defaultFormatString = "yyyy-MM-dd";

export default class DateCellEditor extends Component<ICellEditorParams & DateCellEditorParams> {

  state = {
    value: this.props.value,
  }

  componentDidUpdate = (prevProps: ICellEditorParams) => {
    if(prevProps.value !== this.props.value) {
      this.setState({ value: prevProps.value })
    }
  }

  getValue() {
    return this.state.value;
  }

  isPopup() {
    return true;
  }

  onChange = (date: Date | null) => {
    if (date) {
      this.setState({ value: date }, () => this.props.api?.stopEditing());
    }
  }

  render() {
    const val = this.state.value || undefined;
    return (
      <Container>
        <DatePicker 
          onChange={this.onChange}
          selected={val}
          inline={true}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
`