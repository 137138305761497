import { useAppContext } from "App";
import EvaluationsList from "App/Organization/shared/Evaluations/EvaluationsList";
import React, { FunctionComponent } from "react";
import { usePageTitle } from "shared/hooks";

interface CurrentEvaluationsProps {}

const CurrentEvaluations: FunctionComponent<CurrentEvaluationsProps> = () => {
  usePageTitle('My Evaluation Center');
  const appContext = useAppContext();
  return (
    <React.Fragment>
      <EvaluationsList employeeId={appContext.currentUserId} pageTitle={""}/>
    </React.Fragment>
  );
};

export default CurrentEvaluations;
