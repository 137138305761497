import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import CountUp from 'react-countup';

import { color, mixin } from 'shared/utils/styles';

export type CardSelectedStatus = 'normal' | 'selected' | 'subdued';

interface CardProps {
  title: string;
  subtitle?: string;
  value?: number;
  prefix?: string;
  suffix?: string;
  secondValue?: number;
  secondPrefix?: string;
  secondSuffix?: string;
  separator?: string;
  onClick?: () => void;
  duration?: number;
  animate?: boolean;
  selectedStatus?: CardSelectedStatus;
}

const NormalCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${color.backgroundLightest};
  padding: 1.5rem;
  height: 5rem;
`;

const SelectedCard = styled(NormalCard)`
  transform: scale(1.05);
`;

const SubduedCard = styled(NormalCard)`
  opacity: 0.8;
  color: grey;
  transform: scale(0.95);
`;

const CardText = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`;

const CardSubtitle = styled.div`
  font-size: 0.9rem;
  margin-top: 3px;
`;

const CardValue = styled.div`
  color: ${color.info};
  font-size: 3rem;
  font-weight: bold;
`;

const Tooltip: FunctionComponent<CardProps> = ({
  title,
  subtitle,
  value,
  prefix = '',
  suffix = '',
  secondValue,
  secondPrefix = '',
  secondSuffix = '',
  separator = ',',
  onClick,
  duration = 2,
  animate = true,
  selectedStatus = 'normal',
}) => {
  let finalCard = null;
  const content = (
    <React.Fragment>
      <CardText>
        {title && <CardTitle>{title}</CardTitle>}
        {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
      </CardText>
      <CardValue>
        {value && typeof value == 'number' && (
          <CountUp
            start={animate ? 0 : value}
            duration={duration}
            useEasing={true}
            end={value}
            prefix={prefix}
            suffix={suffix}
            separator={separator}
          />
        )}
        {secondValue ? ' / ' : null}
        {secondValue && typeof secondValue == 'number' && (
          <CountUp
            start={animate ? 0 : value}
            duration={duration}
            useEasing={true}
            end={secondValue}
            prefix={secondPrefix}
            suffix={secondSuffix}
            separator={separator}
          />
        )}
      </CardValue>
    </React.Fragment>
  );

  switch (selectedStatus) {
    case 'selected':
      finalCard = <SelectedCard onClick={onClick}>{content}</SelectedCard>;
      break;
    case 'subdued':
      finalCard = <SubduedCard onClick={onClick}>{content}</SubduedCard>;
      break;
    default:
      finalCard = <NormalCard onClick={onClick}>{content}</NormalCard>;
  }

  return finalCard;
};

export default Tooltip;
