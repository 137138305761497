import { useQuery, AnyQueryKey, QueryFunction, QueryOptions } from 'react-query';
import * as queries from '../client/queries';
import { GraphQLError } from 'graphql-request/dist/src/types';

// Queries

export interface GraphQLErrorWithExtensions extends GraphQLError {
  extensions?: any;
}

export interface GraphQLResult<TDataResult> {
  data?: TDataResult;
  extensions?: any;
  headers: Headers;
  status: number;
  errors?: GraphQLErrorWithExtensions[];
}

function useQueryWithResultSplitting<TResult, TKey extends AnyQueryKey>(
  queryKey:
    | TKey
    | false
    | null
    | undefined
    | (() => TKey | false | null | undefined),
  queryFn: QueryFunction<GraphQLResult<TResult>, TKey>,
  config?: QueryOptions<GraphQLResult<TResult>>
) {
  const result = useQuery(queryKey, queryFn, config);
  const splitResult = { ...result, status: result.status, data: result.data?.data, errors: result.data?.errors}
  return splitResult;
}

export const useGetCurrentUserOrganization = (config?: any) => {
  return useQueryWithResultSplitting(['currentUserOrganization'], queries.getCurrentUserOrganization, config);
};

export const useGetCurrentUser = (config?: any) => {
  return useQueryWithResultSplitting(['currentUser'], queries.getCurrentUser, config);
};

export const useGetDirectory = (userId: number, config?: any) => {
  return useQueryWithResultSplitting(['directory', userId], queries.getDirectory, config);
};

export const useGetDirectReports = (userId: number, config?: any) => {
  return useQueryWithResultSplitting(['directReports', userId], queries.getDirectReports, config);
};

export const useGetDirectoryUser = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['directoryUser', id], queries.getDirectoryUser, config);
};

export const useGetDirectoryUserGoals = (userId: number, config?: any) => {
  return useQueryWithResultSplitting(['userGoals', userId], queries.getDirectoryUserGoals, config);
};

export const useGetEvaluation = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['evaluation', id], queries.getEvaluation, config);
};

export const useGetGoal = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['goal', id], queries.getGoal, config);
};

export const useGetEvaluationCycles = (config?: any) => {
  return useQueryWithResultSplitting(['evaluationCycles'], queries.getEvaluationCycles, config);
};

export const useGetLatestEvaluationCycles = (config?: any) => {
  return useQueryWithResultSplitting(['latestEvaluationCycles'], queries.getLatestEvaluationCycles, config);
};

export const useGetGoalStatusOptions = (config?: any) => {
  return useQueryWithResultSplitting(['goalStatuses'], queries.getGoalStatusOptions, config);
};

export const useGetCompanies = (config?: any) => {
  return useQueryWithResultSplitting(['companies'], queries.getCompanies, config);
};

export const useGetEmployeeRoles = (config?: any) => {
  return useQueryWithResultSplitting(['employeeRoles'], queries.getEmployeeRoles, config);
};

export const useGetEditableEmployeeRoles = (config?: any) => {
  return useQueryWithResultSplitting(['editableEmployeeRoles'], queries.getEditableEmployeeRoles, config);
};

export const useGetEmployeeRole = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['employeeRoles', id], queries.getEmployeeRole, config);
};

export const useGetDepartments = (config?: any) => {
  return useQueryWithResultSplitting(['departments'], queries.getDepartments, config);
};

export const useGetEvaluationSummary = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['evaluationSummary', id], queries.getEvaluationSummary, config);
};

export const useGetSelfEvaluation = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['selfEvaluation', id], queries.getSelfEvaluation, config);
};

export const useGetManagerEvaluation = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['managerEvaluation', id], queries.getManagerEvaluation, config);
};

export const useGetPeerRequest = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['peerRequest', id], queries.getPeerRequest, config);
};

export const useGetEvaluationCycleEvaluationSummaries = (id: number, config?: any) => {
  return useQueryWithResultSplitting(['evaluationCycleSummary', id], queries.getEvaluationCycleEvaluationSummaries, config);
};

export const useGetPeerRequestDenyReasons = (config?: any) => {
  return useQueryWithResultSplitting(['peerRequestDenyReasons'], queries.getPeerRequestDenyReasons, config);
};