import React, { PropsWithChildren } from 'react';
import { Modal, Button, PageDetails } from 'shared/components';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { PartialNullable } from 'shared/utils/utilityTypes';

interface BaseFormModalProps<T extends object> {
  onClose: () => void;
  handleSubmit: (form: T, resetForm: any) => void;
  isWorking: boolean;
  title: string;
  tools?: React.ReactNode[];
  titleTools?: React.ReactNode[];
  slim?: boolean;
  initialValues?: PartialNullable<T>;
  submitTitle?: string;
  validationSchema: Yup.ObjectSchema<T>;
  children: (props: FormikProps<PartialNullable<T>>) => React.ReactNode;
  size?: "sm" | 'lg' | 'xl';
  showButton?: boolean;
}

type FormModalProps<T extends object> = PropsWithChildren<
  BaseFormModalProps<T>
>;

export function FormModal<T extends object>({
  onClose,
  handleSubmit,
  isWorking,
  title,
  tools = [],
  titleTools = [],
  slim = false,
  initialValues = {},
  validationSchema,
  submitTitle = 'Submit',
  size = 'sm',
  showButton = true,
  children,
}: FormModalProps<T>) {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values as T, resetForm);
      }}
      validationSchema={validationSchema}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit} autoComplete={'false'}>
          <Modal
            title={title}
            tools={tools}
            titleTools={titleTools}
            slim={slim}
            isOpen={true}
            onClose={onClose}
            size={size}
            footerTools={[
              showButton ? (
                <Button
                  key={'contact-save'}
                  onClick={props.submitForm}
                  color={'success'}
                  isWorking={isWorking}
                  disabled={!props.dirty || !props.isValid}
                >
                  {submitTitle}
                </Button>
              ) : null,
            ]}
          >

            <PageDetails>{children(props)}</PageDetails>
          </Modal>
        </form>
      )}
    </Formik>
  );
}

interface BaseFormProps<T extends object> {
  handleSubmit: (form: T, resetForm: any) => void;
  initialValues?: PartialNullable<T>;
  validationSchema: Yup.ObjectSchema<T>;
  children: (props: FormikProps<PartialNullable<T>>) => React.ReactNode;
  style?: React.CSSProperties;
}

type FormProps<T extends object> = PropsWithChildren<BaseFormProps<T>>;

export function Form<T extends object>({
  handleSubmit,
  initialValues = {},
  validationSchema,
  children,
  style,
}: FormProps<T>) {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values as T, resetForm);
      }}
      validationSchema={validationSchema}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit} autoComplete={'false'} style={{ ...style, width: '100%', height: '100%' }}>
          {children(props)}
        </form>
      )}
    </Formik>
  );
}
