import React, { FunctionComponent } from "react";
import Button, { ButtonColor } from "./Button";
import styled from "styled-components";

export type ButtonGroupOption = {
  label: string;
  value: string;
};

export interface ButtonGroupProps {
  selected?: ButtonGroupOption;
  options: ButtonGroupOption[];
  color: ButtonColor;
  onChange: (selected: ButtonGroupOption) => void;
}

const getBorderRadius = (index: number, length: number): [number, number, number, number] => {
  if (index === 0) {
    return [4, 0, 0, 4];
  } else if (index === length - 1) {
    return [0, 4, 4, 0];
  } else {
    return [0, 0, 0, 0];
  }
};

const SytledButtonGroup = styled.div`
  width: 100%;
`

const ButtonGroup: FunctionComponent<ButtonGroupProps> = ({
  options,
  selected,
  color,
  onChange,
}) => {
  return (
    <SytledButtonGroup>
      {options.map((opt, index) => (
        <Button
          key={opt.value}
          isActive={selected ? opt.value == selected.value : false}
          variant="standard"
          color={color}
          borderRadius={getBorderRadius(index, options.length)}
          onClick={() => onChange(opt)}
        >
          {opt.label}
        </Button>
      ))}
    </SytledButtonGroup>
  );
};

export default ButtonGroup;
