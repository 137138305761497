import React, { FunctionComponent, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { PageHeader, PageHeaderProps, Tab } from '../pageContent';
import Button from '../button/Button';
import { color } from 'shared/utils/styles';
import { PageDetails } from '..';

type ModalSize = 'sm' | 'lg' | 'xl' | 'fullscreen';

export interface TemplateModalProps extends PageHeaderProps {
  isOpen: boolean; //determines if modal is open
  onClose: () => void; //method used to close the modal
  footerTools?: ReactNode[]; //components used as tools
  size?: ModalSize; //see below, limits size when true
  hideCloseButton?: boolean;
  style?: React.CSSProperties;
}

const ModalContainer = styled.div<{ size: ModalSize }>`
  display: flex;
  background-color: white;
  flex-direction: column;
  min-width: ${props => props.size == 'sm' ? '500px' : props.size == 'lg' ? '900px' : '85%'};
  ${props => props.size == 'xl' && `width: 100%;`}
  max-width: ${props => props.size == 'sm' ? '500px' : props.size == 'lg' ? '900px' : props.size == 'fullscreen' ? '100%' : '85%'};
  background-color: white;
  z-index: 1031;
  max-height: 100%;
  ${props => props.size == 'fullscreen' && `height: 100%;`}
  margin: auto;
`;

const ModalFooter = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 15px;
  border-top: 1px solid ${color.border};

  & > * {
    margin-left: 5px;
  }
`;

const TemplateModal: FunctionComponent<TemplateModalProps> = ({
    children,
    title,
    subtitle,
    isOpen,
    onClose,
    description = '',
    titleTools,
    tools = [],
    footerTools = [],
    tabs = [],
    size = 'lg',
    hideCloseButton = false,
    slim = false,
    style,
    ...props
  }) => {
  const finalTools = useMemo(() => {
    if (!hideCloseButton == true) {
      return [...tools, 
        <Button
          variant="simple"
          color="secondary"
          onClick={onClose}
          icon="times"
          key={"modal-close-button"}
          slim={slim}
        />
      ]
    } else {
      return tools; 
    }
  }, [tools])
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Modal"
      closeTimeoutMS={300}
      className={"Modal__Content"}
      overlayClassName={"Modal__Overlay"}
    >
      <ModalContainer size={size} style={style}>
        <PageHeader
          title={title}
          description={description}
          titleTools={titleTools}
          tools={finalTools}
          tabs={tabs}
          modal={true}
          slim={slim}
          subtitle={subtitle}
          {...props}
        />
        {children}
        <ModalFooter>{footerTools}</ModalFooter>
      </ModalContainer>

    </Modal>
  );
};

export default TemplateModal;
