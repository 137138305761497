import React, { FunctionComponent, useMemo } from 'react';
import { Button, FormModal } from 'shared/components';
import { useNotification } from 'shared/components/notifications';
import { useGetEmployeeRole, useUpdateEmployeeRole } from 'shared/hooks/api';
import {
  ErrorFragment,
  UpdateEmployeeRoleInput,
} from 'shared/hooks/api/graphql/generated';
import EmployeeRoleModalForm from './EmployeeRoleModal';
import { useAppContext } from 'App';
import {
  employeeRoleFormSchema,
  EmployeeRoleFormValues,
} from './NewEmployeeRoleModal';
import { CenteredSpinner } from 'shared/components/spinner/Spinner';
import { PartialNullable } from 'shared/utils/utilityTypes';
import NoAccess from 'shared/components/noAccess';
import { createQueryParamModalHelpers } from 'shared/utils/queryParamModal';
import EmployeeRoleAccessModal from './EmployeeRoleAccessModal';

interface UpdateEmployeeRoleModalProps {
  id: number;
  onClose: () => void;
  onSuccess: () => void;
}

const UpdateEmployeeRoleModal: FunctionComponent<UpdateEmployeeRoleModalProps> = ({
  id,
  onClose,
  onSuccess,
}) => {
  const notify = useNotification();
  const { currentUserId } = useAppContext();
  const [updateEmployeeRole, { status }] = useUpdateEmployeeRole({
    throwOnError: true,
  });

  const { data, status: getEmployeeRoleStatus } = useGetEmployeeRole(id);

  const initialValues: PartialNullable<EmployeeRoleFormValues> = useMemo(() => {
    return {
      name: data?.employeeRole?.name,
      missionStatement: data?.employeeRole?.missionStatement,
      managerRole: data?.employeeRole?.managerRole
        ? {
            value: data.employeeRole.managerRole.id,
            label: data.employeeRole.managerRole.name,
          }
        : null,
      qualifications:
        data?.employeeRole?.qualifications.map((x) => x.name) ?? [],
      competencies: data?.employeeRole?.competencies.map((x) => ({
        name: x.name,
        category: x.category,
      })),
      objectives: data?.employeeRole?.objectives?.map((x) => ({
        name: x.name,
        keyResults: x.keyResults,
      })),
    };
  }, [data]);

  const manageContributorsModalHelpers = createQueryParamModalHelpers('manage-contributors');

  return (
    <>
      {!data && getEmployeeRoleStatus == 'loading' ? (
        <CenteredSpinner />
      ) : (
        <FormModal<EmployeeRoleFormValues>
          title={'Update Role Scorecard'}
          submitTitle={'Update'}
          initialValues={initialValues}
          tools={[
            <Button key={'manage-contributors'} onClick={manageContributorsModalHelpers.open} variant={'outline'} icon={'user-lock'}>
              Manage Contributors
            </Button>
          ]}
          handleSubmit={async (form, resetForm) => {
            const input: UpdateEmployeeRoleInput = {
              id: id,
              name: form.name,
              missionStatement: form.missionStatement,
              managerRoleId: form.managerRole?.value,
              objectives:
                form.objectives?.map((x) => ({
                  name: x.name,
                  keyResults: x.keyResults ?? [],
                })) ?? [],
              qualifications: form.qualifications ?? [],
              competencies:
                form.competencies?.map((x) => ({
                  name: x.name,
                  category: x.category ?? '',
                })) ?? [],
            };
            try {
              const result = await updateEmployeeRole(input);

              if (result.data?.updateEmployeeRole) {
                switch (result.data.updateEmployeeRole?.__typename) {
                  case 'EmployeeRole': {
                    notify({
                      duration: 3000,
                      title: 'Success!',
                      message: 'Role Scorecard Updated!',
                      variant: 'success',
                    });
                    resetForm();
                    onClose();
                    onSuccess();
                    break;
                  }
                  default: {
                    notify({
                      duration: 7000,
                      title: 'Failed to update role scorecard',
                      message: (result.data.updateEmployeeRole as ErrorFragment)
                        .message,
                      variant: 'danger',
                    });
                    break;
                  }
                }
              } else if (result.errors) {
                const forbiddenError = result.errors.find(
                  (x: any) => x?.extensions?.code == 'FORBIDDEN'
                );
                if (forbiddenError) {
                  notify({
                    duration: 5000,
                    title: 'Permission Denied',
                    variant: 'danger',
                    message: forbiddenError?.message,
                  });
                } else {
                  throw Error();
                }
              } else {
                throw Error();
              }
            } catch (error) {
              notify({
                duration: 5000,
                title: 'Yikes!',
                variant: 'danger',
                message:
                  'An unknown error occurred while updating the role scorecard!',
              });
            }
          }}
          isWorking={status == 'loading'}
          onClose={onClose}
          validationSchema={employeeRoleFormSchema}
          size={'lg'}
        >
          {(props) => (
            <>
              {getEmployeeRoleStatus == 'loading' ? (
                <CenteredSpinner />
              ) : !data?.employeeRole?.currentUserCanEdit ? (
                <NoAccess
                  icon={'user-lock'}
                  title={'Permission Denied'}
                  subtitle={`You don't have permission to edit this role scorecard.`}
                />
              ) : (
                <EmployeeRoleModalForm form={props} />
              )}
            </>
          )}
        </FormModal>
      )}
      {manageContributorsModalHelpers.isOpen() && (
        <EmployeeRoleAccessModal employeeRoleId={id} currentUserCanEdit={data?.employeeRole?.currentUserCanEdit ?? false} onClose={manageContributorsModalHelpers.close}/>
      )}
    </>
  );
};

export default UpdateEmployeeRoleModal;
