import React, { FunctionComponent } from 'react';
import {
  FormModal,
} from 'shared/components';
import * as Yup from 'yup';
import { useNotification } from 'shared/components/notifications';
import { useCreateUser } from 'shared/hooks/api';
import { CreateUserInput, ErrorFragment } from 'shared/hooks/api/graphql/generated';
import UserModalForm from './UserModal';
import { useAppContext } from 'App';

interface NewUserModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

type Option = {
  value: number;
  label: string;
}

export type UserFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  title?: string | null;
  manager?: Option | null;
  employeeRole?: Option | null;
  company: Option;
  department: Option;
};

export const userFormSchema: Yup.ObjectSchema<UserFormValues> = Yup.object()
  .shape({
    firstName: Yup.string().required().label('First Name'),
    lastName: Yup.string().required().label('Last Name'),
    email: Yup.string().required().label('Email'),
    title: Yup.string().nullable().label('Title'),
    manager: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .notRequired()
      .label('Manager'),
    employeeRole: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .nullable()
      .notRequired()
      .label('Role Scorecard'),
    company: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .required()
      .label('Company'),
    department: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .required()
      .label('Department'),
  })
  .required();

const NewUserModal: FunctionComponent<NewUserModalProps> = ({
  onClose,
  onSuccess,
}) => {
  const notify = useNotification();
  const { currentUserId } = useAppContext();
  const [createUser, { status }] = useCreateUser(currentUserId, { throwOnError: true });
  return (
    <FormModal<UserFormValues>
      title={'Create New User'}
      submitTitle={'Create'}
      initialValues={{ manager: null, employeeRole: null }}
      handleSubmit={async (form, resetForm) => {
        const input: CreateUserInput = {
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          title: form.title,
          employeeRoleId: form.employeeRole?.value,
          managerId: form.manager?.value,
          companyId: form.company.value,
          departmentId: form.department.value,
        } 
        try {
          const result = await createUser(input);
          
          if (result.data?.createUser) {
            switch (result.data.createUser?.__typename) {
              case "User": {
                notify({
                  duration: 3000,
                  title: 'Success!',
                  message: 'User Created!',
                  variant: 'success',
                });
                resetForm();
                onClose()
                onSuccess();
                break;
              }
              case "UserAlreadyExists": {
                notify({
                  duration: 7000,
                  title: 'User already exists!',
                  variant: 'danger',
                  message: result.data.createUser.message,
                });
                break;
              }
              default: {
                notify({
                  duration: 7000,
                  title: 'Failed to create user',
                  message: (result.data.createUser as ErrorFragment).message,
                  variant: 'danger',
                });
                break;
              }
            }
          } else if (result.errors) {
            const forbiddenError = result.errors.find((x: any) => x?.extensions?.code == "FORBIDDEN")
            if (forbiddenError) {
              notify({
                duration: 5000,
                title: 'Permission Denied',
                variant: 'danger',
                message: forbiddenError?.message,
              });
            } else {
              throw Error();
            }
          } else {
            throw Error()
          }
        } catch (error) {
          notify({
            duration: 5000,
            title: 'Yikes!',
            variant: 'danger',
            message: 'An unknown error occurred while creating the user!',
          });
        }
      }}
      isWorking={status == 'loading'}
      onClose={onClose}
      validationSchema={userFormSchema}
    >
      {() => (
        <UserModalForm />
      )}
    </FormModal>
  );
};

export default NewUserModal;
