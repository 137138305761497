import React, { FunctionComponent, useMemo } from 'react';
import { Button } from 'shared/components';
import { ButtonProps } from 'shared/components/button/Button';
import { ReviewStatus } from 'shared/constants';

interface ReviewButtonProps extends ButtonProps {
  status?: { id: number; name: string };
}

const ReviewButton: FunctionComponent<ReviewButtonProps> = ({
  status,
  disabled = false,
  ...props
}) => {
  const title = useMemo(() => {
    if (disabled) {
      return status?.name ?? 'In Progress';
    }
    switch (status?.id) {
      case ReviewStatus.Completed:
        return "View Completed"
      case ReviewStatus.InProgress:
        return "Continue";
      default:
        return "Start";
    }
  }, [status, disabled]);
  const color = useMemo(() => {
    switch (status?.id) {
      case ReviewStatus.Completed:
        return "success"
      default:
        return "primary";
    }
  }, [status]);
  
  const variant = useMemo(() => {
    switch (status?.id) {
      case ReviewStatus.Completed:
        return "outline"
      default:
        return "standard";
    }
  }, [status]);
  return (
    <Button {...props} disabled={disabled} color={color} variant={variant} style={{ width: '120px' }}>
      {title}
    </Button>
  )
}

export default ReviewButton;