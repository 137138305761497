import React, { FunctionComponent } from "react";
import { HorizontalSeparator } from "shared/components";
import { ButtonProps } from "shared/components/button/Button";
import { color, font } from "shared/utils/styles";
import styled from "styled-components";


interface ActionItemProps extends ButtonProps {
  title: string;
  subtitle: string;
  right: React.ReactNode;
}

const ActionItem: FunctionComponent<ActionItemProps> = ({
  title,
  subtitle,
  right,
}) => {
  return (
    <React.Fragment>
      <ActionItemWrapper>
        <ActionItemTitleWrapper>
          <ActionItemTitle>{title}</ActionItemTitle>
          <ActionItemSubitle>{subtitle}</ActionItemSubitle>
        </ActionItemTitleWrapper>
        {right}
      </ActionItemWrapper>
      <HorizontalSeparator margin={5} />
    </React.Fragment>
  );
};

export default ActionItem;

const ActionItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: space-between;

  & > div {
    ${font.medium};
  }
`;

const ActionItemTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 5px;
  }
`;

const ActionItemTitle = styled.div`
  ${font.medium};
`;

const ActionItemSubitle = styled.div`
  ${font.regular};
  ${font.size(12)};
  color: ${color.textLight};
`;