import React, { FunctionComponent, useState, useMemo, Suspense } from 'react';
import {
  Route,
  useRouteMatch,
  Switch,
  Redirect,
  useParams,
} from 'react-router-dom';
import { useBreadcrumb, useWindowSize } from 'shared/hooks';
import {
  GraphQLErrorWithExtensions,
  useGetCurrentUser,
} from 'shared/hooks/api';
import { Button, PageWrapper } from 'shared/components';
import { useAuthModule } from 'shared/auth/msal';
import { SidebarContext } from 'shared/utils/sidebar/sidebar';
import Navbar from './Navbar';
import HomeSidebar from './Sidebar';
import PerformanceReview from './PerformanceReview';
import Directory from './Directory';
import CurrentUserEvaluations from './Evaluations';
import CurrentUserGoals from './Goals';
import Home from './Home';
import { font, color } from 'shared/utils/styles';
import styled from 'styled-components';
import { AppContext } from 'App';
import { NotRegistered } from 'App/Routes';
import Confetti from 'react-confetti';
import EmployeeRoleDetails from './Directory/EmployeeRoles/Details';
import BenefitsChat from './WolffAI/BenefitsChat';

const Organization: FunctionComponent<any> = () => {
  const match = useRouteMatch();
  const { orgName } = useParams<{ orgName: string }>();
  const { data, errors } = useGetCurrentUser();

  const notRegisteredError = useMemo(() => {
    const error = errors?.find((x) => x.extensions?.code == 'NOT_FOUND');
    return error;
  }, [errors]);

  const notAuthorizedError = useMemo(() => {
    const error = errors?.find(
      (x) =>
        x.extensions?.code == 'VALIDATION_ERROR' &&
        x.extensions?.number == 'authorization'
    );
    return error;
  }, [errors]);

  const org = useMemo(() => {
    const org = data?.currentUser.organization;
    return org;
  }, [data]);

  useBreadcrumb(
    {
      title: org ? org.name : 'Access Denied',
      path: match.url,
      key: 'orgName',
      index: 0,
    },
    [org?.name]
  );

  const [searchText, setSearchText] = useState('');

  const [isMainSidebarOpen, setIsMainSidebarOpen] = useState<
    boolean | undefined
  >(true);
  const [isNestedSidebarOpen, setIsNestedSidebarOpen] = useState<
    boolean | undefined
  >(undefined);

  const [isShowingConfetti, setIsShowingConfetti] = useState(false);
  const { width, height } = useWindowSize();

  return (
    <React.Fragment>
      {notRegisteredError ? (
        <NotRegistered />
      ) : notAuthorizedError ? (
        <AccessDenied />
      ) : (
        data?.currentUser.profile && (
          <AppContext.Provider
            value={{
              currentUserId: data.currentUser.profile.id,
              currentUserName: data.currentUser.profile.displayName,
              celebrate: () => setIsShowingConfetti(true),
            }}
          >
            <SidebarContext.Provider
              value={{
                isMainSidebarOpen: isMainSidebarOpen || false,
                isNestedSidebarOpen,
                setIsNestedSidebarOpen,
                setIsMainSidebarOpen,
              }}
            >
              <PageWrapper
                isMainSidebarOpen={isMainSidebarOpen || false}
                isNestedSidebarOpen={isNestedSidebarOpen}
              >
                <Navbar
                  isSidebarOpen={isMainSidebarOpen || false}
                  setIsSidebarOpen={setIsMainSidebarOpen}
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
                <HomeSidebar />
                <Suspense fallback={<div>Loading...</div>}>
                  <Switch>
                    <Route path={`/:orgName/home`} render={() => <Home />} />
                    <Route
                      path={`/:orgName/directory`}
                      render={() => <Directory />}
                    />
                    <Route
                      path={`/:orgName/employee-roles/:employeeRoleId`}
                      render={() => <EmployeeRoleDetails />}
                    />
                    <Route
                      path={`/:orgName/goals`}
                      render={() => <CurrentUserGoals />}
                    />
                    <Route
                      path={`/:orgName/evaluations`}
                      render={() => <CurrentUserEvaluations />}
                    />
                    <Route
                      path={`/:orgName/performance-reviews`}
                      render={() => <PerformanceReview />}
                    />
                    {/* <Route
                      path={`/:orgName/benefits-chat`}
                      render={() => <BenefitsChat />}
                    /> */}
                    <Redirect to={`${match.url}/home`} />
                  </Switch>
                </Suspense>
              </PageWrapper>
              {isShowingConfetti && (
                <div style={{ zIndex: 1000 }}>
                  <Confetti initialVelocityY={-5} width={width} height={height} numberOfPieces={300} recycle={false} onConfettiComplete={() => setIsShowingConfetti(false)}/>
                </div>
                )}
            </SidebarContext.Provider>
          </AppContext.Provider>
        )
      )}
    </React.Fragment>
  );
};

export const AccessDenied = () => {
  const auth = useAuthModule();
  return (
    <FullScreenMessage
      title={'401 - Access Denied'}
      message={
        'Your account does not have access to this resource or it does not exist. Verify that you are indeed accessing the correct resource, and if that is the case reach out to your HR department to request access.'
      }
      accessory={
        <Button variant={'outline'} onClick={auth.logout} color={'primary'}>
          Use a different account (Sign Out)
        </Button>
      }
    />
  );
};

const ErrorCodesWrapper = styled.div`
  display: flex;
  color: ${color.textLight};
  ${font.medium};
  ${font.size(12)};
  text-transform: uppercase;
`;

interface ErrorCodesProps {
  errors: GraphQLErrorWithExtensions[];
}

export const ErrorCodes: FunctionComponent<ErrorCodesProps> = ({ errors }) => {
  const errorCodes: string[] = errors
    .map((error) => {
      return error.extensions?.code || null;
    })
    .filter((code) => code != null);
  return (
    <ErrorCodesWrapper>
      {`Error Codes: ${errorCodes.join(', ')}`}
    </ErrorCodesWrapper>
  );
};

interface FullScreenMessageProps {
  title: string;
  message: string;
  accessory?: React.ReactNode;
}

export const FullScreenMessage: FunctionComponent<FullScreenMessageProps> = ({
  title,
  message,
  accessory,
}) => {
  return (
    <FullScreenMessageWrapper>
      <FullScreenMessageContent>
        <FullScreenMessageHeader>{title}</FullScreenMessageHeader>
        <FullScreenMessageBody>{message}</FullScreenMessageBody>
        <FullScreenMessageFooter>{accessory}</FullScreenMessageFooter>
      </FullScreenMessageContent>
    </FullScreenMessageWrapper>
  );
};

const FullScreenMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-top: 300px;
`;

const FullScreenMessageContent = styled.div`
  display: flex;
  max-width: 500px;
  flex-direction: column;
  & > * {
    margin-bottom: 10px;
  }
`;

const FullScreenMessageHeader = styled.h1`
  ${font.size(25)};
  ${font.bold};
  color: ${color.textDarkest};
`;

const FullScreenMessageBody = styled.p`
  ${font.size(14)};
  ${font.regular};
  color: ${color.textMedium};
  margin-bottom: 30px;
`;

const FullScreenMessageFooter = styled.p`
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: 10px;
  }
`;

export default Organization;
