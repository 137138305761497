import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GoalIcon,
  GoalsRow,
  GoalSubtitle,
  GoalTitle,
  GoalTitles,
  GoalsRowLeft,
  GoalsListWrapper,
} from 'App/Organization/shared/Goals';
import EmptyDataMessage from 'App/Organization/shared/NoData';
import { FullWidthSpinner } from 'App/Routes';
import React, { FunctionComponent, useMemo } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { PageDetails, Spinner } from 'shared/components';
import { usePageTitle, useRemainingHeight } from 'shared/hooks';
import { useGetDirectoryUserGoals } from 'shared/hooks/api';
import { color } from 'shared/utils/styles';

interface EvaluationsListProps {
  employeeId: number;
  pageTitle: string;
}

const EvaluationsList: FunctionComponent<EvaluationsListProps> = ({
  employeeId,
  pageTitle,
}) => {
  usePageTitle(`${pageTitle} - Evaluations`);
  const { data, status, refetch, errors } = useGetDirectoryUserGoals(Number(employeeId));

  const { orgName } = useParams<{ orgName: string }>();
  const { height, ref } = useRemainingHeight();
  const history = useHistory();
  return (
    <React.Fragment>
      <PageDetails ref={ref}>
        {!data?.evaluations && errors ? (
          <React.Fragment>
            <EmptyDataMessage
              title={'You do not have access to this users goals.'}
              subtitle={`We could not show you this users goals because you don't have the right permissions to view them.`}
              icon={'user-lock'}
            />
          </React.Fragment>
        ) : !data?.evaluations && status == 'loading' ? (
          <FullWidthSpinner minHeight={300} />
        ) : data?.evaluations?.length == 0 ? (
          <EmptyDataMessage
            icon={'list-ul'}
            title={'No Active Evaluation Sets'}
            actionIcon={'list-ul'}
          />
        ) : (
          <GoalsListWrapper>
            {data?.evaluations?.map((evaluation, index) => (
              <GoalsRow
                key={index}
                last={true}
                onClick={() => {
                  history.push(`/${orgName}/evaluations/${evaluation.id}`);
                }}
              >
                <GoalsRowLeft>
                  <GoalIcon color={color.success}>
                    <FontAwesomeIcon icon={['fal', 'list-ul']} />
                  </GoalIcon>
                  <GoalTitles>
                    <GoalTitle>{`Evaluation: ${evaluation.evaluationCycle.name}`}</GoalTitle>
                    <GoalSubtitle>{`Status: ${evaluation.status?.name ?? 'In Progress'}`}</GoalSubtitle>
                  </GoalTitles>
                </GoalsRowLeft>
              </GoalsRow>
            ))}
          </GoalsListWrapper>
        )}
      </PageDetails>
    </React.Fragment>
  );
};

export default EvaluationsList;
