import {
  CreateEmployeeRoleInput,
  CreateEvaluationInput,
  CreateGoalInput,
  CreatePeerRequestInput,
  CreateStrengthOrOpportunityInput,
  CreateUserInput,
  ReviewClassificationEnum,
  UpdateEmployeeRoleInput,
  UpdateGoalInput,
  UpdateReviewValueInput,
  UpdateStrengthOrOpportunityInput,
  UpdateUserInput,
} from '../graphql/generated';
import { getAuthorizedGraphqlClient } from './queries';

export async function createEvaluation(evaluation: CreateEvaluationInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateEvaluation({ evaluation });
  return data;
}

export async function createGoal(goal: CreateGoalInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateGoal({ goal });
  return data;
}

export async function updateGoal(goal: UpdateGoalInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateGoal({ goal });
  return data;
}

export async function deleteGoal(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteGoal({ id });
  return data;
}

export async function createUser(user: CreateUserInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateUser({ user });
  return data;
}

export async function updateUser(user: UpdateUserInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateUser({ user });
  return data;
}

export async function deleteUser(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteUser({ id });
  return data;
}

export async function createSelfEvaluation(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateSelfEvaluation({ id });
  return data;
}

export async function createManagerEvaluation(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateManagerEvaluation({ id });
  return data;
}

export async function createReviewOpportunity(
  opportunity: CreateStrengthOrOpportunityInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateReviewOpportunity({ opportunity });
  return data;
}

export async function updateReviewOpportunity(
  opportunity: UpdateStrengthOrOpportunityInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateReviewOpportunity({ opportunity });
  return data;
}

export async function deleteReviewOpportunity(
  id: number,
  reviewClassification: ReviewClassificationEnum
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteReviewOpportunity({
    id,
    reviewClassification,
  });
  return data;
}

export async function createReviewStrength(
  strength: CreateStrengthOrOpportunityInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateReviewStrength({ strength });
  return data;
}

export async function updateReviewStrength(
  strength: UpdateStrengthOrOpportunityInput
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateReviewStrength({ strength });
  return data;
}

export async function deleteReviewStrength(
  id: number,
  reviewClassification: ReviewClassificationEnum
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteReviewStrength({ id, reviewClassification });
  return data;
}

export async function updateReviewValue(value: UpdateReviewValueInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateReviewValue({ value });
  return data;
}

export async function submitEvaluationReview(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.SubmitEvaluationReview({ id });
  return data;
}

export async function unsubmitEvaluationReview(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UnsubmitEvaluationReview({ id });
  return data;
}

export async function submitValueReview(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.SubmitValueReview({ id });
  return data;
}

export async function unsubmitValueReview(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UnsubmitValueReview({ id });
  return data;
}

export async function submitObjectiveReview(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.SubmitObjectiveReview({ id });
  return data;
}

export async function createPeerRequest(request: CreatePeerRequestInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreatePeerRequest({ request });
  return data;
}

export async function acceptPeerRequest(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AcceptPeerRequest({ id });
  return data;
}

export async function rejectPeerRequest(id: number, reasonId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RejectPeerRequest({ id, reasonId });
  return data;
}

export async function cancelPeerRequest(id: number, reasonId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CancelPeerRequest({ id, reasonId });
  return data;
}

export async function deletePeerRequest(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeletePeerRequest({ id });
  return data;
}

export async function submitEvaluationForReview(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.SubmitEvaluationForReview({ id });
  return data;
}

export async function unsubmitEvaluationFromReview(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UnsubmitEvaluationFromReview({ id });
  return data;
}

export async function publishEvaluationForEmployeeReview(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.PublishEvaluationForEmployeeReview({ id });
  return data;
}

export async function requestEvaluationChanges(
  id: number,
  requestedChanges: string
) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RequestEvaluationChanges({ id, requestedChanges });
  return data;
}

export async function approveEvaluation(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.ApproveEvaluation({ id });
  return data;
}

export async function signEvaluation(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.SignEvaluation({ id });
  return data;
}

export async function createEmployeeRole(input: CreateEmployeeRoleInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.CreateEmployeeRole({ role: input });
  return data;
}

export async function updateEmployeeRole(input: UpdateEmployeeRoleInput) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.UpdateEmployeeRole({ role: input });
  return data;
}

export async function deleteEmployeeRole(id: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.DeleteEmployeeRole({ id: id });
  return data;
}

export async function addUsersToGroup(userId: number, groupId: number) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddUserToGroup({ userId, groupId });
  return data;
}

export async function addUsersToEmployeeRoleContributors(employeeRoleId: number, userIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.AddUsersToEmployeeRoleContributors({ employeeRoleId, userIds });
  return data;
}

export async function removeUsersFromEmployeeRoleContributors(employeeRoleId: number, userIds: number[]) {
  const client = await getAuthorizedGraphqlClient();
  const data = await client.RemoveUsersFromEmployeeRoleContributors({ employeeRoleId, userIds });
  return data;
}
