import GoalsList from "App/Organization/shared/Goals/GoalsList";
import React, { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { usePageTitle } from "shared/hooks";

interface CurrentGoalsProps {
  onSetNewGoals: () => void;
}

const Goals: FunctionComponent<CurrentGoalsProps> = ({
  onSetNewGoals,
}) => {
  usePageTitle("Employee - Goals");
  const { employeeId } = useParams<{ employeeId: string }>();
  return <GoalsList pageTitle={'Employee'} employeeId={Number(employeeId)} onSetNewGoals={onSetNewGoals}/>
};

export default Goals;