import React, {
  forwardRef,
  FunctionComponent,
  ReactElement,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useBreadcrumbs } from 'shared/hooks';
import { useAuthModule } from 'shared/auth/msal';
import { useGetCurrentUser } from 'shared/hooks/api';
import { Input, InputProps } from 'shared/components/form/input/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, MainNavbarWrapper, DropdownButton, DropdownItem } from 'shared/components';
import { MainNavbarLeft, MainNavbarRight, NavbarMenuIcon } from 'shared/components/navbar/Styles';
import { MenuSeparator } from 'shared/components/menu/DropdownButton';
import styled from 'styled-components';

interface MainNavbarProps {
  isSidebarOpen: boolean;
  toggleSidebarButton: ReactElement;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

// eslint-disable-next-line react/display-name
export const NavbarSearchInput = forwardRef<HTMLInputElement, InputProps>(
  ({ placeholder = 'Search...', ...props }, ref) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
      <Input
        ref={ref}
        {...props}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder}
        type={'search'}
        style={{
          width: isFocused ? '500px' : '200px',
          transition: 'all 200ms ease-in-out',
        }}
      />
    );
  }
);

const MainNavbar: FunctionComponent<MainNavbarProps> = ({
  isSidebarOpen,
  toggleSidebarButton,
  searchText,
  setSearchText,
}) => {
  const history = useHistory();
  const { breadcrumbs } = useBreadcrumbs();
  const auth = useAuthModule();

  const { data } = useGetCurrentUser();

  const userFullName = useMemo(() => {
    return data
      ? `${data.currentUser.profile.displayName}`
      : '';
  }, [data]);

  const crumbs = useMemo(() => {
    return breadcrumbs.map((crumb, index, components) => {
      const breadcrumb = (
        <BreadcrumbButton
          key={crumb.key}
          onClick={() => history.push(crumb.path)}
          icon={crumb.icon}
        >
          {crumb.title}
        </BreadcrumbButton>
      );

      if (components.length - 1 == index) {
        return breadcrumb;
      } else {
        return (
          <React.Fragment key={crumb.key}>
            {breadcrumb}
            <span style={{ padding: '0 5px' }}>
              <FontAwesomeIcon icon={['fal', 'chevron-right']} size={'sm'} />
            </span>
          </React.Fragment>
        );
      }
    });
  }, [breadcrumbs]);

  return (
    <MainNavbarWrapper isSidebarOpen={isSidebarOpen}>
      <MainNavbarLeft>
        {!isSidebarOpen && toggleSidebarButton}
        {crumbs}
      </MainNavbarLeft>
      <MainNavbarRight>
        <DropdownButton
          anchor={'right'}
          slim={true}
          variant={'simple'}
          icon={<NavbarMenuIcon icon={['fal', 'question-circle']} />}
        >
          <DropdownItem icon={'book'}>User Guide</DropdownItem>
        </DropdownButton>
        <DropdownButton
          anchor={'right'}
          element={<Avatar size={32} name={userFullName} />}
        >
          <MenuSeparator />
          <DropdownItem icon={'sign-out'} onClick={auth.logout}>
            Sign out
          </DropdownItem>
        </DropdownButton>
      </MainNavbarRight>
    </MainNavbarWrapper>
  );
};

const BreadcrumbButton = ({ ...props }) => {
  return <Button slim={true} variant={'simple'} {...props} />;
};

export default MainNavbar;
