import styled, { css } from 'styled-components';
import { ButtonProps, ButtonVariant, ButtonColor } from './Button';
import { font, color, mixin } from 'shared/utils/styles';

export const Text = styled.div<{
  padLeft?: boolean;
  padRight?: boolean;
  isWorking?: boolean;
}>`
  visibility: ${(props) => (props.isWorking ? 'hidden' : 'visible')};
  padding-left: ${(props) => (props.padLeft ? 5 : 0)}px;
  padding-right: ${(props) => (props.padRight ? 5 : 0)}px;
`;

interface RequiredButtonProps {
  variant: ButtonVariant;
  color: ButtonColor;
  isActive?: boolean;
  slim?: boolean;
  borderRadius?: [number, number, number, number];
  fullWidth?: boolean;
  disabled?: boolean;
  iconOnly?: boolean;
  isWorking?: boolean;
}

export const StyledButton = styled.button<RequiredButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) =>
    props.borderRadius
      ? `${props.borderRadius[0]}px ${props.borderRadius[1]}px ${props.borderRadius[2]}px ${props.borderRadius[3]}px`
      : `4px`};
  ${(props) => !props.slim && `height: 32px`};
  ${(props) => props.fullWidth && `width: 100%`};
  vertical-align: middle;
  line-height: 1;
  padding: ${(props) => (props.iconOnly ? '5px' : '5px 10px')};
  white-space: nowrap;
  transition: all 0.2s;
  ${font.defaultSize};
  appearance: none;
  ${mixin.clickable};
  ${(props) => buttonVariants[props.variant][props.color]};
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export const ButtonSpinnerContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
`;

const standard = css<RequiredButtonProps>`
  color: #fff;
  background: ${(props) => color[props.color]};
  border: 1px solid ${(props) => color[props.color]};
  ${font.medium}
  &:not(:disabled) {
    &:hover {
      background: ${(props) => mixin.lighten(color[props.color], 0.15)};
      border: 1px solid ${(props) => mixin.lighten(color[props.color], 0.15)};
    }
    &:active {
      background: ${(props) => mixin.darken(color[props.color], 0.2)};
      border: 1px solid ${(props) => mixin.lighten(color[props.color], 0.2)};
    }
    ${(props) =>
      props.isActive &&
      css`
        background: ${mixin.darken(color[props.color], 0.2)} !important;
        border: 1px solid ${mixin.darken(color[props.color], 0.2)} !important;
      `}
  }
`;

const outlined = css<RequiredButtonProps>`
  color: ${(props) => color[props.color]};
  background: transparent;
  border: 1px solid ${(props) => color[props.color]};
  ${font.medium}
  &:not(:disabled) {
    &:hover {
      color: #fff;
      background: ${(props) => mixin.lighten(color[props.color], 0.15)};
      border: 1px solid ${(props) => mixin.lighten(color[props.color], 0.15)};
    }
    &:active {
      color: #fff;
      background: ${(props) => mixin.darken(color[props.color], 0.2)};
      border: 1px solid ${(props) => mixin.lighten(color[props.color], 0.2)};
    }
    ${(props) =>
      props.isActive &&
      css`
        color: #fff;
        background: ${mixin.darken(color[props.color], 0.2)} !important;
        border: 1px solid ${mixin.darken(color[props.color], 0.2)} !important;
      `}
  }
`;

const secondaryAndEmptyShared = css<RequiredButtonProps>`
  color: ${(props) =>
    props.color == 'secondary' ? color.textDark : color[props.color]};
  background: ${(props) =>
    props.variant == 'standard' ? color[props.color] : `transparent`};
  border: 1px solid ${(props) =>
    props.variant == 'simple' ? `transparent` : color[props.color]}};
  ${font.regular}
  &:not(:disabled) {
    &:hover {
      background: ${mixin.darken(color.backgroundLight, 0.05)};
      border: 1px solid ${mixin.darken(color.backgroundLight, 0.05)};
    }
    &:active {
      color: ${color.primary};
      background: ${mixin.darken(color.backgroundLight, 0.05)};
      border: 1px solid ${mixin.darken(color.backgroundLight, 0.05)};
    }
    ${(props) =>
      props.isActive &&
      css`
        color: ${color.primary};
        background: ${mixin.darken(color.backgroundLight, 0.05)};
        border: 1px solid ${mixin.darken(color.backgroundLight, 0.05)};
      `}
  }
`;

const buttonVariants = {
  standard: {
    primary: standard,
    success: standard,
    warning: standard,
    danger: standard,
    secondary: secondaryAndEmptyShared,
    info: standard,
  },
  simple: {
    primary: secondaryAndEmptyShared,
    success: secondaryAndEmptyShared,
    warning: secondaryAndEmptyShared,
    danger: secondaryAndEmptyShared,
    secondary: secondaryAndEmptyShared,
    info: secondaryAndEmptyShared,
  },
  outline: {
    primary: outlined,
    success: outlined,
    warning: outlined,
    danger: outlined,
    secondary: secondaryAndEmptyShared,
    info: outlined,
  },
};
