import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppContext } from 'App';
import React, {
  FunctionComponent,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  FormFieldWrapper,
  HorizontalSeparator,
  Modal,
  PageDetails,
  Section,
  SelectField,
  SelectOption,
} from 'shared/components';
import {
  useGetDirectory,
} from 'shared/hooks/api';
import { color } from 'shared/utils/styles';
import styled from 'styled-components';
import { EntityAccessRow } from './EmployeeRoleAccessModal';

interface AddMembersModalProps {
  onClose: () => void;
  onSubmit: (members: MemberSelectOption[]) => void;
  isWorking?: boolean;
  existingMembers: EntityAccessRow[];
}

type MemberSelectOptionValue = {
  id: number;
};

interface MemberSelectOption extends SelectOption {
  value: MemberSelectOptionValue;
  label: string;
}

const AddMembersModal: FunctionComponent<AddMembersModalProps> = ({
  onClose,
  onSubmit,
  isWorking,
  existingMembers = [],
}) => {
  const appContext = useAppContext();
  const { data: usersData } = useGetDirectory(appContext.currentUserId);

  const existingUserIds = useMemo(() => {
    return existingMembers.map(x => x.id);
  }, [existingMembers])

  const options = useMemo(() => {
    const opts: MemberSelectOption[] = [];
    if (usersData?.users) {
      usersData.users.filter(x => !existingUserIds.includes(x.id)).forEach((user) => {
        const opt: MemberSelectOption = {
          value: { id: user.id },
          label: `${user.firstName} ${user.lastName}`,
          icon: 'user',
        };
        opts.push(opt);
      });
    }
    return opts.sort((a, b) => {
      const textA = a.label.toUpperCase();
      const textB = b.label.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }, [usersData, existingUserIds]);

  const [selectedOptions, setSelectedOptions] = useState<MemberSelectOption[]>(
    []
  );

  const filteredOptions = useMemo(() => {
    const selected = selectedOptions.map(
      (opt) => opt.value.id
    );
    return options.filter(
      (opt) => !selected.includes(opt.value.id)
    );
  }, [options, selectedOptions]);

  return (
    <Modal
      title="Add Members"
      isOpen={true}
      onClose={onClose}
      size={'sm'}
      footerTools={[
        <Button
          key={'add-members'}
          color={'primary'}
          disabled={selectedOptions.length == 0}
          onClick={() => {
            if (selectedOptions.length > 0) {
              onSubmit(selectedOptions)
            }
          }}
          isWorking={isWorking}
        >
          Add Selected Members
        </Button>,
      ]}
    >
      <PageDetails>
        <FormFieldWrapper>
          <SelectField
            label={'Select Users'}
            name={'members'}
            placeholder={'Type to filter...'}
            options={filteredOptions}
            onChange={(value) => {
              if (value) {
                setSelectedOptions((opts) =>
                  [...opts, value as MemberSelectOption].sort((a, b) => {
                    const textA = a.label.toUpperCase();
                    const textB = b.label.toUpperCase();
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                  })
                );
              }
            }}
            value={null}
            blurInputOnSelect={false}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <Section
            title={'Selected'}
            style={{ maxHeight: '500px', overflowY: 'scroll' }}
          >
            <SelectedMembersList>
              {selectedOptions.length == 0 ? (
                <NoSelectedMembers>No Users Selected</NoSelectedMembers>
              ) : (
                selectedOptions.map((opt) => {
                  return (
                    <React.Fragment key={opt.value.id}>
                      <SelectedMemberRow>
                        <SelectedMember style={{ width: '100%', display: 'inline-flex' }}>
                          {opt.icon && (
                            <FontAwesomeIcon
                              icon={['fal', opt.icon]}
                              color={color.textLight}
                              fixedWidth={true}
                            />
                          )}
                          <div>{opt.label}</div>
                        </SelectedMember>
                        <Button slim={true} variant={'simple'} color={'primary'} onClick={() => {
                          setSelectedOptions(opts => {
                            return opts.filter(x => {
                              const key = x.value.id;
                              const filterKey = opt.value.id;
                              return key != filterKey;
                            })
                          })
                        }}>Remove</Button>
                      </SelectedMemberRow>
                      <HorizontalSeparator />
                    </React.Fragment>
                  );
                })
              )}
            </SelectedMembersList>
          </Section>
        </FormFieldWrapper>
      </PageDetails>
    </Modal>
  );
};

export default AddMembersModal;

const SelectedMembersList = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoSelectedMembers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
`;

const SelectedMemberRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  & > * {
    margin-right: 10px;
  }
`;

const SelectedMember = styled.div`
  display: inline-flex;
  align-items: center;
  & > * {
    margin-right: 10px;
  }
`;
