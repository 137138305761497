import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { color, mixin, font } from 'shared/utils/styles'
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import FieldWrapper, { FieldWrapperProps } from '.';
import { useField } from 'formik';

export interface TextareaProps extends TextareaAutosizeProps {
  borderless?: boolean;
}

const StyledTextarea = styled(TextareaAutosize)<TextareaProps>`
  width: 100%;  
  outline: none;
  padding: ${props => props.borderless ? '2px' : '7px'};
  background-color: #fff;
  border: 1px solid ${props => props.borderless ? 'transparent' : color.border};
  border-radius: 3px;
  color: ${color.textDark};
  resize: none;

  &::placeholder {
    color: ${color.textMedium};
  }

  &:disabled {
    background-color: ${mixin.darken('#fff', 0.2)};
    cursor: not-allowed;
  }

  &:focus {
    border: 1px solid ${color.info};
  }

  ${(props) =>
    props.borderless &&
    `
    &:hover:not(:focus) {
      border: 1px solid ${color.border};
    }
  `}
`

export const Textarea: FunctionComponent<TextareaProps> = ({ ...props }) => {
  return (
    <StyledTextarea minRows={2} {...props}/>
  )
}

interface TextareaFieldProps extends TextareaProps, FieldWrapperProps {
  name: string;
  borderless?: boolean;
}

export const TextareaField: FunctionComponent<TextareaFieldProps> = ({ label, error, placeholder = "Enter a value", ...props }) => {
  return (
    <FieldWrapper label={label} error={error}>
      <Textarea
        placeholder={placeholder}
        {...props}
      />
    </FieldWrapper>
  )
}

export const TextareaFormikField: FunctionComponent<TextareaFieldProps> = ({
  name,
  ...props
}) => {
  const [field, meta] = useField(name);
  return (
    <TextareaField error={meta.error} {...props} {...field}/>
  );
};