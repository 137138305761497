// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";

// Other imports
import React, { useEffect } from 'react';
import ReactDom from 'react-dom';
import App from 'App';
import { useAuthModule } from 'shared/auth/msal';
import { Spinner } from 'shared/components';
import styled from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';

const AuthModuleWrapper = () => {
  const auth = useAuthModule();
  useEffect(() => {
    auth.loadAuthModule();
  }, []);
  if (auth.currentAccount) {
    return <App/>;
  } else {
    return (
        <FullPageSpinnerComp/>
    );
  }
};

const FullPageSpinnerComp = () => {
  return (
    <FullPageSpinner>
      <Spinner />
    </FullPageSpinner>
  );
};

const ErrorWrappedAuthModule = () => {
  return (
    <ErrorBoundary FallbackComponent={FullPageSpinnerComp} onError={(error) => console.log(error)}>
      <AuthModuleWrapper />
    </ErrorBoundary>
  );
};

const FullPageSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

ReactDom.render(<ErrorWrappedAuthModule />, document.getElementById('root'));
