import React, { FunctionComponent, useMemo } from 'react';
import {
  FormFieldWrapper,
  FormModal,
  SelectFormikField,
} from 'shared/components';
import * as Yup from 'yup';
import { useNotification } from 'shared/components/notifications';
import { useCreateEvaluation, useGetEvaluationCycles, useGetLatestEvaluationCycles } from 'shared/hooks/api';
import { CreateEvaluationInput, ErrorFragment, GoalsTemplateEnum } from 'shared/hooks/api/graphql/generated';

interface NewGoalSetModalProps {
  onClose: () => void;
  onSuccess: () => void;
  employeeId: number;
}

type Option = {
  value: number;
  label: string;
}

type GoalsTemplateOption = {
  value: string;
  label: string;
  evaluationCycleId?: number;
}

type GoalSetFormValues = {
  performanceReviewCycle: Option;
  goalsTemplate: GoalsTemplateOption;
};

export const goalGroupFormSchema: Yup.ObjectSchema<GoalSetFormValues> = Yup.object()
  .shape({
    performanceReviewCycle: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .required()
      .label('Performance Review Cycle'),
    goalsTemplate: Yup.object()
      .shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
      .required()
      .label('Goals Template'),
  })
  .required();

const NewGoalSetModal: FunctionComponent<NewGoalSetModalProps> = ({
  onClose,
  onSuccess,
  employeeId,
}) => {
  const notify = useNotification();

  const { data: latestEvaluationCyclesData, status: latestEvaluationCyclesStatus } = useGetLatestEvaluationCycles();
  const { data: evaluationCyclesData, status: evaluationCyclesStatus } = useGetEvaluationCycles();

  const [createEvaluation, { status }] = useCreateEvaluation({
    throwOnError: true,
  });

  const performanceReviewCycleOptions = useMemo(() => {
    return evaluationCyclesData?.evaluationsCycles?.map(cycle => ({
      value: cycle.id,
      label: cycle.name,
    }));
  }, [evaluationCyclesData])

  const goalsTemplateOptions = useMemo(() => {
    const options: GoalsTemplateOption[] = [
      { value: GoalsTemplateEnum.None, label: 'None'},
      { value: GoalsTemplateEnum.RoleScorecard, label: 'Role Scorecard' },
    ];
    latestEvaluationCyclesData?.latestEvaluationsCycles?.forEach(latestCycle => {
      latestCycle
      evaluationCyclesData?.evaluationsCycles?.forEach(cycle => {
        if (latestCycle.id != cycle.id) {
          options.push({ value: GoalsTemplateEnum.PreviousEvaluation, label: `Evaluation: ${cycle.name}`, evaluationCycleId: cycle.id });
        }
      })
    });
    return options;
  }, [latestEvaluationCyclesData, evaluationCyclesData])

  return (
    <FormModal<GoalSetFormValues>
      title={'Set New Goals'}
      submitTitle={'Set New Goals'}
      initialValues={{ goalsTemplate: { value: GoalsTemplateEnum.RoleScorecard, label: 'Role Scorecard' }}}
      handleSubmit={async (form, resetForm) => {
        const input: CreateEvaluationInput = {
          employeeId: employeeId,
          goalsTemplate: form.goalsTemplate.value as GoalsTemplateEnum,
          evaluationCycleId: form.performanceReviewCycle.value,
          goalsTemplateEvaluationCycleId: form.goalsTemplate.evaluationCycleId,
        }
        try {
          const result = await createEvaluation(input);
          
          if (result.data) {
            switch (result.data.createEvaluation?.__typename) {
              case "Evaluation": {
                notify({
                  duration: 3000,
                  title: 'Success!',
                  message: 'New Goal Set Created!',
                  variant: 'success',
                });
                resetForm();
                onClose()
                onSuccess();
                break;
              }
              case "EvaluationAlreadyExists": {
                notify({
                  duration: 7000,
                  title: 'Goal Set already exists!',
                  variant: 'danger',
                  message: result.data.createEvaluation.message,
                });
                break;
              }
              default: {
                notify({
                  duration: 7000,
                  title: 'Failed to create Goal Set',
                  message: (result.data?.createEvaluation as unknown as ErrorFragment).message,
                  variant: 'danger',
                });
                break;
              }
            }
          } else {
            throw Error()
          }
        } catch {
          notify({
            duration: 5000,
            title: 'Yikes!',
            variant: 'danger',
            message: 'An unknown error occurred while creating the goal set!',
          });
        }
      }}
      isWorking={status == 'loading'}
      onClose={onClose}
      validationSchema={goalGroupFormSchema}
    >
      {(props) => (
        <React.Fragment>
          <FormFieldWrapper>
            <SelectFormikField
              label={'Which Performance Review Cycle are these goals for?'}
              name={'performanceReviewCycle'}
              options={performanceReviewCycleOptions}
              placeholder={'Select a review cycle...'}
            />
          </FormFieldWrapper>
          <FormFieldWrapper>
            <SelectFormikField
              label={'Goals Template'}
              name={'goalsTemplate'}
              options={goalsTemplateOptions}
              placeholder={'Select a template...'}
            />
          </FormFieldWrapper>
        </React.Fragment>
      )}
    </FormModal>
  );
};

export default NewGoalSetModal;
