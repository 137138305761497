import React, { FunctionComponent, useMemo } from 'react';
import { useGetSelfEvaluation } from 'shared/hooks/api';
import EvaluationReviewModal, { EvaluationReview } from './EvaluationReviewModal';

interface SelfEvaluationReviewProps {
  evaluationId: number;
  currentUserIsEvaluationEmployee: boolean;
  employeeName: string;
  evaluationCycleName: string;
  onClose: () => void;
  unsubmitEnabled: boolean;
}

const SelfEvaluationReviewModal: FunctionComponent<SelfEvaluationReviewProps> = ({
  evaluationId,
  currentUserIsEvaluationEmployee,
  employeeName,
  evaluationCycleName,
  onClose,
  unsubmitEnabled,
}) => {
  const { data, status } = useGetSelfEvaluation(evaluationId);

  const review: EvaluationReview | undefined = useMemo(() => {
    if (data?.evaluation?.selfEvaluation && data.evaluation.selfEvaluation.goalsReview && data.evaluation.selfEvaluation.valuesReview) {
      const selfEvaluation = data.evaluation.selfEvaluation;
      const goalsReview = selfEvaluation.goalsReview;
      const valuesReview = selfEvaluation.valuesReview;
      return {
        id: selfEvaluation.id,
        type: selfEvaluation.type ?? undefined,
        status: selfEvaluation.status ?? undefined,
        goalsReview: {
          id: goalsReview.id,
          type: goalsReview.type ?? undefined,
          status: goalsReview.status ?? undefined,
          goals: goalsReview.goals.map(goal => ({
            id: goal.id,
            itemId: goal.id,
            details: goal.subgoals?.map(x => x.name) ?? [],
            name: goal.name,
            score: goal.performanceReward ?? undefined,
            strengths: goal.strengths.map(x => ({
              id: x.id,
              description: x.description || "",
            })),
            opportunities: goal.opportunities.map(x => ({
              id: x.id,
              description: x.description || "",
            })),
          })),
          selfReviewContent: [],
        },
        valuesReview: {
          id: valuesReview.id,
          type: valuesReview.type ?? undefined,
          status: valuesReview.status ?? undefined,
          values: valuesReview.values.map(value => ({
            id: value.reviewValueId,
            itemId: value.id,
            details: value.description ? [value.description] : [],
            name: value.name,
            score: value.score ?? undefined,
            strengths: value.strengths.map(x => ({
              id: x.id,
              description: x.description || "",
            })),
            opportunities: value.opportunities.map(x => ({
              id: x.id,
              description: x.description || "",
            })),
          })),
          peerReviewContent: [],
        },
      }
    }
  }, [data]);
  return (
    <EvaluationReviewModal
      review={review}
      title={'Self-Assessment'}
      subtitle={'Details'}
      evaluationId={Number(evaluationId)}
      type={'self'}
      currentUserIsManager={!currentUserIsEvaluationEmployee}
      currentUserIsEvaluationEmployee={currentUserIsEvaluationEmployee}
      onClose={onClose}
      isLoading={status == 'loading'}
      size={'lg'}
      employeeName={employeeName}
      evaluationCycleName={evaluationCycleName}
      unsubmitEnabled={unsubmitEnabled}
    />
  );
};

export default SelfEvaluationReviewModal;
