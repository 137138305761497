type Comparable = string | number | Date | undefined;
type SortSelector<T> = (obj: T) => Comparable

export function sortByDescending<T>(selector: SortSelector<T>) {
  return (a: T, b: T) => {
    const valueA = selector(a);
    const valueB = selector(b);
    if (valueA != null && valueA != undefined && valueB != null && valueB != undefined) {
      if (valueA < valueB) {
        return 1;
      } else {
        return -1;
      }
    } else if (valueA != null && valueA != undefined && !valueB) {
      return 1;
    } else if (valueB != null && valueB != undefined && !valueA) {
      return -1;
    } else {
      return 0;
    }
  }
}

export function sortBy<T>(selector: SortSelector<T>) {
  return (a: T, b: T) => {
    const valueA = selector(a);
    const valueB = selector(b);
    if (valueA != null && valueA != undefined && valueB != null && valueB != undefined) {
      if (valueA < valueB) {
        return -1;
      } else {
        return 1;
      }
    } else if (valueA != null && valueA != undefined && !valueB) {
      return -1;
    } else if (valueB != null && valueB != undefined && !valueA) {
      return 1;
    } else {
      return 0;
    }
  }
}