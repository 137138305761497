import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { color, font } from 'shared/utils/styles';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SectionProps {
  title: string;
  subtitle?: string;
  icon?: IconName | React.ReactNode;
  right?: ReactNode[];
  style?: React.CSSProperties;
}

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  margin-bottom: 5px;
  border-bottom: 1px solid ${color.border};
  width: 100%;
`;

const SectionTitle = styled.h5`
  ${font.size(14)};
  ${font.medium};
`;

const SectionSubtitle = styled.h5`
  ${font.size(11)};
  color: ${color.textLightest};
`;

const SectionHeaderLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;

  & > * {
    margin-right: 5px;
  }
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 32px;
  & > *:not(last-child) {
    margin-bottom: 5px;
  }
`;

const SectionHeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  & > * {
    margin-left: 5px;
  }
`;

const SectionBody = styled.div`
  padding: 5px 0;
`;

const SectionHeaderIcon = styled.div`
  margin-right: 5px;
  ${font.size(12)};
  color: ${color.textMedium};
`;

const Section: FunctionComponent<SectionProps> = ({
  children,
  title,
  subtitle,
  icon,
  right = [],
  style,
}) => {
  return (
    <SectionWrapper style={style}>
      <SectionHeader>
        <SectionTitleWrapper>
          <SectionHeaderLeft>
            <SectionHeaderIcon>
              {icon && typeof icon === 'string' ? (
                <FontAwesomeIcon icon={['fal', icon as IconName]} />
              ) : (
                icon
              )}
            </SectionHeaderIcon>
            <SectionTitle>{title}</SectionTitle>
          </SectionHeaderLeft>
          {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
        </SectionTitleWrapper>
        <SectionHeaderRight>{right}</SectionHeaderRight>
      </SectionHeader>
      <SectionBody style={style}>{children}</SectionBody>
    </SectionWrapper>
  );
};

export default Section;
