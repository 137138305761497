import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Column,
  Dialog,
  FormModal,
  InputFormikField,
  DropdownButton,
  DropdownItem,
  Row,
  Section,
  SelectFormikField,
  TextareaFormikField,
  Tooltip,
} from 'shared/components';
import { DatePickerFormikField } from 'shared/components/form/datepicker/DatePicker';
import {
  useDeleteGoal,
  useGetGoal,
  useGetGoalStatusOptions,
  useUpdateGoal,
} from 'shared/hooks/api';
import { color, font } from 'shared/utils/styles';
import styled from 'styled-components';
import parse from 'date-fns/parse';
import { GoalRow } from '..';
import { dateFormat } from 'shared/utils/dateFormats';
import EmptyDataMessage from 'App/Organization/shared/NoData';
import { createQueryParamModalHelpers } from 'shared/utils/queryParamModal';
import NewGoalModal from 'App/Organization/shared/Goals/NewGoalModal';

import * as Yup from 'yup';
import { UpdateGoalInput } from 'shared/hooks/api/graphql/generated';
import { useNotification } from 'shared/components/notifications';
import { diff } from 'shared/utils/deepDiff';
import format from 'date-fns/format';
import { PartialNullable } from 'shared/utils/utilityTypes';
import { FullWidthSpinner } from 'App/Routes';

interface GoalModalProps {
  onClose: () => void;
  contextUrl: string;
}

const GoalNameInputField = styled(TextareaFormikField)`
  ${font.medium};
  ${font.size(16)};
`;

type Option = {
  value: number;
  label: string;
};

type GoalFormValues = {
  name: string;
  status: Option;
  weight?: number | null;
  dueDate?: Date | null;
};

export const goalFormSchema: Yup.ObjectSchema<GoalFormValues> = Yup.object()
  .shape({
    name: Yup.string().required().label('Name'),
    status: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .required()
      .label('Status'),
    weight: Yup.number().min(0).max(100).nullable().label('Weight'),
    dueDate: Yup.date().nullable().label('Due Date'),
  })
  .required();

const GoalModal: FunctionComponent<GoalModalProps> = ({
  onClose,
  contextUrl,
}) => {
  const { goalId, goalSetId } = useParams<{
    goalSetId: string;
    goalId: string;
  }>();
  const { data, status, refetch } = useGetGoal(Number(goalId));
  const parentId = useMemo(() => {
    if (data?.goal?.parentId) {
      return data.goal.parentId;
    }
  }, [data]);
  const history = useHistory();

  const navigateToParent = useCallback(() => {
    if (parentId) {
      history.push(`${contextUrl}/goal/${parentId}`);
    } else {
      onClose();
    }
  }, [contextUrl, parentId]);

  const titleTools = useMemo(() => {
    const tools = [];
    if (parentId) {
      tools.push(
        <Button
          key={'back-to-parent-goal'}
          icon={'arrow-left'}
          slim={true}
          variant={'outline'}
          onClick={navigateToParent}
        >
          Back to Parent Goal
        </Button>
      );
    }
    return tools;
  }, [parentId, navigateToParent]);

  const goals = useMemo(() => {
    return data?.goal?.subgoals?.map((goal) => {
      const subSubgoals = goal.subgoals;
      return {
        id: goal.id,
        name: goal.name,
        weight: goal.weight,
        status: goal.status.name,
        dueDate: goal.dueDate
          ? parse(goal.dueDate, dateFormat, new Date())
          : undefined,
        subgoals: subSubgoals?.map((subgoal) => ({
          id: subgoal.id,
          name: subgoal.name,
          weight: subgoal.weight,
          status: subgoal.status.name,
          dueDate: subgoal.dueDate
            ? parse(subgoal.dueDate, dateFormat, new Date())
            : undefined,
        })),
      };
    });
  }, [data]);

  const newGoalModalHelpers = createQueryParamModalHelpers('new-subgoal');

  const {
    data: goalStatusOptions,
    status: goalStatusOptionsStatus,
  } = useGetGoalStatusOptions();

  const [updateGoal, { status: updateGoalStatus }] = useUpdateGoal(
    Number(goalSetId),
    {
      throwOnError: true,
    }
  );

  const [deleteGoal, { status: deleteGoalStatus }] = useDeleteGoal(
    Number(goalSetId),
    parentId,
    {
      throwOnError: true,
    }
  );

  const notify = useNotification();
  const initialValues: PartialNullable<GoalFormValues> = useMemo(() => {
    return {
      name: data?.goal?.name,
      status: data?.goal?.status && {
        value: data.goal.status.id,
        label: data.goal.status.name,
      },
      dueDate: data?.goal?.dueDate
        ? parse(data.goal.dueDate, dateFormat, new Date())
        : null,
      weight: data?.goal?.weight && data.goal.weight * 100,
    };
  }, [data]);

  const goalStatuses = useMemo(() => {
    return goalStatusOptions?.goalStatuses?.map((status) => ({
      value: status.value,
      label: status.label,
    }));
  }, [goalStatusOptions]);

  const [showDeleteGoalDialog, setShowDeleteGoalDialog] = useState(false);

  const tools = useMemo(() => {
    return [
      <DropdownButton
        key={'menu'}
        variant={'simple'}
        icon={'ellipsis-h-alt'}
        anchor={'right'}
      >
        <DropdownItem
          onClick={() => setShowDeleteGoalDialog(true)}
          icon={'trash'}
          style={{ color: color.danger }}
        >
          Delete
        </DropdownItem>
      </DropdownButton>,
    ];
  }, [setShowDeleteGoalDialog]);

  return (
    <React.Fragment>
      <FormModal<GoalFormValues>
        size={'lg'}
        title={'Goal Details'}
        tools={tools}
        titleTools={titleTools}
        slim={true}
        submitTitle={'Save'}
        initialValues={initialValues}
        handleSubmit={async (form, resetForm) => {
          const changes = diff(initialValues, form);
          const input: UpdateGoalInput = {
            id: Number(goalId),
            name: changes.name,
            evaluationObjectiveStatusId: changes.status?.value,
            objectiveWeight: changes.weight && changes.weight / 100,
            dueDate: changes.dueDate
              ? format(changes.dueDate, dateFormat)
              : changes.dueDate,
          };
          try {
            const result = await updateGoal(input);
            if (!result.errors) {
              notify({
                duration: 3000,
                message: 'Goal Updated!',
                variant: 'success',
              });
              onClose();
            } else {
              notify({
                duration: 5000,
                title: 'Yikes!',
                variant: 'danger',
                message: 'An unknown error occurred while updating this goal!',
              });
            }
          } catch {
            notify({
              duration: 5000,
              title: 'Yikes!',
              variant: 'danger',
              message: 'An unknown error occurred while updating this goal!',
            });
          }
        }}
        isWorking={updateGoalStatus == 'loading'}
        onClose={onClose}
        validationSchema={goalFormSchema}
      >
        {(props) => (
          <React.Fragment>
            {status == 'loading' && !data ? (
              <FullWidthSpinner minHeight={200} />
            ) : (
              <Row>
                <Column>
                  <GoalNameInputField
                    name={'name'}
                    placeholder={'Enter a task title'}
                    minRows={1}
                  />
                </Column>
                <Column span={4}>
                  <SelectFormikField
                    name={'status'}
                    label={'Status'}
                    options={goalStatuses}
                    isLoading={goalStatusOptionsStatus == 'loading'}
                    placeholder={'Select a status...'}
                  />
                </Column>
                <Column span={4}>
                  <InputFormikField
                    name={'weight'}
                    label={'Weight'}
                    placeholder={'Enter a percentage...'}
                    icon={'percent'}
                    iconOnRight={true}
                  />
                </Column>
                <Column span={4}>
                  <DatePickerFormikField name={'dueDate'} label={'Due Date'} inputProps={{ icon: 'calendar-alt' }}/>
                </Column>
                <Column>
                  <Section
                    title={'Subgoals'}
                    right={[
                      <Button
                        key={'add-subgoal'}
                        icon={'plus'}
                        variant={'simple'}
                        slim={true}
                        onClick={newGoalModalHelpers.open}
                      >
                        Add Subgoal
                      </Button>,
                    ]}
                  >
                    {goals?.length == 0 ? (
                      <EmptyDataMessage title={'No Subgoals'} />
                    ) : (
                      <Row>
                        {goals?.map((goal) => {
                          return (
                            <Column key={goal.id}>
                              <GoalRow
                                id={goal.id}
                                name={goal.name}
                                outcome={goal.status}
                                weight={goal.weight}
                                dueDate={goal.dueDate}
                                subgoals={goal.subgoals}
                                contextUrl={contextUrl}
                              />
                            </Column>
                          );
                        })}
                      </Row>
                    )}
                  </Section>
                </Column>
              </Row>
            )}
          </React.Fragment>
        )}
      </FormModal>
      {newGoalModalHelpers.isOpen() && (
        <NewGoalModal
          onClose={newGoalModalHelpers.close}
          onSuccess={refetch}
          parentId={Number(goalId)}
        />
      )}
      {showDeleteGoalDialog && (
        <Dialog
          variant={'danger'}
          isOpen={true}
          title={'Delete Goal'}
          confirmTitle={'Yes, Delete'}
          message={`Are you sure you want to delete the goal titled "${data?.goal?.name}"?`}
          isWorking={deleteGoalStatus == 'loading'}
          onConfirm={async () => {
            try {
              await deleteGoal(Number(goalId));
              setShowDeleteGoalDialog(false);
              notify({
                message: 'Goal deleted!',
              });
              navigateToParent();
            } catch {
              notify({
                duration: 5000,
                variant: 'danger',
                message: 'An Error occcurred while deleting this goal!',
              });
            }
          }}
          onClose={() => setShowDeleteGoalDialog(false)}
          onCancel={() => setShowDeleteGoalDialog(false)}
        />
      )}
    </React.Fragment>
  );
};

export default GoalModal;
