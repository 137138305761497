import React, { FunctionComponent, useMemo } from 'react';
import {
  FormFieldWrapper,
  FormModal,
  SelectFormikField,
} from 'shared/components';
import * as Yup from 'yup';
import { useNotification } from 'shared/components/notifications';
import { useCreateEvaluation, useCreatePeerRequest, useGetDirectory, useGetEvaluationCycles } from 'shared/hooks/api';
import { CreateEvaluationInput, CreatePeerRequestInput, ErrorFragment, GoalsTemplateEnum } from 'shared/hooks/api/graphql/generated';
import { useAppContext } from 'App';
import { sortBy } from 'shared/utils/sorting';

interface NewPeerRequestModalProps {
  onClose: () => void;
  onSuccess: () => void;
  evaluationId: number;
}

type Option = {
  value: number;
  label: string;
}

type PeerRequestFormValues = {
  peer: Option;
};

export const peerRequestFormSchema: Yup.ObjectSchema<PeerRequestFormValues> = Yup.object()
  .shape({
    peer: Yup.object()
      .shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      })
      .required()
      .label('Peer'),
  })
  .required();

const NewPeerRequestModal: FunctionComponent<NewPeerRequestModalProps> = ({
  onClose,
  onSuccess,
  evaluationId,
}) => {
  const notify = useNotification();
  const appContext = useAppContext();

  const { data: directoryData, status: directoryDataStatus } = useGetDirectory(appContext.currentUserId);

  const [createPeerRequest, { status }] = useCreatePeerRequest(evaluationId);

  const peerOptions = useMemo(() => {
    return directoryData?.users?.map(user => ({
      value: user.id,
      label: user.displayName,
    })).sort(sortBy(x => x.label));
  }, [directoryData]);

  return (
    <FormModal<PeerRequestFormValues>
      title={'New Peer Request'}
      submitTitle={'Submit Peer Request'}
      initialValues={{ peer: null }}
      handleSubmit={async (form, resetForm) => {
        const input: CreatePeerRequestInput = {
          evaluationId: evaluationId,
          peerId: form.peer.value,
        } 
        try {
          const result = await createPeerRequest(input);
          
          if (result.data) {
            notify({
              duration: 3000,
              title: 'Success!',
              message: 'New Peer Request Created!',
              variant: 'success',
            });
            resetForm();
            onClose()
            onSuccess();
          } else if (result.errors) {
            const forbiddenError = result.errors.find((x: any) => x?.extensions?.code == "FORBIDDEN")
            if (forbiddenError) {
              notify({
                duration: 5000,
                title: 'Permission Denied',
                variant: 'danger',
                message: forbiddenError?.message,
              });
            } else {
              throw Error();
            }
          } else {
            throw Error()
          }
        } catch {
          notify({
            duration: 5000,
            title: 'Yikes!',
            variant: 'danger',
            message: 'An unknown error occurred while creating the peer request!',
          });
        }
      }}
      isWorking={status == 'loading'}
      onClose={onClose}
      validationSchema={peerRequestFormSchema}
    >
      {(props) => (
        <React.Fragment>
          <FormFieldWrapper>
             <SelectFormikField
              label={'Peer'}
              name={'peer'}
              options={peerOptions}
              placeholder={'Select a peer to provide a review...'}
              isLoading={directoryDataStatus == 'loading'}
             />
          </FormFieldWrapper>
        </React.Fragment>
      )}
    </FormModal>
  );
};

export default NewPeerRequestModal;
