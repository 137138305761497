import React, { FunctionComponent } from 'react';
import {
  SidebarTheme,
  Sidebar,
  SidebarLink,
  LinkText,
  SidebarBottom,
  SidebarButton,
  Separator,
  SidebarInfo,
  SidebarTitles,
  SidebarIcon,
  SidebarTitle,
  SidebarSubtitle,
  SidebarIconLink,
  OpenSidebarLink,
  SidebarSectionTitle,
  SidebarText
} from './Styles';
import { color, sizes } from 'shared/utils/styles';
import {
  useSidebarContext,
  useSidebarStorage,
} from 'shared/utils/sidebar/sidebar';
import { Tooltip } from 'shared/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { renderIcon } from '../button/Button';

export const MainSidebar: FunctionComponent = ({ children }) => {
  const { isMainSidebarOpen, setIsMainSidebarOpen } = useSidebarContext();
  useSidebarStorage('main', setIsMainSidebarOpen, isMainSidebarOpen);
  const left = 0;
  return (
    <Sidebar
      isSidebarOpen={isMainSidebarOpen}
      left={left}
      colors={color.sidebar}
    >
      {children}
    </Sidebar>
  );
};

interface NestedSidebarProps {
  id: string;
  disableToggle?: boolean;
  bottom?: React.ReactNode;
}

export const NestedSidebar: FunctionComponent<NestedSidebarProps> = ({
  children,
  bottom,
  id,
  disableToggle = false,
}) => {
  const {
    isMainSidebarOpen,
    isNestedSidebarOpen,
    setIsNestedSidebarOpen,
  } = useSidebarContext();
  useSidebarStorage(id, setIsNestedSidebarOpen, isNestedSidebarOpen);

  const left =
    (isMainSidebarOpen
      ? sizes.appSidebarWidth
      : sizes.collapsedAppSidebarWidth) + 1;
  const iconName = isNestedSidebarOpen
    ? 'chevron-double-left'
    : 'chevron-double-right';
  const title = isNestedSidebarOpen ? 'Show Less' : 'Show More';
  return (
    <Sidebar
      isSidebarOpen={isNestedSidebarOpen || false}
      left={left}
      colors={color.nestedSidebar}
    >
      {children}
      <SidebarBottom>
        <NestedSidebarSeparator />
        {bottom && bottom}
        { !disableToggle && 
        <NestedSidebarButton
          title={title}
          icon={iconName}
          onClick={() => setIsNestedSidebarOpen(!isNestedSidebarOpen)}
        />
        }
      </SidebarBottom>
    </Sidebar>
  );
};

export const MainSidebarSeparator = () => (
  <Separator color={color.sidebar.separator} />
);
export const NestedSidebarSeparator = () => (
  <Separator color={color.nestedSidebar.separator} />
);

interface SidebarHeaderProps {
  title: string;
  subtitle: string;
  path?: string;
  hideIcon?: boolean;
}

interface SidebarInfoProps {
  title: string;
  subtitle: string;
  colors: SidebarTheme;
  isSidebarOpen?: boolean;
  path?: string;
  hideIcon?: boolean;
}

const colors = [
  '#DA7657',
  '#6ADA57',
  '#5784DA',
  '#AA57DA',
  '#DA5757',
  '#DA5792',
  '#57DACA',
  '#57A5DA',
];

const getColorFromName = (name: string) => colors[name.toLocaleLowerCase().charCodeAt(0) % colors.length];

const SidebarHeader: FunctionComponent<SidebarInfoProps> = ({
  path,
  isSidebarOpen,
  colors,
  title,
  subtitle,
  hideIcon = false,
}) => {
  return (
    <SidebarInfo isSidebarOpen={isSidebarOpen} colors={colors}>
      { !hideIcon && 
        <Tooltip
          content={`${title}: ${subtitle}`}
          disabled={isSidebarOpen}
          placement={'right'}
        >
          {path ? (
            <SidebarIconLink to={path} style={{ backgroundColor: getColorFromName(title)}}>{title.charAt(0)}</SidebarIconLink>
          ) : (
            <SidebarIcon style={{ backgroundColor: getColorFromName(title)}}>{title.charAt(0)}</SidebarIcon>
          )}
        </Tooltip>
      }
      {isSidebarOpen && (
        <SidebarTitles>
          <SidebarTitle>{title}</SidebarTitle>
          <SidebarSubtitle>{subtitle}</SidebarSubtitle>
        </SidebarTitles>
      )}
    </SidebarInfo>
  );
};

export const NestedSidebarHeader: FunctionComponent<SidebarHeaderProps> = ({
  title,
  subtitle,
  path,
  ...rest
}) => {
  const { isNestedSidebarOpen } = useSidebarContext();
  return (
    <SidebarHeader
      path={path}
      colors={color.nestedSidebar}
      isSidebarOpen={isNestedSidebarOpen}
      title={title}
      subtitle={subtitle}
      {...rest}
    />
  );
};

export const MainSidebarHeader: FunctionComponent<SidebarHeaderProps> = ({
  title,
  subtitle,
  path,
}) => {
  const { isMainSidebarOpen } = useSidebarContext();
  return (
    <SidebarHeader
      path={path}
      colors={color.sidebar}
      isSidebarOpen={isMainSidebarOpen}
      title={title}
      subtitle={subtitle}
    />
  );
};

interface SidebarItemProps {
  hideOnSidebarClosed?: boolean;
  disabled?: boolean;
}

interface SidebarLinkProps extends SidebarItemProps {
  icon: React.ReactNode | IconName;
  title: string;
  path: string;
  exact?: boolean;
}

export const MainSidebarLink: FunctionComponent<SidebarLinkProps> = ({
  icon,
  title,
  path,
  exact = false,
  hideOnSidebarClosed = false,
  disabled = false,
}) => {
  const { isMainSidebarOpen } = useSidebarContext();
  return renderSidebarLink(
    isMainSidebarOpen,
    icon,
    title,
    path,
    exact,
    color.sidebar,
    hideOnSidebarClosed,
    disabled,
  );
};

export const NestedSidebarLink: FunctionComponent<SidebarLinkProps> = ({
  icon,
  title,
  path,
  exact = false,
  hideOnSidebarClosed = false,
  disabled = false,
}) => {
  const { isNestedSidebarOpen } = useSidebarContext();
  return renderSidebarLink(
    isNestedSidebarOpen || false,
    icon,
    title,
    path,
    exact,
    color.nestedSidebar,
    hideOnSidebarClosed,
    disabled,
  );
};

const renderSidebarLink = (
  isSidebarOpen: boolean,
  icon: IconName | React.ReactNode,
  title: string,
  path: string,
  exact: boolean,
  colors: SidebarTheme,
  hideOnSidebarClosed: boolean,
  disabled: boolean,
) => {
  const linkItem = () => {
    if (isSidebarOpen) {
      return (
        <OpenSidebarLink to={path} colors={colors} exact={exact} disabled={disabled}>
          {renderIcon(icon)}
          {isSidebarOpen && <LinkText>{title}</LinkText>}
        </OpenSidebarLink>
      );
    } else {
      return (
        <SidebarLink to={path} colors={colors} exact={exact} disabled={disabled}>
          {renderIcon(icon)}
          {isSidebarOpen && <LinkText>{title}</LinkText>}
        </SidebarLink>
      );
    }
  };

  if (!isSidebarOpen && hideOnSidebarClosed) {
    return null;
  } if (!isSidebarOpen) {
    return (
      <Tooltip content={title} placement="right" offset={[0, 20]}>
        {linkItem()}
      </Tooltip>
    );
  } else {
    return linkItem();
  }
};

interface SidebarButtonProps extends SidebarItemProps {
  icon: IconName;
  title: string;
  onClick: () => void;
}

export const MainSidebarButton: FunctionComponent<SidebarButtonProps> = ({
  icon,
  title,
  onClick,
  hideOnSidebarClosed = false,
  disabled = false,
}) => {
  const { isMainSidebarOpen } = useSidebarContext();
  return renderSidebarButton(
    isMainSidebarOpen,
    icon,
    title,
    onClick,
    color.sidebar,
    hideOnSidebarClosed,
    disabled,
  );
};

export const NestedSidebarButton: FunctionComponent<SidebarButtonProps> = ({
  icon,
  title,
  onClick,
  hideOnSidebarClosed = false,
  disabled = false,
}) => {
  const { isNestedSidebarOpen } = useSidebarContext();
  return renderSidebarButton(
    isNestedSidebarOpen || false,
    icon,
    title,
    onClick,
    color.nestedSidebar,
    hideOnSidebarClosed,
    disabled,
  );
};

const renderSidebarButton = (
  isSidebarOpen: boolean,
  icon: IconName,
  title: string,
  onClick: () => void,
  colors: SidebarTheme,
  hideOnSidebarClosed: boolean,
  disabled: boolean,
) => {
  const buttonItem = (
    <SidebarButton
      isSidebarOpen={isSidebarOpen}
      colors={colors}
      onClick={onClick}
      disabled={disabled}
    >
      <FontAwesomeIcon icon={['fal', icon]} fixedWidth={true} />
      {isSidebarOpen && <LinkText>{title}</LinkText>}
    </SidebarButton>
  );

  if (!isSidebarOpen && hideOnSidebarClosed) {
    return null;
  } else if (!isSidebarOpen) {
    return (
      <Tooltip content={title} placement="right" offset={[0, 20]}>
        {buttonItem}
      </Tooltip>
    );
  } else {
    return buttonItem;
  }
};

interface SidebarSectionTitleProps extends SidebarItemProps {
  title: string;
}

export const MainSidebarSectionTitle: FunctionComponent<SidebarSectionTitleProps> = ({
  title,
  hideOnSidebarClosed = false,
}) => {
  const { isMainSidebarOpen } = useSidebarContext();
  return renderSidebarSectionTitle(
    isMainSidebarOpen,
    title,
    color.sidebar,
    hideOnSidebarClosed,
  );
};

export const MainSidebarText: FunctionComponent<SidebarSectionTitleProps> = ({
  title,
  hideOnSidebarClosed = false,
}) => {
  const { isMainSidebarOpen } = useSidebarContext();
  return renderSidebarText(
    isMainSidebarOpen,
    title,
    color.sidebar,
    hideOnSidebarClosed,
  );
};

export const NestedSidebarSectionTitle: FunctionComponent<SidebarSectionTitleProps> = ({
  title,
  hideOnSidebarClosed = false,
}) => {
  const { isNestedSidebarOpen } = useSidebarContext();
  return renderSidebarSectionTitle(
    isNestedSidebarOpen || false,
    title,
    color.nestedSidebar,
    hideOnSidebarClosed,
  );
};

const renderSidebarSectionTitle = (
  isSidebarOpen: boolean,
  title: string,
  colors: SidebarTheme,
  hideOnSidebarClosed: boolean,
) => {
  const titleItem = (
    <SidebarSectionTitle colors={colors}>{title}</SidebarSectionTitle>
  );

  if (!isSidebarOpen && hideOnSidebarClosed) {
    return null;
  } else if (!isSidebarOpen) {
    return (
      <Separator color={colors.separator} />
    );
  } else {
    return titleItem;
  }
};

const renderSidebarText = (
  isSidebarOpen: boolean,
  title: string,
  colors: SidebarTheme,
  hideOnSidebarClosed: boolean
) => {
  const titleItem = (
    <SidebarText colors={colors}>{title}</SidebarText>
  );

  if (!isSidebarOpen && hideOnSidebarClosed) {
    return null;
  } else if (!isSidebarOpen) {
    return (
      <Separator color={colors.separator} />
    );
  } else {
    return titleItem;
  }
};
