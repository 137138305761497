import React, { FunctionComponent, useCallback, useMemo } from 'react';
import {
  match,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import {
  PageHeader,
  Button,
} from 'shared/components';
import { usePageTitle } from 'shared/hooks';
import { color, font, mixin } from 'shared/utils/styles';
import styled from 'styled-components';
import { createQueryParamModalHelpers } from 'shared/utils/queryParamModal';
import CurrentGoals from './Current';
import GoalSet from './GoalSet';
import NewGoalSetModal from 'App/Organization/shared/Goals/NewGoalSetModal';
import { useAppContext } from 'App';

const CurrentUserGoals = () => {
  usePageTitle('My Goals');
  const match = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${match.path}/all`}
        exact={true}
        render={() => <CurrentUserGoalsMain match={match} />}
      />
      <Route path={`${match.path}/:goalSetId`} render={() => <GoalSet />} />
      <Redirect to={`${match.url}/all`} />
    </Switch>
  );
};

interface CurrentUserEvaluationsMainProps {
  match: match<{}>;
}

const CurrentUserGoalsMain: FunctionComponent<CurrentUserEvaluationsMainProps> = ({
  match,
}) => {
  usePageTitle('My Goals');
  const newGoalSetModalHelpers = createQueryParamModalHelpers('new-goal-group');
  const onSetNewGoals = useCallback(() => {
    newGoalSetModalHelpers.open();
  }, [newGoalSetModalHelpers]);

  const directReports = useMemo(() => {
    return [];
  }, []);

  const appContext = useAppContext();

  const tabs = useMemo(() => {
    const tabList = [
      { path: `/all`, title: 'All', pinned: true },
    ];
    return tabList;
  }, [directReports]);

  return (
    <React.Fragment>
      <PageHeader
        title={'My Goals'}
        tabs={tabs}
        match={match}
        tools={[
          <Button
            key={'new-goal-set'}
            icon={'list-ul'}
            variant={'outline'}
            onClick={onSetNewGoals}
          >
            Set New Goals
          </Button>,
        ]}
      />
      <Switch>
        <Route
          path={`${match.path}/all`}
          exact={true}
          render={() => <CurrentGoals onSetNewGoals={onSetNewGoals} />}
        />
        <Redirect to={`${match.url}/all`} />
      </Switch>
      {newGoalSetModalHelpers.isOpen() && (
        <NewGoalSetModal
          onClose={newGoalSetModalHelpers.close}
          onSuccess={() => false}
          employeeId={appContext.currentUserId}
        />
      )}
    </React.Fragment>
  );
};

interface GoalsRowProps {
  last: boolean;
  print?: boolean;
}

export const GoalsRow = styled.div<GoalsRowProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  ${(props) => !props.last && `border-bottom: 1px solid ${color.border}`};
  ${mixin.clickable};

  border-radius: 5px;
  ${props => !props.print ?
  
    `box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15),
      0 5px 20px 0 rgba(21, 27, 38, 0.08);` : `
        border: 1px solid ${color.border};
      `
  }
`;

export const GoalsRowLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  & > * {
    margin-right: 20px;
  }
`;

export const GoalsRowRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;

  & > * {
    margin-left: 20px;
  }
`;

export const GoalTitles = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const GoalTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${color.textDarkest};
  ${font.bold};

  & > * {
    margin-right: 10px;
  }
`;

export const GoalSubtitle = styled.p`
  ${font.size(12)};
  color: ${color.textMedium};
`;

export const GoalBody = styled.ul<{noPadding: boolean}>`
  ${font.size(11)};
  ${font.regular};
  color: ${color.textLight};
  padding-left: ${props => props.noPadding ? '0' : '15px'};

  & > li {
    margin: 2px 0;
  }
`;

export const GoalBodyLine = styled.li`
`;

export const GoalTitleSubtitle = styled.p`
  ${font.size(12)};
  ${font.regular};
  color: ${color.textMedium};
  margin-left: 5px;
`;

export const GoalIcon = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${mixin.clickable};
  height: 36px;
  min-height: 36px;
  max-height: 36px;
  width: 36px;
  min-width: 36px;
  max-width: 36px;
  ${font.size(14)};
  ${font.medium};
  border-radius: 5px;
  color: #fff;
  background-color: ${(props) => props.color};
`;

export default CurrentUserGoals;
