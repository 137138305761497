import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useCallback, useMemo, useRef } from 'react';
import {
  Link,
  Route,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import {
  Badge,
  Button,
  Column,
  PageDetails,
  PageHeader,
  PageToolbar,
  Row,
  ToolbarMessage,
} from 'shared/components';
import { useGetEvaluation } from 'shared/hooks/api';
import { formatPercentage } from 'shared/utils/formatting/formatters';
import { color, font } from 'shared/utils/styles';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import {
  GoalBody,
  GoalBodyLine,
  GoalsRow,
  GoalsRowLeft,
  GoalsRowRight,
  GoalSubtitle,
  GoalTitle,
  GoalTitles,
} from '..';
import GoalModal from './Goal';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import { dateFormat, monthDayYearFormat } from 'shared/utils/dateFormats';
import EmptyDataMessage from 'App/Organization/shared/NoData';
import { createQueryParamModalHelpers } from 'shared/utils/queryParamModal';
import NewGoalModal from 'App/Organization/shared/Goals/NewGoalModal';
import { FullWidthSpinner } from 'App/Routes';
import { useRemainingHeight } from 'shared/hooks';

const GoalSet = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { goalSetId } = useParams<{ goalSetId: string }>();
  const { data, status, refetch, errors } = useGetEvaluation(Number(goalSetId));

  const title = useMemo(() => {
    return data?.evaluation?.evaluationCycle.name;
  }, [data]);

  const subtitle = useMemo(() => {
    if (data?.evaluation?.subject) {
      return `${data.evaluation.subject.displayName} - ${
        data.evaluation.subject.title || 'No Title'
      }`;
    }
  }, [data]);

  const goals = useMemo(() => {
    return data?.evaluation?.goals?.map((goal) => {
      return {
        id: goal.id,
        name: goal.name,
        weight: goal.weight,
        status: goal.status.name,
        dueDate: goal.dueDate
          ? parse(goal.dueDate, dateFormat, new Date())
          : undefined,
        subgoals: goal.subgoals?.map((subgoal) => ({
          id: subgoal.id,
          name: subgoal.name,
          weight: subgoal.weight,
          status: subgoal.status.name,
          dueDate: subgoal.dueDate
            ? parse(subgoal.dueDate, dateFormat, new Date())
            : undefined,
        })),
      };
    });
  }, [data]);

  function accurateSum(numbers: number[], precision: number = 2): number {
    const factor = Math.pow(10, precision);
    return numbers.reduce((acc, num) => acc + Math.round(num * factor), 0) / factor;
  }  

  const totalWeight = useMemo(() => {
    const weights = goals?.map(goal => goal.weight || 0) || [];
    const combinedWeight = accurateSum(weights);
    return combinedWeight;
}, [goals]);

  const newGoalModalHelpers = createQueryParamModalHelpers('new-goal');

  const onAddGoal = useCallback(() => {
    newGoalModalHelpers.open();
  }, [newGoalModalHelpers]);

  const printRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <React.Fragment>
      {!data?.evaluation && errors ? (
        <React.Fragment>
          <EmptyDataMessage
            title={'You do not have access to this users goals.'}
            subtitle={`We could not show you this users goals because you don't have the right permissions to view them.`}
            icon={'user-lock'}
          />
        </React.Fragment>
      ) : !data && status == 'loading' ? (
        <FullWidthSpinner minHeight={300} />
      ) : (
        data && (
          <React.Fragment>
            <GoalSetMain
              title={title}
              subtitle={subtitle}
              goals={goals}
              totalWeight={totalWeight}
              handlePrint={handlePrint}
            />
            <GoalSetMain
              title={title}
              subtitle={subtitle}
              goals={goals}
              totalWeight={totalWeight}
              printRef={printRef}
            />
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

export type Goal = {
  id: number;
  name: string;
  weight?: number | null;
  status?: string;
  dueDate?: Date;
  subgoals?: Goal[];
};

interface GoalSetMainProps {
  title?: string;
  subtitle?: string;
  goals?: Goal[];
  totalWeight: number;
  printRef?: React.RefObject<HTMLDivElement>;
  handlePrint?: () => void;
}

const GoalSetPrintWrapper = styled.div<{ print: boolean }>`
${(props) =>
  props.print &&
  `
@media screen {
  visibility: hidden;
    height: 0;
    width: 1000px;
    overflow-y: hidden;
}
`}
}`;

const PrintWrapper: FunctionComponent<{ printRef: any}> = ({ children, printRef }) => {
  if (printRef) {
    return (
      <GoalSetPrintWrapper ref={printRef} print={!!printRef}>
        {children}
      </GoalSetPrintWrapper>
    );
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
};

const GoalSetMain: FunctionComponent<GoalSetMainProps> = ({
  title,
  subtitle,
  goals,
  totalWeight,
  printRef,
  handlePrint,
}) => {
  const match = useRouteMatch();
  const history = useHistory();

  const newGoalModalHelpers = createQueryParamModalHelpers('new-goal');

  const onAddGoal = useCallback(() => {
    newGoalModalHelpers.open();
  }, [newGoalModalHelpers]);

  const tools = useMemo(() => {
    if (!printRef) {
      return [
        <Button
          key={'print'}
          icon={'print'}
          variant={'outline'}
          onClick={handlePrint}
        >
          Print
        </Button>,
        <Button
          key={'set-new-goal'}
          icon={'plus'}
          variant={'outline'}
          onClick={onAddGoal}
        >
          Add Goal
        </Button>,
      ];
    } else {
      return [
        <Badge key={'badge'} color={'info'}>
          {`Printed on ${format(new Date(), 'MM/dd/yyyy')}`}
        </Badge>,
      ];
    }
  }, [onAddGoal, handlePrint, printRef]);

  return (
    <PrintWrapper printRef={printRef}>
      <PageHeader
        title={title ? `Goals: ${title}` : 'Loading...'}
        subtitle={subtitle}
        tools={tools}
      />
      <PageToolbar
        left={
          <React.Fragment>
            <ToolbarMessage
              style={{
                color:
                  totalWeight > 1
                    ? color.danger
                    : totalWeight < 1
                    ? color.primary
                    : color.success,
              }}
              message={
                totalWeight > 1
                  ? `Total Weight: ${formatPercentage(
                      totalWeight, null
                    )} - Lower weights of existing goals`
                  : totalWeight < 1
                  ? `Total Weight: ${formatPercentage(
                      totalWeight, null
                    )} - Edit existing goals or add another weighted goal to reach 100%`
                  : `Total Weight: ${formatPercentage(totalWeight, null)}`
              }
              icon={
                totalWeight > 1
                  ? 'exclamation-triangle'
                  : totalWeight < 1
                  ? 'info-circle'
                  : 'check-circle'
              }
            />
          </React.Fragment>
        }
      />
      <PageDetails>
        {goals?.length == 0 ? (
          <EmptyDataMessage
            icon={'list-ul'}
            title={'No Goals'}
            subtitle={'There arent any goals associated with this goal set.'}
            actionIcon={'list-ul'}
            actionTitle={'Add Goal'}
            action={onAddGoal}
          />
        ) : (
          <Row>
            {goals?.map((goal) => {
              return (
                  <Column key={goal.id}>
                    <GoalRow
                      id={goal.id}
                      name={goal.name}
                      outcome={goal.status}
                      weight={goal.weight}
                      dueDate={goal.dueDate}
                      subgoals={goal.subgoals}
                      contextUrl={match.url}
                      print={printRef ? true : false}
                    />
                  </Column>
              );
            })}
          </Row>
        )}
      </PageDetails>
      <Route
        path={`${match.path}/goal/:goalId`}
        render={() => (
          <GoalModal
            onClose={() => history.push(match.url)}
            contextUrl={match.url}
          />
        )}
      />
      {newGoalModalHelpers.isOpen() && (
        <NewGoalModal
          onClose={newGoalModalHelpers.close}
          onSuccess={() => false}
        />
      )}
    </PrintWrapper>
  );
};

export default GoalSet;

interface GoalProps {
  id: number;
  name: string;
  dueDate?: Date | null;
  outcome?: string;
  weight?: number | null;
  subgoals?: Goal[];
  contextUrl: string;
  print?: boolean;
}

const GoalStatus = styled.div`
  display: flex;
  align-items: center;
  ${font.size(12)};

  & > * {
    margin-right: 10px;
  }
`;

const GoalText = styled.div`
  color: ${color.textMedium};
  ${font.medium};
  ${font.size(12)};
`;

export const GoalRow: FunctionComponent<GoalProps> = ({
  id,
  name,
  dueDate,
  outcome,
  weight,
  subgoals,
  contextUrl,
  print = false,
}) => {
  const iconColor = useMemo(() => {
    switch (outcome) {
      case 'Complete':
        return color.success;
      case 'In Progress':
        return color.primary;
      default:
        return color.secondary;
    }
  }, [outcome]);

  const formattedWeight = useMemo(() => {
    return formatPercentage(weight, null);
  }, [weight]);

  const weightSubtitle = useMemo(() => {
    if (formattedWeight) {
      return `Goal Weight: ${formattedWeight}`;
    } else {
      return 'No Weight Applied';
    }
  }, [formattedWeight]);

  const subtitle = useMemo(() => {
    const subgoalPlurality = subgoals?.length == 1 ? 'Subgoal' : 'Subgoals';
    return `${weightSubtitle} - ${subgoals?.length || 0} ${subgoalPlurality}`;
  }, [weightSubtitle, subgoals]);

  return (
    <Link to={`${contextUrl}/goal/${id}`}>
      <GoalsRow print={print} last={true}>
        <GoalsRowLeft>
          <GoalTitles>
            <GoalTitle>{name}</GoalTitle>
            <GoalSubtitle>{subtitle}</GoalSubtitle>
            {subgoals && subgoals.length > 0 && (
              <GoalBody>
                {subgoals?.map((subgoal) => {
                  return (
                    <GoalBodyLine key={subgoal.id}>{subgoal.name}</GoalBodyLine>
                  );
                })}
              </GoalBody>
            )}
          </GoalTitles>
        </GoalsRowLeft>
        <GoalsRowRight>
          <GoalStatus>
            <GoalText>{outcome || 'Not Evaluated'}</GoalText>
            <FontAwesomeIcon
              icon={['fas', 'circle']}
              style={{ color: iconColor }}
            />
          </GoalStatus>
          <GoalText>
            {dueDate ? format(dueDate, monthDayYearFormat) : 'No Due Date'}
          </GoalText>
        </GoalsRowRight>
      </GoalsRow>
    </Link>
  );
};
