import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

export const SpacerDiv = styled.div<{padding: number; margin: number}>`
  padding: ${props => props.padding}px;
  margin: ${props => props.margin}px;
  width: 100%;
`

interface SpacerProps {
  padding?: number;
  margin?: number;
}

const Spacer: FunctionComponent<SpacerProps> = ({ padding = 10, margin = 0 }) => {
  return (
    <SpacerDiv padding={padding} margin={margin} />
  )
}

export default Spacer;