import { css } from "styled-components";
import Color from "color";

export const color = {
  info: "#359bdf", // blue
  success: "#30cea6", // green
  danger: "#f4516c", // red
  warning: "#ffb822", // yellow
  secondary: "#dadada", // light grey
  primary: "#359bdf", // light grey

  textDarkest: "#172b4d",
  textDark: "#42526E",
  textMedium: "#5E6C84",
  textLight: "#8993a4",
  textLightest: "#a9b0bc",
  textLink: "#0052cc",

  tableHeaderFont: "rgba(23, 43, 77, 0.54)",
  tableHeaderBackground: "#ebedf2",
  tableBorder: "#d1d3d8",
  tableOddRowBackground: "#fcfdfe",
  tableEvenRowBackground: "#fff",

  backgroundDarkPrimary: "#0747A6",
  backgroundMedium: "#dfe1e6",
  backgroundLight: "#ebecf0",
  backgroundLightest: "#F4F5F7",
  backgroundLightPrimary: "#D2E5FE",
  backgroundLightSuccess: "#E4FCEF",

  border: "#ebedf2",
  borderLightest: "#dfe1e6",
  borderLight: "#C1C7D0",
  borderInputFocus: "#4c9aff",

  disabledInputBackground: '#f2f2f2',
  disabledInputText: '#999999',

  nestedSidebar: {
    background: "#fafafa",
    border: "#ebedf2",
    separator: "#c7c7c7",
    link: "#42526E",
    icon: "#42526E",
    activeLink: "#42526E",
    activeBackground: "#e6e6e6",
    activeIcon: "#359bdf",
  },

  sidebar: {
    background: "#f7f7f7",
    border: "#ebedf2",
    separator: "#dadada",
    link: "#000",
    icon: "#000",
    activeLink: "#fff",
    activeBackground: "#359bdf",
    activeIcon: "#fff",
  },
  // sidebar: {
  //   background: "#111323",
  //   border: "#ebedf2",
  //   separator: "#35363e",
  //   link: "#fff",
  //   activeBackground: "#363844",
  //   activeIcon: "#359bdf",
  // },
};

export const sizes = {
  appSidebarWidth: 240,
  collapsedAppSidebarWidth: 62.5,
  navbarHeight: 50,
  defaultFontSize: 12,
};

export const zIndexValues = {
  modal: 1000,
  dropdown: 101,
  navLeft: 100
};

export const font = {
  regular:
    'font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica,Arial, sans-serif; font-weight: normal;',
  medium:
    'font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica,Arial, sans-serif; font-weight: 500;',
  bold:
    'font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica,Arial, sans-serif; font-weight: 600;',
  black:
    'font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica,Arial, sans-serif; font-weight: 700;',
  size: (size: number) => `font-size: ${size}px;`,
  defaultSize: `font-size: ${sizes.defaultFontSize}px;`,
};

// export const font = {
//   regular: 'font-family: SourceSansProRegular; font-weight: normal;',
//   medium: 'font-family: SourceSansProSemiBold; font-weight: normal;',
//   bold: 'font-family: SourceSansProBold; font-weight: normal;',
//   black: 'font-family: SourceSansProBlack; font-weight: normal;',
//   size: (size: number) => `font-size: ${size}px;`
// };

// export const font = {
//   regular: 'font-family: RubikRegular; font-weight: normal;',
//   medium: 'font-family: RubikMedium; font-weight: normal;',
//   bold: 'font-family: RubikBold; font-weight: normal;',
//   black: 'font-family: RubikBlack; font-weight: normal;',
//   size: (size: number) => `font-size: ${size}px;`
// };

// export const font = {
//   regular: 'font-family: NunitoRegular; font-weight: normal;',
//   medium: 'font-family: NunitoSemiBold; font-weight: normal;',
//   bold: 'font-family: NunitoBold; font-weight: normal;',
//   black: 'font-family: NunitoBlack; font-weight: normal;',
//   size: (size: number) => `font-size: ${size}px;`
// };

export const mixin = {
  darken: (colorValue: string, amount: number) =>
    Color(colorValue)
      .darken(amount)
      .string(),
  lighten: (colorValue: string, amount: number) =>
    Color(colorValue)
      .lighten(amount)
      .string(),
  rgba: (colorValue: string, opacity: number) =>
    Color(colorValue)
      .alpha(opacity)
      .string(),
  boxShadowMedium: css`
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  `,
  boxShadowDropdown: css`
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.31) 0px 0px 1px;
  `,
  truncateText: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  clickable: css`
    cursor: pointer;
    user-select: none;
  `,
  hardwareAccelerate: css`
    transform: translateZ(0);
  `,
  cover: css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `,
  placeholderColor: (colorValue: string) => css`
    ::-webkit-input-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    :-moz-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    ::-moz-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    :-ms-input-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
  `,
  scrollableY: css`
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  `,
  customScrollbar: ({
    width = 8,
    background = color.backgroundMedium
  } = {}) => css`
    &::-webkit-scrollbar {
      width: ${width}px;
    }
    &::-webkit-scrollbar-track {
      background: none;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 99px;
      background: ${background};
    }
  `,
  backgroundImage: (imageURL: string) => css`
    background-image: url("${imageURL}");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: ${color.backgroundLight};
  `,
  link: (colorValue = color.textLink) => css`
    cursor: pointer;
    color: ${colorValue};
    ${font.medium}
    &:hover, &:visited, &:active {
      color: ${colorValue};
    }
    &:hover {
      text-decoration: underline;
    }
  `,
  tag: (
    background = color.backgroundMedium,
    colorValue = color.textDarkest
  ) => css`
    display: inline-flex;
    align-items: center;
    height: 24px;
    padding: 0 8px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    color: ${colorValue};
    background: ${background};
    ${font.bold}
    ${font.size(12)}
    i {
      margin-left: 4px;
    }
  `
};
