import React, { FunctionComponent } from "react";
import {
  SidebarLinks,
  SidebarBottom,
  MainSidebarLink,
  MainSidebar,
  MainSidebarSeparator,
} from "shared/components";
import { useRouteMatch, useParams } from "react-router-dom";
import { MainSidebarSectionTitle } from "shared/components/sidebar/Sidebar";

const OrganizationHomeSidebar: FunctionComponent = () => {
  const match = useRouteMatch();
  const { orgName } = useParams<{ orgName: string }>();
  return (
    <React.Fragment>
      <MainSidebar>
        <SidebarLinks>
          <MainSidebarLink
            icon="home"
            title="Home"
            path={`/${orgName}/home`}
          />
          <MainSidebarLink
            icon="address-book"
            title="Directory"
            path={`/${orgName}/directory`}
          />
          <MainSidebarSeparator />
          <MainSidebarLink
            icon="bullseye-arrow"
            title={`My Goals`}
            path={`/${orgName}/goals`}
          />
          <MainSidebarLink
            icon="user-chart"
            title={`My Evaluation Center`}
            path={`/${orgName}/evaluations`}
          />
          <MainSidebarSeparator />
          <MainSidebarSectionTitle title={'Performance Reviews'}/>
          <MainSidebarLink
            icon="analytics"
            title="Employee Reviews"
            path={`/${orgName}/performance-reviews`}
          />
          {/* <MainSidebarSeparator />
          <MainSidebarSectionTitle title={'Wolff AI'}/>
          <MainSidebarLink
            icon="robot"
            title="Benefits AI"
            path={`/${orgName}/benefits-chat`}
          /> */}
        </SidebarLinks>
        <SidebarBottom>
          <MainSidebarSeparator />
          <MainSidebarLink
            icon="cog"
            title="General Settings"
            path={`/${orgName}/settings`}
          />
        </SidebarBottom>
      </MainSidebar>
    </React.Fragment>
  );
};

export default OrganizationHomeSidebar;
