import { useAppContext } from 'App';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import {
  Alert,
  Badge,
  Column,
  Debug,
  Dialog,
  Modal,
  PageDetails,
  PageHeader,
  Row,
  Section,
  SelectField,
  Tooltip,
} from 'shared/components';
import Button from 'shared/components/button/Button';
import NoAccess from 'shared/components/noAccess';
import { useNotification } from 'shared/components/notifications';
import { CenteredSpinner } from 'shared/components/spinner/Spinner';
import {
  EvaluationStatus,
  PeerRequestStatus,
  ReviewStatus,
} from 'shared/constants';
import {
  useAcceptPeerRequest,
  useApproveEvaluation,
  useCancelPeerRequest,
  useCreateManagerEvaluation,
  useCreateSelfEvaluation,
  useGetEvaluationSummary,
  useGetPeerRequestDenyReasons,
  usePublishEvaluationForEmployeeReview,
  useRejectPeerRequest,
  useSubmitEvaluationForReview,
} from 'shared/hooks/api';
import styled from 'styled-components';
import FinalEvaluationReviewModal from '../FinalEvaluationReview';
import ManagerEvaluationReviewModal from '../ManagerEvaluationReview';
import NewPeerRequestModal from '../NewPeerRequestModal';
import PeerReviewModal from '../PeerReviewModal';
import RequestChangesModal from '../RequestChangesModal';
import SelfEvaluationReviewModal from '../SelfEvaluationReview';
import ActionItem from './ActionItem';
import { PeerRequestByMe, PeerRequestFromOthers } from './PeerRequests';
import ReviewButton from './ReviewButton';
import { dateFormat, dateTimeOffsetFormat } from 'shared/utils/dateFormats';
import  parse from 'date-fns/parse';
import differenceInDays from 'date-fns/differenceInDays';
import isBefore from 'date-fns/isBefore';
import addYears from 'date-fns/addYears';
import isAfter from 'date-fns/isAfter';

interface EvaluationSummary {
  id: number;
  employee: { id: number; name: string };
  status?: { id: number; name: string };
  currentUserIsAdmin: boolean;
  currentUserIsManager: boolean;
  evaluationCycle: string;
  managerEvaluation?: Review;
  selfEvaluation?: Review;
  peerRequests: PeerRequest[];
  reviewsRequestedByPeers: PeerRequest[];
  changesRequested?: string;
  manager?: string;
  employeeSignedDate?: string;
  managerSignedDate?: string;
  managerReviewStartDate?: string;
  peerFeedbackStartDate?: string;
  releaseToEmployeeDate?: string;
  selfEvaluationStartDate?: string;
}

interface Review {
  id: number;
  status?: { id: number; name: string };
}

interface PeerRequest {
  id: number;
  status?: { id: number; name: string } | null;
  requestedReview?: Review | null;
  requestedPeer: Employee;
  reviewSubject: Employee;
  denyReason?: { id: number; name: string } | null;
}

interface Employee {
  id: number;
  displayName: string;
  title?: string | null;
  role?: { id: number; name: string } | null;
}

const EvaluationDetails: FunctionComponent = () => {
  const appContext = useAppContext();
  // const currentUserIsEvaluationEmployee = useMemo(() => {
  //   return appContext.currentUserId === evaluation.employee.id;
  // }, [appContext, evaluation]);

  const { evaluationId } = useParams<{ evaluationId: string }>();
  const match = useRouteMatch();

  const {
    data: evaluationSummaryData,
    errors,
    status,
  } = useGetEvaluationSummary(Number(evaluationId));

  const evaluationSummary: EvaluationSummary | undefined = useMemo(() => {
    if (evaluationSummaryData?.evaluation) {
      return {
        id: evaluationSummaryData.evaluation.id,
        employeeSignedDate:
          evaluationSummaryData.evaluation.employeeSignedDate ?? undefined,
        managerSignedDate:
          evaluationSummaryData.evaluation.managerSignedDate ?? undefined,
        employee: {
          id: evaluationSummaryData.evaluation.subject.id,
          name: evaluationSummaryData.evaluation.subject.displayName,
        },
        manager: evaluationSummaryData.evaluation.subject.manager?.displayName,
        changesRequested:
          evaluationSummaryData.evaluation.changesRequested ?? undefined,
        currentUserIsAdmin: evaluationSummaryData.evaluation.currentUserIsAdmin,
        currentUserIsManager:
          evaluationSummaryData.evaluation.subject.manager?.id ==
          appContext.currentUserId,
        status: evaluationSummaryData.evaluation.status ?? undefined,
        evaluationCycle: evaluationSummaryData.evaluation.evaluationCycle.name,
        managerEvaluation: evaluationSummaryData.evaluation.managerEvaluation
          ? {
            id: evaluationSummaryData.evaluation.managerEvaluation.id,
            status: evaluationSummaryData.evaluation.managerEvaluation.status
              ? evaluationSummaryData.evaluation.managerEvaluation.status
              : undefined,
          }
          : undefined,
        selfEvaluation: evaluationSummaryData.evaluation.selfEvaluation
          ? {
            id: evaluationSummaryData.evaluation.selfEvaluation.id,
            status: evaluationSummaryData.evaluation.selfEvaluation.status
              ? evaluationSummaryData.evaluation.selfEvaluation.status
              : undefined,
          }
          : undefined,
        peerRequests: evaluationSummaryData.evaluation.peerRequests,
        reviewsRequestedByPeers:
          evaluationSummaryData.evaluation.reviewsRequestedByPeers,
        managerReviewStartDate: evaluationSummaryData.evaluation.evaluationCycle.managerReviewStartDate ?? undefined,
        peerFeedbackStartDate: evaluationSummaryData.evaluation.evaluationCycle.peerFeedbackStartDate ?? undefined,
        releaseToEmployeeDate: evaluationSummaryData.evaluation.evaluationCycle.releaseToEmployeeDate ?? undefined,
        selfEvaluationStartDate: evaluationSummaryData.evaluation.evaluationCycle.selfEvaluationStartDate ?? undefined,
      };
    }
  }, [evaluationSummaryData]);

  const currentUserIsEvaluationEmployee = useMemo(() => {
    return evaluationSummary?.employee.id == appContext.currentUserId;
  }, [evaluationSummary]);

  const [
    isShowingSubmitForReviewDialog,
    setIsShowingSubmitForReviewDialog,
  ] = useState(false);
  const [
    submitForReview,
    { status: submitForReviewStatus },
  ] = useSubmitEvaluationForReview(Number(evaluationId));

  const onSubmitForReview = useCallback(async () => {
    try {
      const result = await submitForReview(Number(evaluationId));
      if (result.data?.submitEvaluationForReview) {
        notify({
          message: 'Evaluation Submitted for Review!',
        });
      } else if (result.errors) {
        console.log('errors', errors);
        const forbiddenError = result.errors.find(
          (x: any) => x?.extensions?.code == 'FORBIDDEN'
        );
        const badRequestError = result.errors.find(
          (x: any) => x?.extensions?.code == 'BAD_REQUESTT'
        );
        if (forbiddenError) {
          notify({
            duration: 5000,
            title: 'Permission Denied',
            variant: 'danger',
            message: forbiddenError?.message,
          });
        } else if (badRequestError) {
          notify({
            duration: 5000,
            title: 'Bad Request',
            variant: 'danger',
            message: badRequestError?.message,
          });
        } else {
          throw Error();
        }
      } else {
        throw Error();
      }
    } catch (error) {
      notify({
        duration: 5000,
        variant: 'danger',
        message:
          'An unknown error occcurred while submitting this evaluation for review!',
      });
    } finally {
      setIsShowingSubmitForReviewDialog(false);
    }
  }, [submitForReview, evaluationId]);

  const [isShowingApproveEvaluation, setIsShowingApproveEvaluation] = useState(
    false
  );
  const [
    approveEvaluation,
    { status: approvalEvaluationStatus },
  ] = useApproveEvaluation(Number(evaluationId));

  const onApprovalEvaluation = useCallback(async () => {
    try {
      const result = await approveEvaluation(Number(evaluationId));
      if (result.data?.approveEvaluation) {
        notify({
          message: 'Evaluation Approved!',
        });
      } else if (result.errors) {
        const forbiddenError = result.errors.find(
          (x: any) => x?.extensions?.code == 'FORBIDDEN'
        );
        const badRequestError = result.errors.find(
          (x: any) => x?.extensions?.code == 'BAD_REQUESTT'
        );
        if (forbiddenError) {
          notify({
            duration: 5000,
            title: 'Permission Denied',
            variant: 'danger',
            message: forbiddenError?.message,
          });
        } else if (badRequestError) {
          notify({
            duration: 5000,
            title: 'Bad Request',
            variant: 'danger',
            message: badRequestError?.message,
          });
        } else {
          throw Error();
        }
      } else {
        throw Error();
      }
    } catch (error) {
      notify({
        duration: 5000,
        variant: 'danger',
        message: 'An unknown error occcurred while approving this evaluation!',
      });
    } finally {
      setIsShowingApproveEvaluation(false);
    }
  }, [approveEvaluation, evaluationId]);

  const [
    isShowingRequestChangesForEvalution,
    setIsShowingRequestChangesForEvalution,
  ] = useState(false);

  const [
    isShowingPublishForEmployeeReview,
    setIsShowingPublishForEmployeeReview,
  ] = useState(false);
  const [
    publishForEmployeeReview,
    { status: publishForEmployeeReviewStatus },
  ] = usePublishEvaluationForEmployeeReview(Number(evaluationId));

  const onPublishEvaluationForEmployeeReview = useCallback(async () => {
    try {
      const result = await publishForEmployeeReview(Number(evaluationId));
      if (result.data?.publishEvaluationForEmployeeReview) {
        notify({
          message: 'Evaluation Published for Employee Review',
        });
      } else if (result.errors) {
        console.log('errors', errors);
        const forbiddenError = result.errors.find(
          (x: any) => x?.extensions?.code == 'FORBIDDEN'
        );
        const badRequestError = result.errors.find(
          (x: any) => x?.extensions?.code == 'BAD_REQUESTT'
        );
        if (forbiddenError) {
          notify({
            duration: 5000,
            title: 'Permission Denied',
            variant: 'danger',
            message: forbiddenError?.message,
          });
        } else if (badRequestError) {
          notify({
            duration: 5000,
            title: 'Bad Request',
            variant: 'danger',
            message: badRequestError?.message,
          });
        } else {
          throw Error();
        }
      } else {
        throw Error();
      }
    } catch (error) {
      notify({
        duration: 5000,
        variant: 'danger',
        message:
          'An unknown error occcurred while publishing this evalution for employee review!',
      });
    } finally {
      setIsShowingPublishForEmployeeReview(false);
    }
  }, [publishForEmployeeReview, evaluationId]);

  const [isShowingSelfReview, setIsShowingSelfReview] = useState(false);
  const [isShowingManagerReview, setIsShowingManagerReview] = useState(false);
  const [
    isShowingFinalEvaluationModal,
    setIsShowingFinalEvaluationModal,
  ] = useState(false);
  const [
    isShowingViewRequestedChangesModal,
    setIsShowingViewRequestedChangesModal,
  ] = useState(false);

  const publishForEmployeeReviewEnabled = useMemo(() => {
    if (!evaluationSummary) {
      return false;
    } else if (evaluationSummary.releaseToEmployeeDate) {
      const date = parse(evaluationSummary.releaseToEmployeeDate, dateFormat, new Date())
      if (isBefore(new Date(), date)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, [evaluationSummary]);

  const tools = useMemo(() => {
    const tools: React.ReactNode[] = [];
    if (
      (evaluationSummary?.status?.id == EvaluationStatus.InProgress ||
        evaluationSummary?.status?.id == EvaluationStatus.ChangesRequested) &&
      evaluationSummary?.currentUserIsManager
    ) {
      if (
        evaluationSummary?.changesRequested &&
        evaluationSummary.status.id == EvaluationStatus.ChangesRequested
      ) {
        tools.push(
          <Alert
            key={'changes-requested-details'}
            variant={'warning'}
            title={'Changes Requested'}
            message={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p
                  style={{ marginBottom: '10px' }}
                >{`Please make changes to this evaluation before submitting.`}</p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Button
                    key={'view-requested-changes'}
                    variant={'outline'}
                    onClick={() => setIsShowingViewRequestedChangesModal(true)}
                    style={{ marginRight: '10px' }}
                  >
                    {'View Requested Changes'}
                  </Button>
                  <Tooltip
                    content={
                      'The manager review must be completed before submitting for review.'
                    }
                    disabled={
                      evaluationSummary?.managerEvaluation?.status?.id ==
                      ReviewStatus.Completed
                    }
                  >
                    <span>
                      <Button
                        key={'submit-for-review'}
                        color={'primary'}
                        onClick={() => setIsShowingSubmitForReviewDialog(true)}
                        disabled={
                          evaluationSummary?.managerEvaluation?.status?.id !=
                          ReviewStatus.Completed
                        }
                      >
                        {'Submit for Review'}
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              </div>
            }
          />
        );
      } else if (
        evaluationSummary?.managerEvaluation?.status?.id ==
        ReviewStatus.Completed
      ) {
        tools.push(
          // <Tooltip
          //   content={
          //     'The manager review must be completed before submitting for review.'
          //   }
          //   disabled={
          //     evaluationSummary?.managerEvaluation?.status?.id ==
          //     ReviewStatus.Completed
          //   }
          // >
          //   {/* <span>
          //     <Button
          //       key={'submit-for-review'}
          //       color={'primary'}
          //       onClick={() => setIsShowingSubmitForReviewDialog(true)}
          //       disabled={
          //         evaluationSummary?.managerEvaluation?.status?.id !=
          //         ReviewStatus.Completed
          //       }
          //     >
          //       {'Submit for Review'}
          //     </Button>
          //   </span> */}
          // </Tooltip>
        );
      }
    }

    if (
      evaluationSummary?.status?.id == EvaluationStatus.Approved &&
      (evaluationSummary?.currentUserIsManager ||
        evaluationSummary?.currentUserIsAdmin)
    ) {
      tools.push(
        <Tooltip
          content={evaluationSummary.releaseToEmployeeDate ? `An evaluation cannot be published for employee review until ${evaluationSummary.releaseToEmployeeDate}.` : ''}
          disabled={publishForEmployeeReviewEnabled}
        >
          <span>
            <Button
              key={'publish-for-employee-review'}
              color={'primary'}
              onClick={() => setIsShowingPublishForEmployeeReview(true)}
              disabled={!publishForEmployeeReviewEnabled}
            >
              {'Publish for Employee Review'}
            </Button>
          </span>
        </Tooltip>
      );
    }

    if (
      evaluationSummary?.status?.id ==
      EvaluationStatus.PublishedForEmployeeReview &&
      (evaluationSummary?.currentUserIsManager ||
        evaluationSummary?.employee.id == appContext.currentUserId) &&
      (!evaluationSummary.employeeSignedDate ||
        !evaluationSummary.managerSignedDate)
    ) {
      tools.push(
        <Button
          key={'view-and-sign-final-evaluation'}
          color={'primary'}
          onClick={() => setIsShowingFinalEvaluationModal(true)}
        >
          {'View & Sign Evaluation'}
        </Button>
      );
    }

    if (evaluationSummary?.status?.id == EvaluationStatus.Completed) {
      tools.push(
        <Button
          key={'view-completed-evaluation'}
          variant={'outline'}
          onClick={() => setIsShowingFinalEvaluationModal(true)}
          icon={'badge-check'}
        >
          {'View Completed Evaluation'}
        </Button>
      );
    }

    if (
      evaluationSummary?.status?.id == EvaluationStatus.SubmittedForApproval &&
      evaluationSummary?.currentUserIsAdmin
    ) {
      tools.push(
        <Button
          key={'submit-for-review'}
          color={'warning'}
          onClick={() => setIsShowingRequestChangesForEvalution(true)}
        >
          {'Request Changes'}
        </Button>
      );
      tools.push(
        <Button
          key={'approve'}
          color={'success'}
          onClick={() => setIsShowingApproveEvaluation(true)}
        >
          {'Approve'}
        </Button>
      );
    }
    return tools;
  }, [evaluationSummary, onSubmitForReview, publishForEmployeeReviewEnabled]);

  const [
    createSelfEvaluation,
    { status: createSelfEvaluationStatus },
  ] = useCreateSelfEvaluation(Number(evaluationId));
  const [
    createManagerEvaluation,
    { status: createManagerEvaluationStatus },
  ] = useCreateManagerEvaluation(Number(evaluationId));
  const notify = useNotification();

  const onSelfEvaluationClicked = useCallback(async () => {
    if (
      evaluationSummary &&
      !evaluationSummary.selfEvaluation &&
      currentUserIsEvaluationEmployee
    ) {
      // create self evaluation and then open modal
      try {
        const result = await createSelfEvaluation(Number(evaluationId));

        if (result.data?.createSelfEvaluation) {
          notify({
            duration: 3000,
            title: 'Success!',
            message: 'Evaluation started!',
            variant: 'success',
          });
          setIsShowingSelfReview(true);
        } else if (result.errors) {
          const forbiddenError = result.errors.find(
            (x: any) => x?.extensions?.code == 'FORBIDDEN'
          );
          if (forbiddenError) {
            notify({
              duration: 5000,
              title: 'Permission Denied',
              variant: 'danger',
              message: forbiddenError?.message,
            });
          } else {
            throw Error();
          }
        } else {
          throw Error();
        }
      } catch (error) {
        notify({
          duration: 5000,
          title: 'Uh-oh!',
          variant: 'danger',
          message:
            'An unknown error occurred while creating the self-assessment!',
        });
      }
    } else if (evaluationSummary?.selfEvaluation) {
      // open self evaluation
      setIsShowingSelfReview(true);
    } else {
      // hmmm...
    }
  }, [evaluationSummary, evaluationId]);

  const onManagerEvaluationClicked = useCallback(async () => {
    if (
      evaluationSummary &&
      !evaluationSummary.managerEvaluation &&
      !currentUserIsEvaluationEmployee
    ) {
      // create self evaluation and then open modal
      try {
        const result = await createManagerEvaluation(Number(evaluationId));

        if (result.data?.createManagerEvaluation) {
          notify({
            duration: 3000,
            title: 'Success!',
            message: 'Evaluation started!',
            variant: 'success',
          });
          setIsShowingManagerReview(true);
        } else if (result.errors) {
          const forbiddenError = result.errors.find(
            (x: any) => x?.extensions?.code == 'FORBIDDEN'
          );
          if (forbiddenError) {
            notify({
              duration: 5000,
              title: 'Permission Denied',
              variant: 'danger',
              message: forbiddenError?.message,
            });
          } else {
            throw Error();
          }
        } else {
          throw Error();
        }
      } catch (error) {
        notify({
          duration: 5000,
          title: 'Uh-oh!',
          variant: 'danger',
          message:
            'An unknown error occurred while creating the manager review!',
        });
      }
    } else if (evaluationSummary?.managerEvaluation) {
      // open self evaluation
      setIsShowingManagerReview(true);
    } else {
      // hmmm...
    }
  }, [evaluationSummary, evaluationId]);

  const [acceptPeerRequest] = useAcceptPeerRequest(Number(evaluationId));
  const [
    rejectPeerRequest,
    { status: rejectPeerRequestStatus },
  ] = useRejectPeerRequest(Number(evaluationId));
  const [rejectPeerRequestReason, setRejectPeerRequestReason] = useState<
    { value: number; label: string } | undefined
  >(undefined);

  const {
    data: peerRequestDenyReasonsData,
    status: peerRequestDenyReasonStatus,
  } = useGetPeerRequestDenyReasons();
  const peerRequestDenyReasonOptions = useMemo(() => {
    return (
      peerRequestDenyReasonsData?.peerRequestDenyReasons?.map((x) => ({
        value: x.id,
        label: x.name,
      })) || []
    );
  }, [peerRequestDenyReasonsData]);

  const onAcceptPeerRequest = useCallback(
    async (id: number) => {
      try {
        const result = await acceptPeerRequest(id);
        console.log(result);
        if (result.data?.acceptPeerRequest) {
          notify({
            message: 'Peer request accepted!',
          });
        } else if (result.errors) {
          const forbiddenError = result.errors.find(
            (x: any) => x?.extensions?.code == 'FORBIDDEN'
          );
          if (forbiddenError) {
            notify({
              duration: 5000,
              title: 'Permission Denied',
              variant: 'danger',
              message: forbiddenError?.message,
            });
          } else {
            throw Error();
          }
        } else {
          throw Error();
        }
      } catch (error) {
        console.log('error', error);
        notify({
          duration: 5000,
          variant: 'danger',
          message:
            'An unknown error occcurred while accepting this peer request!',
        });
      }
    },
    [acceptPeerRequest]
  );

  const onRejectPeerRequest = useCallback(
    async (id: number, reasonId: number) => {
      try {
        const result = await rejectPeerRequest({ id, reasonId });
        console.log(result);
        if (result.data?.rejectPeerRequest) {
          notify({
            message: 'Peer request rejected.',
          });
        } else if (result.errors) {
          const forbiddenError = result.errors.find(
            (x: any) => x?.extensions?.code == 'FORBIDDEN'
          );
          if (forbiddenError) {
            notify({
              duration: 5000,
              title: 'Permission Denied',
              variant: 'danger',
              message: forbiddenError?.message,
            });
          } else {
            throw Error();
          }
        } else {
          throw Error();
        }
      } catch (error) {
        console.log('error', error);
        notify({
          duration: 5000,
          variant: 'danger',
          message:
            'An unknown error occcurred while rejecting this peer request!',
        });
      } finally {
        setIsShowingRejectPeerRequestDialog(undefined);
      }
    },
    [rejectPeerRequest]
  );

  const [
    isShowingNewPeerRequestModal,
    setIsShowingNewPeerRequestModal,
  ] = useState(false);

  const [isShowingPeerReviewModal, setIsShowingPeerReviewModal] = useState<
    number | undefined
  >(undefined);

  const [
    isShowingVoidedRequestsByEmployee,
    setIsShowingVoidedRequestsByEmployee,
  ] = useState(false);

  const [
    isShowingVoidedRequestsFromOthers,
    setIsShowingVoidedRequestsFromOthers,
  ] = useState(false);

  const [
    isShowingCancelPeerRequestDialog,
    setIsShowingCancelPeerRequestDialog,
  ] = useState<PeerRequest | undefined>(undefined);
  const [
    isShowingRejectPeerRequestDialog,
    setIsShowingRejectPeerRequestDialog,
  ] = useState<PeerRequest | undefined>(undefined);

  const [
    cancelPeerRequest,
    { status: cancelPeerRequestStatus },
  ] = useCancelPeerRequest(Number(evaluationId));

  const filteredRequestsByEmployee = useMemo(() => {
    if (!isShowingVoidedRequestsByEmployee) {
      return (
        evaluationSummary?.peerRequests.filter(
          (x) => x.status?.id != PeerRequestStatus.Void
        ) ?? []
      );
    } else {
      return evaluationSummary?.peerRequests ?? [];
    }
  }, [evaluationSummary, isShowingVoidedRequestsByEmployee]);

  const filteredRequestsFromOthers = useMemo(() => {
    if (!isShowingVoidedRequestsFromOthers) {
      return (
        evaluationSummary?.reviewsRequestedByPeers.filter(
          (x) => x.status?.id != PeerRequestStatus.Void
        ) ?? []
      );
    } else {
      return evaluationSummary?.reviewsRequestedByPeers ?? [];
    }
  }, [evaluationSummary, isShowingVoidedRequestsFromOthers]);

  const requestsSentByEmployee = useMemo(() => {
    return (
      evaluationSummaryData?.evaluation?.peerRequests.filter(
        (x) =>
          x.status.id != PeerRequestStatus.Declined &&
          x.status.id != PeerRequestStatus.Void
      ) ?? []
    );
  }, [evaluationSummaryData?.evaluation?.peerRequests]);

  const completedRequestsSentByEmployee = useMemo(() => {
    return requestsSentByEmployee.filter(
      (x) =>
        x.status.id == PeerRequestStatus.Accepted &&
        x.requestedReview?.status.id == ReviewStatus.Completed
    );
  }, [requestsSentByEmployee]);

  // const reviewRequestedOfEmployee = useMemo(() => {
  //   return (
  //     evaluationSummaryData?.evaluation?.reviewsRequestedByPeers.filter(
  //       (x) => x.status.id != PeerRequestStatus.Declined && x.status.id != PeerRequestStatus.Void
  //     ) ?? []
  //   );
  // }, [evaluationSummaryData?.evaluation?.reviewsRequestedByPeers]);

  const statusBadge = useMemo(() => {
    if (evaluationSummary?.status) {
      switch (evaluationSummary?.status?.id) {
        case EvaluationStatus.Approved:
          return (
            <Badge icon={'badge-check'} variant={'outline'} color={'success'}>
              {evaluationSummary.status?.name}
            </Badge>
          );
        case EvaluationStatus.Completed:
          return (
            <Badge icon={'badge-check'} color={'success'}>
              {evaluationSummary.status?.name}
            </Badge>
          );
        case EvaluationStatus.ChangesRequested:
          return (
            <Badge icon={'highlighter'} color={'warning'}>
              {evaluationSummary.status?.name}
            </Badge>
          );
        case EvaluationStatus.PublishedForEmployeeReview:
          return (
            <Badge icon={'file-export'} color={'primary'}>
              {evaluationSummary.status?.name}
            </Badge>
          );
        case EvaluationStatus.SubmittedForApproval:
          return (
            <Badge icon={'file-export'} color={'primary'}>
              {evaluationSummary.status?.name}
            </Badge>
          );
        default:
          return (
            <Badge icon={'hourglass-start'} color={'warning'}>
              In Progress
            </Badge>
          );
      }
    } else {
      return (
        <Badge icon={'hourglass-start'} color={'warning'}>
          In Progress
        </Badge>
      );
    }
  }, [evaluationSummary]);
  console.log("YEHH", evaluationSummary?.evaluationCycle);

  const viewingIndicator = useMemo(() => {
    let title: string | null = null;
    if (evaluationSummary?.employee.id == appContext.currentUserId) {
      title = 'My Evaluation';
    } else if (evaluationSummary?.currentUserIsManager) {
      title = 'Viewing as Manager';
    } else if (evaluationSummary?.currentUserIsAdmin) {
      title = 'Viewing as Admin';
    }
    if (title != null) {
      return <Badge variant={'outline'}>{title}</Badge>;
    }
  }, [evaluationSummary, appContext.currentUserId]);

  const selfEvaluationEnabled = useMemo(() => {
    if (!evaluationSummary) {
      return false;
    } else if (evaluationSummary.selfEvaluationStartDate) {
      const date = parse(evaluationSummary.selfEvaluationStartDate, dateFormat, new Date())
      if (isBefore(new Date(), date)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, [evaluationSummary]);

  const managerEvaluationEnabled = useMemo(() => {
    if (!evaluationSummary) {
      return false;
    } else if (evaluationSummary.managerReviewStartDate) {
      const date = parse(evaluationSummary.managerReviewStartDate, dateFormat, new Date())
      if (isBefore(new Date(), date)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, [evaluationSummary]);

  const peerRequestsEnabled = useMemo(() => {
    if (!evaluationSummary) {
      return false;
    } else if (evaluationSummary.peerFeedbackStartDate) {
      const date = parse(evaluationSummary.peerFeedbackStartDate, dateFormat, new Date())
      if (isBefore(new Date(), date)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, [evaluationSummary]);

  return (
    <React.Fragment>
      {!evaluationSummary && status == 'loading' ? (
        <CenteredSpinner />
      ) : (
        evaluationSummary && (
          <React.Fragment>
            <PageHeader
              title={`Evaluation - ${evaluationSummary.employee.name}`}
              subtitle={evaluationSummary.evaluationCycle}
              titleTools={
                <React.Fragment>
                  {viewingIndicator && viewingIndicator}
                  {statusBadge}
                </React.Fragment>
              }
              tabs={[{ path: '/summary', title: 'Summary' }]}
              tools={tools}
            />
            <Switch>
              <Route
                path={`${match.path}/summary`}
                exact={true}
                render={() => (
                  <PageDetails>
                    <Row>
                      <Column span={5}>
                        <Section title={'Action Items'} icon={'list-ul'}>
                          <ActionItem
                            title={'Completed 360 Reviews'}
                            subtitle={`Requested by ${evaluationSummary.employee.name}`}
                            right={
                              <div>{`${completedRequestsSentByEmployee.length} / ${requestsSentByEmployee.length} Requested Reviews`}</div>
                            }
                          />
                          <ActionItem
                            title={'Self-Assessment'}
                            subtitle={
                              evaluationSummary.selfEvaluation?.status?.name ||
                              'Not Started'
                            }
                            right={
                              <Tooltip disabled={selfEvaluationEnabled} content={evaluationSummary.selfEvaluationStartDate ? `Self-assessments will be enabled on ${evaluationSummary.selfEvaluationStartDate}` : 'Self-assessments are disabled at this time.'}>
                                <span>
                                  <ReviewButton
                                    status={
                                      evaluationSummary.selfEvaluation?.status
                                    }
                                    onClick={onSelfEvaluationClicked}
                                    disabled={
                                      !selfEvaluationEnabled ||
                                      !currentUserIsEvaluationEmployee &&
                                      !(
                                        evaluationSummary.selfEvaluation?.status
                                          ?.id == ReviewStatus.Completed
                                      )
                                    }
                                    isWorking={
                                      createSelfEvaluationStatus == 'loading'
                                    }
                                  />
                                </span>
                              </Tooltip>
                            }
                          />
                          <ActionItem
                            title={'Manager Review'}
                            subtitle={
                              evaluationSummary.managerEvaluation?.status
                                ?.name || 'Not Started'
                            }
                            right={
                              <Tooltip disabled={managerEvaluationEnabled} content={evaluationSummary.managerReviewStartDate ? `Manager reviews will be enabled on ${evaluationSummary.managerReviewStartDate}` : 'Manager reviews are disabled at this time.'}>
                                <span>
                                  <ReviewButton
                                    status={
                                      evaluationSummary.managerEvaluation?.status
                                    }
                                    onClick={onManagerEvaluationClicked}
                                    disabled={!managerEvaluationEnabled || currentUserIsEvaluationEmployee}
                                    isWorking={
                                      createManagerEvaluationStatus == 'loading'
                                    }
                                  />
                                </span>
                              </Tooltip>
                            }
                          />
                        </Section>
                      </Column>
                      <Column span={7}>
                        <Section
                          title={`360 Reviews: Requested by ${currentUserIsEvaluationEmployee
                              ? 'Me'
                              : evaluationSummary.employee.name
                            }`}
                          icon={'user-clock'}
                          subtitle={'3 to 5 reviews suggested'}
                          right={[
                            <Button
                              key={'show-void'}
                              variant={'simple'}
                              icon={'eye-slash'}
                              onClick={() =>
                                setIsShowingVoidedRequestsByEmployee(
                                  !isShowingVoidedRequestsByEmployee
                                )
                              }
                            >
                              {isShowingVoidedRequestsByEmployee
                                ? 'Hide Voided Requests'
                                : 'Show Voided Requests'}
                            </Button>,
                            <Tooltip disabled={selfEvaluationEnabled} content={evaluationSummary.selfEvaluationStartDate ? `360 Review requests will be enabled on ${evaluationSummary.selfEvaluationStartDate}` : 'Requesting 360 reviews is disabled at this time.'}>
                              <span>
                              <Button
                                key={'new-request'}
                                disabled={!selfEvaluationEnabled}
                                variant={'outline'}
                                icon={'plus'}
                                onClick={() =>
                                  setIsShowingNewPeerRequestModal(true)
                                }
                              >
                                New Request
                              </Button>
                              </span>
                            </Tooltip>,
                          ]}
                        >

                          {filteredRequestsByEmployee.length > 0 ? (
                            filteredRequestsByEmployee.map((peerRequest) => {
                              return (
                                <PeerRequestByMe
                                  key={peerRequest.id}
                                  employee={
                                    peerRequest.requestedPeer.displayName
                                  }
                                  employeePosition={
                                    peerRequest.requestedPeer.role?.name ??
                                    peerRequest.requestedPeer.title ??
                                    'N/A'
                                  }
                                  status={peerRequest.status ?? undefined}
                                  reviewStatus={
                                    peerRequest.requestedReview?.status
                                  }
                                  currentUserIsEvaluationEmployee={
                                    currentUserIsEvaluationEmployee
                                  }
                                  onReviewClicked={() =>
                                    setIsShowingPeerReviewModal(peerRequest.id)
                                  }
                                  denyReason={
                                    peerRequest.denyReason?.name ?? undefined
                                  }
                                  onCancel={
                                    peerRequest.status?.id ==
                                      PeerRequestStatus.Void
                                      ? undefined
                                      : () =>
                                        setIsShowingCancelPeerRequestDialog(
                                          peerRequest
                                        )
                                  }
                                />
                              );
                            })
                          ) : (
                            <NoAccess
                              icon={'empty-set'}
                              title={'No 360 Requests to others'}
                              subtitle={`You haven't made any requests to others...`}
                            />
                          )}
                        </Section>
                      </Column>
                      <Column span={5} />
                      <Column span={7}>
                        <Section
                          title={`360 Reviews: Requested ${currentUserIsEvaluationEmployee
                              ? 'from Others'
                              : `for ${evaluationSummary.employee.name} to complete`
                            }`}
                          icon={'user-friends'}
                          right={[
                            <Button
                              key={'show-void'}
                              variant={'simple'}
                              icon={'eye-slash'}
                              onClick={() =>
                                setIsShowingVoidedRequestsFromOthers(
                                  !isShowingVoidedRequestsFromOthers
                                )
                              }
                            >
                              {isShowingVoidedRequestsFromOthers
                                ? 'Hide Voided Requests'
                                : 'Show Voided Requests'}
                            </Button>,
                          ]}
                        >
                          {filteredRequestsFromOthers.length > 0 ? (
                            filteredRequestsFromOthers.map((peerRequest) => {
                              return (
                                <PeerRequestFromOthers
                                  key={peerRequest.id}
                                  disabled={!peerRequestsEnabled}
                                  disabledMessage={evaluationSummary.peerFeedbackStartDate ? `Performing actions on 360 Feedback requests will be enabled on ${evaluationSummary.peerFeedbackStartDate}` : undefined}
                                  employee={
                                    peerRequest.reviewSubject.displayName
                                  }
                                  employeePosition={
                                    peerRequest.reviewSubject.role?.name ??
                                    peerRequest.reviewSubject.title ??
                                    'N/A'
                                  }
                                  status={peerRequest.status ?? undefined}
                                  reviewStatus={
                                    peerRequest.requestedReview?.status
                                  }
                                  denyReason={
                                    peerRequest.denyReason?.name ?? undefined
                                  }
                                  currentUserIsEvaluationEmployee={
                                    currentUserIsEvaluationEmployee
                                  }
                                  onReviewClicked={() =>
                                    setIsShowingPeerReviewModal(peerRequest.id)
                                  }
                                  onAccepted={async () => {
                                    await onAcceptPeerRequest(peerRequest.id);
                                  }}
                                  onRejected={async () => {
                                    setIsShowingRejectPeerRequestDialog(
                                      peerRequest
                                    );
                                  }}
                                />
                              );
                            })
                          ) : (
                            <NoAccess
                              icon={'empty-set'}
                              title={'No 360 Requests from others'}
                              subtitle={`You have not been requested to complete a 360 review for someone else...`}
                            />
                          )}
                        </Section>
                      </Column>
                    </Row>
                    {/* <Debug state={evaluationSummary} /> */}
                  </PageDetails>
                )}
              />
              <Redirect to={`${match.url}/summary`} />
            </Switch>
            {isShowingCancelPeerRequestDialog && (
              <Dialog
                variant={'danger'}
                isOpen={true}
                title={'Cancel/Decline 360 Feedback Request'}
                confirmTitle={'Yes, cancel/decline request'}
                message={`Are you sure you want to cancel/decline the 360 Feedback request that has been sent to ${isShowingCancelPeerRequestDialog.requestedPeer.displayName}?`}
                isWorking={cancelPeerRequestStatus == 'loading'}
                onConfirm={async () => {
                  try {
                    const result = await cancelPeerRequest({
                      id: isShowingCancelPeerRequestDialog.id,
                      reasonId: rejectPeerRequestReason?.value!,
                    });
                    console.log(result);
                    if (result.data?.cancelPeerRequest) {
                      setIsShowingCancelPeerRequestDialog(undefined);
                      notify({
                        message: 'Peer request cancelled!',
                      });
                    } else if (result.errors) {
                      console.log('errors', errors);
                      const forbiddenError = result.errors.find(
                        (x: any) => x?.extensions?.code == 'FORBIDDEN'
                      );
                      if (forbiddenError) {
                        notify({
                          duration: 5000,
                          title: 'Permission Denied',
                          variant: 'danger',
                          message: forbiddenError?.message,
                        });
                      } else {
                        throw Error();
                      }
                    } else {
                      throw Error();
                    }
                  } catch (error) {
                    console.log('error', error);
                    notify({
                      duration: 5000,
                      variant: 'danger',
                      message:
                        'An unknown error occcurred while canceling this peer request goal!',
                    });
                  }
                }}
                onClose={() => setIsShowingCancelPeerRequestDialog(undefined)}
                onCancel={() => setIsShowingCancelPeerRequestDialog(undefined)}
                valid={rejectPeerRequestReason != undefined}
              >
                <DialogField>
                  <SelectField
                    name={'reason'}
                    label={'Select a reason:'}
                    placeholder={'Select a reason...'}
                    onChange={(option) => {
                      if (option != null) {
                        setRejectPeerRequestReason(option);
                      }
                    }}
                    value={rejectPeerRequestReason}
                    options={peerRequestDenyReasonOptions}
                    isLoading={peerRequestDenyReasonStatus == 'loading'}
                  />
                </DialogField>
              </Dialog>
            )}
            {isShowingSubmitForReviewDialog && (
              <Dialog
                variant={'info'}
                isOpen={true}
                title={'Submit Evaluation for Review'}
                confirmTitle={'Yes, Submit to HR'}
                message={`Are you sure you want to submit this evaluation for ${evaluationSummary.employee.name} to HR for review?`}
                isWorking={submitForReviewStatus == 'loading'}
                onConfirm={onSubmitForReview}
                onClose={() => setIsShowingSubmitForReviewDialog(false)}
                onCancel={() => setIsShowingSubmitForReviewDialog(false)}
              />
            )}
            {isShowingApproveEvaluation && (
              <Dialog
                variant={'success'}
                isOpen={true}
                title={'Approval Evaluation'}
                confirmTitle={'Yes, approve'}
                message={`Are you sure you want to approve this evaluation for ${evaluationSummary.employee.name}? Doing so will allow their manager to share this evalution with them when they are ready.`}
                isWorking={approvalEvaluationStatus == 'loading'}
                onConfirm={onApprovalEvaluation}
                onClose={() => setIsShowingApproveEvaluation(false)}
                onCancel={() => setIsShowingApproveEvaluation(false)}
              />
            )}
            {isShowingPublishForEmployeeReview && (
              <Dialog
                variant={'info'}
                isOpen={true}
                title={'Publish for Employee Review'}
                confirmTitle={'Yes, publish'}
                message={`Are you sure you want to publish this evaluation so that it can be reviewed by ${evaluationSummary.employee.name}?`}
                isWorking={publishForEmployeeReviewStatus == 'loading'}
                onConfirm={onPublishEvaluationForEmployeeReview}
                onClose={() => setIsShowingPublishForEmployeeReview(false)}
                onCancel={() => setIsShowingPublishForEmployeeReview(false)}
              />
            )}
            {isShowingPeerReviewModal && (
              <PeerReviewModal
                onClose={() => setIsShowingPeerReviewModal(undefined)}
                peerRequestId={isShowingPeerReviewModal}
                evaluationId={Number(evaluationId)}
                unsubmitEnabled={isWithin30Days(evaluationSummary.evaluationCycle)}
              />
            )}
            {isShowingRequestChangesForEvalution && (
              <RequestChangesModal
                onClose={() => setIsShowingRequestChangesForEvalution(false)}
                onSuccess={() => setIsShowingRequestChangesForEvalution(false)}
                evaluationId={Number(evaluationId)}
              />
            )}
            {isShowingNewPeerRequestModal && (
              <NewPeerRequestModal
                onClose={() => setIsShowingNewPeerRequestModal(false)}
                onSuccess={() => setIsShowingNewPeerRequestModal(false)}
                evaluationId={Number(evaluationId)}
              />
            )}
            {isShowingSelfReview && (
              <SelfEvaluationReviewModal
                evaluationId={Number(evaluationId)}
                currentUserIsEvaluationEmployee={
                  currentUserIsEvaluationEmployee
                }
                onClose={() => setIsShowingSelfReview(false)}
                employeeName={evaluationSummary.employee.name}
                evaluationCycleName={evaluationSummary.evaluationCycle}
                unsubmitEnabled={isWithin30Days(evaluationSummary.evaluationCycle)}
              />
            )}
            {isShowingManagerReview && (
              <ManagerEvaluationReviewModal
                evaluationId={Number(evaluationId)}
                currentUserIsEvaluationEmployee={
                  currentUserIsEvaluationEmployee
                }
                onClose={() => setIsShowingManagerReview(false)}
                employeeName={evaluationSummary.employee.name}
                evaluationCycleName={evaluationSummary.evaluationCycle}
                unsubmitEnabled={isWithin30Days(evaluationSummary.evaluationCycle)}
              />
            )}
            {isShowingFinalEvaluationModal && (
              <FinalEvaluationReviewModal
                evaluationId={Number(evaluationId)}
                currentUserIsEvaluationEmployee={
                  currentUserIsEvaluationEmployee
                }
                onClose={() => setIsShowingFinalEvaluationModal(false)}
                employeeName={evaluationSummary.employee.name}
                evaluationCycleName={evaluationSummary.evaluationCycle}
                unsubmitEnabled={isWithin30Days(evaluationSummary.evaluationCycle)}
              />
            )}
            {isShowingRejectPeerRequestDialog && (
              <Dialog
                isOpen={true}
                variant={'danger'}
                title={'Decline 360 Feedback Request'}
                message={`Are you sure you want to decline to provide 360 Feedback about ${isShowingRejectPeerRequestDialog.reviewSubject.displayName}?`}
                onClose={() => {
                  setIsShowingRejectPeerRequestDialog(undefined);
                  setRejectPeerRequestReason(undefined);
                }}
                onCancel={() => {
                  setIsShowingRejectPeerRequestDialog(undefined);
                  setRejectPeerRequestReason(undefined);
                }}
                onConfirm={() =>
                  onRejectPeerRequest(
                    isShowingRejectPeerRequestDialog.id,
                    rejectPeerRequestReason?.value!
                  )
                }
                confirmTitle={'Decline Request'}
                cancelTitle={'Cancel'}
                isWorking={rejectPeerRequestStatus == 'loading'}
                valid={rejectPeerRequestReason != undefined}
              >
                <DialogField>
                  <SelectField
                    name={'reason'}
                    label={'Select a reason:'}
                    placeholder={'Select a reason...'}
                    onChange={(option) => {
                      if (option != null) {
                        setRejectPeerRequestReason(option);
                      }
                    }}
                    value={rejectPeerRequestReason}
                    options={peerRequestDenyReasonOptions}
                    isLoading={peerRequestDenyReasonStatus == 'loading'}
                  />
                </DialogField>
              </Dialog>
            )}
            {isShowingViewRequestedChangesModal &&
              evaluationSummary.changesRequested && (
                <Modal
                  isOpen={true}
                  onClose={() => setIsShowingViewRequestedChangesModal(false)}
                  title={'Requested Changes'}
                  size={'sm'}
                >
                  <PageDetails>
                    <p>{evaluationSummary.changesRequested}</p>
                  </PageDetails>
                </Modal>
              )}
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

const DialogField = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

function isWithin30Days(evaluationCycle: string): boolean {
  // Extract the year from the evaluationCycle string
  const yearMatch = evaluationCycle.match(/\d{4}/);
  if (!yearMatch) {
    // Handle the case where the year is not found in the string
    return false;
  }
  const year = parseInt(yearMatch[0], 10);
  // Calculate the start of the next year
  const startOfNextYear = addYears(new Date(year, 0, 1), 1);
  // Calculate the current date
  const currentDate = new Date();
  // Calculate the difference in days
  const daysDifference = differenceInDays(currentDate, startOfNextYear);
  // Check if the difference is greater than or equal to 60 days
  if (daysDifference >= 30) {
    return false;
  }

  // If not, it's within 30 days
  return true;
}

export default EvaluationDetails;
