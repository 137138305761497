import styled from "styled-components"
import { font, color } from "shared/utils/styles"
import { FieldWrapperProps } from "."

export const FieldContainer = styled.div<FieldWrapperProps>`
  display: flex;
  flex-direction: ${props => props.horizontal ? 'row' : 'column'};
  justify-content: ${props => props.labelOnRight ? 'flex-start' : 'flex-end'};
  ${props => props.fullWidth && `width: 100%;`}
  ${props => !props.horizontal ? `
    & > *:not(:last-child) {
      margin-bottom: 5px;
    }
  ` : `
    align-items: center;
    & > *:not(:last-child) {
      margin-right: 5px;
    }
  `} 
`

export const FieldHeader = styled.div<FieldWrapperProps>`
  display: inline-flex;
  align-content: center;
  align-items: center;
  width: 100%;
  justify-content: ${props => props.labelOnRight ? 'flex-end' : 'flex-start'};

  & > *:not(:last-child) {
    margin-${props => props.labelOnRight ? 'left' : 'right' }: 10px;
  }
`

export const FieldLabel = styled.div`
  ${font.defaultSize};
  ${font.medium};
`

export const FieldErrorIcon = styled.div`
  ${font.defaultSize};
  color: ${color.danger};
`

export const FormFieldWrapper = styled.div`
  margin: 10px 0;
`