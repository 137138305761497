import React, { FunctionComponent, useMemo } from 'react';
import {
  match,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { Button, PageHeader } from 'shared/components';
import { usePageTitle } from 'shared/hooks';
import All from './All';
import EmployeeDetails from './EmployeeDetails';
import DirectReports from './DirectReports';
import { useAppContext } from 'App';
import { useGetDirectory } from 'shared/hooks/api';
import { createQueryParamModalHelpers } from 'shared/utils/queryParamModal';
import NewUserModal from './modals/NewUserModal';
import EmployeeRoles from './EmployeeRoles';
import NewEmployeeRoleModal from './modals/NewEmployeeRoleModal';

const Directory = () => {
  usePageTitle('Directory');
  const match = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${match.path}/all`}
        exact={true}
        render={() => <DirectoryMain match={match} />}
      />
      <Route
        path={`${match.path}/direct-reports`}
        exact={true}
        render={() => <DirectoryMain match={match} />}
      />
      <Route
        path={`${match.path}/employee-roles`}
        exact={true}
        render={() => <DirectoryMain match={match} />}
      />
      <Route
        path={`${match.path}/:employeeId`}
        render={() => <EmployeeDetails/>}
      />
      <Redirect to={`${match.url}/all`} />
    </Switch>
  );
};

interface DirectoryMainProps {
  match: match<{}>;
}

const DirectoryMain: FunctionComponent<DirectoryMainProps> = ({ match }) => {
  const appContext = useAppContext();
  const { data, status, refetch } = useGetDirectory(appContext.currentUserId);
  
  const users = useMemo(() => {
    return data?.users?.map((user) => ({
      id: user.id,
      name: user.displayName,
      email: user.email,
      title: user.title,
      role: user.role?.name,
      company: user.company.name,
      department: user.department.name,
      manager: user.manager?.displayName ?? undefined,
      managerEmail: user.manager?.email ?? undefined,
    }));
  }, [data]);

  const directReports = useMemo(() => {
    return data?.directReports?.map((user) => ({
      id: user.id,
      name: user.displayName,
      email: user.email,
      title: user.title,
      role: user.role?.name,
      company: user.company.name,
      department: user.department.name,
      manager: user.manager?.displayName ?? undefined,
      managerEmail: user.manager?.email ?? undefined,
    }));
  }, [data]);

  const newUserModalHelpers = createQueryParamModalHelpers('new-user');
  const newEmployeeRoleModalHelpers = createQueryParamModalHelpers('new-employee-role');
  
  const tools = useMemo(() => {
    if (data?.canEditUsers) {
      return [
        <Button icon={'plus'} variant={'outline'} key={'add-employee'} onClick={newUserModalHelpers.open}>Add Employee</Button>,
        <Button icon={'plus'} variant={'outline'} key={'add-employee-role'} onClick={newEmployeeRoleModalHelpers.open}>Add Role Scorecard</Button>
      ]
    } else {
      return []
    }
  }, [data, newUserModalHelpers, newEmployeeRoleModalHelpers])

  return (
    <React.Fragment>
      <PageHeader
        title={'Directory'}
        tabs={[
          { path: '/all', title: 'All', pinned: true, icon: 'users' },
          { path: '/direct-reports', title: 'Direct Reports' },
          { path: '/employee-roles', title: 'Role Scorecards' },
        ]}
        match={match}
        tools={tools}
      />
      <Switch>
        <Route path={`${match.path}/all`} exact={true} render={() => <All users={users} isLoading={status == 'loading'}/>} />
        <Route
          path={`${match.path}/direct-reports`}
          exact={true}
          render={() => <DirectReports users={directReports} isLoading={status == 'loading'}/>}
        />
        <Route
          path={`${match.path}/employee-roles`}
          exact={true}
          render={() => <EmployeeRoles/>}
        />
        <Redirect to={`${match.url}/all`} />
      </Switch>
      {newUserModalHelpers.isOpen() && <NewUserModal onClose={newUserModalHelpers.close} onSuccess={() => refetch}/>}
      {newEmployeeRoleModalHelpers.isOpen() && <NewEmployeeRoleModal onClose={newEmployeeRoleModalHelpers.close} onSuccess={() => refetch}/>}
    </React.Fragment>
  );
};

export default Directory;
