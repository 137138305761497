import React, { FunctionComponent } from 'react';
import {
  Column,
  FormModal,
  InputFormikField,
  Row,
} from 'shared/components';
import * as Yup from 'yup';
import { useNotification } from 'shared/components/notifications';
import { useCreateGoal } from 'shared/hooks/api';
import { CreateGoalInput } from 'shared/hooks/api/graphql/generated';
import { useParams } from 'react-router-dom';

interface NewGoalModalProps {
  onClose: () => void;
  onSuccess: () => void;
  parentId?: number;
}

type GoalFormValues = {
  name: string;
  weight?: number | null;
};

export const goalFormSchema: Yup.ObjectSchema<GoalFormValues> = Yup.object()
  .shape({
    name: Yup.string().required().label('Name'),
    weight: Yup.number().min(0).max(100).nullable().label('Weight'),
  })
  .required();

const NewGoalModal: FunctionComponent<NewGoalModalProps> = ({
  onClose,
  onSuccess,
  parentId,
}) => {
  const notify = useNotification();
  const { goalSetId } = useParams<{ goalSetId: string }>();

  const [createGoal, { status }] = useCreateGoal({
    throwOnError: true,
  });

  return (
    <FormModal<GoalFormValues>
      title={'New Goal'}
      submitTitle={'Add Goal'}
      handleSubmit={async (form, resetForm) => {
        const input: CreateGoalInput = {
          name: form.name,
          evaluationId: Number(goalSetId),
          evaluationObjectiveParentId: parentId,
          objectiveWeight: form.weight && form.weight / 100,
        };
        try {
          const result = await createGoal(input);
          if (!result.errors) {
            notify({
              duration: 3000,
              title: 'Success!',
              message: 'New Goal Added!',
              variant: 'success',
            });
            resetForm();
            onClose();
            onSuccess();
          } else {
            notify({
              duration: 5000,
              title: 'Yikes!',
              variant: 'danger',
              message: 'An unknown error occurred while creating this goal!',
            });
          }
        } catch {
          notify({
            duration: 5000,
            title: 'Yikes!',
            variant: 'danger',
            message: 'An unknown error occurred while creating this goal!',
          });
        }
      }}
      isWorking={status == 'loading'}
      onClose={onClose}
      validationSchema={goalFormSchema}
    >
      {() => (
        <React.Fragment>
          <Row>
            <Column>
              <InputFormikField
                label={'Name'}
                name={'name'}
                placeholder={'Enter a name for the goal...'}
              />
            </Column>
            <Column>
              <InputFormikField
                name={'weight'}
                label={'Weight'}
                placeholder={'Enter a percentage...'}
                icon={'percent'}
                iconOnRight={true}
              />
            </Column>
          </Row>
        </React.Fragment>
      )}
    </FormModal>
  );
};

export default NewGoalModal;
