import { GridApi, ColumnApi, GridReadyEvent, SortChangedEvent, GridColumnsChangedEvent, Column, ColumnRowGroupChangedEvent, RowDataChangedEvent, RowDataUpdatedEvent } from "ag-grid-community";
import { useRef, useState, useCallback } from "react";

export interface SortModel {
  colId: string;
  sort: string | 'asc' | 'desc';
}

export interface TableApi {
  gridApi: GridApi;
  columnApi: ColumnApi;
}

interface UseTableProps {
  onGridReady?: (params: GridReadyEvent) => void;
  onSortChanged?: (params: SortChangedEvent) => void;
  onGridColumnsChanged?: (params: GridColumnsChangedEvent) => void;
  autoSizeColumns?: boolean;
}

export const useTable = ({
  onGridReady,
  onSortChanged,
  onGridColumnsChanged,
  autoSizeColumns = true,
}: UseTableProps = {}) => {
  const tableRef = useRef<TableApi>();
  const [searchText, setSearchText] = useState('');
  const [sortModel, setLocalSortModel] = useState<SortModel[]>([]);
  const [groupedColumns, setLocalGroupedColumns] = useState<string[]>([]);
  const [gridColumns, setGridColumns] = useState<Column[]>([]);

  const automaticallySizeColumns = (params: GridReadyEvent) => {
    const allColumnIds: string[] = [];
    params.columnApi.getAllColumns().forEach(function (column: Column) {
      allColumnIds.push(column.getColId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, false);
  }

  const onGridReadyWrapper = useCallback(
    (params: GridReadyEvent) => {
      tableRef.current = {
        gridApi: params.api,
        columnApi: params.columnApi,
      };
      setLocalSortModel(params.api.getSortModel());
      setLocalGroupedColumns(params.columnApi.getRowGroupColumns().map(col => col.getColId()));
      if (onGridReady) onGridReady(params);
      if (autoSizeColumns) automaticallySizeColumns(params);
    },
    [setLocalSortModel, onGridReady]
  );

  const onRowDataChangedWrapper = (params: RowDataChangedEvent | RowDataUpdatedEvent) => {
    const model = params.api.getModel();
    if (model.getRowCount() != 0 && autoSizeColumns) {
      automaticallySizeColumns(params);
    }
  }

  const onSortChangedWrapper = useCallback(
    (params: SortChangedEvent) => {
      const sortModel = params.api.getSortModel();
      setLocalSortModel(sortModel);
      if (onSortChanged) onSortChanged(params);
      if (autoSizeColumns) automaticallySizeColumns(params);
    },
    [setLocalSortModel]
  );

  const onGridColumnsChangedWrapper = useCallback(
    (params: GridColumnsChangedEvent) => {
      const columns = params.columnApi.getAllColumns();
      setGridColumns(columns);
      if (onGridColumnsChanged) onGridColumnsChanged(params);
      if (autoSizeColumns) automaticallySizeColumns(params);
    },
    [setGridColumns]
  );

  const onColumnRowGroupChangedWrapper = useCallback((params: ColumnRowGroupChangedEvent) => {
    setLocalGroupedColumns(params.columnApi.getRowGroupColumns().map(col => col.getColId()));
    if (autoSizeColumns) automaticallySizeColumns(params);
  }, [setLocalGroupedColumns])

  const setSortModel = useCallback(
    (sortModel: SortModel[]) => {
      return tableRef.current?.gridApi.setSortModel(sortModel);
    },
    [tableRef]
  );

  const setGroupedColumns = useCallback(
    (groupedColumns: string[]) => {
      return tableRef.current?.columnApi.setRowGroupColumns(groupedColumns)
    },
    [tableRef]
  );

  const onRowGroupOpenedOrClosed = useCallback((params) => {
    if (autoSizeColumns) automaticallySizeColumns(params);
  }, [])

  const resizeColumns = useCallback(() => {
    if (tableRef.current) {
      const allColumnIds: string[] = [];
      tableRef.current.columnApi.getAllColumns().forEach(function (column: Column) {
        allColumnIds.push(column.getColId());
      });
      tableRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }
  }, [tableRef])

  return {
    tableRef,
    searchText,
    setSearchText,
    sortModel,
    setSortModel,
    groupedColumns,
    setGroupedColumns,
    gridColumns,
    gridEvents: {
      onGridReady: onGridReadyWrapper,
      onSortChanged: onSortChangedWrapper,
      onGridColumnsChanged: onGridColumnsChangedWrapper,
      onColumnRowGroupChanged: onColumnRowGroupChangedWrapper,
      onRowDataChanged: onRowDataChangedWrapper,
      onRowDataUpdated: onRowDataChangedWrapper,
      onRowGroupOpened: onRowGroupOpenedOrClosed,
      onRowGroupClosed: onRowGroupOpenedOrClosed,
    },
    resizeColumns,
  };
};