import history from 'browserHistory'
import { queryStringToObject, addToQueryString, omitFromQueryString } from 'shared/utils/url';

const open = (param: string) =>
  history.push({
    pathname: history.location.pathname,
    search: addToQueryString(history.location.search, { [`modal-${param}`]: true }),
  });

const close = (param: string) =>
  history.push({
    pathname: history.location.pathname,
    search: omitFromQueryString(history.location.search, [`modal-${param}`]),
  });

const isOpen = (param: string) => !!queryStringToObject(history.location.search)[`modal-${param}`];

export interface QueryParamModalHelpers {
  open: () => void;
  close: () => void;
  isOpen: () => boolean;
}

export const createQueryParamModalHelpers = (param: string): QueryParamModalHelpers => ({
  open: () => open(param),
  close: () => close(param),
  isOpen: () => isOpen(param),
});
