import React, { FunctionComponent } from 'react';
import {
  FormModal,
} from 'shared/components';
import { useNotification } from 'shared/components/notifications';
import { useGetDirectoryUser, useUpdateUser } from 'shared/hooks/api';
import { UpdateUserInput, ErrorFragment } from 'shared/hooks/api/graphql/generated';
import UserModalForm from './UserModal';
import { useAppContext } from 'App';
import { userFormSchema, UserFormValues } from './NewUserModal';
import { diff } from 'shared/utils/deepDiff';
import { PartialNullable } from 'shared/utils/utilityTypes';

interface EditUserModalProps {
  onClose: () => void;
  onSuccess: () => void;
  userId: number;
}

const EditUserModal: FunctionComponent<EditUserModalProps> = ({
  onClose,
  onSuccess,
  userId,
}) => {
  const notify = useNotification();
  const { currentUserId } = useAppContext();
  const [updateUser, { status }] = useUpdateUser(currentUserId, { throwOnError: true });
  const { data: userData, status: userDataStatus, refetch } = useGetDirectoryUser(userId);

  const initialValues: PartialNullable<UserFormValues> = {
    firstName: userData?.user?.firstName,
    lastName: userData?.user?.lastName,
    email: userData?.user?.email,
    title: userData?.user?.title,
    employeeRole: userData?.user?.role && { value: userData.user.role.id, label: userData.user.role.name },
    manager: userData?.user?.manager && { value: userData.user.manager.id, label: userData.user.manager.displayName },
    company: userData?.user?.company && { value: userData.user.company.id, label: userData.user.company.name },
    department: userData?.user?.department && { value: userData.user.department.id, label: userData.user.department.name },
  }

  return (
    <FormModal<UserFormValues>
      title={'Update User'}
      submitTitle={'Save'}
      initialValues={initialValues}
      handleSubmit={async (form, resetForm) => {
        const changes = diff(initialValues, form);
        const input: UpdateUserInput = {
          id: userId,
          firstName: changes.firstName,
          lastName: changes.lastName,
          email: changes.email,
          title: changes.title,
          employeeRoleId: changes.employeeRole && changes.employeeRole.value,
          managerId: changes.manager && changes.manager.value,
          companyId: changes.company && changes.company.value,
          departmentId: changes.department && changes.department.value,
        } 
        try {
          const result = await updateUser(input);
          
          if (result.data?.updateUser) {
            switch (result.data.updateUser?.__typename) {
              case "User": {
                notify({
                  duration: 3000,
                  title: 'Success!',
                  message: 'User Updated!',
                  variant: 'success',
                });
                resetForm();
                onClose()
                onSuccess();
                break;
              }
              case "UserAlreadyExists": {
                notify({
                  duration: 7000,
                  title: 'User already exists!',
                  variant: 'danger',
                  message: result.data.updateUser.message,
                });
                break;
              }
              default: {
                notify({
                  duration: 7000,
                  title: 'Failed to update user',
                  message: (result.data.updateUser as ErrorFragment).message,
                  variant: 'danger',
                });
                break;
              }
            }
          } else if (result.errors) {
            const forbiddenError = result.errors.find((x: any) => x?.extensions?.code == "FORBIDDEN")
            if (forbiddenError) {
              notify({
                duration: 5000,
                title: 'Permission Denied',
                variant: 'danger',
                message: forbiddenError?.message,
              });
            } else {
              throw Error();
            }
          } else {
            throw Error()
          }
        } catch {
          notify({
            duration: 5000,
            title: 'Yikes!',
            variant: 'danger',
            message: 'An unknown error occurred while updating the user!',
          });
        }
      }}
      isWorking={status == 'loading'}
      onClose={onClose}
      validationSchema={userFormSchema}
    >
      {() => (
        <UserModalForm />
      )}
    </FormModal>
  );
};

export default EditUserModal;
