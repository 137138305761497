import React, { FunctionComponent, useMemo } from 'react';
import {
  FormFieldWrapper,
  FormModal,
  SelectFormikField,
  TextareaFormikField,
} from 'shared/components';
import * as Yup from 'yup';
import { useNotification } from 'shared/components/notifications';
import {
  useCreatePeerRequest,
  useGetDirectory,
  useRequestEvaluationChanges,
} from 'shared/hooks/api';
import { CreatePeerRequestInput } from 'shared/hooks/api/graphql/generated';
import { useAppContext } from 'App';
import { sortBy } from 'shared/utils/sorting';

interface RequestChangesModalProps {
  onClose: () => void;
  onSuccess: () => void;
  evaluationId: number;
}

type RequestChangesFormValues = {
  requestedChanges: string;
};

export const requestChangesFormSchema: Yup.ObjectSchema<RequestChangesFormValues> = Yup.object()
  .shape({
    requestedChanges: Yup.string().required().label('Requested Changes'),
  })
  .required();

const RequestChangesModal: FunctionComponent<RequestChangesModalProps> = ({
  onClose,
  onSuccess,
  evaluationId,
}) => {
  const notify = useNotification();
  const [requestEvaluationChanges, { status }] = useRequestEvaluationChanges(
    evaluationId
  );

  return (
    <FormModal<RequestChangesFormValues>
      title={'Request Changes to Evaluation'}
      submitTitle={'Submit Request'}
      initialValues={{ requestedChanges: null }}
      handleSubmit={async (form, resetForm) => {
        try {
          const result = await requestEvaluationChanges({
            id: evaluationId,
            requestedChanges: form.requestedChanges,
          });

          if (result.data) {
            notify({
              duration: 3000,
              title: 'Success!',
              message: 'Evaluation changes requested!',
              variant: 'success',
            });
            resetForm();
            onClose();
            onSuccess();
          } else if (result.errors) {
            const forbiddenError = result.errors.find(
              (x: any) => x?.extensions?.code == 'FORBIDDEN'
            );
            const badRequestError = result.errors.find(
              (x: any) => x?.extensions?.code == 'BAD_REQUESTT'
            );
            if (forbiddenError) {
              notify({
                duration: 5000,
                title: 'Permission Denied',
                variant: 'danger',
                message: forbiddenError?.message,
              });
            } else if (badRequestError) {
              notify({
                duration: 5000,
                title: 'Bad Request',
                variant: 'danger',
                message: badRequestError?.message,
              });
            } else {
              throw Error();
            }
          } else {
            throw Error();
          }
        } catch {
          notify({
            duration: 5000,
            title: 'Yikes!',
            variant: 'danger',
            message:
              'An unknown error occurred while requesting the evaluation changes!',
          });
        }
      }}
      isWorking={status == 'loading'}
      onClose={onClose}
      validationSchema={requestChangesFormSchema}
    >
      {() => (
        <React.Fragment>
          <FormFieldWrapper>
            <TextareaFormikField
              label={'Requested Changes'}
              name={'requestedChanges'}
              placeholder={'Enter the requested changes here...'}
            />
          </FormFieldWrapper>
        </React.Fragment>
      )}
    </FormModal>
  );
};

export default RequestChangesModal;
